import * as React from 'react';
import LogoJpgImg from '../../../assets/images/logo.jpg';
import LogoImg from '../../../assets/images/logo.png';
import DarkLogoImg from '../../../assets/images/dark_logo.png';
import { NavLink } from 'react-router-dom';
import styled, { StyledComponent } from 'styled-components';
export interface LogoProps {
    linkUrl?: string;
    disableLinking?: boolean;
}
const SDarkLogoBackground = styled.div`
    height: 100px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    margin-top: -15px;
    padding-top: 15px;
`;

const SDarkLogo = styled.div`
  height: 70px;
  background: url(${DarkLogoImg});
  background-size: contain;
  background-repeat: no-repeat;
  margin: 5px 16px 16px 16px;
`;

const SLogoBackground = styled.div`
     height: 100px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    margin-top: -15px;
    padding-top: 15px;
`;

const SLogo = styled.div`
  height: 85px;
  background: url(${LogoImg});
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0px 16px 16px 16px;
`;

export default class Logo extends React.PureComponent<LogoProps, any> {
    public render() {
        return (
            <SLogoBackground>
                <NavLink to={this.props.linkUrl || '/'} >
                    <SLogo {...this.props}>
                        {!this.props.disableLinking && <NavLink to={this.props.linkUrl || '/'} />}
                    </SLogo>
                </NavLink>
            </SLogoBackground>
        );
    }
}

export class DarkLogo extends React.PureComponent<LogoProps, any> {
    public render() {
        return (
            <SDarkLogoBackground>

                <NavLink to={this.props.linkUrl || '/'} >
                    <SDarkLogo {...this.props}>

                    </SDarkLogo>
                </NavLink>
            </SDarkLogoBackground>
        );
    }
}
















