import * as React from 'react';
import { Layout, Row, Col } from 'antd';
import { AppRoutes } from '../../../Core/AppRoutes';
import TopBar from './TopBar';
import ILayout from '../../../Core/models/ILayout';
import { FooterLayout } from '../FooterLayout';
const { Content, Footer } = Layout;

export interface Props extends ILayout {
    children?: React.ReactNode;
}

export interface State { }

export default class ClassicLayout extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Layout className="react-transition fade-in" style={{ animationDuration: '750ms', minHeight: '100vh' }}>
                <TopBar menu={AppRoutes} />
                <Content style={{ minHeight: '50vh', height: '100%', marginBottom: '70px' }}>
                    <Row type="flex" justify="center">
                        <Col xs={23}>
                            <Row type="flex" align="middle" justify="center" style={{paddingTop:'50px'}}>
                                <Col xs={24}>{this.props.children}</Col>
                            </Row>
                        </Col>
                    </Row>
                </Content>
                <FooterLayout />
            </Layout>
        );
    }
}










