import Tag, { TagProps } from 'antd/lib/tag';
import React from 'react';

interface Props extends TagProps {
  value?: any;
  options: { value: any, color: string }[]
}

export default ({ value, options, ...rest }: Props) => {

  const color = options?.find(x => x.value === value)?.color ?? undefined;
  return <Tag {...rest} children={value} color={color} />;
}