import * as React from 'react';
import notification, { IconType } from 'antd/lib/notification';
import hash from 'object-hash';
import { ReactNode } from 'react';
import { Notice } from '../models/Notice';
import { message } from 'antd';

const MAX_NOTICE_TYPE_COUNT = 2;

export default class NotificationService {
  public static info(
    header: ReactNode,
    body?: ReactNode,
    duration?: number,
    shouldPersist?: boolean
  ) {
    NotificationService.Show('info', header, body, duration, shouldPersist);
  }
  public static success(
    header: ReactNode,
    body?: ReactNode,
    duration?: number,
    shouldPersist?: boolean
  ) {
    NotificationService.Show('success', header, body, duration, shouldPersist);
  }
  public static error(
    header: ReactNode,
    body?: ReactNode,
    duration?: number,
    shouldPersist?: boolean
  ) {
    NotificationService.Show('error', header, body, duration, shouldPersist);
  }
  public static warn(
    header: ReactNode,
    body?: ReactNode,
    duration?: number,
    shouldPersist?: boolean
  ) {
    NotificationService.Show('warning', header, body, duration, shouldPersist);
  }
  private static notices: Notice[] = [];

  /**
   * @summary This method regulates which message are on the screen at any point in time
   * Because we do not want to have to many messages on the screen. Right now it allows for only
   * two messages of the same kind.
   *
   * @param type
   * @param header the main part of the message, this is required.
   * @param body
   * @param duration
   * @param shouldPersist
   */
  static Show(
    type: IconType,
    header: ReactNode,
    body: ReactNode,
    duration: number | undefined,
    shouldPersist: boolean | undefined
  ) {
    const key = this.createMessageKey(type, header, body);

    const notices = this.notices;

    const sameType = notices.filter(x => x.type === type);

    if (sameType.length > MAX_NOTICE_TYPE_COUNT - 1) {
      const simillarNotice = sameType.filter(x => x.key === key);

      if (simillarNotice.length) {
        // Remove the old notice here, and the new will be added back
        NotificationService.removeNotice(simillarNotice[0], notices);
      } else {
        // More than notice of type is shown
        NotificationService.removeOlderNotice(sameType, notices);
      }
    }

    notices.push({ type, key, header, body, shouldPersist });
    notification[type]({
      key,
      message: header,
      description: body,
      duration: shouldPersist ? 0 : duration,
    });
  }

  public static CloseNonPersistentNotices() {
    const notices = this.notices;
    notices.filter(x => !x.shouldPersist).forEach(x => this.removeNotice(x, notices));
  }

  private static createMessageKey = (type: IconType, header: ReactNode, body: ReactNode) =>
    hash((type as string) + header + body);

  private static removeOlderNotice(sameType: Notice[], notices: Notice[]) {
    let oldestOrNonPersistentNotice: Notice | undefined = sameType[0];
    if (sameType[0].shouldPersist) {
      oldestOrNonPersistentNotice = sameType[1].shouldPersist ? sameType[0] : sameType[1];
    }
    NotificationService.removeNotice(oldestOrNonPersistentNotice, notices);
  }

  private static removeNotice(notice: Notice, notices: Notice[]) {
    notification.close(notice.key);
    notices.splice(notices.indexOf(notice), 1);
  }

  private static loadingCount = 0;
  private static hider: any;

  public static showLoader() {
    this.loadingCount++;

    if (this.loadingCount && !this.hider) {
      this.hider = message.loading('', 0);
    }
  }

  public static hideLoader() {
    this.loadingCount--;

    if (this.loadingCount <= 0 && this.hider) {
      this.hider();
      this.hider = undefined;
    }
  }

  public static resetHider() {
    this.loadingCount = 0;
    if (this.hider) {
      this.hider();
    }
  }
}
















