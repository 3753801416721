import { IFilter, IOrderBy } from '../../../Api/Api';
/**
 * This will keep track of all filters, sorting and paging information
 * to be sent to the server
 * @export
 * @class TableRequest
 */
export default class TableRequest {
  pageNumber?: number | undefined;
  amountPerPage?: number | undefined;
  filters?: IFilter[] | undefined;
  orderBy?: IOrderBy[] | undefined;
}
















