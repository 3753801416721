import { Col, Button, Row, Form, Modal } from 'antd';
import FilterSelectbox from 'components/Forms/DataGrid/FilterSelectbox';
import FilterTextbox from 'components/Forms/DataGrid/FilterTextbox';
import TableFilterContextProvider from 'components/Forms/DataGrid/TableFilterContextProvider';
import InfoIcon from 'components/Forms/InfoIcon';
import ShowIfTrue from 'components/Forms/ShowIfTrue';
import ISelectOption from 'components/Forms/SimpleSelect/ISelectOption';
import { RightAlignedRow } from 'components/Styled/Layout/RightAlignedRow';
import { JoinType, ProjectStatus } from 'Core/Api/Api';
import DetailsData from 'Core/models/DetailsData';
import DetailsMode from 'Core/models/DetailsMode';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { CompanyProjectsHandler } from '../CompanyProjectsHandler';
import { CompanyProjectsListGrid } from './CompanyProjectsListGrid';
import { CreateProjectGuidelineDetails } from '../CreateProjectGuidelineDetails';

interface Props {
  isEditMode?: boolean;
}

export default ({ isEditMode }: Props) => {
  const history = useHistory();
  const [projectStatus, setProjectStatus] = React.useState<ISelectOption[] | undefined>();
  let companyProjectsHandler = new CompanyProjectsHandler({});
  const [showGuidelineModal, setShowGuidelineModal] = useState(false);

  useEffect(() => {
    getProjectStatus();
  }, []);

  const getProjectStatus = () => {
    //const remapStatus = {[ProjectStatus.Finalized]: ,[ProjectStatus.FirstApproved]:ProjectStatus.subm , ProjectStatus.]

  }

  const createProject = (event: SyntheticEvent) => {
    event.preventDefault();
    const newDetailsData = new DetailsData();
    newDetailsData.mode = DetailsMode.New;
    history.push('projects/new', newDetailsData);
  }

  return <TableFilterContextProvider>
    <ShowIfTrue condition={isEditMode}>
      <RightAlignedRow type="flex" justify='center'>
        <Col md={21} style={{ paddingTop: '20px' }}>
          <Button size="large" type="primary" onClick={() => setShowGuidelineModal(true)} >
            <b>Create Project</b>
          </Button>
        </Col>
      </RightAlignedRow>
    </ShowIfTrue>
    <Row>
      <Col md={24}>
        <Row type="flex" justify='center'>
          <Col span={6}>
            <Form.Item label="" labelAlign="left">
              <FilterTextbox
                style={{ minWidth: "200px" }}
                filterFields="name,overview,description"
                filterJoin={JoinType.Or}
                placeholder={"Search title or keyword"}
                suffix={<InfoIcon text="Search for article" />}
              />
            </Form.Item>
          </Col>
          {/* <Col span={6}>
            <Form.Item label="" labelAlign="left">
              <FilterSelectbox
                filterFields="status"
                options={projectStatus}
                filterJoin={JoinType.Or}
                placeholder={"Select A Status"}
              />
            </Form.Item>
          </Col> */}
        </Row>
      </Col>
    </Row>
    <Row type="flex" justify='center'>
      <Col md={23}>
        <CompanyProjectsListGrid isEditMode={isEditMode} />
      </Col>
    </Row>

    <Modal width={"50%"} destroyOnClose={true} visible={showGuidelineModal} onCancel={e => setShowGuidelineModal(false)} onOk={createProject}>
      <CreateProjectGuidelineDetails/>
    </Modal>

  </TableFilterContextProvider>;
}