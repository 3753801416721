import DetailsMode from '../DetailsMode';

export default class DetailsData {
  constructor(mode?: DetailsMode, id?: string, altId?: string) {
    this.mode = mode ?? DetailsMode.New;
      this.id = id;
      this.alternateId = altId;
  }
  mode: DetailsMode;
    id?: string;
    alternateId?: string;
}













