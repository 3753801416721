import { Row, Col, Input } from 'antd';
import { GetFieldDecoratorOptions } from 'antd/lib/form/Form';
import FormControl from 'components/Forms/FormControl';
import ShowIfTrue from 'components/Forms/ShowIfTrue';
import SimpleSelect from 'components/Forms/SimpleSelect';
import ISelectOption from 'components/Forms/SimpleSelect/ISelectOption';
import ValidationUtility from 'Core/ValidationUtility';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Wrap = styled.div``;

export interface Country {
  country: string;
  states: string[];
}

export interface CountryList {
  countries: Country[];
}


interface Props {
  getFieldDecorator<T extends Object = {}>(id: keyof T, options?: GetFieldDecoratorOptions): (node: React.ReactNode) => React.ReactNode;
}

export default ({ getFieldDecorator }: Props) => {

  const [countries, setCountries] = useState<ISelectOption[] | undefined>(undefined);
  const [fullCountryData, setFullCountryData] = useState<CountryList | undefined>();
  const [states, setStates] = useState<ISelectOption[] | undefined>(undefined);
  useEffect(() => {
    import("./countryStates.json").then(x => {
      setFullCountryData(x);
      const countries: ISelectOption[] = x.countries.map(country => ({ text: country.country, value: country.country }));
      setCountries(countries);
    });
  }, []);

  const onCountryChanged = (value) => {
    const currentCountry = fullCountryData?.countries.filter(x => x.country == value)?.[0];

    if (currentCountry && currentCountry.states?.length) {
      setStates(currentCountry.states.map(x => ({ text: x, value: x })));
    } else {
      setStates(undefined);
    }
  }

  return (<Row>

    <Col>
      <Row>
        <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11, offset: 1 }}>

          <FormControl
            getFieldDecorator={getFieldDecorator}
            rules={[...ValidationUtility.required('Line 1 is required')]}
            name="address.line1"
            label="Line 1"
          >
            <Input />
          </FormControl>
        </Col>

      </Row>
      <Row>
        <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11, offset: 1 }}>

          <FormControl
            getFieldDecorator={getFieldDecorator}
            name="address.line2"
            label="Line 2"
          >
            <Input />
          </FormControl>
        </Col>

      </Row>
      <Row>
        <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11, offset: 1 }}>

          <FormControl
            getFieldDecorator={getFieldDecorator}
            rules={[...ValidationUtility.required('City is required')]}
            name="address.city"
            label="City"
          >
            <Input />
          </FormControl>
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11, offset: 1 }}>

          <FormControl
            getFieldDecorator={getFieldDecorator}
            rules={[...ValidationUtility.required('Country is required')]}
            name="address.country"
            label="Country"
          >
            <SimpleSelect options={countries} onChange={onCountryChanged} />
          </FormControl>
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11, offset: 1 }}>

          <ShowIfTrue condition={states}>
            <FormControl
              getFieldDecorator={getFieldDecorator}
              rules={[...ValidationUtility.required('State / Parish is required')]}
              name="address.stateParish"
              label="Parish / Province / State"
            >
              <SimpleSelect options={states} />
            </FormControl>
          </ShowIfTrue>
          <ShowIfTrue condition={!states}>
            <FormControl
              getFieldDecorator={getFieldDecorator}
              rules={[...ValidationUtility.required('State / Parish is required')]}
              name="address.stateParish"
              label="Parish / Province / State"
            >
              <Input />
            </FormControl>
          </ShowIfTrue>
        </Col>
        <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11, offset: 1 }}>

          <FormControl
            getFieldDecorator={getFieldDecorator}
            name="address.postalCode"
            label="Postal Code"
          >
            <Input />
          </FormControl>
        </Col>
      </Row>
    </Col>
  </Row>);
}