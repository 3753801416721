import { Col, Row } from "antd";
import { RowProps } from "antd/lib/row";
import { ProjectStatus } from "Core/Api/Api";
import styled from "styled-components";


export const ProjectCard = styled(Col) <{ status?: ProjectStatus, isEditMode?: boolean }>`
    
        //width: 400px;
        //height: 460px;
        display: block;
        line-height: 20px;
        padding-bottom: 3vh;
        width: 400px;
        max-width: 91vw;
        padding-right: 1vw;
        
        .projectCard{
            background-color: ${(props) => {
        if (!props.isEditMode) return "#fff";
        switch (props.status) {
            case ProjectStatus.Draft:
                return "#f2f2f2";
            case ProjectStatus.Submitted:
            case ProjectStatus.FirstApproved:
                return "#f4f4ed";
            case ProjectStatus.Rejected:
                return "#f7cccc";
            case ProjectStatus.Finalized:
                return "#def3de";
            default:
                return "#fff";
        }
    }};
            width: 100%;
            //height: 440px;
            //max-width: 80vw;
            padding: 0px;
        
            .ant-card-body{
                padding: 16px;
            }
        
        .ant-avatar{
            width: 100%;
            height: 246px;
            max-width: 80vw;
        }
        
        .outerProject{
            padding-right: 1.5vw;
            padding-left: 1.5vw;
        }

        .projectTitle{
            padding-top: 10px;
            font-family: HelveticaNeueLTStd-57;
            font-size: calc(0.6vw + 50%);
            font-weight: 600;
            font-stretch: condensed;
            text-align: left;
            color: #1c1919;
            height: 16px;
        }

        .projectSubtitle{
            padding-top: 10px;
            font-size: 12px;
            font-style: italic;
            text-align: left;
            color: #1c1919;
            line-height: 0px;
            font-weight: normal;
        }

        .green{
            color: #7bc257;
        }
        .goal{
            display: inline-flex;
            font-size: 14px;
            height: 2px;
        }

        .projectButtons{
            padding-right: 5px;
            padding-top: 2vh;
        }

        .success{
            background-color: #269b48;
            display: inline-block;
            color: #ffff;
        }
        
        .view{
            width: 88px;
            height: 32px;
            background-color: #00aed8;
            display: inline-block;
            color: #ffff;
        }
        .donate{
            width: 102px;
            height: 32px;
            background-color: #e36e19;
            display: inline-block;
            color: #ffff;
        }
        .makeAPledge{
            width: 148px;
            height: 32px;
            background-color: #269b48;
            display: inline-block;
            color: #ffff;
        }

        @media  (max-width: 500px) {
            .projectButtons{
                width: 100%;
                padding-right: 5px;
                padding-top: 2vh;
            }
            .view{
            width: 100%;
            height: 32px;
            background-color: #00aed8;
            display: inline-block;
            color: #ffff;
        }
        .donate{
            width: 100%;
            height: 32px;
            background-color: #e36e19;
            display: inline-block;
            color: #ffff;
        }
        .makeAPledge{
            width: 100%;
            height: 32px;
            background-color: #269b48;
            display: inline-block;
            color: #ffff;
        }
        }
    
    }

`;

