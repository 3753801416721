enum DetailsMode {
  Edit = 'Edit',
  New = 'New',
  View = 'View',
}
export default DetailsMode;













