import * as React from 'react';
import antd from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import styled from 'styled-components';
import FormControl from '../../Forms/FormControl';

const { Form } = antd;

export const StyleRegisterForm = styled(Form)`
  max-width: 90%;
  min-width: 40%;
  align-content: center;
  display: contents;

  .login-form-forgot {
    float: right;
  }

  .login-form-button {
    background-color: rgb(174, 24, 87);
    float: right;
  }

  .cancel-form-button {    
    float: right;
    color: #00AED8;
    border-color: #00AED8;
  }

  .register-form-button {
    background-color: #00AED8;
    border-color: #00AED8;
    
  }

  .cancel-form-button {
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child, .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
    background-color: #99EBFF;
    color: black;
    border: 2px #00AED8 solid;
  }


`;










