import { Button, Col, Row } from 'antd';
import * as React from 'react';
import { NavLink, RouteProps } from 'react-router-dom';
import styled from 'styled-components';
import { ProjectsListView } from 'components/ProjectsListView';
import { Redirect } from 'react-router';
import { BannerDiv, CenteredFormContent } from 'components/Layout/LoginLayout/LoginLayout.Style';
import ProjectList from '../../components/ProjectList';
import DonateForm from '../../components/ProjectList/Donate';

export interface Props extends RouteProps {
  children?: React.ReactNode;
}

export interface State {
  isConfirmed: boolean;
}

const TallCol = styled(Col)`
  height: 100%;
  min-height: 200px;
`;

export default class DonatePage extends React.Component<Props, State> {
  
  constructor(props: Props) {
    super(props);
   

    this.state = { isConfirmed: false };
  }

  componentDidMount() {
  
  }

  render() {
      return <CenteredFormContent>
          <BannerDiv className='donate'>
              <Row style={{ height: '100%' }}>
                  <Col sm={24} className={'bannerNav'}>
                      {/* <Col className={'backDiv'} sm={8}>
                          <NavLink to={{
                              pathname: '/welcome' + encodeURI(''),
                          }}><a className="backToHome" target="">Back to Home</a></NavLink>
                      </Col> */}
                  </Col>
              </Row>
          </BannerDiv>
          <Row type="flex" align="middle" justify="center">
              <Col span={22} offset={1}>
                  <DonateForm {...this.props}/>
              </Col>
          </Row>

      </CenteredFormContent>;
  }
}










