import { Button, Col, Row } from 'antd';
import * as React from 'react';
import { RouteProps } from 'react-router-dom';
import styled from 'styled-components';
import { ContactUsForm } from 'components/Information/ContactUsForm';
import { Redirect } from 'react-router';
import { BannerDiv, CenteredFormContent } from '../../../components/Layout/LoginLayout/LoginLayout.Style';

export interface Props extends RouteProps {
  children?: React.ReactNode;
}

export interface State {
  isConfirmed: boolean;
}

const TallCol = styled(Col)`
  height: 100%;
  min-height: 200px;
`;

export default class ContactUsPage extends React.Component<Props, State> {
  
  constructor(props: Props) {
    super(props);
   

    this.state = { isConfirmed: false };
  }

  componentDidMount() {
  
  }

  render() {
      return <CenteredFormContent>
          <BannerDiv className='contact-us'>
              <Row style={{ height: '100%' }}>
                  <Col sm={24} className={'bannerNav'}>
                  </Col>
              </Row>
          </BannerDiv>
          <Row type="flex" align="middle" justify="center">
              <Col style={{maxWidth:'750px' }}>
                  <ContactUsForm/>
              </Col>
          </Row>

      </CenteredFormContent>;
  }
}










