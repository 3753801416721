import React, { useEffect, useState } from 'react';
import * as jdenticon from 'jdenticon';
interface Props {
  id: string;
  size?: number;
}

export default ({ id, size }: Props) => {
  const [imgString, setImgString] = useState<string | undefined>(undefined);
  useEffect(() => {
    const image = jdenticon.toSvg(id, size ?? 100);
    const svg = new Blob([image], { type: "image/svg+xml" });
    const url = URL.createObjectURL(svg);
    setImgString(url);
  }, [id])
  return (<img src={`${imgString}`} />);
}