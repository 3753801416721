import { ReactNode, SyntheticEvent, useContext, useEffect, useState } from 'react';
import * as React from 'react';
import { Col, Divider, Form, Popconfirm, Row, Typography, Button } from 'antd';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import { FormComponentProps } from 'antd/lib/form';
import { NavLink, useHistory } from 'react-router-dom';
import { NewsHandler } from '../NewsHandler';
import { News } from 'Core/Api/Api';
import { useParams } from 'react-router';
import ShowIfTrue from 'components/Forms/ShowIfTrue';
import AuthContext from 'Context/AuthContext';
import Utility from 'Core/Utility';
import styled from 'styled-components';
import moment from 'moment';
import ConfigService from 'Core/ConfigService';
import { RichTextViewer } from 'components/Forms/RichTextViewer';

interface NewsArticleViewProps {
    children?: ReactNode;
}

const ArticleContainerGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr max-content;
  grid-template-rows: min-content min-content min-content 1fr;
  gap: 10px;
  column-gap: 20px;
  grid-template-areas: 
    "title title title title"
    "info info info info"
    "image image image ."
    "body body body body"
    "link link link link"
  ;

`;

const ArticleTitle = styled.div`
  grid-area:  title ;
`;

const ArticleInfo = styled.div`
  grid-area:  info ;

  display: flex;
  flex-direction:column;
  align-content: flex-start;
  gap: 10px;
`;

const ArticleImage = styled.div`
  grid-area: image;

 img {
  max-width: min(500px, 100%);
  height: auto;
 }
`;

const ArticleBody = styled.div`
  grid-area:  body ;

  
`;

const ArticleLink = styled.div`
  grid-area:  link ;

  
`;

const ArticleSide = styled.div`
  grid-area: side ;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction:column;
  align-content: flex-start;
  gap: 10px;
`;

const ArticleActions = styled.div`
  grid-area:  footer ;

`;

const Page = styled.div`
    img{
        max-width: 100%!important;
    }
    .articleHeader{
        height: 150px;
        background-color: #dedede;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
        margin-top: -70px;
        margin-bottom: 50px;
        
        .articleTitle{
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        
            h2{
                font-family: HelveticaNeueLTStd-57;
                font-weight: normal;
                font-stretch: condensed;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
            }
        }
    }

    @media (max-width: 500px){
        .articleHeader{
            height: 250px;
            background-color: #dedede;
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
            margin-top: -70px;    
        }
    }
    .articleBody{
        padding-top: 2rem;

        .articleLeft{
            padding-bottom: 1rem;
        }
        .articleRight{
            img{
                max-width: 300px;
            }
        }
    }
    .articleMainImage{
        height: 300px;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
        max-width: 100%;
        float: left;
        margin: 0 10px 10px 0;
    }
    .articleText {
        text-align: justify; 
    }
`;


export function NewsArticleView({ children }: NewsArticleViewProps) {

    let history = useHistory();
    let params = useParams<{ id?: string }>();
    let newsHandler = new NewsHandler({});
    let config = ConfigService.getSync();

    const [showPopover, setShowPopover] = useState(false);

    const [news, setNews] = useState<News | undefined>(undefined);

    useEffect(() => {
        if (params.id) {
            newsHandler.GetNewsById({ id: params.id }).then(response => {
                setNewsForm(response.news);
            }).catch(error => {
                history.push("NotFound");
            })
        }
    }, []);

    const setNewsForm = (news: News | undefined) => {
        setNews(news);
    }
    const goToArticle = (link, e?: SyntheticEvent) => {
        e?.preventDefault();
        window.open(link, "_blank");
        setShowPopover(false);
    }

    return (<>

        <Page>
            <Col span={24} className='articleHeader'>
                <div className='articleTitle'>
                    <h2>{news?.title}</h2>
                    <ShowIfTrue condition={!!news?.link}><Typography.Text type='secondary'>
                        <Popconfirm visible={showPopover} onCancel={(e) => setShowPopover(false)} title="You are about to leave the site" onConfirm={(e) => goToArticle(news?.link, e)}>
                            <a href="#" onClick={(e) => setShowPopover(true)}>Click here to go to the the article link</a>
                        </Popconfirm>
                    </Typography.Text></ShowIfTrue>
                </div>
            </Col>

            <Col className = 'articleContainer' xs={{ span: 22, offset: 1 }} md={{ span: 17, offset: 4 }}>
                <div className="articleContent">
                    <img className="articleMainImage" src={news?.mainImageLink} />
                    <div className="articleText" dangerouslySetInnerHTML={{ __html: news?.description?? " " }} />
                </div>
                <NavLink to={{
                    pathname: '/news'
                } }>
                    <Button>
                        Back to news
                    </Button>
                </NavLink>
            </Col>
        </Page >
    </>
    );
}

export default NewsArticleView;


