import * as React from 'react';
import { Button } from 'antd';
import styled, { StyledComponent } from 'styled-components';


export const BrandedButton = styled(Button)`
    border-color: #F0403D;
    background-color: #F0403D;
    color: white;
`;











