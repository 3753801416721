import * as React from 'react';
import { Layout, Row } from 'antd';
import styled from 'styled-components';
import ScreenHelper from '../../Styled/ScreenHelper';

const { Header, Content, Footer } = Layout;

export const AdminContent = styled.div`
  margin-bottom:100px;
`;










