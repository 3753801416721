import * as React from 'react';
import styled from 'styled-components';
import { Layout, Row } from 'antd';

export const LeftAlignedRow = styled(Row)`
  text-align: Left;
`;













