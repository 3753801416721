import { SecuredClient } from 'Core/Api/SecuredClient';

export default class LogoutHandler {
  props: any;
  constructor(props: any) {
    this.props = props;
  }

  RequesLogout(): Promise<any> {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    return client.logout();
  }
}
















