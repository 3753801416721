import { Button, Col, Row } from 'antd';
import * as React from 'react';
import { NavLink, RouteProps } from 'react-router-dom';
import styled from 'styled-components';
import News from 'components/News';
import { Redirect } from 'react-router';
import { NewsList } from 'components/News/NewsList';
import { CenteredFormContent, BannerDiv } from 'components/Layout/LoginLayout/LoginLayout.Style';

export interface Props extends RouteProps {
  children?: React.ReactNode;
}

export interface State {
  isConfirmed: boolean;
}

export default class NewsPage extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);


    this.state = { isConfirmed: false };
  }

  componentDidMount() {

  }

  render() {
    return (<CenteredFormContent>
      <BannerDiv className={"news"} />
      <Row type="flex" align="middle" justify="center">
        <Col >
          <NewsList />
        </Col>
      </Row>
    </CenteredFormContent>);
  }
}










