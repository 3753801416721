import * as React from 'react';
import { Layout, Row } from 'antd';
import styled from 'styled-components';
import BannerImg from '../../../assets/images/Banner.png';
import ScreenHelper from '../../Styled/ScreenHelper';
import logoSvg from 'assets/images/logo.png';
import loginBackground from 'assets/images/background_ologin.jpg';
import Title from 'antd/lib/typography/Title';
import Background1 from 'assets/images/background1.png';
import ad1 from 'assets/images/ad.png';
import lessonsLearntImage from 'assets/images/lessonslearnt.jpg';
import socialPartners from 'assets/images/socialPartners.png';
import CSRbackground from 'assets/images/CSR.png';
import LeftArrow from 'assets/images/LeftArrow.png';
import RightArrow from 'assets/images/RightArrow.png';

const { Header, Content, Footer } = Layout;

export const Page = styled.div`
    .sectionTitle{
        font-stretch: condensed;
        font-style: normal;
        letter-spacing: normal;
    }   
     
     .container .box {
        display : flex;
        flex-direction : row;
    }


    .arrows{
        position: absolute;
        top: 50%;
        display: block;
        margin-top: -10px;
        padding: 0;
        font-size: 0;
        line-height: 0;
        border: 0;
        outline: none;
        cursor: pointer;
        z-index: 10021;
        //box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
        width: 58px;
        height: 58px;
        border-radius: 5%;
        background-position: center;
        background-size: 100%;
        background-color: transparent;
    }
        .news-arrows{
            top: 38%;
            opacity: 0.7;

            :hover, :focus{
                opacity: 1;

            }
            
            &.next{
                right: 13px;
            }
            &.prev{
                left: 7px;
            }
        }
        
        .next{
            right: 10px;
            background-image: url(${RightArrow});
        }

        .prev{
            left: 2px;
            background-image: url(${LeftArrow});
        }
        

    .ant-carousel .slick-prev, .ant-carousel .slick-next {
        z-index: 10021;
        background: rgba(255, 255, 255, 0.5);
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        width: 30px;
        height: 30px;
        border-radius: 50%;
        top: 90%;
    }
    .slick-next{
        right: 250px;
        //background-image: url(${RightArrow});
        background-repeat: round;
    }
    .slick-prev{
        left: 250px;
        //background-image: url(${LeftArrow});
        background-repeat: round;
    }

    .viewAll{
        background-color: #00aed8;
        /*left 46.5%;*/
       /* margin-top: -2vh;*/
        a{
            color: #ffff;
        }
    }
    .socialPartners{
        text-align: center;
        padding-top: 56px;
    }
    .partnersContainer{
        padding-left: 2vw;
        height: 81px;
        max-width:96%;
    }
    .partners{
        //height: 81px;
        //-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        //filter: grayscale(100%);
        max-width: 96vw;
    }
    .socialLogos {
      max-height: 150px;
      height: 100px;
      margin-left: 20px;
    }
    .logoContainer{
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    }
    
    .scrollable-div {
      position: relative;
      overflow: auto;
    }

    .scrollable-div::before,
    .scrollable-div::after {
      content: "";
      position: absolute;
      top: 0;
      width: 30px;
      height: 30px;
      background: rgba(255, 255, 255, 0.5);
      border-radius: 50%;
      pointer-events: none;
    }

    .scrollable-div::before {
      left: 10px;
    }

    .scrollable-div::after {
      right: 10px;
    }

    .scrollable-div::before,
    .scrollable-div::after {
      display: none;
    }

    .scrollable-div::before {
      transform: rotate(45deg);
    }

    .scrollable-div::after {
      transform: rotate(-45deg);
    }

    .defaultModals{
        @media only screen and (min-width: 992px) {

        }
    }

`;

export const BannerDiv = styled.div`
    height: 100%;
    
    background-size: contain;
    background-position: center top;
    background-repeat: no-repeat;
    padding-bottom: 2vh;

@media only screen and (min-width: 992px) {
    height: 100%;
    /* width: 80%; */
    //margin-left: 160px;
    //margin-right: 160px;
}
    /*margin-bottom: 50px;*/

  /*width: 21em;*/
`;

export const DashboardTitle = styled.span`
    .dashboard-logo{
        width: 100%;

@media only screen and (max-width: 992px) {
    margin-bottom: 17.5em !important;
}
    }

 
`;

export const NewsClasses = styled.div`
    max-width:100vw;
    .center-avatar {
        text-align: center;

        @media only screen and (min-width: 574px) {
            padding-right: 24px;
        }
    }

    .offering-status {
        width: 98px;
        height: 30px;
        border-radius: 70px;

        &.Closed {
            border: solid 1px #e28080;
            text-align: center;
            background-color: #fcf2f2;
            font-weight: bold;
            color: #c40002;
            line-height: 1.71;
        }

        &.Opened {
            border: solid 1px #78c487;
            background-color: #f1f9f2;
            text-align: center;
            color: #128710;
            font-weight: bold;
            line-height: 1.71;        
        }

        &.PreOpen {
            border: solid 1px #80bae2;
            background-color: #f2f8fc;
            text-align: center;
            color: #1174c4;
            font-weight: bold;
            line-height: 1.71;        
        }


        &.Suspended {
            border: solid 1px #d7da17;
            background-color: #fcffcb;
            text-align: center;
            color: #c2c500;
            font-weight: bold;
            line-height: 1.71;
        }
    }
    

    .newsItems{
        max-height: 164.25px;
        overflow: hidden;
        max-width: 100vw;
        padding-left: 1vw;
        background-color: #ffff;
        
    }

    .innerNewsItems{
        -ms-overflow-style: none;
        width: 100%;
        height: 170px;
        overflow-x: scroll;
        box-sizing: content-box;
        display: inline-flex;
        -ms-overflow-style: none;
        scrollbar-width: none;

        ::-webkit-scrollbar {
            display:none;
        }
    }


    .cardItems{
        max-height: 130px;
        max-width: 23em;
        min-width: 23em;
        width: 23em;
        background-color: #ffff;
        padding: 8px;
        margin-bottom: 17px;
        border-radius: 3%;
        
    }
    .item{
        width: 100%;
        /*height: 12.46vh;*/
        background-color: #ffff;
        /*padding-bottom: 14vh;*/
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    }
    .cardImage{
        /*width: 6.68vw;*/
        height: 8.45vh;
    }

    .cardInformation{
        font-size: 14px;
        max-height: 60px;
        overflow: hidden;
        padding-bottom: 70px;
        
        .info{
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .cardDate{
        line-height: 24px;
        font-size: 12px;
        color: #272364;
    }

    .readMore{
        width: 64px;
        height: 24px;
        background-color: #1d95d3;
        font-size: 11px;
        padding: 0px;
        color: #ffff;
        position: absolute;
        right: -1.5vw;
    }
    .cardRight{
        padding: 0px!important;
    }
    .buttonBox{
        position: relative;
    }
    


@media only screen and (max-width: 574px) {
    .xs-no-padding {
        padding-left: 10px!important;
        padding-right: 10px!important;
    }

}

padding-bottom:3vh;
    
`;



export const WhoWeAreClasses = styled.div`
    .background-image {
        height: 100%; 
        background-image: url(${Background1});
        padding-left: 6.5vw;
        padding-right: 6.5vw;
        padding-top: 4.5vh;
    }
    .csr-Logo{

        @media only screen and (min-width: 574px) {
            padding-left: 56px;
            padding-right: 102px;
        }
    }
   
     

    //!small
    @media only screen and (min-width: 577px){
        .whoWeAre, .CSR, .jSEAgility{
            height: 400px;
        }

        .whoWeAre{
            .textDiv, .who-we-are-text, .imageDiv{
                height: 100%;
            }
            .imageDiv img{
                max-height: 100%;
                width: 100%;
            }
            .who-we-are-text{
                max-width: 568px;
                padding-bottom: 3vh;
            }
        }
    
        .csr-image {
            /*height:375px;*/
            background-image: url(${CSRbackground});
            padding: 30px;
        }
        .background-tint {
            background-color: #7bc257;
            background-blend-mode: multiply;
        }
        .CSR{
            .csr-text{
                max-width: 704px;
                color: #fff;
            }
        }
        .jSEAgility{
            .agility-Logo img{
                max-height: 100%;
                max-width: 75%;
                border-radius: 15px;
                padding-left: 102px;
                padding-top: 40px;
            }
            .agility{
                padding-left: 6.5vw;
                padding-right: 6.5vw;
                padding-top: 4.5vh;
            }  
        }
    }
    
    //small
    @media only screen and (max-width: 576px){
        .whoWeAre{
            .textDiv, .who-we-are-text, .imageDiv{
                height: 100%;
            }
            .imageDiv img{
                max-height: 100%;
                width: 100%;
            }
            .who-we-are-text{
                max-width: 568px;
                padding-bottom: 3vh;
            }
        }

        .csr-image {
            /*height:375px;*/
            background-image: url(${CSRbackground});
            padding: 30px;
        }
        .background-tint {
            background-color: #7bc257;
            background-blend-mode: multiply;
        }
        .CSR{
            .csr-text{
                max-width: 704px;
                color: #fff;
            }
        }
        .jSEAgility{
            .agility-Logo img{
                max-height: 100%;
                max-width: 75%;
                border-radius: 15px;
                padding-left: 102px;
                padding-top: 40px;
            }
            .agility{
                padding-left: 6.5vw;
                padding-right: 6.5vw;
                padding-top: 4.5vh;
            }  
        }
    }

`;

export const ProjectClasses = styled.div`

padding-left: 0.5vw;
padding-right: 2.5vw;
padding-top: 36px;
padding-bottom:3vh;
max-width: 100vw;
height: fit-content;
overflow: scroll;
-ms-overflow-style: none;
scrollbar-width: none;
::-webkit-scrollbar {
    display:none;
}

.projects{
    padding-left: 3vw;
    padding-right: 2.25vw;
}

.ant-avatar{
    width: 270px;
    height: 230px;
    
    img{
        object-fit: contain;
    }
}


.innerProject{
        -ms-overflow-style: none;
        width: 100%;
        overflow-x: scroll;
        box-sizing: content-box;
        display: inline-flex;
        -ms-overflow-style: none;
        scrollbar-width: none;
::-webkit-scrollbar {
            display:none;
        }
}

.projectItems{
    padding-right: 5vw;
    max-width: 23em;
        min-width: 23em;
}

.ant-card-body{
    padding-top: 15px;
}

.projectItem{
    width: 320px;
    height: 100%;
    background-image: linear-gradient(135deg, #b8baba,#e3e5e6, #f0f1f2, #b8baba);
    border-radius: 3%;
}

.projectTrackerBody{
    height: 310px;
    //background-color: #dfebef!important;
    width: fit-content;
    padding: 20px;
    /* padding-top: 5px;
    padding-bottom: 5px; */
    border-bottom-right-radius: 3%;    
    border-bottom-left-radius: 3%;  
    border: 5px #269b48 solid;  

    .title{
        font-family: HelveticaNeueLTStd-57;
        font-size: clamp(1rem,1.1rem,5.5rem);
        font-weight: normal;
        font-stretch: condensed;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #000;
        text-align: left;
    }

    .icon{

    }
    

    .hr{
        background-color: #000;
        height: 3px;
        border: none;
    }
}

.projectTitle{
    padding-top: 1vh;
    //line-height: 0.2vh;
    text-overflow: ellipsis;
    text-wrap: nowrap;
}

.projectSubtitle{
    font-size: 12px;
    line-height: 0.2vh;
    font-style: italic;
}

.projectFooter{
    color: #000;
}

.green{
    color: #269b48;
}

.goal{
    font-size: 14px;
}

.viewAllCauses{
    width: 152px;
    height: 33px;
    background-color: #00aed8;
    position: absolute;
    right: 10px;
    a{
        color: #ffff!important;
    }
}

    

     //!small
    @media only screen and (min-width: 1201px){
        .amount{
            //font-family: HelveticaLTStd-Compressed;
            /* font-size: clamp(0.25rem,1.11rem,4rem); */
            font-size: 1.875em ;
            font-weight: bold;
            font-stretch: condensed;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: center;
            color: #000;
            padding-left: 2px;
            padding-top: 2px;
            /* white-space: nowrap; */
        }
        .projectTrackerHeader{
            background-color: #269b48;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            height: 6rem;
        }
        .projectTrackerBody{
            width: 100%;
            background-color: #fff;
            //background-image: linear-gradient(to bottom,  rgba(32, 29, 82, 0.31) 10%, #1F1F1F 89%);

        }
        .donationRow{
            padding-bottom: 0.75rem;
            margin-bottom: 0.75rem;
            //border-bottom: solid 3.5px #00aed8;
        }
        .projectsRow{
            padding-bottom: 0.75rem;
            margin-bottom: 0.75rem;
            //border-bottom: solid 3.5px #00aed8;
        }
        .fundedRow{
            padding-bottom: 0.75rem;
            margin-bottom: 0.75rem;
            //border-bottom: solid 3.5px #00aed8;
        }
        .details{
            /* padding-top: 1rem; */
        }
        .number{
            //font-family: HelveticaLTStd-Compressed;
            /* font-size: clamp(0.25rem, 1.4rem, 4rem); */
            font-size:1.875em;
            font-weight: bolder;
            text-align: center;
            color: #269b48;
        }
    }

     //small
    @media only screen and (max-width: 1200px){
        .amount{
            //font-family: HelveticaLTStd-Compressed;
            /* font-size: clamp(1rem,2rem,5rem); */
            font-size: 1.875em;
            font-weight: bold;
            font-stretch: condensed;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: center;
            color: #000;
            padding-left: 2px;
            padding-top: 2px;
            /* white-space: nowrap; */
        }
        .projectTrackerHeader{
            background-color: #269b48;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            height: 6rem;
        }
        .projectTrackerBody{
            width: 100%;
            background-color: #fff;
            height: fit-content;
            //background-image: linear-gradient(to bottom,  rgba(32, 29, 82, 0.31) 10%, #1F1F1F 89%);
        }
        .donationRow{
            padding-bottom: 0.75rem;
            margin-bottom: 0.75rem;
            //border-bottom: solid 3.5px #00aed8;
        }
        .projectsRow{
            padding-bottom: 0.75rem;
            margin-bottom: 0.75rem;
            //border-bottom: solid 3.5px #00aed8;
        }
        .fundedRow{
            padding-bottom: 0.75rem;
            margin-bottom: 0.75rem;
            //border-bottom: solid 3.5px #00aed8;
        }
        .details{
            /* padding-top: 1rem; */
        }
        .number{
            //font-family: HelveticaLTStd-Compressed;
            /* font-size: clamp(1rem, 2rem, 5rem); */
            font-size: 1.875em;
            font-weight: bolder;
            text-align: center;
            color: #269b48;
        }
    }



`;

export const EndorsementClasses = styled.div`
    padding-top: 82px;
    padding-bottom: 66px;
    max-width:100vw;
    

    .endorsementText{
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        ::-webkit-scrollbar {
            display:none;
        }
    }
    
    .readMoreButton{
        background-color: #00aed8;
        color: #FAFAFA;
        
    }
    .readMoreButton:hover{
        background-color: #0090b3;
        color: #FAFAFA;
        border-color: #007a97;
    }

    //!small
    @media only screen and (min-width: 577px){
        .endorsements{
            background-color: #033d5a;
            padding-top: 30px;
            padding-left: 2rem;
            //padding-right: 76px;
            //padding-bottom: 66px;
            height: 38.4rem;
            max-width: 100%;
        }
        .endorsementItems{
            max-height: 32.4rem;
            display: block;
        }
        .ant-carousel{
            height: 31.4rem;
        }
        .slick-slider{
            height: 31.4rem;
        }
        .ad, .ad img{
            height: 100%;
        }
        .endorsementRow{
            height: 614px;
        }
    }
    //small
    @media only screen and (max-width: 576px){
        .endorsements {
            height: 50rem;
            max-height: 50rem;
            max-width: 100%;
            background-color: #1e1e1e;
        }
        .endorsementItems{
            max-height: 45rem;
        }
        .ant-carousel{
            height: 45rem;
        }
        .slick-slider{
            height: 45rem;
        }
    }
    

    .endorsementText{
        padding: 10px;
        color: #ffff;
        font-size: clamp(0.25rem, 0.86rem, 1rem);
    }
    
    

`;

export const LessonsLearntClasses = styled.div`
padding-top: 82px;
max-width:100vw;

    .ad{
        background-image: url(${lessonsLearntImage});
        //width: 100%;
        //min-height: 460px;
        height: 100%;
    } 
    .lessonsLearnt{
        text-align: center;
        background-color: #ffff;
        //height: 460px;
        padding-top: 40px;
        min-height:460px;
    }
    
    .lessonsItem{
        padding-bottom: 40px;
    }
    .whoAvatar{
        justify-content: right;
        display: flex;
    }
    .lessonsText{
        padding-left: 10vw;
        padding-right: 10vw;
        padding-top: 25px;
        font-size: 21px;
        font-style: italic;
    }
    .whoFrom{
        font-weight: bold;
    }
    .who{
        font-size: 14px;
    }
    .whoDetails{
        text-align: left;
        line-height: 5px;
        padding-top: 20px;
    }

`;


export const STabCardDiv = styled.div`


    .ant-tabs-card > .ant-tabs-content {
  margin-top: -16px;
}

.ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
  padding: 16px;
}

.ant-tabs-card > .ant-tabs-bar {
  border-color: #fff;
}

.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  /*border-color: transparent;*/
  /*background: transparent;*/
}

.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #fff;
  background: #fff;
}
`;














