import * as React from 'react';

import { RouteComponentProps, NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import { startCase } from 'lodash';
import { RightAlignedRow } from '../Styled/Layout/RightAlignedRow';
import TableFilterContextProvider from '../Forms/DataGrid/TableFilterContextProvider';
import { Avatar, Button, Card, Col, Icon, Input, Progress, Row } from 'antd';
import ISelectOption from '../Forms/SimpleSelect/ISelectOption';
import SimpleSelect from '../Forms/SimpleSelect';
import { Banner } from 'components/News/NewsStyled';
import HowardMitchell from 'assets/images/board/board_Howard_Mitchell.jpg';
import JulianMair from 'assets/images/board/board_Julian_Mair.jpg';
import MarleneStreetForrest from 'assets/images/board/board_Marlene_Street-Forrest.jpg';
import NevilleYing from 'assets/images/board/board_Neville_Ying.jpg';
import OmarSweeney from 'assets/images/board/board_Omar-Sweeney.jpg';
import ShaunaKayeRowe from 'assets/images/board/board_Shauna-Kaye_Rowe.jpg';
import CharmaineBrimm from 'assets/images/management/management_charmaine_brimm.jpg';
import DenniseWilliams from 'assets/images/board/board-Dennise_Williams.jpg';
import Garth_Kiddoe from 'assets/images/board/board-Garth_Kiddoe.jpg';
import Janet_Morrison from 'assets/images/board/board-Janet_Morrison.jpg';
import Onyka_BarrettScott from 'assets/images/board/board-Onyka_Barrett-Scott.jpg';
import Steven_Gooden from 'assets/images/board/board-Steven_Gooden.jpg';
import { TopAvatar } from '../Styled/Layout/CenterAlignedRow';
import BoardOfDirectorsModal from './BoardOfDirectorsModal';
import { ProfileContainer } from './ProfileContainer';


import JulianMairPdf from 'assets/profiles/board/julian_mair.pdf';
import MarleneStreetForrestPdf from 'assets/profiles/board/marlene_street_forrest.pdf';
import NevilleYingPdf from 'assets/profiles/board/neville_ying.pdf';
import OmarSweeneyPdf from 'assets/profiles/board/omar_sweeney.pdf';
import CharmaineBrimmPdf from 'assets/profiles/board/charmaine_brimm.pdf';
import DenniseWilliamsPdf from 'assets/profiles/board/dennise_williams.pdf';
import Janet_MorrisonPdf from 'assets/profiles/board/janet_morrison.pdf';
import Onyka_BarrettScottPdf from 'assets/profiles/board/onyka_barrett.pdf';
import Steven_GoodenPdf from 'assets/profiles/board/steven_gooden.pdf';
import { useEffect, useState } from 'react';
import { ProfileContainerHolder } from './ProfileContainerHolder';

const { Search } = Input;

export interface Props {
    children?: React.ReactNode;
}

export interface State {
    board?: any[];
}

const BoardOfDirectors = ({ children }: Props) => {

    const [board, setBoard] = useState<any[] | undefined>(undefined);

    useEffect(() => {
        getTeam();
    }, [])

    const getTeam = () => {
        setBoard([
            { name: 'Professor Neville Ying CD, PHD', position: 'Chairman', img: <TopAvatar style={{ width: '348px', height: '400px', maxWidth: '86vw' }} src={NevilleYing} shape="square" />, pdfUrl: NevilleYingPdf },
            { name: 'Dr. Marlene Street Forrest. JP, CD', position: 'Managing Director (JSE)', img: <Avatar style={{ width: '348px', height: '400px', maxWidth: '86vw' }} src={MarleneStreetForrest} shape="square" />, pdfUrl: MarleneStreetForrestPdf },
            { name: 'Chairmaine Brimm', position: 'Technical Specialist (PIOJ)', img: <TopAvatar style={{ width: '348px', height: '400px', maxWidth: '86vw' }} src={CharmaineBrimm} shape="square" />, pdfUrl: CharmaineBrimmPdf },
            /*{ name: 'Steven Gooden', position: 'Chief Executive Officer (NCB Capital Markets Limited)', img: <TopAvatar style={{ width: '348px', height: '400px', maxWidth: '86vw' }} src={Steven_Gooden} shape="square" />, pdfUrl: Steven_GoodenPdf },*/
            { name: 'Julian Mair', position: 'Group Chief Investment Strategist (JMMB)', img: <Avatar style={{ width: '348px', height: '400px', maxWidth: '86vw' }} src={JulianMair} shape="square" />, pdfUrl: JulianMairPdf },
            { name: 'Janet Morrison', position: 'Partner (Hart Muirhead Fatta)', img: <Avatar style={{ width: '348px', height: '400px', maxWidth: '86vw' }} src={Janet_Morrison} shape="square" />, pdfUrl: Janet_MorrisonPdf },
            { name: 'Onyka Barrett Scott', position: 'Chief Executive Officer (Pinnacle Impact International Limited)', img: <TopAvatar style={{ width: '348px', height: '400px', maxWidth: '86vw' }} src={Onyka_BarrettScott} shape="square" />, pdfUrl: Onyka_BarrettScottPdf },
            { name: 'Omar Sweeney', position: 'Managing Director (JSIF)', img: <TopAvatar style={{ width: '348px', height: '400px', maxWidth: '86vw' }} src={OmarSweeney} shape="square" />, pdfUrl: OmarSweeneyPdf },
            { name: 'Dennise Williams', position: 'Creative Director (Financially Focused Media)', img: <Avatar style={{ width: '348px', height: '400px', maxWidth: '86vw' }} src={DenniseWilliams} shape="square" />, pdfUrl: DenniseWilliamsPdf },
        ]
        );
    }

    return (
        <>
            <ProfileContainerHolder profiles={board} />
        </>
    );
}

export default BoardOfDirectors;