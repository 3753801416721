import React from 'react';
import LoginPage from '../Pages/LoginPage';
import ConfirmEmailPage from '../Pages/ConfirmEmailPage';
import RegisterPage from '../Pages/RegisterPage';
import AppRoute, { LayoutType } from './models/AppRoute';
import {
    AdminLayout,
    ClassicLayout,
    LoginLayout,
    LandingLayout,
    ResetLayout,
    RegisterLayout,
} from '../components/Layout';
import LogoutPage from 'Pages/LogoutPage';
import { Operations } from './Api/Api';
import NotFoundView from 'components/Error/NotFoundView';
import EmptyLayout from 'components/Layout/EmptyLayout';
import JcsdAccountListPage from 'Pages/JcsdAccountListPage';
//import JSIXDetailsPage from 'Pages/JSIXDetailsPage';
import JcsdAccountWizardPage from 'Pages/JcsdAccountWizardPage';
import DashboardPage from 'Pages/DashboardPage';
import FAQPage from 'Pages/Information/FAQPage';
import ContactUsPage from 'Pages/Information/ContactUsPage';
import HowToDonatePage from 'Pages/Information/HowToDonatePage';
import AboutUsPage from 'Pages/Information/AboutUsPage';
import NewsPage from 'Pages/NewsPage';
import ManagementTeam from 'Pages/ManagementTeamPage';
import BoardOfDirectors from 'Pages/BoardOfDirectorsPage';
import OfferingDetailsViewPage from 'Pages/OfferingDetailsViewPage';
import ProjectsListPage from 'Pages/ProjectsListPage';
import CenteredLandingLayout from 'components/Layout/CenteredLandingLayout';
import ResetPasswordPage from 'Pages/UserProfile/ResetPasswordPage';
import RequestPasswordResetPage from 'Pages/RequestPasswordResetPage';
import UserProfilePage from '../Pages/UserProfile/UserProfilePage';
import EditUserProfilePage from '../Pages/UserProfile/EditUserProfilePage';
import ChangePasswordPage from '../Pages/UserProfile/ChangePasswordPage';
import UpdateSecurityQuestionPage from '../Pages/UserProfile/UpdateSecurityQuestionPage';
import OfferingApplicationListPage from 'Pages/OfferingApplicationListPage';
import OfferingApplicationDetailPage from 'Pages/OfferingApplicationDetailPage';
import ResendActivationPage from '../Pages/ResendActivationPage';
import ProjectDetailsPage from '../Pages/ProjectDetailsPage';
import PledgePage from '../Pages/PledgePage';
import AdminProjectListPage from '../Pages/Admin/AdminProjectListPage';
import AuditListPage from '../Pages/Admin/AuditListPage';
import { ClientPaymentsListPage } from '../Pages/UserProfile/PaymentsListPage';
import PaymentsListPage from '../Pages/Admin/PaymentsListPage';
import PledgeListPage from '../Pages/Admin/PledgeListPage';
import RolesListPage from '../Pages/Admin/RolesListPage';
import UserListPage from '../Pages/Admin/UserListPage';
import DonatePage from '../Pages/DonatePage';
import NewsArticleViewPage from 'Pages/NewsArticleViewPage';
import { CompanyProjectsPage } from 'Pages/CompanyProjects/CompanyProjectsPage';
import { NewCompanyProjectPage } from 'Pages/CompanyProjects/NewCompanyProjectPage';
import CartListPage from 'Pages/CartListPage';
import { EditCompanyProjectPage } from 'Pages/CompanyProjects/EditCompanyProjectPage';
import EntrepreneurProjectDetailPage from 'Pages/EntrepreneurProjectDetailPage';
import CompanyProfilePage from 'Pages/CompanyProfilePage';
import ListingAndSelectionCommitteePage from 'Pages/ListingAndSelectionCommitteePage/';
import './AppRoutesStyles.css';

export const AppRoutes: AppRoute[] = [
    {
        title: 'Home',
        component: () => <h1> Home !</h1>,
        path: '/',
        redirect: 'welcome',
        icon: 'star',
        exact: true,
        skip: true,
        routes: [
            {
                title: 'Home',
                hideInMainMenu: true,
                icon: 'home',
                layout: (props: any) => <LandingLayout {...props} />,
                path: 'dashboard',
                component: DashboardPage,
            },
            {
                title: 'Dashboard',
                icon: 'area-chart',
                hideInMainMenu: true,

                layout: (props: any) => <LandingLayout {...props} />,
                path: 'welcome',
                component: DashboardPage,
            },
            //{
            //    title: 'My Accounts',
            //    icon: 'wallet',
            //    layout: (props: any) => <LandingLayout {...props} />,
            //    requiredOperations: [Operations.Is_Authenticated],
            //    path: 'jcsd-accounts',
            //    component: JcsdAccountListPage,
            //},
            //{
            //    title: 'My Applications',
            //    icon: 'wallet',
            //    layout: (props: any) => <LandingLayout {...props} />,
            //    requiredOperations: [Operations.Is_Authenticated],
            //    path: 'applications',
            //    component: OfferingApplicationListPage,F
            //},
            {
                title: 'Application Details',
                path: 'applications/:name',
                icon: 'Users',
                layout: (props: any) => <LandingLayout {...props} />,
                requiredOperations: [Operations.Is_Authenticated],
                hideInMainMenu: true,
                exact: true,
                component: OfferingApplicationDetailPage,
            },

            {
                title: 'JCSD Account Wizard',
                path: 'accounts/wizard',
                layout: (props: any) => <LandingLayout {...props} />,
                icon: 'Users',
                requiredOperations: [Operations.Is_Authenticated],
                hideInMainMenu: true,
                exact: true,
                component: JcsdAccountWizardPage,
            },
            {
                title: 'Settings',
                icon: 'cog',
                hideInMainMenu: true,
                path: 'settings',
                component: () => <h1> settings </h1>,
                routes: [
                    {
                        title: 'Account',
                        hideInMainMenu: true,
                        icon: 'user',
                        layout: (props: any) => <LandingLayout {...props} />,
                        path: 'settings/account',
                        component: () => <h1> Account </h1>,
                    },
                ],
            },
            {
                title: 'Offering',
                path: '/JSIX/details/:id',
                icon: 'area-chart',
                hideInMainMenu: true,
                layout: (props: any) => <CenteredLandingLayout {...props} />,
                component: OfferingDetailsViewPage,
            },
            {
                title: 'Change Password',
                path: '/change-password',
                icon: 'folder',
                layout: (props: any) => <LandingLayout {...props} />,
                requiredOperations: [Operations.Is_Authenticated],
                hideInMainMenu: true,
                exact: true,
                component: ChangePasswordPage
            },
            {
                title: 'Update Security Questions',
                path: '/update-security',
                icon: 'folder',
                layout: (props: any) => <LandingLayout {...props} />,
                requiredOperations: [Operations.Is_Authenticated],
                hideInMainMenu: true,
                exact: true,
                component: UpdateSecurityQuestionPage
            },
            {
                title: 'User Profile',
                path: '/user-profile',
                icon: 'folder',
                layout: (props: any) => <LandingLayout {...props} />,
                // requiredOperations: [Operations.Is_Authenticated],
                hideInMainMenu: true,
                exact: true,
                component: UserProfilePage,
                routes: [
                    {
                        title: 'Payment List',
                        path: 'payment-list',
                        layout: (props: any) => <LandingLayout {...props} />,
                        hideInMainMenu: true,
                        exact: true,
                        component: ClientPaymentsListPage,
                    },
                    {
                        title: 'Company Projects',
                        path: 'projects',
                        icon: 'folder',
                        layout: (props: any) => <LandingLayout {...props} />,
                        requiredOperations: [Operations.Is_Authenticated],
                        hideInMainMenu: true,
                        exact: true,
                        component: CompanyProjectsPage,
                        routes: [
                            {
                                title: 'New Project',
                                path: 'new',
                                icon: 'folder',
                                layout: (props: any) => <LandingLayout {...props} />,
                                requiredOperations: [Operations.Is_Authenticated],
                                hideInMainMenu: true,
                                exact: true,
                                component: NewCompanyProjectPage,
                            },
                            {
                                title: 'Entrepreneur Project Details',
                                path: 'view/:id',
                                hideInMainMenu: true,
                                // requiredOperations: [Operations.View_Project_Details],
                                layout: (props: any) => <LandingLayout {...props} />,
                                component: EntrepreneurProjectDetailPage,
                                routes: [
                                    {
                                        title: 'Edit Project',
                                        path: 'edit/:id/',
                                        layout: (props: any) => <LandingLayout {...props} />,
                                        requiredOperations: [Operations.Is_Authenticated],
                                        hideInMainMenu: true,
                                        exact: true,
                                        component: EditCompanyProjectPage,
                                    },
                                    {
                                        title: 'Entrepreneur Project Details',
                                        path: 'view/:id/pre-view',
                                        hideInMainMenu: true,
                                        // requiredOperations: [Operations.View_Project_Details],
                                        layout: (props: any) => <LandingLayout {...props} />,
                                        component: ProjectDetailsPage,
                                    },
                                ]
                            }
                        ]
                    },
                ]
            },
            {
                title: 'Company Profile',
                path: '/company-profile',
                icon: 'idcard',
                layout: (props: any) => <LandingLayout {...props} />,
                requiredOperations: [Operations.Is_Authenticated],
                hideInMainMenu: true,
                exact: true,
                component: CompanyProfilePage
            },
            {
                title: 'Edit User Profile',
                path: '/edit-user-profile',
                icon: 'folder',
                layout: (props: any) => <LandingLayout {...props} />,
                requiredOperations: [Operations.Is_Authenticated],
                hideInMainMenu: true,
                exact: true,
                component: EditUserProfilePage
            },
        ],
    },
    //{
    //    title: 'Admin',
    //    path: '/admin',
    //    icon: 'user',
    //    exact: true,
    //    layout: (props: any) => <AdminLayout {...props} />,
    //    redirect: '/admin/users',
    //    // requiredOperations: [Operations.Is_Authenticated],
    //    routes: [
    //        {
    //            title: 'Users',
    //            path: 'users',
    //            icon: 'user',
    //            exact: true,
    //            // requiredOperations: [Operations.View_Admin_User],
    //            component: UserListPage,
    //        },
    //        //{
    //        //    title: 'User Details',
    //        //    path: 'users/:name',
    //        //    icon: 'user',
    //        //    hideInMainMenu: true,
    //        //    requiredOperations: [Operations.View_Admin_User],
    //        //    exact: true,
    //        //    component: UsersDetailPage,
    //        //},
    //        {
    //            title: 'Roles',
    //            path: 'roles',
    //            icon: 'usergroup-add',
    //            exact: true,
    //            // requiredOperations: [Operations.View_Roles],
    //            component: RolesListPage,
    //        },
    //        {
    //            title: 'Project Management',
    //            path: 'projects',
    //            icon: 'usergroup-add',
    //            exact: true,
    //            // requiredOperations: [Operations.View_Roles],
    //            component: AdminProjectListPage,
    //        },
    //        {
    //            title: 'Donations',
    //            path: 'donation',
    //            icon: 'usergroup-add',
    //            exact: true,
    //            // requiredOperations: [Operations.View_Roles],
    //            component: DonationListPage,
    //        },
    //        {
    //            title: 'Payments',
    //            path: 'payments',
    //            icon: 'usergroup-add',
    //            exact: true,
    //            // requiredOperations: [Operations.View_Roles],
    //            component: PaymentsListPage,
    //        },
    //        {
    //            title: 'Pledges',
    //            path: 'pledges',
    //            icon: 'usergroup-add',
    //            exact: true,
    //            // requiredOperations: [Operations.View_Roles],
    //            component: PledgeListPage,
    //        },
    //        //{
    //        //    title: 'Role Details',
    //        //    path: 'roles/:name',
    //        //    icon: 'group',
    //        //    hideInMainMenu: true,
    //        //    requiredOperations: [Operations.View_Roles],
    //        //    exact: true,
    //        //    component: RolesDetailsPage,
    //        //},
    //        {
    //            title: 'Audit',
    //            path: 'audits',
    //            icon: 'audit',
    //            exact: true,
    //           // requiredOperations: [Operations.View_Audit],
    //            component: AuditListPage,
    //        },
    //        //{
    //        //    title: 'Audit',
    //        //    path: 'audits/:id',
    //        //    icon: 'time',
    //        //    hideInMainMenu: true,
    //        //    exact: true,
    //        //    requiredOperations: [Operations.View_Audit],
    //        //    component: AuditDetailsPage,
    //        //},
    //    ],
    //},
    {
        title: 'Logout',
        path: '/logout',
        icon: 'power',
        exact: true,
        hideInMainMenu: true,
        layout: (props: any) => <LoginLayout {...props} />,
        component: LogoutPage,
    },
    {
        title: 'Login',
        path: '/login',
        icon: 'up',
        exact: true,
        hideInMainMenu: true,
        layout: (props: any) => <LoginLayout {...props} />,
        component: LoginPage,
    },


    //{
    //    title: 'Donate',
    //    path: '/donate',
    //    icon: 'project',
    //    hideInMainMenu: true,
    //    layout: (props: any) => <LandingLayout {...props} />,
    //    component: DonatePage,
    //},
    {
        title: 'Cart',
        path: '/cart',
        icon: 'project',
        hideInMainMenu: true,
        layout: (props: any) => <LandingLayout {...props} />,
        component: CartListPage,
    },
    {
        title: 'Projects',
        path: '/projects',
        icon: 'project',
        hideInMainMenu: false,
        layout: (props: any) => <LandingLayout {...props} />,
        component: ProjectsListPage,
    },
    {
        title: 'Project Details',
        path: '/projects/view/:id',
        hideInMainMenu: true,
        layout: (props: any) => <LandingLayout {...props} />,
        component: ProjectDetailsPage,
    },
    {
        title: 'Pledge',
        path: '/pledge',
        hideInMainMenu: true,
        layout: (props: any) => <LandingLayout {...props} />,
        component: PledgePage,
    },
    {
        title: 'Pledge View',
        path: '/pledge/:id',
        hideInMainMenu: true,
        layout: (props: any) => <LandingLayout {...props} />,
        component: PledgePage,
    },
    {
        title: 'About ▾',
         path: '/about-us',
        icon: 'question',
         //hideInMainMenu: false,
        layout: (props: any) => <LandingLayout {...props} />,
        component: AboutUsPage,
        routes: [
            {
                title: 'About Us',
                path: '',
                icon: 'question',
                hideInMainMenu: false,
                layout: (props: any) => <LandingLayout {...props} />,
                component: AboutUsPage,
            },
            {
                title: 'Board Of Directors',
                path: 'board-of-directors',
                icon: 'user',
                hideInMainMenu: false,
                layout: (props: any) => <LandingLayout {...props} />,
                component: BoardOfDirectors,
            },
            {
                title: 'Listing and Selection Committee',
                path: 'listing-and-selection-committee',
                icon: 'user',
                hideInMainMenu: false,
                layout: (props: any) => <LandingLayout {...props} />,
                component: ListingAndSelectionCommitteePage,
            },
            {
                title: 'Management Team',
                path: 'management-team',
                icon: 'user',
                hideInMainMenu: false,
                layout: (props: any) => <LandingLayout {...props} />,
                component: ManagementTeam,
            },
            
        ]
    },
    {
        title: 'How To Donate',
        path: '/how-to-donate',
        hideInMainMenu: true,
        layout: (props: any) => <LandingLayout {...props} />,
        component: HowToDonatePage,
    },

    {
        title: 'News',
        path: '/news',
        icon: 'file',
        hideInMainMenu: false,
        layout: (props: any) => <LandingLayout {...props} />,
        component: NewsPage,
    },
    {
        title: 'News Article',
        path: '/news/:id/:title',
        icon: 'file',
        hideInMainMenu: true,
        layout: (props: any) => <LandingLayout {...props} />,
        component: NewsArticleViewPage,
    },
    {
        title: 'Contact Us',
        path: '/contact-us',
        icon: 'form',
        hideInMainMenu: false,
        layout: (props: any) => <LandingLayout {...props} />,
        component: ContactUsPage,
    },
    {
        title: 'My Cart',
        icon: 'wallet',
        layout: (props: any) => <LandingLayout {...props} />,
        //requiredOperations: [Operations.Is_Authenticated],
        path: '/donate',
        component: DonatePage,
    },
    //{
    //    title: 'View JCSD Accounts',
    //    path: '/view-jcsd-accounts',
    //    icon: 'up',
    //    exact: true,
    //    hideInMainMenu: false,
    //    layout: (props: any) => <ClassicLayout {...props} />,
    //    component: ConfirmEmailPage, /* JcsdAccountListPage,*/
    //},
    {
        title: 'Confirm Email',
        path: '/auth/confirm',
        icon: 'up',
        exact: true,
        hideInMainMenu: true,
        layout: (props: any) => <LoginLayout {...props} />,
        component: ConfirmEmailPage,
    },


    {
        title: 'Terms',
        path: '/terms',
        exact: true,
        hideInMainMenu: true,
    },
    {
        title: 'Privacy',
        path: '/privacy',
        exact: true,
        hideInMainMenu: true,
    },
    {
        title: 'Register',
        path: '/register',
        icon: 'up',
        exact: true,
        hideInMainMenu: true,
        layout: (props: any) => <LoginLayout {...props} />,
        component: RegisterPage,
    },
    {
        title: 'Reset Password',
        path: '/reset-password',
        hideInMainMenu: true,
        layout: (props: any) => <ResetLayout {...props} />,
        component: RequestPasswordResetPage,
    },
    {
        title: 'Resend Activation',
        path: '/resend-activation',
        hideInMainMenu: true,
        layout: (props: any) => <ResetLayout {...props} />,
        component: ResendActivationPage,
    },
    {
        title: 'Reset Password Confirmation',
        path: '/reset-password-confirm',
        hideInMainMenu: true,
        layout: (props: any) => <ResetLayout {...props} />,
        component: ResetPasswordPage,
    },
    {
        title: '404',
        path: '/*',
        hideInMainMenu: true,
        layout: (props: any) => <EmptyLayout {...props} />,
        component: NotFoundView,
    },
];










