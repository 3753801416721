import { Col, Row } from 'antd';
import * as React from 'react';
import { RouteProps } from 'react-router-dom';
import styled from 'styled-components';
import JSIXApplicationFormWithoutRouter from '../../components/Client/JSIXApplicationForm/';
import NotificationService from '../../Core/NotificationService'; // 'Core/NotificationService';
import { ClientJSIXHandler } from '../../components/Client/ClientJSIXHandler';
import queryString from 'query-string';
import { Redirect } from 'react-router';
import DashboardView from 'components/Dashboard/DashboardView';

export interface Props extends RouteProps {
  children?: React.ReactNode;
}

export interface State {
  isConfirmed: boolean;
}

const TallCol = styled(Col)`
  height: 100%;
  min-height: 200px;
`;

export default class DashboardPage extends React.Component<Props, State> {
  JSIXApplicationFormHandler: ClientJSIXHandler;

  constructor(props: Props) {
    super(props);
    this.JSIXApplicationFormHandler = new ClientJSIXHandler(props);

    this.state = { isConfirmed: false };
  }

  componentDidMount() {
    const values = queryString.parse(this.props?.location?.search);

    //  this.confirmJointHolderFormHandler.ConfirmEmail(values.email, values.token).then(x => {
    //  NotificationService.success(
    //    'Email Confirmed',
    //    <>
    //      <p>
    //        Your email address has been confirmed.
    //      </p>
    //    </>, undefined, true
    //  );
    //  // setTimeout(() => { this.setState({ isConfirmed: true }); }, 15000);
    //  this.setState({ isConfirmed: true });

    //  return x;
    //});

    //let state: { from?: string; noPermission?: boolean } = {};
  }

  render() {
    return <DashboardView {...this.props} />;
  }
}










