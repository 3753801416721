import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from 'antd'
import styled from 'styled-components';
import { useCartContext } from 'Context/CartContext';


const CartMenuItem: React.FC = () => {
    const { cartItems, updateCartItemCount } = useCartContext();
    const CartStyled = styled.div`
        .cart-counter {
            display: inline-block;
            background-color: #ff0000;
            color: #fff;
            font-size: 12px;
            font-weight: bold;
            padding: 2px 8px;
            border-radius: 50%;
            position: absolute;
            top: 10px;
            right: -10px;
        }
    `;
    React.useEffect(() => {
        updateCartItemCount();
    }, []);


    return (
        <React.Fragment>
            <CartStyled>
                {//<Icon type="shopping-cart" />
                }
                My Cart
                {cartItems > 0 && <span className="cart-counter">{cartItems}</span>}
            </CartStyled>
        </React.Fragment>
    );
};

export default CartMenuItem;








