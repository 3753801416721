import * as React from 'react';
import { Col, Row } from 'antd';
import { RouteProps, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import  PaymentsListTable from 'components/UserManagement/PaymentsListTable';
import { BannerDiv, CenteredFormContent } from 'components/Layout/LoginLayout/LoginLayout.Style';

export interface Props extends RouteProps {
  children?: React.ReactNode;
}

export interface State {}

const TallCol = styled(Col)`
  height: 100%;
  min-height: 200px;
`;

export default class PaymentsListPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return ( 
      <CenteredFormContent>
        <BannerDiv className='project-list'>
          <Row style={{ height: '100%' }}>
            <Col sm={24} className={'bannerNav'}>
            </Col>
          </Row>
        </BannerDiv>
        <br />
        <br />
        <Row type="flex" align="middle" justify="center">
          <Col md={19}>
            <PaymentsListTable />
          </Col>
        </Row>
      </CenteredFormContent>
    );
  }
}










