import { useEffect } from 'react';
import * as React from 'react';
import { Button, Col, DatePicker, Descriptions, Form, Icon, Input, Row, Upload, Divider, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { fullWidthSingleColFormColSize, fullWidthSingleColFormColSizeTwo, FullWithButton } from 'components/Forms/Form.Styled';
import FormControl from 'components/Forms/FormControl';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import ValidationUtility from 'Core/ValidationUtility';
import { useHistory, useParams } from 'react-router-dom';

import NotificationService from 'Core/NotificationService';
import { FormComponentProps } from 'antd/lib/form';

import styled from 'styled-components';
import { RichTextEditor } from 'components/Forms/RichTextEditor';
import { CompanyProjectsHandler } from '../../CompanyProjectsHandler';
import SimpleSelect from 'components/Forms/SimpleSelect';
import SimpleUpload from 'components/Forms/SimpleUpload';
import { FilesEditor } from 'components/Forms/FilesEditor';
import ISelectOption from 'components/Forms/SimpleSelect/ISelectOption';
import { DocumentModel, ICreateProjectModel, IDocumentModel, ProjectFullDetailsModel } from 'Core/Api/Api';
import { Base64FileUploader } from 'components/Forms/Base64FileUploader';
import MaskedInput from 'components/Forms/MaskedInput';
import NumberFormat from 'react-number-format';
import { lastIndexOf } from 'lodash';
import moment, { Moment } from 'moment';
import { SimpleDatePicker } from 'components/Forms/SimpleDatePicker';
import Utility from 'Core/Utility';
import ConfigService from 'Core/ConfigService';
import { DocumentItemDisplay } from 'components/Forms/FilesEditor/DocumentItemDisplay';
import { RichTextViewer } from 'components/Forms/RichTextViewer';
import { ColoredPill } from 'components/Forms/ColoredPill';
import ShowIfTrue from 'components/Forms/ShowIfTrue';

const ApprovalContainer = styled.div`
  display: flex;
  flex-direction:column;
  align-content: flex-start;
  gap: 3px;
`

export interface SubmitForApprovalViewProps {
  categories?: ISelectOption[] | undefined;
  fundingTypes?: ISelectOption[] | undefined;
  fundingPurposes?: ISelectOption[] | undefined;
  project?: ProjectFullDetailsModel | undefined;
  otherDocuments?: IDocumentModel[] | undefined;
}

export const ButtonGroup = styled.div`
  
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(100px,200px));
    gap: 5px;
  `;

export function SubmitForApprovalView({ categories, fundingPurposes, fundingTypes, project, otherDocuments }: SubmitForApprovalViewProps) {

  let history = useHistory();
  let config = ConfigService.getSync();


  const GetFileNameFromApiUrl = (url: String) => {
    return url.substring(url.lastIndexOf("/") + 1);
  }

  return (
    <Row>
      <Col span={19}>

        <Descriptions column={{ md: 3, lg: 3, xl: 3, sm: 3, xxl: 3 }} >
          <Descriptions.Item span={3} label="Title">{project?.name}</Descriptions.Item>
          <Descriptions.Item span={3} label="Sub-Title">{project?.subTitle}</Descriptions.Item>
          <Descriptions.Item span={1} label="Category">{categories?.find(x => x?.value == project?.category)?.text}</Descriptions.Item>
          <Descriptions.Item span={1} label="Goal Amount"> <NumberFormat thousandSeparator="," value={project?.amountRequested} displayType="text" /></Descriptions.Item>
          <Descriptions.Item span={1} label="Time To Fund">{project?.timeToFunding && Utility.renderDateWithReference(moment(project?.timeToFunding), config.Display.DateFormat)}</Descriptions.Item>
          <Descriptions.Item label="Funding Type">{fundingTypes?.find(x => x?.value == project?.typeOfFunding)?.text}</Descriptions.Item>
          <Descriptions.Item span={2} label="Funding Purpose">{fundingPurposes?.find(x => x?.value == project?.fundingPurpose)?.text}</Descriptions.Item>
          <Descriptions.Item span={3} label="Description"><RichTextViewer fontSize={16} value={project?.description} /></Descriptions.Item>
          <Descriptions.Item span={3} label={<span style={{ marginRight: "56px" }}>Thumbnail</span>}> {project?.thumbnailFileUrl && <img src={project?.thumbnailFileUrl} width="200" height="200" />}</Descriptions.Item>
          <Descriptions.Item span={3} label="Dashboard Picture"> {project?.projectDashboardFileUrl && <img src={project?.projectDashboardFileUrl} width="356" height="246" />}</Descriptions.Item>
          <Descriptions.Item span={3} label="Video Url"> {project?.videoUrl && <a href={project?.videoUrl} target="_blank">{project?.videoUrl}</a>}</Descriptions.Item>
          <Descriptions.Item span={3} label="Business Plan"> {project?.projectDocumentFileUrl && <a href={project?.projectDocumentFileUrl} target="_blank">{GetFileNameFromApiUrl(project?.projectDocumentFileUrl)}</a>}</Descriptions.Item>
          <Descriptions.Item span={3} label="Others Documents">{otherDocuments?.map(document => (
            <DocumentItemDisplay document={document} readonly={true} ></DocumentItemDisplay>
          ))}
          </Descriptions.Item>

        </Descriptions>
      </Col>
        
      <Col span={5}>
        <Descriptions column={{ md: 1, lg: 1, xl: 1, sm: 1, xxl: 1 }}>
          <Divider />
          {project?.projectApprovals?.map(approval => (
            <>
              <ApprovalContainer>
                <Typography.Text><b>Action: </b><ColoredPill value={approval.status} options={[{ color: "green", value: "Approved" }, { color: "red", value: "Rejected" }]} /></Typography.Text>
                <br />
                <Typography.Text><b>on: </b> {Utility.renderDateWithReference(moment(approval?.creationDate), config.Display.DateTimeFormat)}</Typography.Text>
                <ShowIfTrue condition={!!approval.reason}>
                  <br />
                  <Typography.Text><b>Reason: </b> {approval?.reason}</Typography.Text>
                </ShowIfTrue>
                <Divider />
              </ApprovalContainer>
            </>
            ))}

        </Descriptions>

      </Col>


    </Row>
  );
}

export default SubmitForApprovalView;