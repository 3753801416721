import * as React from 'react';
import { Layout, Row, Col, Icon } from 'antd';
import { CenteredContent, MainLogo, LoginWithBackground, LoginContent } from './LoginLayout.Style';
import Title from 'antd/lib/typography/Title';
import Paragraph from 'antd/lib/typography/Paragraph';
import { SFooter } from '../Footer.Styled';
import { FooterLayout } from '../FooterLayout';
const { Header, Content, Footer } = Layout;

export interface Props {
    children?: React.ReactNode;
}

export interface State { }

export default class LoginLayout extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <LoginWithBackground className="react-transition fade-in" style={{ animationDuration: '750ms' }}>
                <CenteredContent>
                   
                    <Row type="flex" align="middle" justify="center">
                        <Col style={{ backgroundColor: 'white' }}>
                            <LoginContent>
                                {this.props.children}
                            </LoginContent>
                        </Col>
                    </Row>

                </CenteredContent>
                <FooterLayout />
            </LoginWithBackground>
        );
    }
}
















