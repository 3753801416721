import { DataFileType, IDocumentModel } from 'Core/Api/Api';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import FsLightbox from "fslightbox-react";
import { Card, Empty, List } from 'antd';
import Utility from 'Core/Utility';
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';
import ReactPlayer from 'react-player/youtube';
import { bind } from 'lodash';

const GalleryContainer = styled.div`
    padding-bottom: 5vh;
    .image-gallery-play-button{
        bottom: -50px;
    }
    .image-gallery-fullscreen-button{
         bottom: -50px;
    }
`;

declare type GalleryItem = {
  thumbnail?: string;
  description?: string;
  original?: string;
  type?: "video" | "image";
  renderItem?: Function;
}

interface Props {
  documents: IDocumentModel[] | undefined;
}

function RenderVideo(item: GalleryItem) {
  return <ReactPlayer url={item.original} />;
}

export default ({ documents }: Props) => {

  const getGalleryItemFromDocument = (document: IDocumentModel): GalleryItem => {
    const galleryItem: GalleryItem = {};
    galleryItem.description = document?.description ?? document?.name;
    galleryItem.original = (document?.content?.url as string) ?? (document.url as string);
    if (galleryItem.original.includes("youtube")) {
      galleryItem.type = 'video';
      galleryItem.thumbnail = Utility.getYouTubeImgUrl(galleryItem.original);

      galleryItem.renderItem = RenderVideo;
    } else {
      galleryItem.type = 'image';
      galleryItem.thumbnail = Utility.makeAbsoluteUrl(galleryItem.original);
      galleryItem.original = galleryItem.thumbnail;
    }
    return galleryItem;

  }

  const sources: GalleryItem[] | undefined = documents?.filter(x => x.fileType == DataFileType.GalleryDocument || x.url).map(getGalleryItemFromDocument);

  return <GalleryContainer>
    <ImageGallery onErrorImageUrl={"https://via.placeholder.com/600x400?text=no+Image"} thumbnailPosition={"top"} items={sources} />
  </GalleryContainer>;
}