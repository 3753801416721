import * as React from 'react';
import styled from 'styled-components';
import { Avatar } from 'antd';

// This is needed because SubMenu styling was throwing of .anticon styles
const MenuAvatar = styled(Avatar)`
  .anticon {
    margin-right: 0px;
    font-size: 18px;
  }
`;

export default MenuAvatar;
















