import { SecuredClient } from 'Core/Api/SecuredClient';
import {
    CompanyDetailsModel,
    OrderBy,
    CreateProjectModel,
    ICreateProjectModel,
    ProjectCategory,
    FundingPurpose,
    DataFileType,
    FundingType,
    GetMyProjectsByFilterQueryRequest,
    IEditProjectModel,
    EditProjectModel,
    SubmitProjectForApprovalCommandRequest,
    ProjectStatus,
    SubmitProjectUpdateForApprovalCommandRequest,
    UpsertProjectUpdateModel,
    IUpsertProjectUpdateModel,
    IGetProjectUpdateDetailsByProjectIdQueryRequest,
    GetProjectUpdateDetailsByProjectIdQueryRequest,
    EditCompanyModel,
    IEditCompanyModel
} from 'Core/Api/Api';
import Utility from 'Core/Utility';
import ITableDataFetcher from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataFetcher';
import TableRequest from 'Core/TableUtility/Models/TableRequest';
import ITableDataResponse from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataResponse';

export class CompanyProjectsHandler implements ITableDataFetcher<CompanyDetailsModel> {

    props: any;
    constructor(props) {
        this.props = props;
    }

    GetData(tableRequest: TableRequest): Promise<ITableDataResponse<CompanyDetailsModel>> {
        return this.GetCompanyProjects(tableRequest);
    }


    GetCompanyProjects(tableRequest: TableRequest) {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;

        const request = new GetMyProjectsByFilterQueryRequest(tableRequest);

        if (!request.amountPerPage) {
            request.amountPerPage = 6;
        }

        if (request.orderBy == undefined) {
            request.orderBy = [];
            request.orderBy.push(new OrderBy({ field: "creationDate", ascending: false }));
            request.orderBy.push(new OrderBy({ field: "LastSubmittedDate", ascending: false }));
        }

        return client.getMyProjectsByFilter(request);
    }

    GetProjectCategory() {
        return Utility.enumToSelectionOptionArray(ProjectCategory, true);
    }

    GetFundingPurpose() {
        return Utility.enumToSelectionOptionArray(FundingPurpose, true);
    }

    GetFundingType() {
        return Utility.enumToSelectionOptionArray(FundingType, true)
            .map(x => x.value === FundingType.ImpactInvestment ? ({ value: x.value, text: "Equity Investment (Jamaica Impact Investment Market-JIIM)" }) :
                ({ value: x.value, text: "Donations (Jamaica Social Investment Market-JSIM)" }));
    }

    GetFileType() {
        return Utility.enumToSelectionOptionArray(DataFileType, true);
    }

    getProjectStatus() {
        return Utility.enumToSelectionOptionArray(ProjectStatus, true);
    }

    AddToCart(id?: string) {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;

        return client.addToCart(id, 0);
    }

    GetProjectById(id: string) {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;

        return client.getProjectByIdForEntrepreneur(id);
    }


    AddProject(model: ICreateProjectModel) {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;

        const request = new CreateProjectModel(model);
        return client.createProject(request);
    }

    EditProject(model: IEditProjectModel) {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;

        const request = new EditProjectModel(model);
        return client.editProject(request);
    }

    submitForApproval(id) {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;

        const request = new SubmitProjectForApprovalCommandRequest({ id: id });
        return client.submitProjectForApproval(request);
    }

    SubmitProjectUpdateForApproval(id) {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;

        const request = new SubmitProjectUpdateForApprovalCommandRequest({ id: id });
        return client.submitProjectUpdateForApproval(request);
    }

    UpsertProjectUpdate(model: IUpsertProjectUpdateModel) {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;

        const request = new UpsertProjectUpdateModel(model);
        return client.upsertProjectUpdate(request);
    }

    GetProjectUpdateDetailsByProjectId(projectId: string) {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;

        const request = new GetProjectUpdateDetailsByProjectIdQueryRequest({ projectId: projectId });
        return client.getProjectUpdateDetailsByProjectId(request);
    }

    RefreshToken() {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;
        client.refreshIdle();
    }

    GetMyCompanyProfile() {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;

        return client.getMyCompanyProfile();
    }

    EditCompany(model: IEditCompanyModel) {
        const client = new SecuredClient();
        client.showLoading = true;
        client.handleGeneralError = true;

        const request = new EditCompanyModel(model);
        return client.editCompany(request);
    }



}










