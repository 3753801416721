import * as React from 'react';

import { RouteComponentProps, NavLink, Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import { startCase } from 'lodash';
import { RightAlignedRow } from '../Styled/Layout/RightAlignedRow';
import { Page, SearchPanel, ProjectListing, Banner, FundedBadge } from './ProjectListStyled';
import TableFilterContextProvider from '../Forms/DataGrid/TableFilterContextProvider';
import { Avatar, Button, Card, Col, Icon, Input, Progress, Row, Popover, Modal } from 'antd';
import ISelectOption from '../Forms/SimpleSelect/ISelectOption';
import SimpleSelect from '../Forms/SimpleSelect';
import { ProjectsHandler } from '../ProjectsListView/ProjectsHandler';
import { ProjectListModel } from '../../Core/Api/Api';
import NumberFormat from 'react-number-format';
import DetailsMode from '../../Core/models/DetailsMode';
import SharePopup from '../Styled/SocialMedia/SharePopup';
import NotificationService from '../../Core/NotificationService';
import FilterTextbox from 'components/Forms/DataGrid/FilterTextbox';
import SimpleViewList from 'components/Forms/DataGrid/SimpleViewList';
import FullyFundedImg from 'assets/images/fully-funded.png';
import { useCartContext } from 'Context/CartContext';

const { Search } = Input;
interface Props extends RouteComponentProps {
    children?: React.ReactNode;
}

interface State {
    projects?: ProjectListModel[];
    projectNames?: ISelectOption[];
    statusList?: ISelectOption[];
    activeProject?: ProjectListModel;
    showModal: boolean;
    selectedItemId?: string;
    selectedItemName?: string;

}
const ProjectListWithoutRouter: React.FC<Props> = (props) => {
    const handler = new ProjectsHandler(props);
    const cartContext = useCartContext();

    const [state, setState] = React.useState<State>({
        showModal: false,
    });





    const handleAddToCart = (itemId: string | undefined, itemName: string | undefined) => {

        setState({
            ...state,
            showModal: true,
            selectedItemId: itemId,
            selectedItemName: itemName,
        });
        addToCart(itemId);

    };

    const handleModalConfirm = () => {
        const { selectedItemId } = state;
        addToCart(selectedItemId)

        setState({
            ...state,
            showModal: false,
            selectedItemId: '',
        });
    };

    const handleModalCancel = () => {
        setState({
            ...state,
            showModal: false,
            selectedItemId: '',
        });
    };

    const addDirectlyToCart = (itemId: string | undefined) => {
        addToCart(itemId)
    }

    const getProjects = () => {
        //this.setState({
        //     projects: [{ thumbnailFileUrl: "#", name: 'Mustard Seed Communities', companyName: 'Mustard Seed Communities', amountReceived: 1900750.00, amountRequested: 6000000.00 },
        //         { thumbnailFileUrl: "#", name: 'Mona-Tech Engineering Support', companyName: 'Mona-Tech Engineering Services Limited', amountReceived: 3509200.00, amountRequested: 3509200.00 },
        //         { thumbnailFileUrl: "#", name: 'Connect A Child JA', companyName: 'One Laptop Or Tablet Per Child Initiative', amountReceived: 2139792.50, amountRequested: 200000000.00 },
        //         { thumbnailFileUrl: "#", name: 'Mustard Seed Communities', companyName: 'Mustard Seed Communities', amountReceived: 1900750.00, amountRequested: 6000000.00 },
        //         { thumbnailFileUrl: "#", name: 'Mona-Tech Engineering Support', companyName: 'Mona-Tech Engineering Services Limited', amountReceived: 3509200.00, amountRequested: 3509200.00 },
        //         { thumbnailFileUrl: "#", name: 'Connect A Child JA', companyName: 'One Laptop Or Tablet Per Child Initiative', amountReceived: 2139792.50, amountRequested: 200000000.00 }
        //     ],
        // });

        // this.handler.GetProjectsForPublic().then(x => {
        //     this.setState({ projects: x.results })
        // });

    }

    const addToCart = (id) => {
        handler.AddToCart(id).then(x => {
            NotificationService.success("Added to cart");
            cartContext.updateCartItemCount();
        });
    }

    React.useEffect(() => {
        getProjects();
    }, []);


    return (
        <Page style={{ width: '100%' }}>
            <TableFilterContextProvider>
                <SearchPanel>
                    <Row type={'flex'} >
                        <Col className={"filters"} span={24}>
                            <Col span={9} className={"keyword"}>
                                <FilterTextbox filterFields='name,subTitle' placeholder="Filter projects by title" className='search-textbox' />
                            </Col>

                        </Col>
                    </Row>
                </SearchPanel>
                <ProjectListing>
                    <Modal
                        destroyOnClose={true}
                        visible={state.showModal}
                        onCancel={handleModalCancel}
                        title={state.selectedItemName + " added to cart"}
                        footer={[
                            <Button key="ok" type='primary' onClick={() => handleModalCancel()}>
                                Ok
                            </Button>
                        ]}
                    >
                        <a style={{ fontSize: 20, textDecoration: 'underline' }} href='/donate'>View your cart</a>
                    </Modal>


                    <Row className={'itemsRow'}>
                        <Col className={'itemsCol'} xs={{ span: 23, offset: 1 }} sm={{ span: 24 }} md={{ span: 20 }} lg={{ span: 22, offset: 2 }} xl={{ span: 20, offset: 4 }}>
                            <SimpleViewList<ProjectListModel>
                                renderItem={
                                    item => {
                                        return <Col xs={24} xl={12} xxl={8} className={'projectItem'}>
                                            <Card className={'projectCard'} >
                                                <Row>
                                                    <Col className={'projectImage'} xs={{ span: 24 }}>
                                                        {(item.amountReceived ?? 0) >= (item.amountRequested ?? 0) ?
                                                            <Link to={`/projects/view/${item.id}`} onClick={() => window.scrollTo(0, 0)}>
                                                                <div className="center-avatar">
                                                                    <Avatar src={item.thumbnailFileUrl} shape="square" />
                                                                    <FundedBadge src={FullyFundedImg} />
                                                                </div>
                                                            </Link>
                                                            :
                                                            <Link to={`/projects/view/${item.id}`} onClick={() => window.scrollTo(0, 0)}>

                                                                <div className="center-avatar"> <Avatar src={item.thumbnailFileUrl} shape="square" />
                                                                </div>
                                                            </Link>
                                                        }
                                                    </Col>
                                                    <Col style={{ paddingTop: '15px' }} className={'projectFooter'} xs={{ span: 24 }}>
                                                        <div className= 'scrollable-text'>
                                                            <p className={'projectTitle'}>{item.name}</p>
                                                            <p className={'projectSubtitle'}>{item.companyName}</p>
                                                        </div>
                                                        <div style={{ textAlign: 'right' }}> <div className="" style={{ marginLeft: '15px' }}>
                                                            <SharePopup shareMessage={'Donate now to the ' + (state.activeProject?.name ?? '') + ' Project'}
                                                                popupMessage={'Share Project'}
                                                                url={window.location.href + '/view/' + item.id ?? ''} title="Share">
                                                                <Button onClick={() => setState({ ...state, activeProject: item })} style={{ borderRadius: '16px', color: 'white', backgroundColor: '#646464' }}><Icon type="share-alt" /> Share</Button>
                                                            </SharePopup>
                                                        </div></div>
                                                        <div style={{ paddingTop: '10px' }} >
                                                            {(item.amountReceived ?? 0) >= (item.amountRequested ?? 0) ?
                                                                <p className={'goal'}>JMD$<NumberFormat displayType='text' decimalScale={2} fixedDecimalScale={true} thousandSeparator=',' value={item.amountReceived} />&nbsp;raised <div className={'green'}>&nbsp;of $<NumberFormat displayType='text' decimalScale={2} fixedDecimalScale={true} thousandSeparator=',' value={item.amountRequested} /></div></p>
                                                                :
                                                                <p className={'goal'}><div className={'green'}>JMD$<NumberFormat displayType='text' decimalScale={2} fixedDecimalScale={true} thousandSeparator=',' value={item.amountReceived} />&nbsp; raised </div>&nbsp;of $<NumberFormat displayType='text' decimalScale={2} fixedDecimalScale={true} thousandSeparator=',' value={item.amountRequested} /></p>
                                                            }
                                                            <Progress className={'progressBar'} showInfo={false} percent={(item.amountRequested ?? 0) == 0 ? 0 : ((item.amountReceived ?? 0) / (item.amountRequested ?? 0)) * 100} ></Progress>
                                                        </div>
                                                    </Col>
                                                    <Col className={'projectButtons'} xs={{ span: 24 }}>
                                                        <Col style={{ display: 'inline-block', paddingRight: '1px' }}>
                                                            <NavLink to={{
                                                                pathname: 'projects/view/' + encodeURI(item.id ?? ''),
                                                                state: { id: item.id, mode: DetailsMode.New }
                                                            }}>
                                                                <Button className={'view'}>View</Button></NavLink></Col>
                                                        <Col style={{ display: 'inline-block', paddingRight: '1px' }}>
                                                            <Button className="donate" onClick={() => handleAddToCart(item.id, item.name)}>
                                                                Donate
                                                            </Button>
                                                        </Col>
                                                        <Col style={{ display: 'inline-block' }}><NavLink to={{
                                                            pathname: 'pledge/' + encodeURI(item.id ?? ''),
                                                            state: { id: item.id, mode: DetailsMode.New }
                                                        }}><Button className={'makeAPledge'}>Make a Pledge</Button></NavLink></Col>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    }
                                }
                                pagination={{ hideOnSinglePage: true, className: 'paginatorClass' }}
                                dataFetcher={handler} >
                            </SimpleViewList>
                        </Col>
                    </Row>
                </ProjectListing>
            </TableFilterContextProvider>
        </Page>
    );

};

const ProjectList = withRouter(ProjectListWithoutRouter);

export default ProjectList;











