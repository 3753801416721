import { RegisterFormModel } from './Models/RegisterFormModel';
import { SecuredClient } from '../../../Core/Api/SecuredClient';
import { IRegisterInvestorOrEntrepreneurWebModel, RegisterInvestorOrEntrepreneurWebModel, SlimUser, } from '../../../Core/Api/Api';

export class RegisterFormHandler {
    props: any;
    constructor(props) {
        this.props = props;
    }

    RequestRegister(RegisterformModel: IRegisterInvestorOrEntrepreneurWebModel): Promise<SlimUser | undefined> {
        const client = new SecuredClient();
        const request = new RegisterInvestorOrEntrepreneurWebModel();
        request.init(RegisterformModel);
        console.log(request);
        client.showLoading = true;
        client.handleGeneralError = true;
        return client.register(request);
    }
}
















