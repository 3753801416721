import * as React from 'react';
import {Col, Row } from 'antd'
import { RouteProps } from 'react-router-dom';
import styled from 'styled-components';
import { HowToDonate } from '../../../components/Information/HowToDonate/index';
import { BannerDiv, CenteredFormContent } from 'components/Layout/LoginLayout/LoginLayout.Style';
import Title from 'antd/lib/typography/Title';

export interface Props extends RouteProps {
    children?: React.ReactNode;
}

export interface State {
}

const TallCol = styled(Col)`
  height: 100%;
  min-height: 200px;
`;

export default class HowToDonatePage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);


    }

    componentDidMount() {

    }

    render() {
        return <CenteredFormContent>
            <BannerDiv className={"bannerAlternative"}>
                <Row style={{ height: '100%' }}>
                    <Col sm={24} className={'bannerNav'}>
                        <div className='pageTitle'>
                            <Title>How To Donate</Title>
                        </div>
                    </Col>
                </Row>
            </BannerDiv>
            <Row type="flex" align="middle" justify="center">
                <Col span={24}>
                    <HowToDonate {...this.props} />
                </Col>
            </Row>
        </CenteredFormContent>;


    }
}










