import { SecuredClient } from '../../Core/Api/SecuredClient';
import {
    RequestPasswordResetCommandRequest,
    IRequestPasswordResetCommandRequest,
    ResetPasswordCommandRequest,
    IResetPasswordCommandRequest,
    ChangePasswordCommandRequest,
    IChangePasswordCommandRequest,
    SimpleUserProfile,

} from '../../Core/Api/Api';

export default class UserProfileHandler {
    props: any;
    constructor(props) {
        this.props = props;
    }

    ResetPassword(model: IResetPasswordCommandRequest) {
        const client = new SecuredClient();
        client.showLoading = true;
        client.handleGeneralError = true;
        const request = new ResetPasswordCommandRequest(model);

        return client.resetPassword(request);
    }

    ChangePassword(model: IChangePasswordCommandRequest) {
        const client = new SecuredClient();
        client.showLoading = true;
        client.handleGeneralError = true;
        const request = new ChangePasswordCommandRequest(model);

        return client.changePassword(request);
    }

    GetMyProfile() {
        const client = new SecuredClient();
        client.showLoading = true;
        client.handleGeneralError = true;
        const request = new ChangePasswordCommandRequest();

        return client.getMyUserProfile();
    }

    EditUserProfile(profile: SimpleUserProfile) {
        const client = new SecuredClient();
        client.showLoading = true;
        client.handleGeneralError = true;

        return client.editUserProfile(profile);
    }

}










