import { Form, Icon, Input, Button, Checkbox, Spin } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React from 'react';
import { Redirect, RouteProps, NavLink } from 'react-router-dom';
import AuthContext from '../../../Context/AuthContext';
import FormControl from '../../Forms/FormControl';
import { StyleConfirmEmailForm } from './ConfirmEmailForm.Style';
import { ConfirmEmailFormHandler } from './ConfirmEmailFormHandler';
import Title from 'antd/lib/typography/Title';
import Paragraph from 'antd/lib/typography/Paragraph';

export interface Props extends FormComponentProps, RouteProps {
  children?: React.ReactNode;
}

export interface State {
  isConfirmed: boolean;
}

class BaseConfirmEmailForm extends React.Component<Props, State> {
  static contextType = AuthContext;
  context!: React.ContextType<typeof AuthContext>;

  confirmEmailFormHandler: ConfirmEmailFormHandler;

  constructor(props: Props) {
    super(props);
    this.confirmEmailFormHandler = new ConfirmEmailFormHandler(props);
    this.state = { isConfirmed: false };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    // if (this.context.data) {
    //   this.setState({ isConfirmed: true });
    // }
  }

  handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    // this.props.form.validateFieldsAndScroll((err, values) => {
    //   if (!err) {
    //     this.confirmEmailFormHandler.ConfirmEmail(values.email).then(user => {
    //       this.context.Update(user);
    //       this.setState({ isLoggedIn: true });
    //     });
    //   }
    // });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    let state: { from?: any; noPermission?: boolean } = {};

    if (this.props.location) {
      state = this.props.location.state || {};
    }


    return (
      <StyleConfirmEmailForm onSubmit={this.handleSubmit}>

        <Paragraph> Please wait while we confirm your email address.</Paragraph>

        <Spin size="large" />

      </StyleConfirmEmailForm>
    );
  }

  private ShouldRedirectTo(state: { from?: any; noPermission?: boolean | undefined }): boolean {
    return !!state.from && state.from.pathname !== '/logout';
  }
}

const ConfirmEmailForm = Form.create<Props>({
  name: 'Login',
})(BaseConfirmEmailForm);

export default ConfirmEmailForm;













