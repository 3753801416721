import {
    Form,
    Icon,
    Input,
    Button,
    Checkbox,
    Spin,
    InputNumber,
    Row,
    Col,
    Typography,
    Descriptions,
    Divider,
    Avatar,
    Modal,
    Popover,
    Upload,
} from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React, { ChangeEvent } from 'react';
import { Redirect, RouteProps, NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import AuthContext from 'Context/AuthContext';
import FormControl from '../Forms/FormControl';
import Title from 'antd/lib/typography/Title';
import Paragraph from 'antd/lib/typography/Paragraph';
import ValidationUtility from 'Core/ValidationUtility';
import queryString from 'query-string';
import SimpleSelect from '../Forms/SimpleSelect';
import IFilterSelectOption from '../Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import Utility from 'Core/Utility/Utility';
import NotificationService from 'Core/NotificationService';
import DetailsMode from 'Core/models/DetailsMode';
import { CartModel, Item, PaymentMethod } from '../../Core/Api/Api';
import SimpleUpload from 'components/Forms/SimpleUpload';
import DetailsData from 'Core/models/DetailsData';
import { debounce, startCase } from 'lodash';
import { LeftAlignedRow } from '../Styled/Layout/LeftAlignedRow';
import moment from 'moment';
import ConfigService from 'Core/ConfigService';
import Config from 'Core/Config.json';
import renderHTML from 'react-render-html';
import { WhiteBackgroundCard, singleColFormColSize } from '../Forms/Form.Styled';

import NumberFormat from 'react-number-format';
import { RightAlignedRow } from '../Styled/Layout/RightAlignedRow';
import { BrandedButton } from '../Styled/Branded/BrandedButton';
import TextArea from 'antd/lib/input/TextArea';
import numeral from 'numeral';
import { CartHandler } from './CartHandler';
import { CenterAlignedRow } from '../Styled/Layout/CenterAlignedRow';
import Search from 'antd/lib/input/Search';
import TableRequest from 'Core/TableUtility/Models/TableRequest';
import { FilterType, JoinType } from 'Core/Api/Api';


export interface Props extends RouteComponentProps {
    children?: React.ReactNode;
}

export interface State {
    cart?: Item[];
}


export class CartListViewWithoutRouter extends React.Component<Props, State> {
    handler: CartHandler;
    constructor(props: Props) {
        super(props);

        this.state = {};
        this.handler = new CartHandler(props);
    }

    componentDidMount() {
        this.getCart();

    }

    getCart() {
        this.handler.GetCart().then(x => {
            this.setState({ cart: x.cart?.items })
        })
    }


    onChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.filterProjects(event.currentTarget.value);
    };

    filterProjects = debounce((text: any) => {
        var request = new TableRequest();
        if (text) {
            request.filters = [];
            request.filters?.push({
                value: text,
                filterType: FilterType.Contains,
                joinType: JoinType.Or,
                field: "title"
            });
        }
        //this.handler.GetAllSlimOfferingsByFilter(request).then(x => {
        //    this.setState({ offerings: x.results })
        //})
    }, 700);

    render() {
        return (
            <Row>
                <Col span={24} style={{ padding: '20px' }}>
                    <Row>
                        <Col>
                            <Title style={{ color: '#202020' }}>
                                Projects
                            </Title>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={16} offset={4}>
                            <Search
                                placeholder="Search for an IPO..."
                                onSearch={value => this.filterProjects(value)}
                                onChange={this.onChange}
                                style={{ marginBottom: '50px', marginTop: '50px', height: '60px', fontSize: '16px' }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form>
                                <Row>
                                    <Col md={12} xs={24}>
                                        <h6><b>Donation Cart</b> ({this.state.cart?.length} Items)</h6>
                                    </Col>
                                    <Col md={12} xs={24}>
                                        <i className="warning-text">N.B. For PayPal we only accept USD donations.</i>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}


const CartListView = withRouter(CartListViewWithoutRouter);

export default CartListView;


