import { Row, Col, Button } from 'antd';
import { AddCompanyProjectsForm } from 'components/CompanyProjects/AddCompanyProjectsForm';
import { CompanyProjectsList } from 'components/CompanyProjects/CompanyProjectsList';

import { CenteredFormContent, BannerDiv } from 'components/Layout/LoginLayout/LoginLayout.Style';
import React from 'react';
import styled from 'styled-components';

const Wrap = styled.div``;

interface Props {
}

export default ({ }: Props) => {
  return <CenteredFormContent>
    <BannerDiv className={"board"}>
      <Row style={{ height: '100%' }}>
      </Row>
    </BannerDiv>
    <Row type="flex" align="middle" justify="center">
      <Col span={20} >
        <AddCompanyProjectsForm />
      </Col>
    </Row>
  </CenteredFormContent>;;
}