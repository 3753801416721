import * as React from 'react';
import { Upload } from 'antd';
import { random } from 'lodash';
import { UploadProps, UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { LongUpload } from './SimpleUpload.Styled';

declare type fileType = File;

export interface Props extends Omit<UploadProps, 'onChange'> {
  onChange?: Function;
  value?: UploadFile[];
  children?: React.ReactNode;
}

export interface State {
  fileList?: UploadFile[];
}

export default class SimpleUpload extends React.Component<Props, State> {
  static defaultProps: Props = {
    listType: 'picture',
    beforeUpload: () => false,
  };

  constructor(props: Props) {
    super(props);

    this.state = { fileList: props.value };
  }

  handlOnChange = (uploadEvt: UploadChangeParam) => {
    const onChange = this.props.onChange ?? (() => null);
    const files = uploadEvt.fileList.map(x => x.originFileObj as File);

    onChange(files);
    this.setState({ fileList: uploadEvt.fileList.slice() });
  };

  render() {
    let { onChange, className, ...rest } = this.props;
    const uploadCssClass = className + ' ' + (this.props.listType == 'picture' ? 'long' : '');

    return (
      <LongUpload
        {...rest}
        className={uploadCssClass}
        fileList={this.props.value}
        defaultFileList={this.props.defaultFileList}
        onChange={this.handlOnChange}
        children={this.props.children}
      />
    );
  }
}










