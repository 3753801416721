import { List } from 'antd';
import SimpleViewList from 'components/Forms/DataGrid/SimpleViewList';
import { NewsArticleCard } from 'components/News/NewsArticleCard';
import { NewsHandler } from 'components/News/NewsHandler';
import { NewsListModel } from 'Core/Api/Api';
import React from 'react';




interface Props {
}

export default ({ }: Props) => {

  const handler = new NewsHandler({});



  return <SimpleViewList<NewsListModel>
    grid={{ gutter: 25, sm: 1, md: 2, lg: 3 }}
    dataFetcher={handler}
    scrollToTop={true}
    pagination={{ position: "bottom", pageSize: 9, showSizeChanger: false, showQuickJumper: true }}
      renderItem={x => (<List.Item style={{ paddingBottom: '2vh'}}>
        <NewsArticleCard showDate={true} isFullGrid={true} news={x} />
    </List.Item>)}
  />;
}