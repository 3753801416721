import {
    Form,
    Icon,
    Input,
    Button,
    Checkbox,
    Spin,
    InputNumber,
    Row,
    Col,
    Typography,
    Descriptions,
    Divider,
    Avatar,
    Modal,
    Popover,
    Upload,
    Radio,
    Card,
} from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React, { useContext, useEffect } from 'react';
import { Redirect, RouteProps, NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import AuthContext from '../../../Context/AuthContext';
import FormControl from '../../Forms/FormControl';
import Title from 'antd/lib/typography/Title';
import DetailsMode from 'Core/models/DetailsMode';
import { CartItemModel, CartModel, GetPaymentDetailsQueryResponse, Item, Operations, PaymentMethod, PaymentStatus } from '../../../Core/Api/Api';
import SimpleUpload from 'components/Forms/SimpleUpload';
import DetailsData from 'Core/models/DetailsData';
import { debounce, forEach, initial, startCase } from 'lodash';
import { LeftAlignedRow } from '../../Styled/Layout/LeftAlignedRow';
import moment from 'moment';
import ConfigService from '../../../Core/ConfigService';
import Config from '../../../Core/Config.json';
import renderHTML from 'react-render-html';
import { WhiteBackgroundCard, singleColFormColSize } from '../../Forms/Form.Styled';

import NumberFormat from 'react-number-format';
import { RightAlignedRow } from '../../Styled/Layout/RightAlignedRow';
import { BrandedButton } from '../../Styled/Branded/BrandedButton';
import ConfirmRemoveButton from 'components/Shared/ConfirmRemoveButton';
import TextArea from 'antd/lib/input/TextArea';
import numeral from 'numeral';
import { ProjectsHandler } from '../../ProjectsListView/ProjectsHandler';
import { CenterAlignedRow } from '../../Styled/Layout/CenterAlignedRow';
import NotificationService from '../../../Core/NotificationService';
import ShowIfHavePermission from '../../Forms/ShowIfHavePermission';
import Utility from '../../../Core/Utility';
import ValidationUtility from '../../../Core/ValidationUtility';
import { DonateLoginForm } from './DonateLoginForm';
import PrivacyPdf from 'assets/pdfs/privacy.pdf';
import styled from 'styled-components';
import './DonateStyle.css';
import { useCartContext } from 'Context/CartContext';

interface Props extends FormComponentProps, RouteComponentProps {
    children?: React.ReactNode;
}

const ProjectCardsCart = styled.div`
    .projectCard {
        background-color: white;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        position: relative;
    }

  .specialCard {
        border: 2px solid #24C4E5;
    }
    .specialProjectIcon {
        position: absolute;
        top: -67px; 
        right: -54px; 
    }
`;
/*
        backgroundColor: 'white', height: '100%',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        style={{ marginTop: '20px', backgroundColor: 'white', padding: '20px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', border: '1px solid rgba(0, 0, 0, 0.1)', borderRadius: '10px' }}
    */

interface State {
    currency: string;
    cart?: CartModel;
    items?: Item[];
    total: number;
    showPaymentModal: boolean;
    showPaymentCompletedModal?: boolean;
    paymentUrl?: string;
    transactionRef?: string;
    paymentLinkClicked: boolean;
    checkForPayment?: boolean;
    paymentReceived: boolean;
    orderId?: string;
    completed: boolean;
    paymentStatus?: PaymentStatus;
    payment?: GetPaymentDetailsQueryResponse;
    requireAdditionalInfo: boolean;
    showLoginToDonate: boolean;
    showDonateWithoutLogin: boolean;
    cartInvalid?: boolean;
    numberOfCartItems: number;
}

const DonateFormWithoutFormAndRouter: React.FC<Props> = (props) => {
    const authContext = useContext(AuthContext);
    const cartContext = useCartContext();
    const { getFieldDecorator } = props.form;
    const [state, setState] = React.useState<State>({
        currency: 'JMD',
        total: 0,
        showPaymentModal: false,
        paymentLinkClicked: false,
        paymentReceived: false,
        requireAdditionalInfo: false,
        showLoginToDonate: false,
        numberOfCartItems: 0,
        showDonateWithoutLogin: false,
        completed: false
    });
    let intervalId;
    const handler = new ProjectsHandler(props);


    const getCart = () => {
        handler.GetCart().then(x => {
            //console.log(x.cart);
            setState((prevState) => ({
                ...prevState,
                cart: x.cart,
                items: x.cart?.items,
                cartInvalid: x.cart?.invalidCartCheckout,
                numberOfCartItems: x.cart?.items?.length ? x.cart?.items?.length : 0,
            }));
        });
    }

    const removeFromCart = (val) => {

        return handler.RemoveFromCart(val).then(x => {
            getCart();
            cartContext.updateCartItemCount();
        });
    }

    const debouncedEmailSearach = debounce(async (val) => {
        //console.log('! - ' + val);
        handler.RequireAdditionalDetailsForPayment(val).then(x => {
            setState((prevState) => ({ ...prevState, requireAdditionalInfo: x.additionalDetailsRequired ?? false }));
        });
    }, 700);

    const emailChange = (val) => {
        //console.log(val);
        debouncedEmailSearach(val.target.value);
        //return this.handler.RequireAdditionalDetailsForPayment(val).then(x => {
        //    this.setState({ requireAdditionalInfo: x.additionalDetailsRequired ?? false })
        //});
    }

    const paymentClicked = () => {
        //console.log('Payment Clicked');
        setState((prevState) => ({
            ...prevState,
            paymentLinkClicked: true,
            checkForPayment: true,
        }));
        //console.log(state);
        //setInterval(checkForPayment, 5 * 1000);
    };

    const paymentExpired = () => {
        if (state.checkForPayment) {
            //console.log("EXPIRED PAYMENT LINK -------------------------------------------")
            setState((prevState) => ({
                ...prevState,
                checkForPayment: false,
                showPaymentModal: false,
                paymentUrl: undefined,
                paymentLinkClicked: false,
                showPaymentCompletedModal: false,
            }));

            NotificationService.error('Link has expired. Please try again.', null, 0, true);
        }
    };

    const checkForPayment = () => {
        //console.log(' checking1');
        ////console.log(state.transactionRef)
        //console.log(state)

        if (state.checkForPayment) {
            //console.log('checking');
            //console.log(state);

            handler.CheckPaymentStatus(state.orderId).then((x) => {
                //console.log(x);
                if (x.status !== PaymentStatus.AwaitingAuthentication) {
                    //console.log("PAYMENT RECIEVED HERE -------------------------------------------")
                    handler.GetPaymentInfo(state.orderId ?? '').then((x) => {
                        setState((prevState) => ({
                            ...prevState, payment: x
                        }));
                    });
                    clearInterval(intervalId);

                    setState((prevState) => ({
                        ...prevState,
                        checkForPayment: false,
                        paymentReceived: true,
                        paymentStatus: x.status,
                        showPaymentCompletedModal: true,
                        showPaymentModal: false,
                    }));
                }
            });
        }
    };

    const onChange = (e) => {
        //console.log(e);
        setState((prevState) => ({
            ...prevState,
            currency: e.target.value,
        }));
    };

    const checkAmount = (items) => {
        const minJMD = 1;
        const minUSD = 1;

        return items.every((item) => {
            if (state.currency === 'JMD' && item.amount < minJMD) {
                NotificationService.error(`Amount cannot be less than JMD $${minJMD}`);
                return false;
            } else if (state.currency === 'USD' && item.amount < minUSD) {
                NotificationService.error(`Amount cannot be less than USD $${minUSD}`);
                return false;
            }
            return true;
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (err) {
                return;
            }

            if (!checkAmount(state.items)) {
                return;
            }

            const items = state.items?.map((e) => {
                const item = new CartItemModel();
                item.amount = e.amount;
                item.itemId = e.id;
                return item;
            });
            const timeoutId = setTimeout(paymentExpired, 5 * 60 * 1000);
            handler.GetUrl(state.currency, state.total, items).then((x) => {
                setState((prevState) => ({
                    ...prevState,
                    paymentUrl: x.paymentUrl,
                    transactionRef: x.transactionId,
                    showPaymentModal: true,
                    orderId: x.orderId,
                    paymentReceived: false,
                    paymentStatus: undefined,
                    checkForPayment: true
                }));

                if (x.success) {
                    clearTimeout(timeoutId);
                }
            });
        });

    };

    const guestSubmit = (e) => {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (err) {
                return;
            }

            if (!checkAmount(state.items)) {
                return;
            }

            const items = state.items?.map((e) => {
                const item = new CartItemModel();
                item.amount = e.amount;
                item.itemId = e.id;
                return item;
            });
            const timeoutId = setTimeout(paymentExpired, 5 * 60 * 1000);

            handler.GetGuestUrl(
                state.currency,
                state.total,
                items,
                values.email,
                values.firstName,
                values.lastName,
                values.phoneNumber
            ).then((x) => {
                setState((prevState) => ({
                    ...prevState,
                    paymentUrl: x.paymentUrl,
                    transactionRef: x.transactionId,
                    showPaymentModal: true,
                    checkForPayment: true,
                    orderId: x.orderId,
                    paymentReceived: false,
                    paymentStatus: undefined,
                }));
                if (x.success) {
                    clearTimeout(timeoutId);
                }
            });
        });

    };

    const amountChanged = (values, id) => {
        //console.log(values);
        //console.log(id);

        var runningTotal = 0;
        const newItems = state.items?.map(p => {
            if (p.id == id) {
                p.amount = values.floatValue;
            }

            runningTotal += (p.amount ?? 0);
            return p;
        });

        setState((prevState) => ({
            ...prevState, items: newItems, total: runningTotal
        }));
    };

    const handleCancel = (event) => {
        //console.log("cancel pressed")
        setState((prevState) => ({
            ...prevState,
            showPaymentModal: false,
            paymentUrl: undefined,
            paymentLinkClicked: false,
            showPaymentCompletedModal: false,
        }));
    };

    const handleCancel2 = (event) => {
        console.log('Reloading');
        setState((prevState) => ({
            ...prevState,
            completed: true
        }));
       /* window.location.reload();*/
    };

    const OptionButton = styled(Button)`
        width: 100%;
        height: 7vh;
        font-weight: 500;
        font-size: 24px;
        overflow: hidden;
    `;

    const SpecialProjectIcon = ({ size }) => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="#24C4E5"
            width={size}
            height={size}
            viewBox="0 0 16 16"
        >
            <path d="M8 0l2.9 5.9h6.1l-4.4 4.2 1.1 6.1-5.6-3-5.6 3 1.1-6.1-4.4-4.2h6.1z" />
        </svg>
    );

    React.useEffect(() => {
        getCart();
    }, []);

    React.useEffect(() => {
        intervalId = setInterval(() => {
            checkForPayment();
        }, 5 * 1000);

        return () => clearInterval(intervalId);
    }, [state.checkForPayment]);



    if (state.completed) {
        return <Redirect to="/projects" />;
    }


    return (
        <>
            <Modal
                width="70%"
                title={<Title level={3}>Payment {state.paymentStatus == PaymentStatus.Successful ? 'Successful' : 'Failed'}</Title>}
                visible={state.showPaymentCompletedModal}
                onCancel={handleCancel2}
                footer={[<Button key="back" onClick={handleCancel2}>
                    Close
                </Button>]} >


                {state.paymentStatus == PaymentStatus.Successful ?
                    <>
                        <Row>
                            <Col span={24}>
                                <Title level={2}>Your donation was successful!</Title>
                                an email has been sent with your receipt.

                                <h3>Jamaica Social Stock Exchange</h3>
                                <h5>
                                    40 Harbour Street, P.O.Box 1084, Kingston, Jamaica, West Indies.
                                </h5>
                                <h5>email: jsse@jamstockex.com | call:(876) 967-3271 ext. 2363, (876) 618-1118 ext. 2363
                                </h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <div>
                                    <h5>Customer Name:</h5>
                                    <h4>{state.payment?.user?.firstName} {state.payment?.user?.lastName}</h4>
                                </div>
                                <div>
                                    <h5>Payment Method:</h5>
                                    <h4>{state.payment?.paymentTypeName}</h4>
                                </div>
                                <div>
                                    <h5>Donation Date:</h5>
                                    <h4>{state.payment?.date} </h4>
                                </div>
                                <div>
                                    <h5>Invoice #:</h5>
                                    <h4>{state.payment?.invoiceNumber}</h4>
                                </div>

                                <Divider></Divider>
                                {state.items ?
                                    state.items.map((item, i) => {
                                        return <Row>
                                            <Col span={24}>
                                                <Row>
                                                    <Col sm={16} lg={8}>
                                                        {item.projectName}
                                                    </Col>
                                                    <Col sm={8} lg={4}>
                                                        <NumberFormat required={true} value={item.amount}
                                                            displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} prefix={(state.currency ?? '') + ' $'} />
                                                    </Col>
                                                </Row>

                                            </Col>
                                        </Row>
                                    }
                                    )
                                    : ''
                                }
                                <br />
                                <p>* We will respond to customer service queries within two business days</p>
                                <p>* Please retain a copy of the receipt to use if you have any queries</p>
                            </Col>
                        </Row>

                    </>
                    :
                    <>
                        <Title level={4}>Failed to process order</Title>
                        <p>
                            Something when wrong with the order. Please contact the adminstrator with this code: {state.orderId}

                        </p>

                        <p>Invoice Number: {state.payment?.invoiceNumber}</p>
                    </>
                }

            </Modal>

            <Modal
                width="70%"
                title={<Title level={3}>Cart Totals</Title>}
                visible={state.showPaymentModal}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Close
                    </Button>
                ]} >
                <Title level={4}>DONATION DETAILS</Title>
                {state.items ?
                    state.items.map((item, i) => {
                        return <Row key={item.id}>
                            <Col span={24}>
                                <Row>
                                    <Col span={16}>
                                        {item.projectName}
                                    </Col>
                                    <Col span={8}>
                                        <NumberFormat required={true} value={item.amount}
                                            displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} prefix={(state.currency ?? '') + ' $'} />
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                    }
                    )
                    : ''
                }


                <Row>
                    <Col span={24}>
                        <Divider></Divider>
                        <Row>
                            <Col span={16}>
                                <b>
                                    Total Payable
                                </b>
                            </Col>
                            <Col span={8}>
                                <b>
                                    <NumberFormat required={true} value={state.total}
                                        displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} prefix={(state.currency ?? '') + ' $'} />
                                </b>
                            </Col>
                        </Row>

                        <br />
                        <Divider></Divider>
                    </Col>
                </Row>


                <RightAlignedRow>
                    <Col span={24}>
                        {state.paymentUrl ?
                            <>
                                <br />
                                <a href={state.paymentUrl} onClick={paymentClicked} style={{ cursor: (state.paymentLinkClicked ? 'not-allowed' : ''), pointerEvents: (state.paymentLinkClicked ? 'none' : 'unset'), height: '61px', display: 'inline-block', padding: '19px 18px', minWidth: '221px', backgroundColor: '#031926', borderRadius: '4px', color: 'white', fontSize: '21px', textAlign: 'center', textTransform: 'uppercase', letterSpacing: '1px', textDecoration: 'none', fontFamily: 'Helvetica, Arial, sans-serif' }}
                                    target="_blank" title="Fygaro Payment Processor">
                                    <svg style={{ display: 'inline', height: '24px', fill: '#d8aa4e', verticalAlign: 'sub' }} viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg">
                                        <path className="st0" d="M15.5 20.6c0 0 2.5-9.8-1-15.2S4.3 0.6 4.3 0.6 3.1 6.7 3.4 10.7 7.3 20.7 15.5 20.6z"></path>
                                        <path className="st0" d="M22 14.4c0 0 3.6-9.5 9.6-11.8s11.1 1.9 11.1 1.9-2.5 5.7-5.1 8.8S28.7 19.2 22 14.4z"></path>
                                        <radialGradient cx="22.1" cy="25.8" gradientUnits="userSpaceOnUse" r="18"><stop offset="0" stopColor="#FF9500"></stop>
                                            <stop offset="0.1622" stopColor="#FF8E05"></stop><stop offset="0.3902" stopColor="#FF7C14"></stop><stop offset="0.6573" stopColor="#FF5E2D"></stop><stop offset="0.9518" stopColor="#FF354F"></stop><stop offset="1" stopColor="#FF2D55"></stop></radialGradient>
                                        <circle className="st1" cx="22.1" cy="25.8" r="18"></circle><path className="st2" d="M33.7 12c0 0-6.7-2.3-10.1 4.2-1.6 3.6-1.6 9.7-1.6 9.7S33.7 30 33.7 12z"></path><path className="st2" d="M10.5 12c0 0 6.5-2.4 9.9 4.2 1.6 3.6 1.6 9.7 1.6 9.7S10.5 30 10.5 12z"></path>
                                        <path className="st3" d="M29.1 17.9c0 0-3.1-1.1-4.8 1.7-1.1 1.6-1.1 5.3-1.1 5.3S29.2 27 29.1 17.9z"></path><path className="st3" d="M14.8 17.9c0 0 3.1-1.1 4.8 1.7 1.1 1.6 1.1 5.3 1.1 5.3S14.8 27.1 14.8 17.9z"></path>
                                    </svg>&nbsp;Donate Now</a>
                                {state.paymentLinkClicked ?
                                    <> <Icon type="loading" /> Awaiting Payment Confirmation
                                    </>
                                    : ''}
                            </>
                            : ''
                        }

                    </Col>
                </RightAlignedRow>
            </Modal>

            {
                !Utility.HasOperations(authContext.data, [Operations.Is_Authenticated]) ?
                    <Row type='flex' justify='center' >
                        <Col xs={24} lg={15}>
                            <Row type='flex' justify='center' style={{ gap: '10px', paddingBottom: 20 }}>
                                <Col xs={24} sm={11}>
                                    <OptionButton
                                        type="primary" htmlType='button'
                                        onClick={() => {
                                            setState((prevState) => ({
                                                ...prevState, showLoginToDonate: true, showDonateWithoutLogin: false
                                            }))
                                        }}
                                    >
                                        Login to Donate
                                    </OptionButton>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <OptionButton
                                        type="primary" htmlType='button'
                                        onClick={() => {
                                            setState((prevState) => ({
                                                ...prevState, showDonateWithoutLogin: true, showLoginToDonate: false
                                            }))
                                        }}
                                    >
                                        Donate Without Login
                                    </OptionButton>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    : ''
            }

            <Row type='flex' justify='center'>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 15, offset: 0 }}>
                    {
                        state.showLoginToDonate ?
                            Utility.HasOperations(authContext.data, [Operations.Is_Authenticated]) ?
                                '' :
                                <DonateLoginForm onLoggedIn={getCart} items={state.items}></DonateLoginForm>
                            : ''
                    }

                    {
                        Utility.HasOperations(authContext.data, [Operations.Is_Authenticated]) || state.showDonateWithoutLogin ?
                            <Form labelCol={{ sm: 7 }}
                                wrapperCol={{ sm: 16 }}
                                onSubmit={handleSubmit}
                                layout="vertical">
                                <Col xs={{ span: 24 }} style={{ backgroundColor: "#ffff", padding: 50, boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', border: '1px solid rgba(0, 0, 0, 0.1)', borderRadius: '10px' }}>
                                    {
                                        Utility.HasOperations(authContext.data, [Operations.Is_Authenticated]) ?
                                            <LeftAlignedRow>
                                                <Title className={'sectionTitle'} level={2}>Donate</Title>
                                                <Col span={1} style={{ height: '0.6vh', backgroundColor: '#1d95d3', marginTop: '-2vh' }}  ></Col>
                                            </LeftAlignedRow>
                                            :
                                            state.showDonateWithoutLogin ?
                                                <LeftAlignedRow>
                                                    <Title className={'sectionTitle'} level={2}>Donate Without Login</Title>
                                                    <Col span={1} style={{ height: '0.6vh', backgroundColor: '#1d95d3', marginTop: '-2vh' }}  ></Col>
                                                </LeftAlignedRow>
                                                : ''
                                    }
                                    <Col xs={{ span: 24 }} lg={{ span: 24 }} style={{ padding: 20, paddingTop: 1 }}>
                                        <CenterAlignedRow>
                                            <Col span={24} style={{ paddingTop: '1.5vh' }}>
                                                <Title level={3} style={{ float: 'left' }}>Cart Details</Title>
                                                <Divider></Divider>

                                            </Col>
                                        </CenterAlignedRow>
                                        <LeftAlignedRow>
                                            <Col xs={24} >
                                                <Title level={4}>Select Currency</Title>
                                                <Radio.Group onChange={onChange} value={state.currency}>
                                                    <Radio value={'JMD'}>JMD</Radio>
                                                    <Radio value={'USD'}>USD</Radio>
                                                </Radio.Group>
                                            </Col>
                                        </LeftAlignedRow>
                                        <br />

                                        <Row type="flex">
                                            {state.cart && state.cart?.items ?
                                                state.cart.items.map((item, i) => {
                                                    return <>
                                                        <Col xs={{ span: '24' }} lg={{ span: '12' }} style={{
                                                            paddingLeft: '1vw', paddingTop: '1vh', paddingRight: '1vw',
                                                        }}>
                                                            <ProjectCardsCart>
                                                                <Card
                                                                    className={item.isSpecialProject ? "specialCard projectCard" : "projectCard"}
                                                                    title={''} >
                                                                    <Row>
                                                                        {item.isSpecialProject ? <Col className="specialProjectIcon"> <SpecialProjectIcon size={60} /> </Col> : ''}
                                                                        <Col xs={24} md={12} xl={10}>
                                                                            <div className="center-avatar"> <Avatar src={item.thumbnailFileUrl} size={128} shape="square" />
                                                                            </div>

                                                                        </Col>
                                                                        <Col xs={24} lg={20} xl={16} xxl={10}>
                                                                            <Row><Col style={{ overflowWrap: "break-word" }}><Title level={4}>
                                                                                {item.projectName}
                                                                            </Title>
                                                                                <Divider></Divider>
                                                                            </Col></Row>
                                                                        </Col>                                                          <Col xs={24}>  <Row><Col><b>Amount: </b></Col></Row>

                                                                            <Row><Col xs={{ span: 20 }} sm={{ span: 20 }}>
                                                                                <NumberFormat style={{
                                                                                    backgroundColor: 'white', height: '100%',
                                                                                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                                                                                    border: '1px solid #269b48',
                                                                                    borderRadius: '5px',
                                                                                    color: 'rgba(0, 0, 0, 0.7)'
                                                                                }} required={true} name={'amount' + i} value={item.amount} onValueChange={(values) => amountChanged(values, item.id)}
                                                                                    displayType={'input'} customInput={Input} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} prefix={(state.currency ?? '') + ' $'} />

                                                                            </Col>
                                                                                <Col xs={{ span: 1 }} sm={{ span: 3 }} style={{ marginLeft: '4px', float: 'left' }}>
                                                                                    <ConfirmRemoveButton style={{ backgroundColor: '#ca5849', color: 'white', borderRadius: '15px', padding: '0', width: '32px' }} type="default" onConfirm={() => removeFromCart(item.id)} >
                                                                                        <Icon style={{ fontSize: '1.0em' }} theme='filled' type="delete" />
                                                                                    </ConfirmRemoveButton>
                                                                                </Col>

                                                                            </Row>
                                                                        </Col>


                                                                    </Row>

                                                                </Card>
                                                            </ProjectCardsCart>
                                                        </Col>
                                                    </>
                                                }
                                                )
                                                : ''
                                            }
                                        </Row>
                                        <br></br>
                                        <Divider></Divider>
                                    </Col>

                                    {state.cart && state.cart?.items?.length ?
                                        <>
                                            <Title level={3} style={{ paddingLeft: 20, paddingRight: 20 }}>Cart Totals</Title>
                                            <Title level={4} style={{ paddingLeft: 20, paddingRight: 20 }}>DONATION DETAILS</Title>

                                            <Row>
                                                <Col span={24}>
                                                    <Divider></Divider>
                                                    <Row>
                                                        <Col span={16}>
                                                            <b>
                                                                Total Payable
                                                            </b>
                                                        </Col>
                                                        <Col span={8} >
                                                            <b>
                                                                <NumberFormat style={{ float: 'right' }} required={true} value={state.total}
                                                                    displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} prefix={(state.currency ?? '') + ' $'} />
                                                            </b>
                                                        </Col>
                                                        <Col span={16}>
                                                            <b>
                                                                Total Items
                                                            </b>
                                                        </Col>
                                                        <Col span={8} >
                                                            <b>
                                                                <NumberFormat style={{ float: 'right' }} required={true} value={state.items?.length}
                                                                    displayType={'text'} thousandSeparator={true} suffix={state.items?.length ? state.items?.length <= 1 ? ' Item' : ' Items' : ' Items'} />
                                                            </b>
                                                        </Col>
                                                    </Row>
                                                    <br />

                                                </Col>
                                            </Row>

                                            {
                                                Utility.HasOperations(authContext.data, [Operations.Is_Authenticated]) ?
                                                    <ShowIfHavePermission operations={[Operations.Is_Authenticated]} >
                                                        <RightAlignedRow>
                                                            <Col span={24}>
                                                                <Button type="primary" htmlType='submit'>Continue</Button>
                                                            </Col>
                                                        </RightAlignedRow>
                                                    </ShowIfHavePermission>
                                                    :
                                                    <>
                                                        <FormControl style={{ width: '70%' }}
                                                            getFieldDecorator={getFieldDecorator}
                                                            rules={[...ValidationUtility.required('Email is required'),
                                                            ...ValidationUtility.email('Email format incorrect')
                                                            ]}

                                                            name="email" //getFieldDecorator(name: { hidden: true })
                                                            label="Email"  >
                                                            <Input onChange={emailChange} className='ant-form-item-children' />
                                                        </FormControl>

                                                        {state.requireAdditionalInfo ?
                                                            <>
                                                                <FormControl style={{ width: '70%' }}
                                                                    getFieldDecorator={getFieldDecorator}
                                                                    rules={[...ValidationUtility.required('First Name is required'),
                                                                    ]}
                                                                    name="firstName" //getFieldDecorator(name: { hidden: true })
                                                                    label="First Name">
                                                                    <Input />
                                                                </FormControl>
                                                                <FormControl style={{ width: '70%' }}
                                                                    getFieldDecorator={getFieldDecorator}
                                                                    rules={[...ValidationUtility.required('Last Name is required'),
                                                                    ]}
                                                                    name="lastName" //getFieldDecorator(name: { hidden: true })
                                                                    label="Last Name">
                                                                    <Input />
                                                                </FormControl>
                                                                <FormControl style={{ width: '70%' }}
                                                                    getFieldDecorator={getFieldDecorator}
                                                                    rules={[...ValidationUtility.required('Phone Number is required'),
                                                                    ]}
                                                                    name="phoneNumber" //getFieldDecorator(name: { hidden: true })
                                                                    label="Phone Number">
                                                                    <Input />
                                                                </FormControl>
                                                                <FormControl
                                                                    wrapperCol={{ xs: { offset: 0, span: 24 }, sm: { offset: 0, span: 24 } }}
                                                                    getFieldDecorator={getFieldDecorator}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            transform: value => value || undefined,
                                                                            type: 'boolean',
                                                                            message: 'You must agree to the privacy notice.',
                                                                        },
                                                                    ]}
                                                                    label=""
                                                                    name="agreement" //getFieldDecorator(name: { hidden: true })
                                                                >
                                                                    <p className="text-content-justified">
                                                                        <Checkbox style={{ lineHeight: 3 }}>
                                                                            I have read the privacy notice and I understand how my data will be used.
                                                                            <br />
                                                                            <a href={PrivacyPdf} target="_blank" >Read Privacy Notice</a>
                                                                        </Checkbox>
                                                                    </p>
                                                                </FormControl>

                                                            </>
                                                            : ''

                                                        }

                                                        <RightAlignedRow>
                                                            <Col span={24}>
                                                                <Button type="primary" htmlType='button' /*disabled={ state.cartInvalid }*/ onClick={guestSubmit}>Continue</Button>
                                                            </Col>
                                                        </RightAlignedRow>
                                                    </>
                                            }
                                        </>
                                        :
                                        <Title level={4}>Cart is Empty</Title>
                                    }
                                </Col>
                            </Form>
                            :
                            ''
                    }
                </Col>
                {/*
                        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 7, offset: 1 }} >
                            <div style={{ height: '580px', width: '100%' }}>
                                <Form labelCol={{ sm: 7 }}
                                    wrapperCol={{ sm: 16 }}
                                    onSubmit={this.handleSubmit}
                                    layout="vertical">
                                    <div style={{ height: '70px', padding: '10px', borderTopRightRadius: '8px', borderTopLeftRadius: '8px', backgroundColor: '#269b48' }}>
                                        <Title level={3} style={{ textAlign: 'center', color: 'white' }}>
                                            Select Donation Amount
                                        </Title>
                                    </div>
                                    <div style={{ height: '100%', padding: '15px', borderBottomRightRadius: '8px', borderBottomLeftRadius: '8px', backgroundColor: '#fff' }}>


                                    </div>


                                </Form>
                            </div>
                    </Col>
                    */
                }


            </Row >

        </>
    );

};

const DonateFormWithoutRouter = Form.create<Props>({
    name: 'DonateForm',
})(DonateFormWithoutFormAndRouter);

const DonateForm = withRouter(DonateFormWithoutRouter);

export default DonateForm;
