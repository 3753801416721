import * as React from 'react';
import { ErrorViewContainer } from './ErrorView.Styled';
import { NavLink } from 'react-router-dom';
import logo from '../../../assets/images/logo.png';
export interface Props {
  children?: React.ReactNode;
}

export interface State {
  hasError?: boolean;
}

export default class ErrorView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { hasError: false };
  }

  componentDidCatch(error) {
    this.setState({ hasError: true });
  }

  renderError() {
    return (
      <ErrorViewContainer>
        <img src={logo} className="logo" width="192" />
        <div className="browser">
          <div className="controls">
            <i></i>
            <i></i>
            <i></i>
          </div>

          <div className="eye"></div>
          <div className="eye"></div>
        </div>

        <h1>Unfortunately, something has gone wrong.</h1>
        <p>
          We're unable to fulfill your request. Rest assured we have been notified and are looking
          into the issue. Please refresh your browser. If the error continues, please contact our{' '}
          <NavLink to="/contact-us">support team</NavLink>.
        </p>
      </ErrorViewContainer>
    );
  }

  render() {
    return this.state.hasError ? this.renderError() : <>{this.props.children}</>;
  }
}
















