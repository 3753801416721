import { Button, Col, Divider, Form, Icon, Input, Row } from 'antd';
import { FormComponentProps, FormProps } from 'antd/lib/form';
import ResizableTextArea from 'antd/lib/input/ResizableTextArea';
import TextArea from 'antd/lib/input/TextArea';
import { RcFile } from 'antd/lib/upload';
import { CompanyProjectsHandler } from 'components/CompanyProjects/CompanyProjectsHandler';
import { ButtonGroup } from 'components/CompanyProjects/AddCompanyProjectsForm/AddCompanyProjectsForm';
import { Base64FileUploader } from 'components/Forms/Base64FileUploader';
import { fullWidthSingleColFormColSize, fullWidthSingleColFormColSizeTwo, FullWithButton } from 'components/Forms/Form.Styled';
import FormControl from 'components/Forms/FormControl';
import { RichTextEditor } from 'components/Forms/RichTextEditor';
import ShowIfTrue from 'components/Forms/ShowIfTrue';
import SimpleSelect from 'components/Forms/SimpleSelect';
import ISelectOption from 'components/Forms/SimpleSelect/ISelectOption';
import SimpleUpload from 'components/Forms/SimpleUpload';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import { DataFileType, DocumentModel, IDocumentModel } from 'Core/Api/Api';
import ValidationUtility from 'Core/ValidationUtility';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Wrap = styled.div``;

export const formColumns: FormProps = {
  labelCol: {
    xs: { span: 5 },
    md: { span: 9 },
  },
  wrapperCol: {
    xs: { span: 14 },
    md: { span: 14 },
  },
  labelAlign: "left"
};

export interface Props extends FormComponentProps<IDocumentModel> {
  value?: IDocumentModel;
  onAddDocument: (document: IDocumentModel) => void;
  onEditDocument: (document: IDocumentModel) => void;
  onCancel?: Function;
}

export const AddDocumentFormWithoutForms = ({ value: previousDocument, onAddDocument, onEditDocument, onCancel, form }: Props) => {
  const getFieldDecorator = form.getFieldDecorator;
  let companyProjectsHandler = new CompanyProjectsHandler({});
  const [fileInputFilter, setFileInputFilter] = useState("image/*");
  const [fileTypes, setFileTypes] = React.useState<ISelectOption[] | undefined>();

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    form?.validateFieldsAndScroll((errors, values) => {
      if (errors != null) { return; }
      values.name = values.content?.fileName ?? (values.content as any)?.name ?? values.url;
      if (previousDocument != undefined) {
        onEditDocument?.(values);
      } else {
        onAddDocument?.(values);
      }

    });
  };

  useEffect(() => {
    GetFileTypes();
    setPreviousDocument();
  }, []);

  const handleFileTypeChanged = (value: string) => {
    if (value == DataFileType.ProjectDocument) {
      setFileInputFilter(".pdf");
    } else {
      setFileInputFilter("image/*");
    }
    form.setFieldsValue({ content: undefined });

  }
  const setPreviousDocument = () => {
    if (previousDocument) {
      form.setFieldsValue({ fileType: previousDocument.fileType }, () => {
        form.setFieldsValue(previousDocument);
      });
      console.log("previous document", previousDocument);
    }
  }

  const GetFileTypes = () => {
    const fileTypes = companyProjectsHandler.GetFileType()
      .filter(x => x.value == DataFileType.GalleryDocument || x.value == DataFileType.ProjectDocument);
    setFileTypes(fileTypes);
  }

  return (<LeftAlignedRow>
    <Col span="23">
      <Form colon={true} {...formColumns} layout="horizontal" onSubmit={onSubmit}>
        <LeftAlignedRow type='flex' justify='start'>

          <Col span={20}>
            <FormControl
              getFieldDecorator={getFieldDecorator}
              name="id"
              label="">
              <Input type='hidden' />
            </FormControl>
            <FormControl
              getFieldDecorator={getFieldDecorator}
              rules={[...ValidationUtility.required('Description is required')]}
              name="description"
              label="Description">
              <Input.TextArea rows={3} />
            </FormControl>
            <FormControl
              getFieldDecorator={getFieldDecorator}
              rules={[...ValidationUtility.required('Document Type is required')]}
              name="fileType"
              labelAlign='left'
              label="Document Type">
              <SimpleSelect onChange={handleFileTypeChanged} options={fileTypes} size='small' />
            </FormControl>
            <fieldset disabled={form.getFieldValue("url") || undefined == form.getFieldValue("fileType")} >
              <FormControl
                getFieldDecorator={getFieldDecorator}
                rules={[
                  ...ValidationUtility.OneMustBeSupplied(form, ["content", "url"])
                ]}
                validateTrigger={["url"]}
                name="content"
                label="File">
                <Base64FileUploader accept={fileInputFilter} />
              </FormControl>
            </fieldset>
            <ShowIfTrue condition={form.getFieldValue("fileType") == DataFileType.GalleryDocument}>

              <Row>
                <Col><Divider>Or</Divider></Col>
              </Row>

              <FormControl
                getFieldDecorator={getFieldDecorator}
                name="url"
                label="Video Url">
                <Input disabled={form.getFieldValue("content")} />
              </FormControl>
            </ShowIfTrue>
            <Col><Divider></Divider></Col>



          </Col>
        </LeftAlignedRow>


        <LeftAlignedRow>
          <Col span={23}>

            <ButtonGroup >
              <Button onClick={(e) => onCancel?.(e)} type="default">
                Cancel
              </Button>
              <FullWithButton type="primary" htmlType="submit">
                {!!previousDocument ? "Edit" : "Add"} Document
              </FullWithButton>
            </ButtonGroup>
          </Col>
        </LeftAlignedRow>
      </Form>
    </Col>
  </LeftAlignedRow>);
}
export default Form.create<Props>({ name: "add-document-form" })(AddDocumentFormWithoutForms);

