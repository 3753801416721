import * as React from 'react';
import { Menu, Icon, Row, Col, PageHeader } from 'antd';
import { Link, NavLink } from 'react-router-dom';
import { SFooter } from './Footer.Styled';
import MenuAvatar from '../MenuAvatar';
import styled from 'styled-components';
import Logo from '../../../assets/images/logo.png';
import LogoWhite from '../../../assets/images/logowhite.png';
import AuthContext from '../../../Context/AuthContext';
import Header from 'antd/lib/calendar/Header';
import Title from 'antd/lib/typography/Title';
import termsPdf from '../../../assets/pdfs/terms.pdf';
import privacyPdf from '../../../assets/pdfs/privacy.pdf';
import riskPdf from '../../../assets/pdfs/risk.pdf';

export interface Props {
    children?: React.ReactNode;
}

export interface State { }

export default class FooterLayout extends React.Component<Props, State> {
    static contextType = AuthContext;
    context!: React.ContextType<typeof AuthContext>;

    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <SFooter>
                <Row className={"upperFooter"} type="flex">
                    <Row>
                        <Col md={1} lg={1} xl={2} />
                        <Col xs={24} lg={11} xl={5} >
                            <img src={LogoWhite} style={{ maxHeight: '130px', maxWidth: '100%' }} />
                            <br />
                            <br />
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 11, offset: 1 }} xl={{ span: 5, offset: 1, order: 2 }}>
                            <Col xs={{ span: 24 }} sm={{ span: 15 }} md={{ span: 13 }} lg={{ span: 24 }}>
                            <p style={{ color: 'white', marginTop: '0px', textAlign: 'justify' }}  >
                                The Jamaica Social Stock Exchange, JSSE, seeks
                                to engage the entire Jamaican economy in
                                promoting the Social Capital Market, believing that
                                true development of any country happens in all
                                areas of life and facets of society.
                            </p>
                            </Col>
                        </Col>
                        <Col lg={{ span: 12, offset: 1 }} xl={{ span: 4, offset: 1 }} style={{ color: 'white !important' }}>
                            <Title level={4} style={{ color: 'white' }}> Contact Us </Title>
                            <Col style={{ width: '2vw', height: '0.6vh', backgroundColor: '#1d95d3', marginTop: '-1vh', right: '-0.2vw' }} xs={{ span: 1 }} />
                            <p >40 Harbour Street, P.O.Box 1084,
                                <br />
                                Kingston, Jamaica, West Indies.
                                <br />
                                <br />
                                jsse@jamstockex.com
                                <br />
                                <br />
                                (876) 967-3271 ext. 2363
                                <br />
                                (876) 618-1118 ext. 2363
                                <br />
                                (876) 322-4505 (cell)

                            </p>
                            <p></p>
                            <p></p>
                            <br />
                            <br />
                        </Col>
                        <Col lg={{ span: 11 }} xl={{ span: 5, offset: 1 }} style={{ color: 'white !important' }}>
                            <Title level={4} style={{ color: 'white' }}>Useful Links</Title>
                            <Col style={{ width: '2vw', height: '0.6vh', backgroundColor: '#1d95d3', marginTop: '-1vh', right: '-0.2vw' }} xs={{ span: 1 }} />
                            <Row> <Col span={12}>
                                <Link to="/">Home</Link>
                                <br />
                                <Link to="/projects">Current Projects</Link>
                                <br />
                                <Link to="/about-us">About Us</Link>
                                <br />
                                <Link to="/contact-us">Contact Us</Link>
                            </Col>
                                <Col span={12}>
                                    {/* <a href="/faq">FAQ</a>
                                <br />
                                <a href="#">How To Apply</a>
                                <br />
                                <a href="#">Social Partners</a>
                                <br />
                                <a href="#">Glossary</a> */}
                                </Col>
                            </Row>
                            <Row gutter={16} style={{ marginTop: '30px' }} className="social-media">
                                <Col xs={3} sm={2} md={3} lg={3} xl={3}>
                                    <a target='_blank' href="https://www.instagram.com/jssexchange/"><Icon type="instagram" /></a>
                                </Col>
                                <Col xs={3} sm={2} md={3} lg={3} xl={3}>
                                    <a target='_blank' href="https://www.linkedin.com/company/jssexchange"><Icon type="linkedin" theme='filled' /></a>
                                </Col>
                                <Col xs={3} sm={2} md={3} lg={3} xl={3}>
                                    <a target='_blank' href="https://twitter.com/jssexchange"><Icon style={{}} type="twitter" /></a>
                                </Col>
                                <Col xs={3} sm={2} md={3} lg={3} xl={3}>
                                    <a target='_blank' href="https://www.facebook.com/jssexchange"><Icon style={{}} type="facebook" /></a>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Row>
                <Col span={24} className={"lowerFooter"}>
                    <Row type="flex">
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} style={{ textAlign: 'right', fontSize: '0.8em', paddingRight: '0.5vw' }}  >
                            Copyright  &copy; 2023 Jamaica Social Stock Exchange. All Rights Reserved </Col>

                        <Col xs={{ span: 24 }} sm={{ span: 12 }} style={{ textAlign: 'left', fontSize: '0.8em', paddingLeft: '0.5vw' }}  >
                            {/*<a href="#">Disclaimer</a>  |*/} <a href={privacyPdf} target="_blank" title="Privacy and Security Policy">Privacy and Security Policy</a> | <a href={termsPdf} target="_blank" title="Terms" >Terms and Conditions</a> | <a href={riskPdf} target="_blank" title="Risk" >Risk</a>
                        </Col>
                        {/*<Col span={8} offset={8}>*/}
                        {/*    <div className='social-media'>*/}
                        {/*        <Icon type="facebook" theme="filled" />*/}
                        {/*        <Icon type="instagram" theme="filled" />*/}
                        {/*        <Icon type="twitter-circle" theme="filled" />*/}
                        {/*    </div>*/}
                        {/*</Col>*/}
                    </Row>
                </Col>

            </SFooter>
        );
    }
}
















