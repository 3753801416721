import * as React from 'react';
import { Tooltip, Icon } from 'antd';

export interface Props {
  text?: React.ReactNode;
  color?: string;
}

export interface State {}

export default class InfoIcon extends React.Component<Props, State> {
  static defaultProps: Props = {
    color: 'rgba(0,0,0,.45)',
  };
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Tooltip title={this.props.text}>
        <Icon type="info-circle" style={{ color: this.props.color }} />
      </Tooltip>
    );
  }
}
















