import { Button, Col, Drawer, Icon, Input, Radio, Row } from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import { doubleColFormColSize, FullWithButton } from 'components/Forms/Form.Styled';
import FormControl from 'components/Forms/FormControl';
import ShowIfTrue from 'components/Forms/ShowIfTrue';
import { ProjectsHandler } from 'components/ProjectsListView/ProjectsHandler';

import ButtonGroup from 'components/Styled/ButtonGroup';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import { IApproveProjectCommandRequest, Project, ProjectApprovalStatus } from 'Core/Api/Api';
import NotificationService from 'Core/NotificationService';
import ValidationUtility from 'Core/ValidationUtility';
import React, { SyntheticEvent } from 'react';
import { useHistory } from 'react-router';

interface Props extends FormComponentProps<IApproveProjectCommandRequest> {
  project?: Project;
  visible: boolean;
  onSubmitted: Function;
  onClose?: (e: SyntheticEvent) => void;
}

export const ProjectApprovalSidebarWithoutForms = ({ project, form, onSubmitted, visible, onClose }: Props) => {

  let { getFieldDecorator } = form;
  let projectsHandler = new ProjectsHandler({});
  let history = useHistory();

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    form.validateFieldsAndScroll((errors, values) => {
      if (errors) { return; }

      values.id = project?.id;
      projectsHandler.ApproveProject(values).then(response => {
        NotificationService.success(`${response?.project?.name} Status Has Been Updated`, "", 10, true);
        onSubmitted?.(response.project);
      })
    });
  }

  return <Drawer
    title="Project Approval"
    width={520}
    onClose={onClose}
    visible={visible}
    bodyStyle={{ paddingBottom: 80 }}
  >
    <Form {...doubleColFormColSize} layout="vertical" onSubmit={onSubmit}>
      <Row>
        <Col>
          <FormControl
            getFieldDecorator={getFieldDecorator}
            rules={[...ValidationUtility.required('Please select a response')]}
            name="projectApprovalStatus"
            label="Please select an action:">
            <Radio.Group defaultValue="" size="large" buttonStyle='solid' >
              <Radio.Button value="Approved"><Icon type="check-circle" /> Approve</Radio.Button>
              <Radio.Button value="Rejected"><Icon type="close-circle" /> Reject</Radio.Button>
            </Radio.Group>
          </FormControl>
          <ShowIfTrue condition={form.getFieldValue("projectApprovalStatus") == ProjectApprovalStatus.Rejected}>
            <FormControl
              getFieldDecorator={getFieldDecorator}
              rules={[...ValidationUtility.required('Reason is required')]}
              name="reason"
              label="Reason">
              <TextArea className='react-transition fade-in' rows={3} />
            </FormControl>
          </ShowIfTrue>
        </Col>
      </Row>
      <LeftAlignedRow>
        <Col span={20}>
          <Form.Item
            colon={false}>
            <ButtonGroup>
              <Button onClick={(e) => onClose?.(e)} type="default">
                cancel
              </Button>
              <FullWithButton type={form.getFieldValue("projectApprovalStatus") == ProjectApprovalStatus.Approved ? "primary" : "danger"} htmlType="submit">
                {form.getFieldValue("projectApprovalStatus") == ProjectApprovalStatus.Approved ? "Approve" : "Reject"} Project
              </FullWithButton>
            </ButtonGroup>
          </Form.Item>
        </Col>
      </LeftAlignedRow>
    </Form>
  </Drawer>;
}

export default Form.create<Props>({
  name: 'UserDetails',
})(ProjectApprovalSidebarWithoutForms)