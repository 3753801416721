import React from 'react';

interface Props {
  url?: string | undefined;
  name?: string | undefined;
}

export default ({ url, name }: Props) => {
  const GetFileNameFromApiUrl = (url: String) => {
    return url.substring(url.lastIndexOf("/") + 1);
  }

  return url ? <a href={url} target="_blank">{name ?? GetFileNameFromApiUrl(url)}</a> : <></>;
};
