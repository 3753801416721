import { SecuredClient } from '../../../Core/Api/SecuredClient';
import {
  RequestPasswordResetCommandRequest,
  IRequestPasswordResetCommandRequest,
  ResetPasswordCommandRequest,
  IResetPasswordCommandRequest,
  ChangePasswordCommandRequest,
  IChangePasswordCommandRequest,
  IResendMyActivationEmailCommandRequest,
  ResendMyActivationEmailCommandRequest,
} from '../../../Core/Api/Api';

export default class PasswordResetHandler {
  props: any;
  constructor(props) {
    this.props = props;
  }

  RequestResetPassword(model: IRequestPasswordResetCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    const request = new RequestPasswordResetCommandRequest(model);

    return client.requestPasswordReset(request);
  }

  ResetPassword(model: IResetPasswordCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    const request = new ResetPasswordCommandRequest(model);

    return client.resetPassword(request);
  }

  ResendActivationEmail(model: IResendMyActivationEmailCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    const request = new ResendMyActivationEmailCommandRequest(model);

    return client.resendMyActivationEmail(request);
  }
}










