import * as React from 'react';
import { Menu, Icon } from 'antd';
import { NavLink } from 'react-router-dom';
import MenuAvatar from '../MenuAvatar';
import styled from 'styled-components';
import AuthContext from '../../../Context/AuthContext';
import ShowIfHavePermission from 'components/Forms/ShowIfHavePermission';
import { Operations } from 'Core/Api/Api';

export interface Props {
    children?: React.ReactNode;
}

export interface State { }

export default class UserBar extends React.Component<Props, State> {
    static contextType = AuthContext;
    context!: React.ContextType<typeof AuthContext>;

    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <Menu style={{ display: 'flex', alignItems: 'center', float: 'right', padding: 14}} mode="horizontal" theme="dark">
                <Menu.SubMenu 
                    title={
                        <span style={{
                            fontWeight: 500,
                        }}>
                            <MenuAvatar shape="circle" icon="user" />
                            &nbsp;

                            {this.context.data ? "Welcome " + this.context.data.firstName + " ▾" : '' }
                        </span>
                    }>

                    <Menu.Item>
                        <NavLink to="/user-profile">
                            <Icon type="folder" style={{ color: '#F0403D' }} />
                            User Profile
                        </NavLink>
                    </Menu.Item>

                    <Menu.Item>
                        <ShowIfHavePermission operations={[Operations.Edit_Company]}>
                            <NavLink to="/company-profile">
                                <Icon type="idcard" style={{ color: '#F0403D' }} />
                                Company Profile
                            </NavLink>
                        </ShowIfHavePermission>
                    </Menu.Item>

                    <Menu.Item>
                        <ShowIfHavePermission operations={[Operations.Edit_Project]} >
                            <NavLink to="/user-profile/projects">
                                <Icon type="project" style={{ color: '#F0403D' }} />
                                Company Projects
                            </NavLink>
                        </ShowIfHavePermission>
                    </Menu.Item>

                    {/* <ShowIfHavePermission operations={[Operations.Edit_Project]}> */}
                    <Menu.Item>
                        <NavLink to="/user-profile/payment-list">
                            <Icon type="money-collect" style={{ color: '#F0403D' }} />
                            Payments
                        </NavLink>
                    </Menu.Item>
                    {/* </ShowIfHavePermission> */}

                    <Menu.Divider />
                    <Menu.Item>
                        <NavLink to="/logout">
                            <Icon type="poweroff" style={{ color: '#F0403D' }} />
                            Logout
                        </NavLink>
                    </Menu.Item>
                </Menu.SubMenu>
            </Menu>
        );
    }
}
















