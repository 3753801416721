import * as React from 'react';
import styled from 'styled-components';
import { Layout, Form, Button } from 'antd';

const { Footer } = Layout;

export const SFooter = styled(Footer)`
    min-height: 200px;
    bottom: 0;
    left: 0;

    @media only screen and (min-width: 574px) {
        padding-left: 50px;
    }

    @media only screen and (max-width: 574px) {
        padding-left: 20px;
        padding-right: 20px;
    }
    position: relative;
    width: 100%;
    color: white !important;

    a {
        color: white !important;
    }

    .social-media {
        font-size: 2em;

        a i {
            color: #00aed8;
        }
    }

    .lower-footer a{
        margin-left: 10px;
    }
`;










