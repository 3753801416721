import * as React from 'react';
import { Table } from 'antd';
import {
    TableProps,
    PaginationConfig,
    SorterResult,
    TableCurrentDataSource,
    ColumnProps,
    TableSize,
} from 'antd/lib/table';
import equals from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import TableRequest from 'Core/TableUtility/Models/TableRequest';
import { TableFilterContext } from '../TableFilterContextProvider';
import TableUtility from 'Core/TableUtility';
import Utility from 'Core/Utility';
import ITableDataFetcher from './Models/ITableDataFetcher';
import debouce from 'lodash/debounce';

export interface Props<Model> {
    children?: React.ReactNode;
    tableKey?: string;
    scroll?: {
        x?: string | number | boolean | undefined;
        y?: string | number | boolean | undefined;
        scrollToFirstRowOnChange?: boolean | undefined;
    } | undefined;
    size?: TableSize;
    dataFetcher: ITableDataFetcher<Model>;
    columns: Array<ColumnProps<Model>>;
    customData?: any;
}

export interface State<DataType> {
    data: DataType[];
    pagination: PaginationConfig;
    isLoading: boolean;
    // maintain a copy of table request
    // This will keep track of all filters, sorting and paging information
    // to be sent to the server
    tableRequest: TableRequest;
}
export default class SimpleTable<Model> extends React.Component<Props<Model>, State<Model>> {
    static defaultProps = {
        tableKey: 'id',
        dataFetcher: [],
    };

    static contextType = TableFilterContext;
    context!: React.ContextType<typeof TableFilterContext>;

    constructor(props: Props<Model>) {
        super(props);

        this.state = {
            data: [],
            pagination: {},
            isLoading: false,
            tableRequest: {},
        };
        this.TableChange = debouce(this.TableChange.bind(this), 10);
    }

    /**
     * This method is used to handle filtering and paging for table
     *
     * @param {PaginationConfig} pagination
     * @param {Record<keyof Model, string[]>} filters
     * @param {SorterResult<Model>} sorter
     * @param {TableCurrentDataSource<Model>} extra
     * @memberof  SimpleTable
     */
    TableChange(
        pagination: PaginationConfig,
        filters: Partial<Record<keyof Model, string[]>>,
        sorter: SorterResult<Model>,
        extra: TableCurrentDataSource<Model>
    ) {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({ pagination: pager });

        const tableRequest = TableUtility.GetTableRequest<Model>(
            pagination,
            filters,
            sorter,
            this.context.data
        );
        this.GetData(tableRequest);
    }

    componentDidUpdate(prevProps: Props<Model>, pervState: State<Model>) {
        // When the state is update, use that to kickoff the request for data
        if (!equals(this.context.data, this.state.tableRequest) && !this.state.isLoading) {
            this.GetData(this.context.data || {});
        }
        // When the state is update, use that to kickoff the request for data
        if (!equals(pervState.tableRequest, this.state.tableRequest)) {
        }
    }

    componentDidMount() {
        this.GetData(this.context.data || {});
    }

    refresh = () => {
        this.GetData(this.context.data || {});
    };

    GetData(tableRequest: TableRequest | {}) {
        this.setState({ isLoading: true, tableRequest: cloneDeep(tableRequest) }, () => {
            // In callback for setState, ensuring that tableRequest is updated before proceeding

            this.props.dataFetcher
                .GetData(tableRequest, this.props.customData)
                .then(data => {
                    const pager = { ...this.state.pagination };
                    pager.total = data.count;

                    this.context.Update(tableRequest);
                    this.setState({
                        isLoading: false,
                        data: data.results || [],
                        pagination: pager,
                    });
                })
                .catch(reason => {
                    this.setState({
                        isLoading: false,
                    });
                });
        });
    }

    render() {
        return (
            <Table<Model>
                rowKey={row => row[this.props.tableKey!] || Utility.RandomNumber(292838)}
                columns={this.props.columns}
                dataSource={this.state.data}
                scroll={this.props.scroll}
                pagination={this.state.pagination}
                loading={this.state.isLoading}
                onChange={this.TableChange}
                size={this.props.size}
                {...this.props}
            />
        );
    }
}










