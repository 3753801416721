import React, { useState } from 'react'
import { Col } from 'antd';
import styled from 'styled-components';
import BoardOfDirectorsModal from '../BoardOfDirectorsModal';


const ProfileContainer = styled(Col)<{showShadow?: boolean;}>`
    width: 365px;
    height: 550px;
    padding-right: 1.5vw;
    max-width: 86vw;
    & >.ant-col:hover{
        box-shadow:${({showShadow})=> showShadow ? "0px 3px 11px 1px black" : "none" };
    }
`;

type ProfileProps = {
    board: {
        name: string,
        position: string,
        img: any,
        pdfUrl: any,
    }
}


export const Profile = (props: ProfileProps) => {
    const [openModal, setOpenModal] = useState(false);

    return (
        <>
            <ProfileContainer showShadow={!!props.board?.pdfUrl} onClick={() => setOpenModal(true)} title={!!props.board?.pdfUrl ? "Click to view profile" : ""}>
                <Col style={{ backgroundColor: '#fff', width: '348px', height: '515px', paddingBottom: '20px' }}>
                    <Col xs={{ span: 24 }}>
                        <div > {props.board.img}
                        </div>
                    </Col>
                    <Col xs={{ span: 24 }}>
                        <p style={{ fontSize: '20px', paddingTop: '30px', lineHeight: '1px', textAlign: 'center' }} >{props.board.name}</p>
                        <p style={{ fontSize: '15px', color: '#59ba56', textAlign: 'center' }}>{props.board.position}</p>
                    </Col>
                </Col>
            </ProfileContainer>

            <BoardOfDirectorsModal visible={openModal && !!props.board.pdfUrl} onClose={x => setOpenModal(false)} pdfUrl={props.board.pdfUrl} />
        </>
    );
}



