import { List } from 'antd';
import SimpleViewList from 'components/Forms/DataGrid/SimpleViewList';
import { CompanyProjectsCard } from 'components/CompanyProjects/CompanyProjectsCard';
import { CompanyProjectsHandler } from 'components/CompanyProjects/CompanyProjectsHandler';
import React from 'react';
import { ProjectDetailsModel } from 'Core/Api/Api';




interface Props {
  isEditMode?: boolean;
}

export default ({ isEditMode }: Props) => {

  const handler = new CompanyProjectsHandler({});



  return <SimpleViewList<ProjectDetailsModel>
    grid={{ gutter: 10, sm: 2, md: 3, lg: 3, xl: 3 }}
    dataFetcher={handler}
    scrollToTop={true}
    pagination={{ position: "bottom", pageSize: 6, showSizeChanger: false, showQuickJumper: false }}
    renderItem={x => (<List.Item style={{ display: 'flex', justifyContent: 'center' }}>
      <CompanyProjectsCard isEditMode={isEditMode} isFullGrid={true} project={x} />
    </List.Item>)}
  />;
}