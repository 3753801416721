import { useEffect, useState } from 'react';
import * as React from 'react';
import { Button, Col, DatePicker, Form, Icon, Input, Modal, Upload } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { fullWidthSingleColFormColSize, fullWidthSingleColFormColSizeTwo, FullWithButton } from 'components/Forms/Form.Styled';
import FormControl from 'components/Forms/FormControl';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import ValidationUtility from 'Core/ValidationUtility';
import { useHistory, useParams } from 'react-router-dom';

import NotificationService from 'Core/NotificationService';
import { FormComponentProps } from 'antd/lib/form';

import styled from 'styled-components';
import { RichTextEditor } from 'components/Forms/RichTextEditor';
import { CompanyProjectsHandler } from '../CompanyProjectsHandler';
import SimpleSelect from 'components/Forms/SimpleSelect';
import SimpleUpload from 'components/Forms/SimpleUpload';
import { FilesEditor } from 'components/Forms/FilesEditor';
import ISelectOption from 'components/Forms/SimpleSelect/ISelectOption';
import { DocumentModel, ICreateProjectModel, IDocumentModel, IProjectUpdate, ProjectFullDetailsModel, ProjectStatus } from 'Core/Api/Api';
import { Base64FileUploader } from 'components/Forms/Base64FileUploader';
import MaskedInput from 'components/Forms/MaskedInput';
import NumberFormat from 'react-number-format';
import { lastIndexOf } from 'lodash';
import moment, { Moment } from 'moment';
import { SimpleDatePicker } from 'components/Forms/SimpleDatePicker';
import ShowIfTrue from 'components/Forms/ShowIfTrue/ShowIfTrue';



export interface AddCompanyProjectFormProps extends FormComponentProps<ICreateProjectModel> {
  children?: React.ReactNode;
}

export const ButtonGroup = styled.div`
  
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(100px,200px));
    gap: 5px;
  `;

export function AddCompanyProjectForm({ children, form }: AddCompanyProjectFormProps) {

  let getFieldDecorator = form?.getFieldDecorator;
  let history = useHistory();
  let companyProjectsHandler = new CompanyProjectsHandler({});
  let params = useParams<{ id?: string }>();
  const [nameFieldIsDirty, setNameFieldIsDirty] = React.useState<boolean>(false);
  const [fundingPropose, setFundingPropose] = React.useState<ISelectOption[] | undefined>();
  const [fundingTypes, setFundingTypes] = React.useState<ISelectOption[] | undefined>();
  const [category, setCategory] = React.useState<ISelectOption[] | undefined>();
  const [project, setProject] = React.useState<ProjectFullDetailsModel | undefined>();
  const [projectUpdate, setProjectUpdate] = React.useState<IProjectUpdate | undefined>();
  const [formattedDocuments, setFormattedDocuments] = useState<IDocumentModel[] | undefined>(undefined);
  const [showAddDocumentPromptModal, setShowAddDocumentPromptModal] = useState(true);

  useEffect(() => {
    GetCategories();
    GetFundingPurpose();
    GetFundingType()
  }, []);

  useEffect(() => {
    // This was done because the client was complaining that
    // While creating a project, there would get signed out.
    // This makes a api call that will refresh the token while on the page
    const timeOutHandle = setInterval(() => {
      companyProjectsHandler.RefreshToken();
    }, 30000);

    return () => clearInterval(timeOutHandle);
  }, [])
  
  useEffect(() => {
    if (params.id) {

      companyProjectsHandler.GetProjectById(params.id).then(response => {
        if (response.project) {
          const project = response.project;

          setProject(project);
          setProjectUpdate(project.projectUpdates?.pop());

          form.setFieldsValue(project ?? {});

          const otherDocuments: IDocumentModel[] = [];

          if (projectUpdate == null) {

            project?.projectDocuments?.forEach(document => {
              const documentModel: IDocumentModel = {};
              if (document?.document) {
                documentModel.content = { name: document?.document?.name, url: document?.document?.url } as any;
              }
              documentModel.name = document?.document?.name;
              documentModel.fileType = document?.document?.type;
              documentModel.description = document?.description;
              documentModel.url = document.videoUrl;
              documentModel.id = document.id;
              documentModel.removed = false;
              otherDocuments.push(documentModel);

            });
            form.setFieldsValue({ "documents": otherDocuments });
          }

          else {

            projectUpdate?.projectDocuments!.forEach(document => {
              const documentModel: IDocumentModel = {};
              if (document?.document) {
                documentModel.content = { name: document?.document?.name, url: document?.document?.url } as any;
              }
              documentModel.name = document?.document?.name;
              documentModel.fileType = document?.document?.type;
              documentModel.description = document?.description;
              documentModel.url = document.videoUrl;
              documentModel.id = document.id;
              documentModel.removed = false;
              otherDocuments.push(documentModel);

            });

            setFormattedDocuments(otherDocuments);
          }

        }
      }).catch(error => {
        history.push("NotFound");
      });
    }
  }, []);

  useEffect(() => {
    (!projectUpdate?.thumbnailDataFile && project?.thumbnailFileUrl) && form.setFieldsValue({ "thumbnail": { name: project.thumbnailFileUrl?.substring(project.thumbnailFileUrl.lastIndexOf("\/") + 1), url: project.thumbnailFileUrl } });
    (!projectUpdate?.dashboardDataFile && project?.projectDashboardFileUrl) && form.setFieldsValue({ "dashboardPicture": { name: project.projectDashboardFileUrl?.substring(project.projectDashboardFileUrl?.lastIndexOf("\/") + 1), url: project.projectDashboardFileUrl } });
    (!projectUpdate?.projectDocumentDataFile && project?.projectDocumentFileUrl) && form.setFieldsValue({ "businessPlan": { name: project?.projectDocumentFileUrl?.substring(project.projectDocumentFileUrl?.lastIndexOf("\/") + 1), url: project.projectDocumentFileUrl } });
  }, [project]);

  useEffect(() => {
    SetUpdatedValuesInField();

  }, [project && formattedDocuments && project?.status === ProjectStatus.Finalized, projectUpdate]);

  const GetCategories = () => {
    const categories = companyProjectsHandler.GetProjectCategory();
    setCategory(categories);
  }

  const GetFundingPurpose = () => {
    const fundingPurpose = companyProjectsHandler.GetFundingPurpose();
    setFundingPropose(fundingPurpose);
  }

  const GetFundingType = () => {
    const fundingTypes = companyProjectsHandler.GetFundingType();
    setFundingTypes(fundingTypes);
  }

  const SetUpdatedValuesInField = () => {
    projectUpdate?.name && form.setFieldsValue({ "name": projectUpdate?.name });
    projectUpdate?.subTitle && form.setFieldsValue({ "subTitle": projectUpdate?.subTitle });
    projectUpdate?.category && form.setFieldsValue({ "category": projectUpdate?.category });
    projectUpdate?.timeToFunding && form.setFieldsValue({ "timeToFunding": projectUpdate?.timeToFunding });
    projectUpdate?.description && form.setFieldsValue({ "description": projectUpdate?.description });
    projectUpdate?.fundingPurpose && form.setFieldsValue({ "fundingPurpose": projectUpdate?.fundingPurpose });
    projectUpdate?.typeOfFunding && form.setFieldsValue({ "typeOfFunding": projectUpdate?.typeOfFunding });
    projectUpdate?.amountRequested && form.setFieldsValue({ "amountRequested": projectUpdate?.amountRequested });
    projectUpdate?.videoUrl && form.setFieldsValue({ "videoUrl": projectUpdate?.videoUrl });
    projectUpdate?.reason && form.setFieldsValue({ "reason": projectUpdate?.reason });
    projectUpdate?.thumbnailDataFile && form.setFieldsValue({ "thumbnail": { name: projectUpdate.thumbnailDataFile.url?.substring(projectUpdate.thumbnailDataFile.url.lastIndexOf("\/") + 1), url: projectUpdate.thumbnailDataFile.url } });
    projectUpdate?.dashboardDataFile && form.setFieldsValue({ "dashboardPicture": { name: projectUpdate.dashboardDataFile.url?.substring(projectUpdate.dashboardDataFile.url.lastIndexOf("\/") + 1), url: projectUpdate.dashboardDataFile.url } });
    projectUpdate?.projectDocumentDataFile && form.setFieldsValue({ "businessPlan": { name: projectUpdate.projectDocumentDataFile.url?.substring(projectUpdate.projectDocumentDataFile.url.lastIndexOf("\/") + 1), url: projectUpdate.projectDocumentDataFile.url } });
    formattedDocuments && form.setFieldsValue({ "documents": formattedDocuments });
  }

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    form?.validateFieldsAndScroll((errors, values) => {
      if (errors != null) { return; }
      values.timeToFunding = values?.timeToFunding ? moment(values?.timeToFunding).toISOString() : undefined;
      companyProjectsHandler.EditProject(values).then(response => {
        history.goBack();
        
        if (form.getFieldValue('status') === ProjectStatus.Finalized) {
          NotificationService.success(<span>Project Update Submitted for {project?.name}</span>, "Our team will review your changes and if approved they will be added to the project for others to see.", 10, true);
        }
        else {
          NotificationService.success(`Updated ${response.project?.name}`, null, 10, true);
        }
      });

      console.info(values);
      
      
    });
  };


  return (
    <LeftAlignedRow>
      <Col span={23}>
        <Form {...fullWidthSingleColFormColSizeTwo} layout="vertical" onSubmit={onSubmit}>
          <LeftAlignedRow type='flex' justify='start'>
            <Col span={20}>

              <Modal
                title='Add Gallery / Projects to Other Documents'
                destroyOnClose={true}
                visible={showAddDocumentPromptModal && form.getFieldValue('status') === ProjectStatus.Finalized}
                onCancel={e => setShowAddDocumentPromptModal(false)}
                footer={[
                  <Button key="ok" type='primary' onClick={() => setShowAddDocumentPromptModal(false)}>
                    Ok
                  </Button>
                ]}
              >
                <p style={{ fontWeight: 'bold', fontSize: '17px' }}>Let investors know what is happening.
                  Add new document or media to keep them posted on progress made on the project.</p>
              </Modal>

              <FormControl
                style={{ display: 'none' }}
                getFieldDecorator={getFieldDecorator}
                rules={[...ValidationUtility.required('Id is required')]}
                name="id"
                label="">
                <Input type='hidden' />
              </FormControl>
              <FormControl
                style={{ display: 'none' }}
                getFieldDecorator={getFieldDecorator}
                rules={[...ValidationUtility.required('Status is required')]}
                name="status"
                label="">
                <Input type='hidden' />
              </FormControl>

              <FormControl
                getFieldDecorator={getFieldDecorator}
                rules={[...ValidationUtility.required('Title is required')]}
                name="name"
                label="Title">
                <Input size='large' />
              </FormControl>
              <FormControl
                getFieldDecorator={getFieldDecorator}
                rules={[...ValidationUtility.required('Sub-title is required')]}
                name="subTitle"
                label="Sub-Title">
                <Input />
              </FormControl>
              <FormControl
                getFieldDecorator={getFieldDecorator}
                rules={[...ValidationUtility.required('Name is required')]}
                name="category"
                labelAlign='left'
                label="Category">
                <SimpleSelect options={category} />
              </FormControl>
              <FormControl
                getFieldDecorator={getFieldDecorator}
                name="timeToFunding"
                labelAlign='left'
                label="Time To Fund">
                <SimpleDatePicker />
              </FormControl>
              <FormControl
                getFieldDecorator={getFieldDecorator}
                rules={
                  [
                    {
                      transform: ValidationUtility.TransformEditorState
                    },
                  ]
                }
                name="description"
                label="Theory Of Change">
                <RichTextEditor rows={9} />
              </FormControl>
              <FormControl
                getFieldDecorator={getFieldDecorator}
                rules={[...ValidationUtility.required('Project Life Cycle Stage is required')]}
                name="fundingPurpose"
                label="Project Life Cycle Stage ">
                <SimpleSelect options={fundingPropose} />
              </FormControl>
              <FormControl
                getFieldDecorator={getFieldDecorator}
                rules={[...ValidationUtility.required('Funding Purpose is required')]}
                name="typeOfFunding"
                label="Market">
                <SimpleSelect options={fundingTypes} />
              </FormControl>
              <FormControl
                getFieldDecorator={getFieldDecorator}
                rules={[...ValidationUtility.required('Goal Amount is required')]}
                name="amountRequested"
                label="Target Amount">
                <NumberFormat
                  thousandSeparator="," customInput={Input} />
              </FormControl>
              <FormControl
                getFieldDecorator={getFieldDecorator}
                name="thumbnail"
                rules={[...ValidationUtility.required('Thumbnail is required'),
                ...ValidationUtility.Base64ImageHasValidImageSizes([{ width: 350, height: 250 }], "thumbnail image size is not correct")]}
                help={"Thumbnail images must be 350x250 pixels"}
                validateTrigger="onChange"
                label="Thumbnail">
                <Base64FileUploader accept='image/*' previewSize={{ width: "200", height: "150" }} />
              </FormControl>
              <FormControl
                getFieldDecorator={getFieldDecorator}
                name="dashboardPicture"
                rules={[...ValidationUtility.required('Dashboard Image is required'),
                ...ValidationUtility.Base64ImageHasValidImageSizes([{ width: 600, height: 300 }], "Dashboard image size is not correct")]}
                help={"Dashboard images must be 600x300 pixels"}
                validateTrigger="onChange"
                label="Dashboard Image">
                <Base64FileUploader previewSize={{ width: "200", height: "150" }} accept='image/*' />
              </FormControl>
              <FormControl
                getFieldDecorator={getFieldDecorator}
                name="videoUrl"
                label="Video Url (Youtube only)">
                <Input />
              </FormControl>
              <FormControl
                getFieldDecorator={getFieldDecorator}
                name="businessPlan"
                label="Project Document">
                <Base64FileUploader />
              </FormControl>
              <FormControl
                getFieldDecorator={getFieldDecorator}
                name="documents"
                label="Others Document">
                <FilesEditor />
              </FormControl>
              <ShowIfTrue condition={project?.status === ProjectStatus.Finalized}>
                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  name="reason"
                  label="Reason for Update">
                  <TextArea />
                </FormControl>
              </ShowIfTrue>
            </Col>
          </LeftAlignedRow>


          <LeftAlignedRow>
            <Col span={20}>
              <Form.Item
                colon={false}>
                <ButtonGroup>
                  <Button onClick={() => history.goBack()} type="default">
                    Back
                  </Button>
                  <FullWithButton type="primary" htmlType="submit">
                    Save
                  </FullWithButton>
                </ButtonGroup>
              </Form.Item>
            </Col>
          </LeftAlignedRow>
        </Form>
      </Col>
    </LeftAlignedRow>
  );
}

export default Form.create({ name: "add-project-form" })(AddCompanyProjectForm);