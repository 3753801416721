import { Layout } from 'antd';
import * as React from 'react';
import styled from 'styled-components';
import topBarImage from 'assets/images/projectsBanner.png';

const { Header, Content, Footer } = Layout;

export const FundedBadge = styled.img`
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 0;
    height: 110px;
    width: 155px;
`;

export const Page = styled.div`
    .donateHeader{
            position: absolute;
            width: 102px;
            height: 32px;
            background-color: #e36e19;
            display: inline-block;
            color: #ffff;
            right: 184px;
            top: 50px;
        }
  
    .volunteer{
            position: absolute;
             width: 116px;
            height: 32px;
            background-color: #269b48;
            color: #ffff;
            right: 42px;
            top: 50px;
        }
    .backToHome{
            position: absolute;
            color: #ffff;
             left: 42px;
            top: 50px;
        }
    .paginatorClass{
        display: flex;
    }
    .ant-modal-footer{
        display: none;
    }
`;

export const Banner = styled.div`
    height: 260px;
    background-size: contain;
    background-position: center top;
    background-repeat: no-repeat;

    &.projectListing {
        background: url(${topBarImage});
    }

@media only screen and (min-width: 992px) {
    /*height: 50em;*/
    /* width: 80%; */
    //margin-left: 160px;
    //margin-right: 160px;
}
`;


export const SearchPanel = styled.div`
    height: fit-content;
    background-color: #e5e5e5;
    width: 100%;
    
    .filters{
        display: flex;
        min-height: fit-content;
        min-width: fit-content;
        height: 100%;
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        
        .clear{
            font-style: italic;
            color: #1d95d3;
            font-size: 14px;
            line-height: 50px;
        }

        .keyword{
            padding-top: 1.5vh;
            padding-bottom: 1.5vh;
            min-height: inherit;
            min-width: inherit;
            max-width: 20vw;
            height: inherit;
            width: inherit;
            display: flex;
            justify-content: center;
        }
        
        .projectNames{
            padding-top: 1.5vh;
            padding-bottom: 1.5vh;
            min-height: inherit;
            min-width: inherit;
            max-width: 20vw;
            height: inherit;
            width: inherit;

            .ant-select-selection--single{
                width: 250px;
                height: 48px;
            }
        }

        .status{
            padding-top: 1.5vh;
            padding-bottom: 1.5vh;
            padding-right: 1.5vw;
            min-height: inherit;
            min-width: inherit;
            max-width: 20vw;
            height: inherit;
            width: inherit;            

            .ant-select-selection--single{
               width: 250px;
               height: 48px;
            }

        }
        
        .clearAll{
            padding-top: 1.5vh;
            padding-bottom: 1.5vh;
            padding-right: 1.5vw;
            min-height: inherit;
            min-width: inherit;
            //max-width: 20vw;
            height: 48px;
            width: 250px;   
        }
        
        .search-textbox{
           width: 250px;
           height: 48px
        }

        
    }
`;
export const ProjectDetailsDiv = styled.div`
    padding: 60px 12vw 32px 12vw;
    max-width: 100vw;
    justify-content: center;

    .image{
       // width: inherit;
        height: inherit;
    }

    .video{
        height: 26em;
        width: 100%;
        max-width: 90vw;    
    }
    .green{
        color: #7bc257;
    }
    .goal{
        display: inline-flex;
        font-size: 26px;
        height: 2px;
    }
    .ant-avatar{
        width: 348px;
        height: 246px;
        
        img{
            object-fit: contain;
        }
    }

    .projectTitle{
        line-height: 70px;
        font-family: HelveticaNeueLTStd-57;
        font-size: calc(0.6vw + 50%);
        font-weight: 600;
        font-stretch: condensed;
        text-align: left;
        color: #1c1919;
        height: 90px;
    }

    .projectSubtitle{
        padding-top: 2px;
        font-size: 17px;
        font-style: italic;
        text-align: left;
        color: #1c1919;
        font-weight: normal;
    }
    .about{
        padding-left: 5%;
    }
    
    .tab{
        padding-right: 2.5%;
    }

    .others{
        padding-left: 2.5%;
    }

    .whoFrom{
        font-weight: bold;
    }
    .who{
        font-size: 14px;
    }
    .whoOccu{
        font-size: 12px;
    }
    .whoDetails{
        text-align: left;
        line-height: 5px;
        padding-top: 20px;
    }

    .donate{
        width: 100%;
        height: 40px;
        background-color: #e36e19;
        color: #fff;
        font-size: 17px;
    }
    
    .makeAPledge{
        width: 100%;
        height: 40px;
        background-color: #269b48;
        color: #fff;
        font-size: 17px;
    }
    
    .progressBar{
        padding-top: 18px;
        padding-bottom: 26px;
    }

    @media (max-width: 1700px){
        padding: 60px 5vw 32px 5vw;
        max-width: 100vw;
        justify-content: center;
        .about{
            //width: 100%
        }
        .tab{
            //width: 100%;
            min-height: 400px;
        }
        .others{
           // width: 100%
        }

         .green{
            color: #7bc257;
        }
        .goal{
            display: inline-flex;
            font-size: 16px;
            height: 2px;
            line-height: 34px;
        }
        .ant-avatar{
            width: 348px;
            height: 246px;
            
            img{
                object-fit: contain;
            }
        }

        .projectTitle{
            font-family: HelveticaNeueLTStd-57;
            font-size: calc(0.6vw + 50%);
            font-weight: 600;
            font-stretch: condensed;
            text-align: left;
            color: #1c1919;
        }

        .projectSubtitle{
            padding-top: 2px;
            font-size: 12px;
            font-style: italic;
            text-align: left;
            color: #1c1919;
            font-weight: normal;
            line-height: 34px;
        }
        .company{
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 2vh;
        }
        .whoDetails{
            width: 75%;
        }
        .whoAvatar{
            width: 25%;
            align-self: center;
        }
        .whoFrom{
            line-height: 20px;
        }
        
    }
    .ant-progress-inner {
        background-color: #d5d5d5!important;
    }
    
`;


export const ProjectListing = styled.div`
    /* padding-left: 5vw; */
    padding-top: 3vh;
    /* padding-right: 5vw; */
    /* display: block;
    margin-left: auto;
    margin-right: auto; */
    width: 100%;
.itemsRow{
    width: 100%;
    display: block;
    //margin-left: auto;
    //margin-right: auto;

}

    
    .projectItem{
        //width: 400px;
        //height: 460px;
        display: block;
        line-height: 20px;
        padding-bottom: 3vh;
         width: 411.5px;
        max-width: 91vw;
        padding: 1%;
        

        .projectCard{
            background-color: #fff;
            width: 100%;
            //height: 440px;
            //max-width: 80vw;
            padding: 0px;
        
            .ant-card-body{
                padding: 16px;
            }
        
        .ant-avatar{
            width: 100%;
            height: 246px;
            max-width: 80vw;
            
            img{
                object-fit: contain;
            }
        }
        
        .outerProject{
            padding-right: 1.5vw;
            padding-left: 1.5vw;
        }

        .projectTitle{
            padding-top: 10px;
            font-family: HelveticaNeueLTStd-57;
            font-size: 22px;
            font-weight: 600;
            font-stretch: condensed;
            text-align: left;
            color: #1c1919;
            //line-height: 1.2;
        }

        .projectSubtitle{
            padding-top: 10px;
            font-size: 12px;
            font-style: italic;
            text-align: left;
            color: #1c1919;
            line-height: 1.2;
            font-weight: normal;
        }
        .projectTitle,
        .projectSubtitle {
            text-wrap: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            //max-width: 100%;

            transition: all 1.5s;
        }
        .projectTitle:hover, .projectSubtitle:hover {
            text-wrap: balance;
            overflow: hidden;
            text-overflow: clip;
        }
        .green{
            color: #7bc257;
        }
        .goal{
            display: inline-flex;
            font-size: 14px;
            height: 2px;
        }

        .projectButtons{
            padding-right: 5px;
            padding-top: 2vh;
        }
        
        .view{
            width: 88px;
            height: 32px;
            background-color: #00aed8;
            display: inline-block;
            color: #ffff;
        }
        .donate{
            width: 102px;
            height: 32px;
            background-color: #e36e19;
            display: inline-block;
            color: #ffff;
        }
        .makeAPledge{
            width: 148px;
            height: 32px;
            background-color: #269b48;
            display: inline-block;
            color: #ffff;
        }

        @media  (max-width: 500px) {
            .projectButtons{
                width: 100%;
                padding-right: 5px;
                padding-top: 2vh;
            }
            .view{
            width: 100%;
            height: 32px;
            background-color: #00aed8;
            display: inline-block;
            color: #ffff;
        }
        .donate{
            width: 100%;
            height: 32px;
            background-color: #e36e19;
            display: inline-block;
            color: #ffff;
        }
        .makeAPledge{
            width: 100%;
            height: 32px;
            background-color: #269b48;
            display: inline-block;
            color: #ffff;
        }
        }
    
    }
    
        

    }

    .cartView{
        font-size: 20px;
    }
        
`;