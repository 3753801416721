import * as React from 'react';
import styled from 'styled-components';
import { Layout, Form, Button, Card } from 'antd';
import { FormProps } from 'antd/lib/form';

export const singleColFormColSize = {
  labelCol: {
    xs: { span: 9 },
    md: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 14 },
    md: { span: 14 },
  },
};

export const fullWidthSingleColFormColSize: FormProps = {
  labelCol: {
    xs: { span: 5 },
    md: { span: 3 },
  },
  wrapperCol: {
    xs: { span: 14 },
    md: { span: 17 },
  },
  labelAlign: "left"
};

export const fullWidthSingleColFormColSizeTwo: FormProps = {
  labelCol: {
    xs: { span: 5 },
    md: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 14 },
    md: { span: 15 },
  },
  labelAlign: "left"
};

export const doubleColFormColSize = {
  labelCol: {
    xs: { span: 9 },
    md: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 15 },
    md: { span: 17 },
  },
};

export const FullWithButton = styled(Button)`
  width: 100%;
`;

export const VMFullWithButton = styled(Button)`
  width: 100%;
  margin-top: '10px';
  color: 'white';
`;

export const WhiteBackgroundCard = styled(Card)`
  background-color: white;
  min-height: 60vh;
  padding-bottom: 50px;
`;










