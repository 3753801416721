import * as React from 'react';
import Utility from '../../../../Core/Utility';
import { IFilter, JoinType, FilterType } from 'Core/Api/Api';
import { TableFilterContext } from '../TableFilterContextProvider';
import TableRequest from 'Core/TableUtility/Models/TableRequest';
import debounce from 'lodash/debounce';

/**
 * Used to uniquely identify filters that were created by this
 * control
 * @interface MarkedFilter
 * @extends {IFilter}
 */
interface MarkedFilter extends IFilter {
  filterId?: number;
}

export interface FilterFieldWrapperProps {
  children?: React.ReactNode;
  filterFields: string;
  filterJoin?: JoinType;
  filterType?: FilterType;
  onFilterUpdated?: (filters: IFilter[]) => void;
}

export interface State {
  filterId?: number;
}

export default class FilterFieldWrapper<
  extendablePropsType extends FilterFieldWrapperProps
> extends React.Component<extendablePropsType, State> {
  static defaultProps: FilterFieldWrapperProps = {
    filterType: FilterType.Contains,
    filterJoin: JoinType.And,
    filterFields: '',
  };
  static contextType = TableFilterContext;
  context!: React.ContextType<typeof TableFilterContext>;

  constructor(props: extendablePropsType) {
    super(props);

    this.state = {
      filterId: Utility.RandomNumber(93823),
    };
  }

  UpdateFilter(filterValue: string | any) {
    // remove filters that were added by this control
    const filters = this.GetCleanContextFilters();

    // add filters that pertain to this control
    const newFilters = filterValue ? this.CreateFilterList(filterValue) : [];
    filters.unshift(...newFilters);

    this.context.data!.filters = filters;
    this.context.Update(this.context.data);
  }

  render() {
    return <>{this.props.children}</>;
  }

  /**
   * Get the filter from the context object with any previous filters that this
   * control would have added (but keep other filters)
   *
   * @protected
   * @returns {IFilter[]}
   * @memberof FilterFieldWrapper
   */
  protected GetCleanContextFilters(): IFilter[] {
    const tableRequest = this.context.data || { filters: [] };
    const filters = this.RemoveOldFilters(tableRequest.filters || []);
    return filters;
  }

  /**
   * Remove filters that this control would have added.
   *
   * @protected
   * @param {IFilter[]} filters
   * @returns {IFilter[]}
   * @memberof FilterFieldWrapper
   */
  protected RemoveOldFilters(filters: IFilter[]): IFilter[] {
    return filters.filter(x => (x as MarkedFilter).filterId !== this.state.filterId);
  }

  /**
   * Create a list of filters based on the controls properties
   * and the value(s) that have been passed in
   *
   * @protected
   * @param {*} filterValue
   * @returns {IFilter[]}
   * @memberof FilterFieldWrapper
   */
  protected CreateFilterList(filterValue: any): IFilter[] {
    const filters: IFilter[] = [];
    const filterFields = this.props.filterFields.split(',').map(x => x.trim());
    for (const field of filterFields) {
      const markedFilter = {
        field,
        filterId: this.state.filterId,
        filterType: this.props.filterType,
        joinType: JoinType.Or,
        value: filterValue,
      };
      filters.push(markedFilter);
    }
    return filters;
  }
}
















