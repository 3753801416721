import * as React from 'react';
import { CenterAlignedRow } from 'components/Styled/Layout/CenterAlignedRow';
import { Col, Form, Input, Row, Button, DatePicker, Select } from 'antd';
import FormControl from 'components/Forms/FormControl';
import { FormComponentProps } from 'antd/lib/form';
import { withRouter, RouteComponentProps, NavLink } from 'react-router-dom';
import ValidationUtility from 'Core/ValidationUtility';
import UserProfileHandler from '../UserProfileHandler';
import { IResetPasswordCommandRequest, IChangePasswordCommandRequest, SimpleUserProfile } from 'Core/Api/Api';
import FormItem from 'antd/lib/form/FormItem';
import NotificationService from 'Core/NotificationService';
import ConfigService from 'Core/ConfigService';
import Title from 'antd/lib/typography/Title';
import { BrandedButton } from '../../Styled/Branded/BrandedButton';
import moment from 'moment';

export interface Props extends FormComponentProps, RouteComponentProps {
    children?: React.ReactNode;
}

export interface State {
    token?: string;
    profile?: any;
    emailAddress?: string;
}

export class EditUserProfileFormWithoutFormsAndRouter extends React.Component<Props, State> {
    handler: UserProfileHandler;

    constructor(props: Props) {
        super(props);

        this.state = {};
        this.handler = new UserProfileHandler(props);
    }

    componentDidMount() {
        this.getProfileInfo();
    }
    getProfileInfo() {
        this.handler.GetMyProfile().then(
            x => {
                this.setState({ profile: x });
                this.props.form.setFieldsValue(x ?? {});
            },
            x => NotificationService.error(x.message)
        )
    }

    onSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        console.log('values', this.props.form.getFieldsError());
        this.props.form.validateFields((error, values: SimpleUserProfile) => {
            if (error) {
                return;
            }

            this.handler.EditUserProfile(values).then(() => {
                this.props.history.push('/user-profile');
                NotificationService.success('Profile has been updated');
            });
        });
    };

    render() {
        var { getFieldDecorator } = this.props.form;
        var passwordValidations = ValidationUtility.password;
        var compareValidations = ValidationUtility.compare;
        return (
            <Row>
                <Col offset={3} span={18} style={{ marginTop: '20px', backgroundColor: 'white', padding: '20px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', border: '1px solid rgba(0, 0, 0, 0.1)', borderRadius: '10px' }}>
                    <Form onSubmit={this.onSubmit}>
                        <Title>Edit User Profile </Title>
                        <Row>
                            <Col xs={{ span: 24, offset: 0 }} md={{ span: 10, offset: 0 }}>
                                <FormControl
                                    getFieldDecorator={getFieldDecorator}
                                    rules={[...ValidationUtility.required("First Name is required")]}
                                    label="First Name"
                                    name="firstName">
                                    <Input />
                                </FormControl>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} md={{ span: 10, offset: 1 }}>
                                <FormControl
                                    getFieldDecorator={getFieldDecorator}
                                    label="Last Name"
                                    name="lastName"
                                    rules={[...ValidationUtility.required("Last Name is required")]}>
                                    <Input />
                                </FormControl>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ span: 24, offset: 0 }} md={{ span: 10, offset: 0 }}>
                                <FormControl
                                    getFieldDecorator={getFieldDecorator}
                                    rules={[...ValidationUtility.required('Id Type is required')]}
                                    name="idType"
                                    label="ID Type">
                                    <Select allowClear={true}
                                        placeholder={'please select an option'}
                                        optionFilterProp={'label'} >
                                        <Select.Option key={'Passport'} label={'Passport'} value={'Passport'}>
                                            Passport
                                        </Select.Option>
                                        <Select.Option key={"Driver's License"} label={"Driver's License"} value={"DriversLicense"}>
                                            Driver's License
                                        </Select.Option>
                                        <Select.Option key={'National ID'} label={'National ID'} value={'NationalID'}>
                                            National ID
                                        </Select.Option>
                                    </Select>
                                </FormControl>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} md={{ span: 10, offset: 1 }}>
                                <FormControl
                                    getFieldDecorator={getFieldDecorator}
                                    rules={[...ValidationUtility.required('Id Number is required')]}
                                    name="idNumber"
                                    label="ID Number">
                                    <Input />
                                </FormControl>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ span: 24, offset: 0 }} md={{ span: 10, offset: 0 }}>
                                <FormControl
                                    getFieldDecorator={getFieldDecorator}
                                    label="Phone Number"
                                    name="phoneNumber">
                                    <Input />
                                </FormControl>
                            </Col>
                        </Row>
                        <FormItem>
                            <Row>
                                <Col>
                                    <BrandedButton htmlType="submit">Update Profile</BrandedButton>
                                </Col>
                            </Row>
                        </FormItem>
                    </Form>
                </Col>
            </Row>
        );
    }
}

const EditUserProfileFormWithoutRouter = Form.create<Props>({
    name: 'EditUserProfileForm',
})(EditUserProfileFormWithoutFormsAndRouter);

const EditUserProfileForm = withRouter(EditUserProfileFormWithoutRouter);

export default EditUserProfileForm;










