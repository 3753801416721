import React from 'react';
import { Col, Row } from 'antd';
import AttachmentOnePdf from 'assets/project/guidelines/Attachment 1 - Project Document Guidelines.pdf';
import AttachmentTwoPdf from 'assets/project/guidelines/Attachment 2 - Supporting Information Required.pdf';
import AttachmentThreePdf from 'assets/project/guidelines/Attachment 3 - 10 Point Business Plan Guidelines.pdf';
import AttachmentFourPdf from 'assets/project/guidelines/Attachment 4 - JSSE Business Plan Template.pdf';
import AttachmentSixPdf from 'assets/project/guidelines/Attachment 6 - TERMS-Definitions  Interpretaions.pdf'
import styled from 'styled-components';




const I = styled.i`
  white-space: nowrap;
  `;

const Wrap = styled.i`
  white-space: nowrap;
  font-weight: 500;
  `;

export const CreateProjectGuidelineDetails = () => {
  return (
    <>
      <Row type='flex' justify='center'>
        <Col span={20} style={{ textAlign: 'left' }}>
          <br />
          <h1 style={{ justifyContent: 'center', display: 'flex', fontSize: '40px' }}>Guidelines for Application to the JSSE</h1> <br />
          <p>To successfully submit a Project to the JSSE, each <Wrap>Social Sector Organization (SSO)</Wrap> is required to submit a <b>Project Proposal</b> and <b>Supporting Information</b> (See <i>Attachment 1</i> & <i>Attachment 2</i>)</p>

          <p>
            <ul>
              <li>
                <b>Project Document Guidelines</b> - The <Wrap>Project Proposal</Wrap> should follow the format set out in <a href={AttachmentOnePdf} download><I>Attachment 1</I></a>
              </li>
              <br />
              <li>
                <b>Supporting Information</b> - The <Wrap>Project Proposal</Wrap> must be submitted accompanied by <Wrap>Supporting Information</Wrap>, set out in <a href={AttachmentTwoPdf} download><I>Attachment 2</I></a>
              </li>
            </ul>
          </p>

          <p>To furthere aid in writing a <Wrap>Proposal</Wrap>, around a <Wrap>Theory of Change</Wrap>, the JSSE has also provided a <Wrap>10-Point Business Plan Guideline</Wrap> as set out in <i>Attachment 3</i>, along with a <Wrap>Business Plan Template</Wrap> as set out in <i>Attachment 4</i></p>

          <p>Financial and other guidelines to help the organization in their application, outlined below:</p>
          <p>
            <ul>
              <li>
                <b>10 Point Business Plan</b> - A list of critical questions to be answered in developing a <Wrap>Business Plan</Wrap> - set out in <a href={AttachmentThreePdf} download><I>Attachment 3</I></a>
              </li>
              <br />
              <li>
                <b>Business Plan Template</b> - Be guided by the layout and necessary components of a <Wrap>Business Plan</Wrap> - set out in <a href={AttachmentFourPdf} download><I>Attachment 4</I></a>
              </li>
              <br />
              <li>
                <b>JSSE Financial Templates</b> - This Folder includes sample <Wrap>Financial Documents</Wrap> and the actual <Wrap>JSSE Financial Templates</Wrap> to be used in the <Wrap>Business Plan</Wrap> as set out in <a href={process.env.PUBLIC_URL + '/JSSE_Financial_Templates.zip' } download><I>Attachment 5</I></a>
              </li>
              <br />
              <li>
                <b>Interpretations of Terms and Definitions </b> - This document will aid you in understanding terms used in the <b>Listing Agreement to be entered into upon Listing of the Project on the JSSE</b> (<a href={AttachmentSixPdf} download><I>Attachment 6</I></a>)
              </li>
            </ul>
          </p>
          
        </Col>
      </Row>
    </>
  );
}
export default CreateProjectGuidelineDetails;

