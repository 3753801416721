import * as React from 'react';
import antd from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import styled from 'styled-components';
import FormControl from '../../Forms/FormControl';

const { Form } = antd;

export const StyleLoginForm = styled(Form)`
  max-width: 300px;
  min-width: 300px;

  .login-form-forgot {
    float: right;
  }

  .login-form-sign-up{
    float: left;
  }

  .login-form-button {
    width: 100%;
    background-color: rgb(174, 24, 87);
  }
`;
















