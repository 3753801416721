import * as React from 'react';
import { Layout, Row, Col, Icon } from 'antd';
import { AppRoutes } from 'Core/AppRoutes';
import TopBar from './TopBar';
import ILayout from 'Core/models/ILayout';
import { FooterLayout } from '../FooterLayout';
import DarkLogoImg from '../../../assets/images/dark_logo.png';
import { DarkLogo } from '../../Styled/Logo';
const { Content, Footer } = Layout;

export interface Props extends ILayout {
    children?: React.ReactNode;
}

export interface State { }

export default class LandingLayout extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Layout className="react-transition fade-in" style={{ animationDuration: '750ms' }}>
                <TopBar menu={AppRoutes} />
                <Row type="flex" justify="center" style={{ minHeight: '85vh', marginBottom: '70px' }}>
                    <Col xs={24}>
                        <Content>
                            <Row type="flex" align="middle" justify="center">
                                <Col xs={24}>{this.props.children}</Col>
                            </Row>
                        </Content>
                    </Col>
                </Row>
                <FooterLayout /> 
            </Layout>
        );
    }
}










