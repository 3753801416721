import * as React from 'react';
import { Popover, Button, Row, Col, Input, Tooltip, Icon } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import Utility from 'Core/Utility';
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    OKShareButton,
    PinterestShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    HatenaIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WeiboIcon,
    WhatsappIcon,
    WorkplaceIcon
} from 'react-share';
import styled from 'styled-components';

export interface Props extends ModalProps {
    children: React.ReactNode;
    title: string;
    condition?: boolean;
    showCancel?: boolean;
    url: string;
    actions?: React.ReactNode[];
    shareMessage: string;
    popupMessage?: string;
}

export interface State {
    showModal?: boolean;
}

const StyledPopover = styled(Popover)`
    &.ant-btn:not([disabled]):hover {
        color: #25c5e6;
    }
`;

export default class SharePopup extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    onClick = (event: React.SyntheticEvent) => {
        if (!this.props.condition) {
            event.stopPropagation();
            this.setState({ showModal: true });
        } else {
            const child = this.props.children as React.ReactElement<any>;
            child.props.onClick?.call(this, event);
        }
    };

    onCancel = () => {
        this.setState({ showModal: false });
    };


    render() {
        const { Search } = Input;
        let { children, condition, title, url, actions, popupMessage, shareMessage, ...modalProps } = this.props;
        //let modifiedChildren = Utility.replaceChildProps(children, { onClick: this.onClick });
        return (
            <StyledPopover overlayStyle={{ width: '350px' }} content={
                <>
                    <Row style={{ marginBottom: '20px' }}>
                        <Col>
                            {popupMessage}
                            <br />
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '20px' }}>
                        <Col>
                            <FacebookShareButton url={url} quote={shareMessage}><FacebookIcon size={32} round={true} /></FacebookShareButton>
                            <EmailShareButton url={url} subject={shareMessage} body={shareMessage}><EmailIcon size={32} round={true} /></EmailShareButton>
                            <LinkedinShareButton url={url} title={shareMessage} summary={''} ><LinkedinIcon size={32} round={true} /></LinkedinShareButton>
                            <WhatsappShareButton url={url} title={shareMessage} separator=" - "><WhatsappIcon size={32} round={true} /></WhatsappShareButton>
                            <TwitterShareButton url={url} title={shareMessage} ><TwitterIcon size={32} round={true} /></TwitterShareButton>
                        </Col>
                    </Row>

                    <Row style={{ marginBottom: '20px' }}>
                        <Col>
                            <Search style={{ backgroundColor: '#938d8d', cursor: 'not-allowed' }} value={url}
                                onSearch={value => navigator.clipboard.writeText(value)}
                                enterButton={
                                    <Button type='primary'>
                                        Copy Link
                                    </Button>
                                } />
                        </Col>
                    </Row>
                </>}
                title={title ?? "Share"} trigger="click">
                {children}

            </StyledPopover>

        );
    }
}

