import { UniqueImage } from 'components/Forms/UniqueImage';
import { News, NewsListModel } from 'Core/Api/Api';
import DetailsData from 'Core/models/DetailsData';
import DetailsMode from 'Core/models/DetailsMode';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { NewsArticleCard } from '../NewsArticleCard';
import { NewsHandler } from '../NewsHandler';

const NewsRowContainer = styled.div`

`;

const ArticlesRow = styled.div`
  display: flex;
  gap: 10px;
  flex-flow: row wrap;
  justify-content: space-evenly;

`;


const ViewMoreRow = styled.div`
  display: flex;

  justify-content: flex-end;
`

interface Props {
    showDate?: boolean
}

export default ({showDate}: Props) => {

    const handler = new NewsHandler({});
    const history = useHistory();
    const [newsArticles, setNewsArticles] = useState<NewsListModel[] | undefined>(undefined);


    useEffect(() => {
        getNews();
    }, [])

    const getNews = () => {
        handler.GetNews({ amountPerPage: 4 }).then(response => {
            setNewsArticles(response.results);
        })
    }



    return (

        <NewsRowContainer>
            <ViewMoreRow></ViewMoreRow>
            <ArticlesRow>
                {newsArticles?.map(news => (
                    <NewsArticleCard key={news.id} showDate={showDate?? true} news={news}></NewsArticleCard>
                ))}
            </ArticlesRow>
        </NewsRowContainer>);
}