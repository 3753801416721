import { Empty } from 'antd';
import { DocumentItemDisplay } from 'components/Forms/FilesEditor/DocumentItemDisplay';
import { DataFileType, IDocumentModel } from 'Core/Api/Api';
import React from 'react';
import styled from 'styled-components';

const Wrap = styled.div``;

interface Props {
  documents?: IDocumentModel[] | undefined;
}

export default ({ documents }: Props) => {
  const projectDocuments = documents?.filter(x => x.fileType == DataFileType.ProjectDocument);
  return <Wrap>
    {!projectDocuments?.length && <Empty description={"No Project Documents Yet"} />}
    {!!projectDocuments?.length && projectDocuments?.map(document => (<DocumentItemDisplay document={document} readonly={true} ></DocumentItemDisplay>))}
  </Wrap>;
}