import { Form, Input, Button, Row, Col, Divider, Avatar, Progress, Typography, Select, Collapse, Checkbox, Radio, Icon, } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import AuthContext from '../../../Context/AuthContext';
import FormControl from '../../Forms/FormControl';
import Title from 'antd/lib/typography/Title';
import ValidationUtility from 'Core/ValidationUtility';


import TextArea from 'antd/lib/input/TextArea';
import { ProjectsHandler } from '../../ProjectsListView/ProjectsHandler';
import { CenterAlignedRow } from '../../Styled/Layout/CenterAlignedRow';
import { InvestorType, IPledgeModel, IProjectFullDetailsModel, ProjectStatus } from 'Core/Api/Api';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import { ColoredPill } from 'components/Forms/ColoredPill';
import { findLastIndex, startCase } from 'lodash';
import NumberFormat from 'react-number-format';
import SimpleSelect from 'components/Forms/SimpleSelect';
import { useHistory } from 'react-router';
import { FullWithButton } from 'components/Forms/Form.Styled';
import ButtonGroup from 'components/Styled/ButtonGroup';
import ShowIfTrue from 'components/Forms/ShowIfTrue';
import UserHandler from 'components/Admin/UserHandler';
import UserProfileHandler from 'components/UserManagement/UserProfileHandler';
import NotificationService from 'Core/NotificationService';
import termsPdf from '../../../assets/pdfs/terms.pdf';
import privacyPdf from '../../../assets/pdfs/privacy.pdf';
import riskPdf from '../../../assets/pdfs/risk.pdf';
import { Page } from '../../../components/ProjectList/Pledge/PledgeForm.Style';
import bankingDetails from '../../../assets/pdfs/Banking-Details-JSSE.pdf';


export interface Props extends FormComponentProps<IPledgeModel>, RouteComponentProps {
    children?: React.ReactNode;
}

export interface State {
    isCompany: boolean;
}

export const PledgeFormWithoutFormAndRouter = ({ form, }: Props) => {

    const { Update, data: user } = useContext(AuthContext);
    const { id: projectId } = useParams<{ id: string }>();
    const [project, setProject] = useState<IProjectFullDetailsModel | undefined>(undefined);
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const handler = new ProjectsHandler({});
    const userProfileHandler = new UserProfileHandler({});
    const history = useHistory();

    useEffect(() => {
        if (projectId) {
            handler.GetProjectByIdForPublic(projectId).then(x => {
                setProject(x.project);
            })
        }

    }, [projectId])

    useEffect(() => {
        if (user) {
            setUserLoggedIn(true);
            userProfileHandler.GetMyProfile().then(x => {
                if (x.companyName) {
                    form.setFieldsValue(
                        {
                            investorType: InvestorType.Company,
                            companyName: x.companyName,
                            phoneNumber: x.phoneNumber,
                            email: user.email,
                        }, () => form.setFieldsValue({ companyName: x.companyName })
                    );

                } else {
                    form.setFieldsValue(
                        {
                            investorType: InvestorType.Individual,
                            phoneNumber: x.phoneNumber,
                            email: user.email,
                        }, () => form.setFieldsValue({
                            firstName: x.firstName,
                            lastName: x.lastName,
                        }
                        ));
                }
            })
        }
    }, []);

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        form.validateFieldsAndScroll((err, values) => {
            if (err) return;
            values.projectId = projectId;

            handler.Pledge(values).then(x => {
                NotificationService.success(`Thank you for your pledge to ${project?.name}.`, "", 10, true);
                history.goBack();
            })
        });
    }

    var { getFieldDecorator } = form;

    return (
        <>
            <Page>
                <Col span={24}>
                    <Row>
                        <Col sm={{ span: 17, offset: 7 }} xs={{ offset: 1 }}>
                            <Row>
                                <Col sm={{ offset: 4 }} xs={{ offset: 0 }}>
                                    <Title level={4}>Make A Pledge To {project?.name} </Title>
                                </Col>
                            </Row>
                            <Row>
                                <Col className={'about'} sm={{ span: 14 }} xs={{ span: 22, offset: 1 }}>
                                    <Row>
                                        <Col className={'whoAvatar'} xs={{ offset: 7 }} sm={{ span: 6, offset: 2 }}>
                                            <Avatar src={project?.thumbnailFileUrl} size={100} />
                                        </Col>
                                        <Col className={'whoDetails'} xs={{ span: 22, offset: 1 }} sm={{ span: 15 }}>
                                            <p className={'whoFrom'}>{project?.company?.name}</p>
                                            <p className={'whoOccu'}>{project?.subTitle}</p>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={{ span: 16, offset: 4 }} xs={{ span: 22, offset: 1 }}>

                                            {(project?.amountReceived ?? 0) >= (project?.amountRequested ?? 0) ?
                                                <p style={{ display: "flex", flexDirection: "row" }} className={'goal'}>JMD$<NumberFormat displayType='text' decimalScale={2} fixedDecimalScale={true} thousandSeparator=',' value={project?.amountReceived} />&nbsp;raised<div className={'green'}>&nbsp;of $<NumberFormat displayType='text' decimalScale={2} fixedDecimalScale={true} thousandSeparator=',' value={project?.amountRequested} /></div></p>
                                                :
                                                <p style={{ display: "flex", flexDirection: "row" }} className={'goal'}><div className={'green'}>JMD$<NumberFormat displayType='text' decimalScale={2} fixedDecimalScale={true} thousandSeparator=',' value={project?.amountReceived} />&nbsp;raised </div>&nbsp;of $<NumberFormat displayType='text' decimalScale={2} fixedDecimalScale={true} thousandSeparator=',' value={project?.amountRequested} /></p>
                                            }
                                            <Progress className={'progressBar'} showInfo={false} percent={(project?.amountRequested ?? 0) == 0 ? 0 : ((project?.amountReceived ?? 0) / (project?.amountRequested ?? 0)) * 100} ></Progress>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col style={{ backgroundColor: 'white', padding: '3%', borderRadius: '2%' }} xs={{ span: 22, offset: 2 }} sm={{ span: 10, offset: 2 }}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                                <Title level={3} style={{ width: 'fit-content', color: '#133b3a' }}>Complete Pledge Form</Title>
                                <Col style={{ width: '2vw', height: '0.6vh', backgroundColor: '#00AED8', marginTop: '-2vh' }} xs={{ span: 1 }} />
                            </Col>
                            <Form onSubmit={onSubmit} layout="horizontal"  >
                                <Col span={24}>
                                    <Row>
                                        <Col>
                                            <FormControl
                                                getFieldDecorator={getFieldDecorator}
                                                initialValue={InvestorType.Individual}
                                                rules={[...ValidationUtility.required('Please select a investor type!')]}
                                                name="investorType" //getFieldDecorator(name: { hidden: true })
                                            >
                                                <Radio.Group >
                                                    <Radio value={InvestorType.Individual}>Individual</Radio>
                                                    <Radio value={InvestorType.Company}>Company</Radio>
                                                </Radio.Group>
                                            </FormControl>
                                        </Col>
                                    </Row>
                                    <ShowIfTrue condition={form.getFieldValue("investorType") === InvestorType.Individual}>
                                        <Row>
                                            <Col span={8}>
                                                <FormControl
                                                    getFieldDecorator={getFieldDecorator}
                                                    rules={[...ValidationUtility.required('Full name is required')]}
                                                    name="firstName"
                                                    label="First Name">
                                                    <Input />
                                                </FormControl>
                                            </Col>

                                            <Col span={9} offset={1}>
                                                <FormControl
                                                    getFieldDecorator={getFieldDecorator}
                                                    rules={[...ValidationUtility.required('Full name is required')]}
                                                    name="lastName"
                                                    label="Last Name">
                                                    <Input />
                                                </FormControl>
                                            </Col>

                                        </Row>

                                    </ShowIfTrue>
                                    <ShowIfTrue condition={form.getFieldValue("investorType") === InvestorType.Company}>
                                        <Row>
                                            <Col span={18}>
                                                <FormControl
                                                    getFieldDecorator={getFieldDecorator}
                                                    rules={[...ValidationUtility.required('Full name is required')]}
                                                    name="companyName"
                                                    label="Company Name">
                                                    <Input />
                                                </FormControl>
                                            </Col>
                                        </Row>
                                    </ShowIfTrue>
                                    <Row>
                                        <Col span={18}>
                                            <FormControl
                                                getFieldDecorator={getFieldDecorator}
                                                rules={[...ValidationUtility.required('Email address is required')]}
                                                name="email"
                                                label="Email Address">
                                                <Input placeholder={''} />
                                            </FormControl>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={18}>
                                            <FormControl
                                                getFieldDecorator={getFieldDecorator}
                                                rules={[...ValidationUtility.required('Phone number is required')]}

                                                name="phoneNumber"
                                                label="Phone Number">
                                                <Input />
                                            </FormControl>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col span={13} xs={{ span: 18 }}>
                                            <FormControl
                                                getFieldDecorator={getFieldDecorator}
                                                rules={[...ValidationUtility.required('Pledge amount is required')]}
                                                name="amount"
                                                label="Pledge Amount">
                                                <NumberFormat
                                                    thousandSeparator="," customInput={Input} />
                                            </FormControl>
                                        </Col>
                                        <Col span={4} offset={1} xs={{ span: 7, offset: 0 }}>
                                            <FormControl
                                                getFieldDecorator={getFieldDecorator}
                                                rules={[...ValidationUtility.required('Message is required')]}
                                                name="currency"
                                                label="Currency">
                                                <Select>
                                                    <Select.Option value={"JMD"} label="JMD">JMD</Select.Option>
                                                    <Select.Option value={"USD"} label="USD">USD</Select.Option>
                                                </Select>
                                            </FormControl>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormControl
                                                getFieldDecorator={getFieldDecorator}
                                                rules={[...ValidationUtility.required(' the Terms and Conditions to continue')]}
                                                name="terms"
                                                label="">
                                                <Checkbox>
                                                    I agree to the <a href={termsPdf} download >Terms of Use <Icon type="download" /></a>
                                                </Checkbox>
                                            </FormControl>
                                            <br></br>
                                            <p className="text-content-justified">
                                                Please note: <a target='_blank' href={bankingDetails} >Click here to see the banking details</a>
                                            </p>
                                        </Col>
                                    </Row>

                                    <LeftAlignedRow>
                                        <Row>
                                            <Col xs={{ span: 6 }} sm={{ span: 4 }}>
                                                <ButtonGroup>
                                                    <Button onClick={() => history.goBack()} type="default">
                                                        Back
                                                    </Button>
                                                </ButtonGroup>
                                            </Col>
                                            <Col xs={{ span: 8, offset: 4 }} sm={{ span: 12, offset: 6 }}>
                                                <ButtonGroup>
                                                    <Button type="primary" htmlType="submit">
                                                        Save
                                                    </Button>
                                                </ButtonGroup>
                                            </Col>
                                        </Row>
                                    </LeftAlignedRow>
                                </Col>
                            </Form>
                        </Col>
                        <Col className='FAQ' sm={{ span: 9 }} xs={{ span: 22, offset: 1 }}>
                            <Title level={3} style={{ width: 'fit-content', color: '#7bc257' }}>Frequently Asked Questions</Title>
                            <Col style={{ width: '2vw', height: '0.6vh', backgroundColor: '#00AED8', marginTop: '-2vh' }} xs={{ span: 1 }} />
                        </Col>
                        <Col sm={{ span: 9 }} xs={{ span: 22, offset: 1 }}>
                            <Collapse>
                                <Collapse.Panel header="How do I confirm my pledge contribution?" key="1" forceRender={true}>
                                    <p>Simply complete the pledge form and submit. You will receive a confirmation that the pledge has been submitted.</p>
                                    <p>Thereafter we will call you to make the arrangements to receive your contribution.</p>
                                </Collapse.Panel>
                                <Collapse.Panel header="How can I donate offline?" key="2">
                                    <p>You can donate offline using these methods:</p>
                                    <p>
                                        <ul>
                                            <li>Cash/Cheque - Delivered to Jamaica Stock Exchange 40 Harbour Street</li>
                                            <li>International bank wire transfers and Direct Transfers:  <a target='_blank' href={bankingDetails} >Click here to see the banking details</a></li>
                                        </ul>
                                    </p>
                                </Collapse.Panel>
                            </Collapse>
                        </Col>
                    </Row >
                </Col >
            </Page>
        </>
    );

}

const PledgeFormWithoutRouter = Form.create<Props>({
    name: 'PledgeForm',
})(PledgeFormWithoutFormAndRouter);

const PledgeForm = withRouter(PledgeFormWithoutRouter);

export default PledgeForm;


