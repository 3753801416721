import * as React from 'react';
import styled from 'styled-components';
import { Layout, Form, Button, Upload } from 'antd';

export const LongUpload = styled(Upload)`
  &.long .upload-list-inline .ant-upload-list-item {
    float: left;
    width: 200px;
    margin-right: 8px;
  }
  .upload-list-inline .ant-upload-animate-enter {
    animation-name: uploadAnimateInlineIn;
  }
  .upload-list-inline .ant-upload-animate-leave {
    animation-name: uploadAnimateInlineOut;
  }
`;










