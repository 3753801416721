import * as React from 'react';
import JcsdAccountWizardForm from 'components/Client/JcsdAccountWizardForm';

export interface Props {
  children?: React.ReactNode;
}

export interface State { }

export default class JcsdAccountWizardPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return <JcsdAccountWizardForm />;
  }
}













