import * as React from 'react';
import { RouteProps } from 'react-router-dom';
import CompanyProfileForm from 'components/CompanyProjects/CompanyProfileForm';

export interface Props extends RouteProps {
  children?: React.ReactNode;
}

export interface State {}

export default class CompanyProfilePage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return ( <CompanyProfileForm /> );
  }
}

