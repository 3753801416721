import * as React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import ISelectOption from './ISelectOption';
const { Option } = Select;

export interface Props extends SelectProps<string> {
  options: ISelectOption[];
}

export interface State {}

export default class SimpleSelect extends React.Component<Props, State> {
  static defaultProps: Props = {
    options: [],
    showSearch: true,
    allowClear: true,
    placeholder: 'Please select an option',
    optionFilterProp: 'label',
  };

  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  public buildSelectOptions(options?: ISelectOption[]) {
    options = options ?? [];
    return options.map(x => (
      <Option key={x.value || x.text} label={x.text || x.value} value={x.value || x.text}>
        {x.text || x.value}
      </Option>
    ));
  }

  render() {
    var { options, ...rest } = this.props;
    return <Select {...rest}>{this.buildSelectOptions(options)}</Select>;
  }
}













