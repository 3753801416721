import React from 'react'
import { Row, Col } from 'antd';
import { ProfileContainer } from '../ProfileContainer';

type ProfileProps = {
    profiles: any
}

export const ProfileHolder = (props: ProfileProps) => {
    return (
        <>
            <Row>
                <Col style={{ maxWidth: '100vw', display: 'flex', flexWrap: 'wrap' }} >
                    <Row type="flex" justify="space-around" style={{ width: '85vw'}}>
                        {props.profiles?.map(item => {
                            return (
                                <ProfileContainer board={item} />
                            )
                        })}
                    </Row>
                </Col>
            </Row>
        </>
    );
}
