import { Button, Col, Divider, Row } from 'antd';
import * as React from 'react';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import Title from 'antd/lib/typography/Title';
import ShowIfTrue from '../../Forms/ShowIfTrue';
import bankingDetails from '../../../assets/pdfs/Banking-Details-JSSE.pdf';

import continuePNG from 'assets/images/howtopictures/continue.png'
import donatenowPNG from 'assets/images/howtopictures/donatenow.png'
import donationamountPNG from 'assets/images/howtopictures/donationamount.png'
import loginPNG from 'assets/images/howtopictures/loginsample.png'
import makeapledgePNG from 'assets/images/howtopictures/makeapledge.png'
import pledgeformPNG from 'assets/images/howtopictures/pledgeform.png'
import projectsnavPNG from 'assets/images/howtopictures/projectsnav.png'
import submitformPNG from 'assets/images/howtopictures/submitform.png'
import viewcartPNG from 'assets/images/howtopictures/viewcart.png'
import viewcartheaderPNG from 'assets/images/howtopictures/viewcartheader.png'
import withoutstep1 from 'assets/images/howtopictures/withoutstep1.png'
import withoutstep3 from 'assets/images/howtopictures/withoutstep3.png'
import withoutstep5 from 'assets/images/howtopictures/withoutstep5.png'
import withoutstep6 from 'assets/images/howtopictures/withoutstep6.png'


interface Props extends RouteComponentProps {
    children?: React.ReactNode;
};

interface State {
    withoutAccount: boolean;
    withAccount: boolean;
    offlineDonate: boolean;
};

const HowToPage = styled.div`
.buttonBox{
    padding: 10px;

    button{
        margin-bottom: 10px;
        box-shadow: 0.2px 0.2px 5px rgba(0, 0, 0, 0.3);
    }
}

.title-div{
    align-self: center;
}

.title-font{
    text-align: center;
    font-weight: 400;
    font-size: 1.5rem;
}

.detailsViewBox{
    background-color: #FFFFFF;
    padding-top: 2vh;
    padding-bottom: 2vh;
    padding-left: 2vw;
    padding-right: 2vw;
    border-radius: 20px;
    display: flex;
    box-shadow: 0.2px 0.2px 5px rgba(0, 0, 0, 0.3);

    img{
        max-width: 100%; // Ensure images don't exceed the width of their container
        height: auto;    // Maintain aspect ratio
        display: block;  // Remove extra space below inline images
        margin-top: 10px; // Adjust the margin based on your design
        margin-bottom: 5vh;
        align-self: flex-start;
        box-shadow: 0.2px 0.2px 5px rgba(0, 0, 0, 0.3);
    }
    
    li{
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }
    ol {
        list-style-type: decimal;
    }
}

.content-div{
    width: 47%;
    align-self: center;
}


`;

const OptionButton = styled(Button)`
        width: 100%;
        height: auto;
        padding: 10px;
        font-weight: 500;
        font-size: clamp(1rem, 1vw, 1.2rem);
        overflow: wrap;
        text-overflow: wrap;
        background-color: #01AED8;
        color: #ffff;

         &.active{
             background-color: #269B48;
             border: 1px #228c41;
             color: #ffff;

        }
    `;

const HowToDonateWithoutRouter: React.FC<Props> = (props) => {
    const { history, location, match } = props;
    const [state, setState] = React.useState<State>({
        withAccount: false,
        withoutAccount: true,
        offlineDonate: false
    });



    const onClickAccountType = (accountType) => (e) => {
        setState((prevState) => ({
            ...prevState,
            withoutAccount: accountType === 'without',
            withAccount: accountType === 'with',
            offlineDonate: accountType === 'offline',
        }));
        document.querySelectorAll('.option-button').forEach((button) => {
            button.classList.remove('active');
        });

        e.currentTarget.classList.add('active');
    };


    React.useEffect(() => {
        //Some Effect
    }, []);

    return (
        <>
            <HowToPage>
                <Row >
                    <Col className="buttonBox" md={{ span: 20, offset: 2 }} sm={{ span: 20, offset: 1 }} xs={{ span: 22, offset: 1 }}>
                        <Col md={{ span: 5, offset: 4 }} xs={{ span: 22, offset: 1 }}>
                            <OptionButton className="option-button active" onClick={onClickAccountType('without')}>Without Logging in</OptionButton>
                        </Col>
                        <Col md={{ span: 5, offset: 1 }} xs={{ span: 22, offset: 1 }}>
                            <OptionButton className="option-button" onClick={onClickAccountType('with')}>While Logged in</OptionButton>
                        </Col>
                        <Col md={{ span: 5, offset: 1 }} xs={{ span: 22, offset: 1 }}>
                            <OptionButton className="option-button" onClick={onClickAccountType('offline')}>Make a Pledge</OptionButton>
                        </Col>
                        <Divider />
                    </Col>
                </Row>
                <Row>
                    <Col className='detailsViewBox' md={{ span: 15, offset: 5 }} sm={{ span: 20, offset: 1 }} xs={{ span: 22, offset: 1 }}>

                        <ShowIfTrue condition={state.withoutAccount}>
                            <Col className='title-div' >
                                    <p className='title-font' >How to donate without an account</p>
                                <Divider />
                                <Row type = 'flex' justify= 'center'>
                                <Col className='content-div'>
                                    
                                            <p>1. Click <b><i>Donate</i></b> on a specific project.</p>
                                            <img src={withoutstep1} />
                                            <p>2. Click <b><i>View your Cart or My Cart</i></b> at the top of the page.</p>
                                            <img src={viewcartPNG} />
                                            <img src={viewcartheaderPNG} />
                                            <p>3. Click <b><i>Donate Without Login</i></b>.</p>
                                            <img src={withoutstep3} />
                                            <p>4. Add the <b><i>donation amount</i></b>.</p>
                                            <img src={donationamountPNG} />
                                                <p>5. Enter your <b><i>email address, first and last name, and phone number</i></b>.</p>
                                            <img src={withoutstep5} />
                                            <p>6. <b><i>Read Privacy Notice</i></b>.</p>
                                            <img src={withoutstep6} />
                                            <p>7. Click <b><i>Continue</i></b>.</p>
                                            <img src={continuePNG} />
                                            <p>8. Click <b><i>Donate Now!</i></b></p>
                                            <img src={donatenowPNG} />
                                </Col>
                                </Row>
                            </Col>
                        </ShowIfTrue>

                        <ShowIfTrue condition={state.withAccount}>
                            <Col className='title-div' md={{ span: 20, offset: 2 }} sm={{ span: 20, offset: 1 }} xs={{ span: 22, offset: 1 }}>
                                <p className='title-font' >How to donate with an account</p>
                                <Divider />
                                <Row type='flex' justify='center'>
                                    <Col className='content-div'>
                                        <p>1. <NavLink to={{ pathname: '/login' }}>Log into your account</NavLink></p>
                                        <img src={loginPNG} />
                                        <p>2. Click <b><i>Donate</i></b> on a specific project.</p>
                                        <img src={withoutstep1} />
                                        <p>3. Click <b><i>View your Cart or My Cart</i></b> at the top of the page.</p>
                                        <img src={viewcartPNG} />
                                        <img src={viewcartheaderPNG} />
                                        <p>4. Add the <b><i>donation amount</i></b>.</p>
                                        <img src={donationamountPNG} />
                                        <p>5. Click <b><i>Continue</i></b>.</p>
                                        <img src={continuePNG} />
                                        <p>6. Click <b><i>Donate Now!</i></b></p>
                                        <img src={donatenowPNG} />
                                    </Col>
                                </Row>
                            </Col>
                        </ShowIfTrue>

                        <ShowIfTrue condition={state.offlineDonate}>
                            <Col className='title-div' md={{ span: 20, offset: 2 }} sm={{ span: 20, offset: 1 }} xs={{ span: 22, offset: 1 }}>
                                <p className='title-font' >How to make a pledge</p>
                                <Divider />
                                <Row type='flex' justify='center'>
                                    <Col className='content-div'>
                                        <p>1. Click on the <b><i>Projects</i></b> tab.</p>
                                        <img src={projectsnavPNG} />
                                        <p>2. Click <b><i>Make a pledge</i></b> on a specific project.</p>
                                        <img src={makeapledgePNG} />
                                        <p>3. <b><i>Complete</i></b> the Pledge form.</p>
                                        <img src={pledgeformPNG} />
                                        <p>4. <b><i>Submit</i></b> form.</p>
                                        <img src={submitformPNG} />
                                        <p>5. <b><i>Use</i></b> banking details  <a target='_blank' href={bankingDetails}>provided here</a> to complete offline donation.</p>
                                    </Col>
                                </Row>
                            </Col>
                        </ShowIfTrue>
                    </Col>
                </Row>
            </HowToPage>
        </>
    );
};


const HowToDonateWithRouter = withRouter(HowToDonateWithoutRouter);

export default HowToDonateWithRouter;






