import * as React from 'react';
import { Col, Row } from 'antd';
import { RouteProps, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import ResetPasswordForm from 'components/UserManagement/ResetPasswordForm';
import EditUserProfileForm from 'components/UserManagement/EditUserProfileForm';
import NotificationService from 'Core/NotificationService';
import ConfirmResetPasswordForm from 'components/UserManagement/ConfirmResetPasswordForm';

export interface Props extends RouteProps {
    children?: React.ReactNode;
}

export interface State { }

const TallCol = styled(Col)`
  height: 100%;
  min-height: 200px;
`;

export default class EditUserProfilePage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <EditUserProfileForm />
        );
    }
}










