import * as React from 'react';
import { CenterAlignedRow } from 'components/Styled/Layout/CenterAlignedRow';
import { Col, Form, Input, Row, Button } from 'antd';
import FormControl from 'components/Forms/FormControl';
import { FormComponentProps } from 'antd/lib/form';
import { withRouter, RouteComponentProps, NavLink } from 'react-router-dom';
import ValidationUtility from 'Core/ValidationUtility';
import PasswordResetHandler from '../RequestPasswordResetForm/PasswordResetHandler';
import { IResetPasswordCommandRequest, IChangePasswordCommandRequest } from 'Core/Api/Api';
import FormItem from 'antd/lib/form/FormItem';
import NotificationService from 'Core/NotificationService';
import ConfigService from 'Core/ConfigService';
import Title from 'antd/lib/typography/Title';
import { useContext } from 'react';
import AuthContext from 'Context/AuthContext';

export interface Props extends FormComponentProps, RouteComponentProps {
    children?: React.ReactNode;
}

export const ChangePasswordFormWithoutFormsAndRouter = ({ history, form }: Props) => {
    const handler: PasswordResetHandler = new PasswordResetHandler({});
    const userContext = useContext(AuthContext);

    const onSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        form.validateFields((error, values: IChangePasswordCommandRequest) => {
            if (error) {
                return;
            }
            handler.ChangePassword(values).then(() => {
                history.goBack();
                NotificationService.success('Password has been updated');
            });
        });
    };


    var { getFieldDecorator } = form;
    var passwordValidations = ValidationUtility.password;
    var compareValidations = ValidationUtility.compare;
    return (
        <Row>
            <Col offset={3} span={6}>
                <Form onSubmit={onSubmit}>
                    <Title>Change Password </Title>
                    <FormControl
                        getFieldDecorator={getFieldDecorator}
                        rules={[...ValidationUtility.required("Current Password is required")]}
                        label="Current Password"
                        name="oldPassword">
                        <Input type="password" />
                    </FormControl>
                    <FormControl
                        getFieldDecorator={getFieldDecorator}
                        label="New Password"
                        name="newPassword"
                        rules={[...ValidationUtility.required("New Password is required"), ...passwordValidations()]}>
                        <Input type="password" />
                    </FormControl>
                    <FormControl
                        getFieldDecorator={getFieldDecorator}
                        rules={[...ValidationUtility.required("Confirm Password is required"), ...compareValidations(form, 'newPassword', 'Passwords do not match')]}
                        label="Confirm Password"
                        name="confirmPassword">
                        <Input type="password" />
                    </FormControl>
                    <FormItem>
                        <Row>
                            <Col span={5}>
                                <Button onClick={x => history.goBack()} type="default">
                                    Back
                                </Button>
                            </Col>
                            <Col span={5}>
                                <Button type={"primary"} htmlType="submit">Update Password</Button>
                            </Col>
                        </Row>
                    </FormItem>
                </Form>
            </Col>
        </Row>
    );
}

const ChangePasswordFormWithoutRouter = Form.create<Props>({
    name: 'ChangePasswordForm',
})(ChangePasswordFormWithoutFormsAndRouter);

const ChangePasswordForm = withRouter(ChangePasswordFormWithoutRouter);

export default ChangePasswordForm;










