import { Form, Icon, Input, Button, Checkbox, Row, Col, DatePicker, Divider, Radio, Collapse, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React, { useState } from 'react';
import { Redirect, RouteProps, NavLink } from 'react-router-dom';
import AuthContext from '../../../Context/AuthContext';
import FormControl from '../../Forms/FormControl';
import { StyleRegisterForm } from './RegisterForm.Style';
import { RegisterFormHandler } from './RegisterFormHandler';
import Title from 'antd/lib/typography/Title';
import Paragraph from 'antd/lib/typography/Paragraph';
import { singleColFormColSize } from 'components/Forms/Form.Styled';
import ValidationUtility from 'Core/ValidationUtility';
import MaskedInput from 'components/Forms/MaskedInput';
import moment from 'moment';
import { RightAlignedRow } from 'components/Styled/Layout/RightAlignedRow';
import NotificationService from 'Core/NotificationService';
import ISelectOption from '../../Forms/SimpleSelect/ISelectOption';
import SimpleSelect from '../../Forms/SimpleSelect';
import styles from './RegistrationStyles.module.css';
import { MainLogo } from '../../Layout/RegisterLayout/RegisterLayout.Style';
import { CenterAlignedRow } from '../../Styled/Layout/CenterAlignedRow';
import { Base64FileUploader } from '../../Forms/Base64FileUploader';
import { IFile } from '../../Forms/Base64FileUploader/Base64FileUploader';
import { AddressForm } from './AddressForm';
import { InvestorType, IRegisterInvestorOrEntrepreneurWebModel, UserAccountType } from 'Core/Api/Api';
import ShowIfTrue from 'components/Forms/ShowIfTrue';
import investorImg from '../../../assets/images/investor.png';
import EntrepreneurImg from '../../../assets/images/entrepreneur.png';
import termsPdf from '../../../assets/pdfs/terms.pdf';
import privacyPdf from '../../../assets/pdfs/privacy.pdf';
import riskPdf from '../../../assets/pdfs/risk.pdf';
const { Option } = Select;
const { Panel } = Collapse;

export interface Props extends FormComponentProps<IRegisterInvestorOrEntrepreneurWebModel>, RouteProps {
    children?: React.ReactNode;
}

export interface State {
    registered: boolean;
    isCompany: boolean;
    isIndividual: boolean;
    isEntrepreneur: boolean;
    isInvestor: boolean;
    questions: ISelectOption[];
    file?: IFile;
    dobMode: "decade" | "time" | "year" | "month" | "date" | undefined;


}

class BaseRegisterForm extends React.Component<Props, State> {
    static contextType = AuthContext;
    context!: React.ContextType<typeof AuthContext>;

    registerFormHandler: RegisterFormHandler;

    constructor(props: Props) {
        super(props);
        this.registerFormHandler = new RegisterFormHandler(props);
        this.state = {
            registered: false,
            isCompany: false,
            isIndividual: false,
            isEntrepreneur: true,
            isInvestor: false,
            questions: [],
            file: undefined,
            dobMode: 'decade'
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.setSecurityQuestions();
    }

    setSecurityQuestions() {
        var questions = [
            { text: 'What is the name of your childhood best friend?' },
            { text: 'What is the name of your manager at your first job?' },
            { text: 'Who is your favourite author?' },
            { text: 'What was the name of your first pet?' },
            { text: 'Where did you meet your spouse?' },
            { text: 'What is the name of the hospital in which you were born?' },
            { text: "What is your mother's middle name?" },
            { text: 'What was your favourite restaurant in college?' },
            { text: 'What was your favourite food as a child?' },
            { text: 'What is the name of your favourite book?' },
            { text: 'What is the first name of your favourite aunt?' },
            { text: 'What was your dream job as a child?' },
            { text: 'What was the name of your first school?' },
            { text: 'What is the first name of your oldest niece?' },
            { text: 'Where did you spend your honeymoon?' }
        ];
        this.setState({ questions: questions });
    }

    onAccountTypeChange = e => {
        if (e.target.value == UserAccountType.SocialInvestor) {
            this.setState({ isInvestor: true });
            this.props.form.setFieldsValue({ investorType: InvestorType.Individual });

        } else {
            this.setState({ isInvestor: false, isCompany: true, isIndividual: false });
            this.props.form.setFieldsValue({ clientType: UserAccountType.Entrepreneur });
        }
        this.setState({
            isEntrepreneur: e.target.value == UserAccountType.Entrepreneur
        });
    };

    onChangeInvestor = e => {
        if (e.target.value == 1) {
        } else {
        }
        this.setState({
            isCompany: (e.target.value == 1 ? false : true),
            isIndividual: (e.target.value == 2 ? false : true),
        });
    };

    handleSubmit(e: React.FormEvent) {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {

            if (!err) {
                //console.log(values);

                values.dateOfBirth = moment(values.dateOfBirth).format('YYYY-MM-DD');
                this.registerFormHandler.RequestRegister(values).then(response => {
                    NotificationService.success(
                        'Successfully Registered',
                        <>
                            <p>
                                You have successfully registered your account. Please check your email to activate your account.
                            </p>
                        </>, undefined, true
                    );
                    // this.context.Update(user);
                    this.setState({ registered: true });


                });
            }
        });
    }

    onPanelChange = (v, m) => {

        if (m != null) {
            this.setState({
                dobMode: m
            });
        } else {
            var newMode;
            switch (this.state.dobMode) {
                case 'date': newMode = 'date';
                    break;
                case 'decade': newMode = 'year';
                    break;
                case 'month': newMode = 'date';
                    break;
                case 'year': newMode = 'month';
                    break;
                default: newMode = null;
                    break;

            }

            this.setState({
                dobMode: newMode
            });
        }
    }

    onPanelOpen = (v) => {

        this.setState({
            dobMode: 'decade'
        });

    }

    handleFileChange = (e) => {
        this.setState({
            file: e
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        let state: { from?: any; noPermission?: boolean } = {};

        if (this.props.location) {
            state = this.props.location.state || {};
        }

        if (this.state.registered) {
            return <Redirect to="/login" />;
        }

        const isCompany = this.props.form.getFieldValue("accountType") == UserAccountType.Entrepreneur ||
            this.props.form.getFieldValue("accountType") == UserAccountType.SocialInvestor &&
            this.props.form.getFieldValue("investorType") == InvestorType.Company;

        const addressHeaderName = `${isCompany ? "Company" : "Personal"} Address`;
        return (

            <StyleRegisterForm labelAlign={'left'} labelCol={{
                xs: { span: 24 }, sm: { span: 24 }
            }}
                wrapperCol={{
                    xs: { span: 24 }, sm: { span: 24 }
                }}

                autoComplete="off" layout="horizontal" onSubmit={this.handleSubmit}>
                {//<NavLink to="/welcome" ><MainLogo /></NavLink>
                }

                <Row type={'flex'} align={'middle'} style={{ textAlign: 'center' }}>
                    <Col xs={24} sm={24}>
                        <Title style={{ color: '#005B8A', fontWeight: 'bold' }} level={2}>Create a new JSSE account</Title>
                    </Col>
                    <Col xs={24} sm={24}>
                        <Col>
                            <Paragraph>Already have an account? <NavLink to={{ pathname: 'login' }} style={{ color: '#00AED8', fontWeight: 'normal' }}>Login to your account here</NavLink> </Paragraph>
                        </Col>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 24 }}>
                        <Row>
                            <Col xs={{ span: 23, offset: 0 }} sm={{ span: 23, offset: 0 }}>
                                <CenterAlignedRow >
                                    <div className={styles.separator}>Account Type</div>
                                    <FormControl
                                        getFieldDecorator={getFieldDecorator}
                                        help={"Please select an account type"}

                                        rules={[...ValidationUtility.required('Please select an account type!')]}
                                        name="accountType" //getFieldDecorator(name: { hidden: true })
                                    >
                                        <Radio.Group style={{ height: "fit-content" }} size="large" onChange={this.onAccountTypeChange}>
                                            <Radio.Button className='radioChecked' style={{ height: "fit-content", justifyContent: "center", paddingTop:10}} value={UserAccountType.Entrepreneur}>
                                                <div  style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "5px", width:"290px"}} >
                                                    <img src={EntrepreneurImg} width={64} height={64} />
                                                    <h2> Entrepreneur</h2>
                                                    <p>You wish to get help for your project</p>
                                                </div>
                                            </Radio.Button>
                                            <Radio.Button className='radioChecked' style={{ height: "fit-content", justifyContent: "center", paddingTop:10 }} value={UserAccountType.SocialInvestor}>
                                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "5px", width:"290px" }}>
                                                    <img src={investorImg} width={64} height={64} />
                                                    <h2> Social Investor</h2>
                                                    <p>You wish to support a project</p>
                                                </div>
                                            </Radio.Button>
                                        </Radio.Group>
                                    </FormControl>

                                </CenterAlignedRow>

                            </Col>
                        </Row>
                        <ShowIfTrue condition={!!this.props.form.getFieldValue("accountType")}>
                            <Row>
                                <ShowIfTrue condition={this.props.form.getFieldValue("accountType") == UserAccountType.SocialInvestor}>
                                    <div className={styles.separator}>Investor Type</div>
                                    <FormControl
                                        getFieldDecorator={getFieldDecorator}
                                        initialValue={InvestorType.Individual}
                                        rules={[...ValidationUtility.required('Please select a investor type!')]}
                                        name="investorType" //getFieldDecorator(name: { hidden: true })
                                    >
                                        <Radio.Group onChange={this.onChangeInvestor}>
                                            <Radio value={InvestorType.Individual}>Individual</Radio>
                                            <Radio value={InvestorType.Company}>Company</Radio>
                                        </Radio.Group>
                                    </FormControl>
                                </ShowIfTrue>
                            </Row>

                            <ShowIfTrue condition={!isCompany}>
                                <div className={styles.separator}>Personal Details</div>
                                <Row>
                                    <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11, offset: 1 }}>

                                        <FormControl
                                            getFieldDecorator={getFieldDecorator}
                                            rules={[...ValidationUtility.If(!this.state.isCompany, ValidationUtility.required('First name is required'))]}
                                            name="firstName" //getFieldDecorator(name: { hidden: true })
                                            label="First Name"
                                        >
                                            <Input />
                                        </FormControl>
                                    </Col>
                                    <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11 }}>
                                        <FormControl
                                            getFieldDecorator={getFieldDecorator}
                                            rules={[...ValidationUtility.If(!this.state.isCompany, ValidationUtility.required('Last name is required'))]}
                                            name="lastName"
                                            label="Last Name"
                                        >
                                            <Input />
                                        </FormControl>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11, offset: 1 }}>

                                        <FormControl
                                            getFieldDecorator={getFieldDecorator}
                                            rules={[...ValidationUtility.If(!this.state.isCompany, ValidationUtility.required('Telephone is required'))]}
                                            name="telephone"
                                            label="Telephone"
                                        >
                                            <Input />
                                        </FormControl>
                                    </Col>
                                    <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11 }}>
                                        <FormControl
                                            getFieldDecorator={getFieldDecorator}
                                            rules={[...ValidationUtility.If(!this.state.isCompany, ValidationUtility.required('Date of Birth is required'))]}
                                            name="dateOfBirth"
                                            label="Date of Birth"
                                        >

                                            <DatePicker mode={this.state.dobMode} onPanelChange={this.onPanelChange} onOpenChange={this.onPanelOpen} defaultPickerValue={moment("1999-01-01")} disabledDate={d => !d || d.isSameOrAfter(moment())} style={{ width: '100%' }}></DatePicker>
                                        </FormControl>
                                    </Col>

                                </Row>

                                <div className={styles.separator}>Identification</div>

                                <Row>
                                    <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11, offset: 1 }}>
                                        <FormControl
                                            getFieldDecorator={getFieldDecorator}
                                            rules={[...ValidationUtility.If(!this.state.isCompany, ValidationUtility.required('Id Type is required'))]}
                                            name="idType"
                                            label="ID Type"
                                        >
                                            <Select allowClear={true}
                                                placeholder={'Please select an option'}
                                                optionFilterProp={'label'} >
                                                <Option key={'Passport'} label={'Passport'} value={2}>
                                                    Passport
                                                </Option>
                                                <Option key={"Driver's License"} label={"Driver's License"} value={1}>
                                                    Driver's License
                                                </Option>
                                                <Option key={'National ID'} label={'National ID'} value={3}>
                                                    National ID
                                                </Option>
                                            </Select>
                                        </FormControl>
                                    </Col>
                                    <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11 }}>

                                        <FormControl
                                            getFieldDecorator={getFieldDecorator}
                                            rules={[...ValidationUtility.If(!this.state.isCompany, ValidationUtility.required('Id Number is required'))]}
                                            name="idNumber"
                                            label="ID Number"
                                        >
                                            <Input />
                                        </FormControl>
                                    </Col>
                                    <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11, offset: 1 }}>

                                        <FormControl
                                            getFieldDecorator={getFieldDecorator}
                                            rules={[...ValidationUtility.required('Tax Registration Number is required')]}
                                            name="trn"
                                            label="Tax Identification Number (TID)"
                                        >
                                            <Input placeholder="eg. TRN, SSN, etc..." />
                                        </FormControl>

                                    </Col>
                                </Row>

                                <div className={styles.separator}>{addressHeaderName}</div>
                                <AddressForm getFieldDecorator={getFieldDecorator} />

                            </ShowIfTrue>
                            <ShowIfTrue condition={isCompany}>
                                <div className={styles.separator}>Company Details</div>
                                <Row>
                                    <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11, offset: 1 }}>

                                        <FormControl
                                            getFieldDecorator={getFieldDecorator}
                                            rules={[...ValidationUtility.If(this.state.isCompany, ValidationUtility.required('Business name is required'))]}
                                            name="businessName"
                                            label="Company Name"
                                        >
                                            <Input />
                                        </FormControl>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11, offset: 1 }}>

                                        <FormControl
                                            getFieldDecorator={getFieldDecorator}
                                            rules={[...ValidationUtility.If(this.state.isCompany, ValidationUtility.required('TRN is required'))]}
                                            name="trn"
                                            label="Company Tax Registration Number"
                                        >
                                            <Input />
                                        </FormControl>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11, offset: 1 }}>

                                        <FormControl
                                            getFieldDecorator={getFieldDecorator}

                                            name="companyPhoneNumber"
                                            label="Company Telephone"
                                        >
                                            <Input />
                                        </FormControl>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11 }}>
                                        <FormControl
                                            getFieldDecorator={getFieldDecorator}
                                            rules={[...ValidationUtility.If(this.state.isCompany, ValidationUtility.required('Proof of Incorporation is required'))]}
                                            name="proofOfIncorporationFileBase64"
                                            label="Proof of Incorporation"
                                        >
                                            <Base64FileUploader />
                                        </FormControl>
                                    </Col>
                                    <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11 }}>
                                        <FormControl
                                            getFieldDecorator={getFieldDecorator}
                                            rules={[...ValidationUtility.If(this.state.isCompany, ValidationUtility.required('Proof of Tax Compliance is required'))]}
                                            name="proofOfTaxComplianceFileBase64"
                                            label="Proof of Tax Compliance"
                                        >
                                            <Base64FileUploader />
                                        </FormControl>
                                    </Col>
                                </Row>
                                <div className={styles.separator}>Identification</div>
                                <Row>
                                    <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11 }}>
                                        <FormControl
                                            getFieldDecorator={getFieldDecorator}
                                            rules={[...ValidationUtility.If(this.state.isCompany, ValidationUtility.required('Contact First Name is required'))]}
                                            name="contactFirstName"
                                            label="Contact First Name"
                                        >
                                            <Input />
                                        </FormControl>
                                    </Col>
                                    <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11, offset: 1 }}>
                                        <FormControl
                                            getFieldDecorator={getFieldDecorator}
                                            rules={[...ValidationUtility.If(this.state.isCompany, ValidationUtility.required('Contact Last Name is required'))]}
                                            name="contactLastName"
                                            label="Contact Last Name"
                                        >
                                            <Input />
                                        </FormControl>
                                    </Col>
                                    <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11 }}>
                                        <FormControl
                                            getFieldDecorator={getFieldDecorator}
                                            rules={[...ValidationUtility.If(this.state.isCompany, ValidationUtility.required('Telephone is required'))]}
                                            name="contactTelephone"
                                            label="Contact Telephone"
                                        >
                                            <Input />
                                        </FormControl>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11, offset: 1 }}>
                                        <FormControl
                                            getFieldDecorator={getFieldDecorator}
                                            rules={[...ValidationUtility.required('Id Type is required')]}
                                            name="idType"
                                            label="Contact ID Type"
                                        >
                                            <Select allowClear={true}
                                                placeholder={'Please select an option'}
                                                optionFilterProp={'label'} >
                                                <Option key={'Passport'} label={'Passport'} value={2}>
                                                    Passport
                                                </Option>
                                                <Option key={"Driver's License"} label={"Driver's License"} value={1}>
                                                    Driver's License
                                                </Option>
                                                <Option key={'National ID'} label={'National ID'} value={3}>
                                                    National ID
                                                </Option>
                                            </Select>
                                        </FormControl>
                                    </Col>
                                    <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11 }}>

                                        <FormControl
                                            getFieldDecorator={getFieldDecorator}
                                            rules={[...ValidationUtility.required('Id Number is required')]}
                                            name="idNumber"
                                            label="Contact ID Number"
                                        >
                                            <Input />
                                        </FormControl>
                                    </Col>
                                </Row>
                                <ShowIfTrue condition={!isCompany}>
                                    <Row>
                                        <Col xs={{ span: 11, offset: 1 }}>

                                            <FormControl
                                                getFieldDecorator={getFieldDecorator}
                                                rules={[...ValidationUtility.required('Tax Registration Number is required')]}
                                                name="trn"
                                                label="Tax Identification Number (TID)"
                                            >
                                                <Input placeholder="eg. TRN, SSN, etc..." />
                                            </FormControl>

                                        </Col>
                                    </Row>
                                </ShowIfTrue>
                                <ShowIfTrue condition={isCompany}>
                                    <Row>
                                        <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11, offset: 1 }}>

                                            <FormControl
                                                getFieldDecorator={getFieldDecorator}
                                                rules={[...ValidationUtility.If(this.state.isCompany, ValidationUtility.required('TRN is required'))]}
                                                name="contactTrn"
                                                label="Contact Tax Registration Number"
                                            >
                                                <Input />
                                            </FormControl>

                                        </Col>
                                    </Row>
                                </ShowIfTrue>

                                <div className={styles.separator}>{addressHeaderName}</div>
                                <AddressForm getFieldDecorator={getFieldDecorator} />
                            </ShowIfTrue>

                            <div className={styles.separator}>Account Setup</div>
                            <Row>
                                <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11, offset: 1 }}>

                                    <FormControl
                                        getFieldDecorator={getFieldDecorator}
                                        rules={[...ValidationUtility.required('Email is required')]}
                                        name="emailAddress"
                                        label="Email"
                                    >
                                        <Input />
                                    </FormControl>
                                </Col>
                                <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11 }}>

                                    <FormControl
                                        getFieldDecorator={getFieldDecorator}
                                        rules={[...ValidationUtility.required('Confirm email is required')]}
                                        name="confirmEmailAddress"
                                        label="Confirm Email"
                                    >
                                        <Input />
                                    </FormControl>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11, offset: 1 }}>

                                    <FormControl
                                        getFieldDecorator={getFieldDecorator}
                                        rules={[...ValidationUtility.required('Password is required')]}
                                        name="password"
                                        label="Password"
                                    >
                                        <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            type="password" />
                                    </FormControl>
                                </Col>
                                <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11 }}>
                                    <FormControl
                                        getFieldDecorator={getFieldDecorator}
                                        rules={[...ValidationUtility.required('Confirm Password is required')]}
                                        name="confirmPassword"
                                        label="Confirm Password"
                                    >
                                        <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            type="password" />
                                    </FormControl>
                                </Col>
                            </Row>
                        </ShowIfTrue>
                    </Col>

                </Row>

                <Row>
                    <Col span={24}>
                        <FormControl style={{width: '100%', display:'flex', justifyContent:'center'}}
                            wrapperCol={{ xs: { offset: 1, span: 23 }, sm: { offset: 1, span: 20 } }}
                            getFieldDecorator={getFieldDecorator}
                            rules={[
                                {
                                    required: true,
                                    transform: value => value || undefined,
                                    type: 'boolean',
                                    message: 'You must agree to the terms of use and the privacy policy.',
                                },
                            ]}
                            label=""
                            name="agreement" //getFieldDecorator(name: { hidden: true })
                        >
                            <p className="text-content-justified">
                                <Checkbox >
                                    I agree to the <a href={termsPdf} download >Terms of Use <Icon type="download" /></a> and have read and understand the <a href={privacyPdf} download >Privacy Policy <Icon type="download" /></a>
                                </Checkbox>
                            </p>
                        </FormControl>


                    </Col>
                </Row>


                <Row justify='center' style={{display:'flex', justifyContent:'center', paddingBottom: 20}}>
                    {/* <Col span={15}></Col> */}
                    <Col xs={{ span: 8 }} sm={{ span: 6 }} md={{ span: 4 }} style={{display:'flex', justifyContent:'center'}}>
                        <Button className="register-form-button" size="large" type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Col>
                    <Col xs={{ span: 8}} sm={{ span: 6}} md={{ span: 4 }} style={{display:'flex', justifyContent:'center'}}>
                        <NavLink to="/">
                            <Button className="cancel-form-button" size="large" ghost >Cancel</Button>
                        </NavLink>
                    </Col>
                </Row>
            </StyleRegisterForm >

        );
    }

    private ShouldRedirectTo(state: { from?: any; noPermission?: boolean | undefined }): boolean {
        return !!state.from && state.from.pathname !== '/logout';
    }
}

const RegisterForm = Form.create<Props>({
    name: 'Register',
})(BaseRegisterForm);

export default RegisterForm;













