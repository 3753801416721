import React from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import styled from 'styled-components';
import ImageResize from 'quill-image-resize-module-react';
import { ImageDrop } from 'quill-image-drop-module';

const QuillContainer = styled.div<Props>`
  .ql-editor {
    background-color: white;
    height: calc(${(props) => (props.rows ?? 4) * 15}px) ;
  }
`
interface Props {
    value?: any;
    rows?: number;
    onChange?: (value: any, editor: ReactQuill.UnprivilegedEditor) => void;
}

export default ({ value, onChange, rows }: Props) => {
    Quill.register('modules/imageResize', ImageResize);
    Quill.register('modules/imageDrop', ImageDrop);

    const modules = {
        toolbar: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline'],
            ['image', 'code-block']
        ],
        imageResize: {
            parchment: Quill.import('parchment'),
            modules: ['Resize', 'DisplaySize'],
        },
        imageDrop: false,
    };
    const handleOnChange = (value: string, delta: any, source: any, editor: ReactQuill.UnprivilegedEditor) => {
        onChange?.(value, editor);
    }
    return <QuillContainer rows={rows}><ReactQuill theme="snow" modules={modules} value={value} onChange={handleOnChange} /></QuillContainer>;
}