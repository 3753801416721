import { SecuredClient } from 'Core/Api/SecuredClient';
import {

    GetAllNewsByFilterQueryRequest,
    GetAllUsersByFilterModel,

    GetNewsByIdQueryRequest,

    IGetNewsByIdQueryRequest,

    NewsListModel,
    OrderBy, SlimUser, User,
} from 'Core/Api/Api';
import Utility from 'Core/Utility';
import ITableDataFetcher from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataFetcher';
import TableRequest from 'Core/TableUtility/Models/TableRequest';
import ITableDataResponse from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataResponse';

export class NewsHandler implements ITableDataFetcher<NewsListModel> {

    props: any;
    constructor(props) {
        this.props = props;
    }

    GetData(tableRequest: TableRequest): Promise<ITableDataResponse<NewsListModel>> {
        return this.GetNews(tableRequest);
    }


    GetNews(tableRequest: TableRequest) {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;

        const request = new GetAllNewsByFilterQueryRequest(tableRequest);

        if (request.orderBy == undefined) {
            request.orderBy = [];
            request.orderBy.push(new OrderBy({ field: "publishDate", ascending: false }));
        }

        return client.getAllNewsByFilter(request);
    }



    GetNewsById(request: IGetNewsByIdQueryRequest) {
        const client = new SecuredClient();
        client.showLoading = true;
        client.handleGeneralError = true;

        return client.getNewsById(new GetNewsByIdQueryRequest(request));
    }

}










