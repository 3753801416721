import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { message } from 'antd';
import { withRouter, StaticRouter } from 'react-router-dom';
import NotificationService from '../NotificationService';

import React from 'react';
import { generateShowHourMinuteSecond } from 'antd/lib/time-picker';

export interface ErrorDetails {
  statusCode?: number;
  message?: string;
  eventMarker?: string;
}

export interface ExtendedAxios extends AxiosInstance {
  handleSecurityError?: boolean;
  showLoading?: boolean;
  handleGeneralError?: boolean;
}

const getSecuredAxiosInstance: () => ExtendedAxios = () => {
  const SecuredAxios: ExtendedAxios = Object.assign(
    { handleSecurityError: true },
    axios.create({
      timeout: 30000000,
    })
  );

  SecuredAxios.interceptors.request.use(
    config => {
      // turning on loader

      ShowLoader(config);

      // Add header
      //config.headers.authorization = 'Bearer ' + sessionStorage.getItem('token');

      return config;
    },
    error => {
      // Do something with error
      return Promise.reject(error);
    }
  );

  SecuredAxios.interceptors.response.use(
    response => {
      if (SecuredAxios.showLoading) {
        NotificationService.hideLoader();
      }
      return response;
    },
    (error: { config: AxiosRequestConfig; response: AxiosResponse }) => {
      HideAllLoaders();
      HandleSecurityErrors(error);
      HandleErrors(error);
      return Promise.reject(error);
    }
  );

  function HideAllLoaders() {
    if (SecuredAxios.showLoading) {
      NotificationService.hideLoader();
    }
  }

  function HandleSecurityErrors(error: {
    config: AxiosRequestConfig;
    response: AxiosResponse<any>;
  }) {
    if (SecuredAxios.handleSecurityError) {
      const errorDetails = GetErrorDetails(error);
      if (errorDetails.statusCode === 403) {
        NotificationService.error(
          'You do not have permission to access that operation. You can try login with credentials that do.',
          undefined,
          100
        );
      }
      if (errorDetails.statusCode === 401) {
        NotificationService.error(
          'You were inactive for a long time and need to login again. Please login to continue',
          undefined,
          100
        );
      }
    }
  }

  function HandleErrors(error: { config: AxiosRequestConfig; response: AxiosResponse<any> }) {
    if (SecuredAxios.handleGeneralError) {
      const errorDetails = GetErrorDetails(error);
      if (errorDetails.statusCode === 400) {
        NotificationService.error(errorDetails.message, undefined, 10);
      } else if (errorDetails.statusCode == 800) {
        NotificationService.error(
          'Had a problem making that last request, please ensure you are connected to the internet.',
          undefined,
          10
        );
      } else if (errorDetails.statusCode === 403 || errorDetails.statusCode == 401) {
        // It is a security error and will be handled somewhere else
      } else {
        NotificationService.error(
          'Oops...Something Went Wrong',
          <>
            <p>
              Something happend on that last request. Please contact administrator with this error
              code:
            </p>
            <p>{errorDetails.eventMarker}</p>
          </>,
          0,
          true
        );
      }
    }
  }

  function GetErrorDetails(error: any) {
    return (error.response || { data: { statusCode: 800, eventMarker: '1111-1111-1111-1111' } })
      .data as ErrorDetails; // here we handle the senario if the issue is client side and we don't get back the response object
  }

  function ShowLoader(config: any) {
    if (SecuredAxios.showLoading) {
      NotificationService.showLoader();
    }
  }

  return SecuredAxios;
};

export default getSecuredAxiosInstance;













