import * as React from 'react';
import { Button } from 'antd';
import { ButtonType } from 'antd/lib/button';
import { CSSProperties } from 'react';

export interface Props {
  text?: string;
  maxLength: number;
  canReveal?: boolean;
  revealButtonType?: ButtonType
  style?: CSSProperties;
}

export interface State {
  displayText?: string;
  reveled: boolean;
  isPassMaxLength?: boolean;
}

export default class TextClip extends React.Component<Props, State> {
  static defaultProps: Props = {
    canReveal: true,
    maxLength: 100,
    revealButtonType: 'ghost',
  };

  constructor(props: Props) {
    super(props);

    this.state = { reveled: false };
  }

  componentDidMount() {
    let { text, maxLength } = this.props;
    if (text && text?.length > maxLength) {
      this.setState({ displayText: text.substr(0, maxLength - 3), isPassMaxLength: true });
    } else {
      this.setState({ displayText: text });
    }
  }

  revelClick = (event: React.SyntheticEvent) => {
    event.persist();
    event.stopPropagation();
    if (this.props.canReveal) {
      if (this.state.reveled) {
        this.setState({
          displayText: this.props.text?.substr(0, this.props.maxLength - 3),
          reveled: !this.state.reveled,
        });
      } else {
        this.setState({ displayText: this.props.text, reveled: !this.state.reveled });
      }
    }
  };

  render() {
    return (
      <>
        {this.state.displayText ?? ""}
        {this.state.isPassMaxLength && (
          this.props.canReveal ? <Button type={this.props.revealButtonType} style={{ margin: "0px", height: "1em", padding: "0px", ...this.props.style }} onClick={this.revelClick}>
            ...
          </Button>
            : <span>...</span>
        )}
      </>
    );
  }
}
