import { Layout } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

const { Header, Content, Footer } = Layout;

export const Page = styled.div`
    margin-top: -3%;

    .ant-form-item{
        margin-bottom: 0px;
        line-height: 0;
    }

    .ant-form-item-label{
        line-height:0;
    }

    .about{
        background-color: #fbfbfb;
        margin-bottom: 43px;
        padding-bottom: 23px;
        border-radius: 40px;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);


        .whoAvatar{
            margin-top: 4px;
        }
        .green{
            color: #7bc257;
        }
        .goal{
            display: inline-flex;
            height: 2px;
        }
        .whoDetails{
            padding-top: 3%;
        }
    }

    @media only screen and (max-width: 575px){
        .FAQ{
            margin-top: 10%;
        }
        .goal{
            font-size: 14px;
        }
    }
    .ant-collapse-item-active{
        .ant-collapse-header{
            background-color: #00AED8!important;
            color: #fefefe;
        } 
    }
    

`;