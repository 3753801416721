import * as React from 'react';
import { Table, List } from 'antd';
import {
  TableProps,
  PaginationConfig,
  SorterResult,
  TableCurrentDataSource,
  ColumnProps,
} from 'antd/lib/table';
import equals from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import TableRequest from 'Core/TableUtility/Models/TableRequest';
import { TableFilterContext } from '../TableFilterContextProvider';
import TableUtility from 'Core/TableUtility';
import Utility from 'Core/Utility';
import ITableDataFetcher from './Models/ITableDataFetcher';
import debouce from 'lodash/debounce';
import { ListProps } from 'antd/lib/list';
import ITableDataResponse from './Models/ITableDataResponse';
import styled, { css } from 'styled-components';

export type TableRequestMethod<Model> = (tableRequest: TableRequest) => Promise<ITableDataResponse<Model>>
export interface Props<Model> extends ListProps<Model> {
  children?: React.ReactNode;
  tableKey?: string;
  dataFetcher: ITableDataFetcher<Model> | TableRequestMethod<Model>;
  pagination?: PaginationConfig;
  renderItem: (item: Model, index: number) => React.ReactNode;
  scrollToTop?: boolean;

}


const ListContainer = styled.div`
  .ant-list .ant-spin-container div.ant-row {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  } 
`

export interface State<DataType> {
  data: DataType[];
  pagination: PaginationConfig;
  isLoading: boolean;
  // maintain a copy of table request
  // This will keep track of all filters, sorting and paging information
  // to be sent to the server
  tableRequest: TableRequest;
}
export default class SimpleViewList<Model> extends React.Component<Props<Model>, State<Model>> {
  static defaultProps: Props<any> = {
    tableKey: 'id',
    dataFetcher: (): any => { },
    scrollToTop: true,
    renderItem: (): any => { },
  };

  static contextType = TableFilterContext;
  context!: React.ContextType<typeof TableFilterContext>;

  constructor(props: Props<Model>) {
    super(props);
    this.TableChange = debouce(this.TableChange.bind(this), 10);
    const pagination: PaginationConfig = { ...props.pagination ?? {}, style: { marginBottom: "20px" }, onChange: this.ViewChanged, onShowSizeChange: this.ViewChanged };

    this.state = {
      data: [],
      pagination: pagination ?? {},
      isLoading: false,
      tableRequest: {},
    };
  }

  ViewChanged = (page: number, pageSize?: number) => {
    const NewTableRequest = { ...this.context.data, pageNumber: page, amountPerPage: pageSize ?? this.context.data?.amountPerPage ?? 20 };
    this.GetData(NewTableRequest);
  }

  /**
   * This method is used to handle filtering and paging for table
   *
   * @param {PaginationConfig} pagination
   * @param {Record<keyof Model, string[]>} filters
   * @param {SorterResult<Model>} sorter
   * @param {TableCurrentDataSource<Model>} extra
   * @memberof  SimpleViewList
   */
  TableChange(
    pagination: PaginationConfig,
    filters: Partial<Record<keyof Model, string[]>>,
    sorter: SorterResult<Model>,
    extra: TableCurrentDataSource<Model>
  ) {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager });

    const tableRequest = TableUtility.GetTableRequest<Model>(
      pagination,
      filters,
      sorter,
      this.context.data
    );
    this.GetData(tableRequest);
  }

  componentDidUpdate(prevProps: Props<Model>, pervState: State<Model>) {
    // When the state is update, use that to kickoff the request for data
    if (!equals(this.context.data, this.state.tableRequest) && !this.state.isLoading) {
      this.GetData(this.context.data || {});
    }
    // When the state is update, use that to kickoff the request for data
    if (!equals(pervState.tableRequest, this.state.tableRequest)) {
    }
  }

  componentDidMount() {
    this.GetData(this.context.data || {});
  }

  refresh() {
    this.GetData(this.context.data || {});
  }

  GetData(tableRequest: TableRequest | {}) {

    this.setState({ isLoading: true, tableRequest: cloneDeep(tableRequest) }, () => {
      // In callback for setState, ensuring that tableRequest is updated before proceeding
      let getDataMethod: TableRequestMethod<Model> = (this.props.dataFetcher as any).GetData ?? this.props.dataFetcher;
      getDataMethod.call(this.props.dataFetcher, tableRequest)
        .then(data => {
          const pager = { ...this.props.pagination, ...this.state.pagination };
          pager.total = data.count;
          pager.pageSize = data.amountPerPage;
          pager.current = data.pageNumber;

          this.context.Update(tableRequest);
          this.setState({
            isLoading: false,
            data: data.results || [],
            pagination: pager,
          });
        })
        .catch(reason => {
          this.setState({
            isLoading: false,
          });
        });
    });

    if (this.props.scrollToTop) {
      window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
    }

  }

  render() {
    const { pagination, dataFetcher, tableKey, ...propsWithoutPagination } = this.props;
    return (
      <ListContainer>
        <List<Model>
          rowKey={row => row[tableKey!] || Utility.RandomNumber(292838)}
          dataSource={this.state.data}
          pagination={this.state.pagination}
          loading={this.state.isLoading}
          //onChange={this.TableChange}
          {...propsWithoutPagination}
        />
      </ListContainer>
    );
  }
}
