import { Button, Col, Row } from 'antd';
import * as React from 'react';
import { RouteProps } from 'react-router-dom';
import styled from 'styled-components';
import BoardOfDirectors from 'components/BoardOfDirectors';
import { BannerDiv, CenteredFormContent } from 'components/Layout/LoginLayout/LoginLayout.Style';
import { Redirect } from 'react-router';

export interface Props extends RouteProps {
  children?: React.ReactNode;
}

export interface State {
  isConfirmed: boolean;
}

export default class NewsPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);


        this.state = { isConfirmed: false };
    }

    componentDidMount() {

    }

    render() {
      return <CenteredFormContent>
            <BannerDiv className={"board"}>
              <Row style={{ height: '100%' }}>
                  <Col sm={24} className={'bannerNav'}>
                  </Col>
              </Row>
          </BannerDiv>
          <Row type="flex" align="middle" justify="center">
              <Col >
                  <BoardOfDirectors {...this.props} />
              </Col>
          </Row>
        </CenteredFormContent>;

    }
}










