import { Button, Icon } from 'antd';
import React, { SyntheticEvent, useEffect, useState } from 'react';

import ConfigService from 'Core/ConfigService';
import DetailsData from 'Core/models/DetailsData';
import DetailsMode from 'Core/models/DetailsMode';
import { NewsListModel } from 'Core/Api/Api';
import { ReadMoreButton } from './ReadMoreButton';
import SharePopup from 'components/Styled/SocialMedia/SharePopup';
import TextClip from 'components/Forms/TextClip';
import { UniqueImage } from 'components/Forms/UniqueImage';
import Utility from 'Core/Utility';
import moment from 'moment';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';

interface Props {
  news?: NewsListModel;
  isFullGrid?: boolean;
  showDate?: boolean;
}

const ArticleContainer = styled.div<{ isFullGrid?: boolean }>`
  flex: 2 1 auto;
  width: ${props => (props.isFullGrid ? '100%' : 'min-content')};
  min-height: 320px;

  display: grid;
  padding: 20px;

  background-color: white;
  column-gap: 0px;
  grid-template-rows: auto min-content min-content min-content auto;
  grid-template-areas:
    'image title title    title    .'
    'image title title    title    .'
    'overview overview overview overview overview'
    'time     time  .     .     .'
    'more     more    .     .     .';
`;

const ArticleImage = styled.div`
  grid-area: image;
  width: 120px;
  img {
    width: 120px;
    height: 120px;
    aspect-ratio: 1/1;
  }
`;

const ArticleTitle = styled.div`
  grid-area: title;
  padding-left: 15px;
  width: 100%;
 
  font-size: 1.3em;
  font-weight: bold;
`;

const ArticleTime = styled.div`
  grid-area: time;
  font-size: 11px;
  column-gap: 0px;
  padding-bottom: 5px;
  color: #269b48;
`;
const ArticleOverview = styled.div`
  grid-area: overview;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
`;

const ArticleAction = styled.div`
  grid-area: more;

  a {
    width: 64px;
    height: 23px;
    background-color: #00aed8;
    font-size: 11px;
    padding: 8px 15px;
    border-radius: 4px;
    color: #ffff;
    margin-left: 5px;
    margin-top: 2px;
  }

  button {
    font-size: 12px;
    border-radius: 4px;
    background-color: #646464;
    &.ant-btn {
      color: white;
    }
  }
`;

export default ({ news, isFullGrid, showDate }: Props) => {
  const history = useHistory();
  const config = ConfigService.getSync();
  const [newsUrl, setNewsUrl] = useState<string | undefined>();
  const [newsPage, setNewsPage] = useState<string | undefined>();

  useEffect(() => {
    if (news) {
      setNewsUrl(getUrl(news));
      setNewsPage(getPage(news));
    }
  }, [news]);

  const getUrl = (news: NewsListModel | undefined) => {
    if (!news) {
      return;
    }
    const titleSlug = encodeURIComponent(news.title?.replace(' ', '-').substring(0, 100) ?? '');
    const idSlug = encodeURIComponent(news.id ?? '');
    const path = `news/${idSlug}/${titleSlug}`;
    const url = new URL(globalThis.location.origin);
    url.pathname = path;
    console.log(url);
    return url.toString();
  };

  const getPage = (news: NewsListModel | undefined) => {
    if (!news) {
      return;
    }
    const titleSlug = encodeURIComponent(news.title?.replace(' ', '-').substring(0, 100) ?? '');
    const idSlug = encodeURIComponent(news.id ?? '');
    const path = `news/${idSlug}/${titleSlug}`;
    const url = new URL(globalThis.location.origin);
    url.pathname = path;
    return url.pathname;
  };

  const goToArticle = (news: NewsListModel | undefined, e?: SyntheticEvent) => {
    if (!news) {
      return;
    }
    e?.preventDefault();

    if (news?.link) {
      globalThis.location.href = news.link;
    } else {
      const viewDetailsData = new DetailsData();
      viewDetailsData.mode = DetailsMode.New;
      const titleSlug = encodeURIComponent(news.title?.replace(' ', '-').substring(0, 100) ?? '');
      const idSlug = encodeURIComponent(news.id ?? '');
      const path = `news/${idSlug}/${titleSlug}`;
      history.push(path, viewDetailsData);
    }
  };

  return (
    <ArticleContainer isFullGrid={isFullGrid}>
      <Link to={newsPage ?? '#'} onClick={() => window.scrollTo(0, 0)}>
        <ArticleImage>
          {news?.mainImageLink ? (
            <img src={news?.mainImageLink} />
          ) : news?.newsDataFile?.url ? (
            <img src={news?.newsDataFile.url} />
          ) : (
            <UniqueImage size={200} id={news?.id!} />
          )}
        </ArticleImage>
      </Link>
      <ArticleTitle>{news?.title}</ArticleTitle>
      {showDate ? (
        <ArticleTime>
          {' '}
          {Utility.renderDateWithReference(moment(news?.publishDate), config.Display.DateFormat)}
        </ArticleTime>
      ) : (
        ''
      )}
      <ArticleOverview>
        <TextClip canReveal={false} maxLength={150} text={news?.overview}></TextClip>
      </ArticleOverview>
      <ArticleAction>
        {
          <SharePopup
            shareMessage={'News from the Jamaica Social Stock Exchange: ' + (news?.title ?? '')}
            popupMessage={'Share News'}
            url={newsUrl ?? '#'}
            title="Share">
            <Button>
              <Icon type="share-alt" /> Share
            </Button>
          </SharePopup> /**/
        }
        <a href={newsPage} style={{ whiteSpace: "nowrap" }} onClick={() => window.scrollTo(0, 0)}>Read More</a>
        {/*<ReadMoreButton news={news} />*/}
      </ArticleAction>
    </ArticleContainer>
  );
};
