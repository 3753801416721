import { Row, Col, Collapse, Icon, Carousel } from 'antd';
import Title from 'antd/lib/typography/Title';
import React from 'react';
import World from 'assets/images/world.png';
import CSRLogo from 'assets/images/CSRLogo.png';
import AgilityImage from 'assets/images/agilitycropped.jpg';
import { AboutUsContainer } from '../../../components/Layout/LoginLayout/LoginLayout.Style';

const { Panel } = Collapse;

interface Props {
}

export default ({ }: Props) => {
    return (<Row style={{ maxWidth: '100vw' }}>
        <AboutUsContainer>
            <Col className={"a"} sm={{ span: 24 }} style={{ minHeight: 'fit-content', height: '487px' }}>
                <Col className={"image"} md={{ span: 12 }} style={{ height: '100%' }} >
                    <img src={World} className='logos' />
                </Col>
                <Col className={"text"} md={{ span: 12 }} style={{ height: '100%' }} >
                    <div className={'background-image'}>
                        <Title style={{ color: '#269b48' }}>Who We Are</Title>
                        <Col style={{ width: '2vw', height: '0.6vh', backgroundColor: '#7bc257', marginTop: '-2vh' }} xs={{ span: 1 }} />
                        <div className="who-we-are-text">
                            <div>
                                <p>The Jamaica Social Stock Exchange, JSSE, seeks to engage the entire
                                    Jamaican economy in promoting the Social Capital Market, believing
                                    that true development of any country happens in all areas of life
                                    and facets of society.
                                </p>
                                <p>The JSSE is a state-of-the-art virtual environment and a venue for
                                    the process where the "socially responsible investor(s)", people
                                    interested in contributing and improving the quality of life in
                                    Jamaica, are enabled to invest in wholesome projects that require
                                    public funding, and by extension promote the socio-cultural economy
                                    and the protection of the physical environment.

                                </p>
                            </div>
                        </div>
                    </div>
                </Col>
            </Col>
            <Col className={"b"} sm={{ span: 24 }} style={{ minHeight: 'fit-content', height: '487px' }}>
                <Col className={"text"} md={{ span: 17 }} style={{ height: '100%' }} >
                    <div className={'csr-image background-tint'}>
                        <Title style={{ color: '#fefefe' }}>Corporate Social Responsibility (CSR)</Title>
                        <Col style={{ width: '2vw', height: '0.6vh', backgroundColor: '#fefefe', marginTop: '-2vh' }} xs={{ span: 1 }} />
                        <div className="csr-text">
                            <div>
                                <p>The JSSE, is a social program driven by the JSE&#39;s own practice of
                                    Corporate Social Responsibility (CSR), in partnership with
                                    companies listed on the JSE, international development partners,
                                    local donors and donors in the Jamaican Diaspora. The JSSE,
                                    through this initiative, will demonstrate a practical application
                                    of the five (5) P&#39;s of the United Nations Sustainable Development
                                    Goals (SDGs) with a People-Centered approach of People, Planet,
                                    Prosperity, Partnerships and Peace.
                                </p>
                                <p>Believing in the Value proposition - &#34;Sustainable Growth in the
                                    Social Sector is good for business&#34;. The JSSE will be
                                    implementing this socially-oriented initiative in Phases.
                                </p>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col className={"image"} md={{ span: 7 }} style={{ height: '100%' }} >
                    <img src={CSRLogo} className = 'logos' />
                </Col>
            </Col>
            <Col className={"c"} sm={{ span: 24 }} style={{ minHeight: 'fit-content', height: '487px' }}>
                <Col className={'agility-Logo'} md={{ span: 9 }} style={{}} >
                    <img src={AgilityImage} className=' logos agility-Logo' />
                </Col>
                <Col className={"text"} md={{ span: 15 }} style={{ height: '100%' }} >
                    <div className={'background-image'}>
                        <Title style={{ color: '#269b48' }}>JSSE&#39;s Agility</Title>
                        <Col style={{ width: '2vw', height: '0.6vh', backgroundColor: '#7bc257', marginTop: '-2vh' }} xs={{ span: 1 }} />
                        <div className="agility-text">
                            <div>
                                <p>Globally, businesses are being responsive to the fact that non-financial
                                    factors, especially socio-cultural and environmental impact, do in fact,
                                    affect the long-term returns. Consequently, the consuming public and
                                    investors, are now requiring more people and businesses to conscientiously
                                    ponder such considerations, especially considering how general business
                                    practices are pursued. It is in response to this that the Jamaica Stock
                                    Exchange seeks to introduce, the Jamaica Social Stock Exchange, JSSE.
                                </p>
                            </div>
                        </div>
                    </div>
                </Col>
            </Col>

        </AboutUsContainer>
    </Row>);
}









