import { Button, Icon } from 'antd';
import React, { useEffect, useState } from 'react';
import FileBase64 from 'react-file-base64';
import styled from 'styled-components';
import random from 'lodash/random'


const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  img {
    border: 1px grey solid;
  }
`

export interface IFile {

  name: string;
  type: string;
  size: string;
  base64: string;

}

interface Props {
  value?: any;
  onChange?: Function;
  accept?: string;
  previewSize?: { width: string, height: string };

}
// Do this for looks : https://jsfiddle.net/4cwpLvae/
export default ({ previewSize, value, onChange, accept }: Props) => {
  const [containerClassName, setContainerClassName] = useState("container-class-name");
  useEffect(() => {
    setCurrentFile(value);
    const containerClassName = "container-class-name-" + random(1111, 9999, false);
    setContainerClassName(containerClassName);
    if (!value) {
      document.querySelectorAll(`.${containerClassName} input`)?.forEach(element => (element as any).value = "");
    }
  }, [value])

  useEffect(() => {
    const inputElement = document.querySelectorAll(`.${containerClassName} input`);
    inputElement.forEach?.(element => {
      element.id = containerClassName;
      element.setAttribute("style", "display: none");
    });
  }, [containerClassName])

  useEffect(() => {
    document.querySelectorAll(`.${containerClassName} input`)?.forEach(element => element.setAttribute("accept", accept ?? ""));
  }, [accept, containerClassName]);

  const [currentFile, setCurrentFile] = useState(undefined);
  const callBack = (files: IFile[] | IFile) => {
    if (!files) { onChange?.(undefined); return; }
    const file = (files as IFile[]).length ? files[0] : files;
    onChange?.({ fileName: file.name, content: file.base64, type: file.type });
  }

  const isImage = (value) => {
    return value?.type?.includes("image") || [".jpg", ".png", ".jpeg", ".webp", ".gif", ".svg"].some(x => value?.name?.includes(x));
  }

  return (<Container className={containerClassName} >

    {isImage(value) && <img width={previewSize?.width ?? `100px`} height={previewSize?.height ?? "100px"} src={value?.content ?? value?.url} />}
    <span style={{ display: "flex", alignItems: "center" }}>
      {!value?.content && (value?.name ?? value?.fileName)}
      {value && <Icon title={"Remove File"} style={{ textAlign: "left", marginLeft: "5px" }} type="delete" onClick={() => onChange?.(undefined)}  ></Icon>}
    </span>
    
    <label htmlFor={containerClassName}>
      <a className='ant-btn' >Choose File</a> {value?.fileName ?? "Click To Choose File"}
    </label>

    <FileBase64 multiple={false} onDone={callBack}  >

    </FileBase64>

  </Container >);
}