import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import AuthContext from 'Context/AuthContext';
import { LogoutHandler } from '.';
import { Redirect } from 'react-router-dom';
import ConfigService from 'Core/ConfigService';
import Utility from 'Core/Utility';

export interface Props extends RouteComponentProps {
  children?: React.ReactNode;
}
export interface State {
  isLogout: boolean;
}

export class LogoutWithoutRouter extends React.Component<Props, State> {
  static contextType = AuthContext;
  context!: React.ContextType<typeof AuthContext>;
  logoutHandler: LogoutHandler;
  config = ConfigService.getSync();

  constructor(props: Props) {
    super(props);
    this.logoutHandler = new LogoutHandler(props);
    this.state = { isLogout: false };
  }

  componentDidMount() {
    this.logoutHandler.RequesLogout().then(() => {
      this.context.Update(undefined);
      setTimeout(
        () => this.setState({ isLogout: true }),
        Number.parseInt(this.config.Security.cookieCheckIntervalMs) + 100
      );
    });
  }

  render() {
    return (
      <>
        {!this.state.isLogout ? (
          <div>Logging out...</div>
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: this.props.location, noPermission: false },
            }}
          />
        )}
      </>
    );
  }
}

const Logout = withRouter(LogoutWithoutRouter);
export default Logout;










