import { Button } from 'antd';
import React, { Component } from 'react';
import { AdminLayout, ClassicLayout } from '../Layout';
import logo from './../../assets/images/logo.svg';
import './app.css';
import '../../assets/styles/react-transitions.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import MainContent, { RoutesRenderer } from '../Layout/MainContent';
import AuthContextProvider from '../UserManagement/AuthContextProvider';
import './ant-design-overrides.less';
import LogRocketWrapper from 'components/LogRocketWrapper';
import NavigationEventNotifier from '../Shared/NavigationEventNotifier';
import ErrorView from '../Error/ErrorView';
import CartProvider from 'components/CartProvider'
import AnalyticsWrapper from 'components/AnalyticsWrapper';

class App extends Component {
    public render() {
        return (
            <div className="App">
                <AuthContextProvider>
                    <CartProvider>
                        <BrowserRouter>
                        <AnalyticsWrapper>
                            <ErrorView>
                                <NavigationEventNotifier>
                                    <div className="transition-container">
                                        <RoutesRenderer defaultLayout={AdminLayout} />
                                    </div>
                                </NavigationEventNotifier>
                                </ErrorView>
                            </AnalyticsWrapper>
                        </BrowserRouter>
                    </CartProvider>
                </AuthContextProvider>
            </div>
        );
    }
}

export default App;
















