import {
    Form, Input,
    Button, Col, Row
} from 'antd';
import React, { SyntheticEvent, useContext } from 'react';
import FormControl from '../../Forms/FormControl';
import Title from 'antd/lib/typography/Title';
import { LeftAlignedRow } from '../../Styled/Layout/LeftAlignedRow';
import { FormComponentProps } from 'antd/lib/form';
import { Item } from 'Core/Api/Api';
import UserHandler from 'components/Admin/UserHandler';
import { LoginFormHandler } from 'components/UserManagement/LoginForm/LoginFormHandler';
import AuthContext from 'Context/AuthContext';
import { ProjectsHandler } from 'components/ProjectsListView/ProjectsHandler';
import NotificationService from 'Core/NotificationService';
import { LoginFormModel } from 'components/UserManagement/LoginForm/Models/LoginFormModel';
import ValidationUtility from 'Core/ValidationUtility';
import './DonateStyle.css';

export interface Props extends FormComponentProps<LoginFormModel> {
    items?: Item[];
    onLoggedIn: Function;
}

export const DonateLoginFormWithoutForm = ({ onLoggedIn, form, items }: Props) => {

    const loginFormHandler = new LoginFormHandler({});
    const projectsHandler = new ProjectsHandler({});
    const context = useContext(AuthContext);

    const onSubmit = (e: SyntheticEvent) => {
        e.preventDefault();

        form.validateFieldsAndScroll((err, values) => {
            if (err) return;

            loginFormHandler.RequestLogin(values).then(async user => {
                context.Update(user);

                for (const item of (items ?? [])) {
                    await projectsHandler.AddToCart(item.id, item.amount);
                }

                onLoggedIn?.();
                NotificationService.success("Logged in and cart is refreshed");
            });

        });
    }

    return (
   
        <LeftAlignedRow style={{backgroundColor: 'white', padding: 50,  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', border: '1px solid rgba(0, 0, 0, 0.1)', borderRadius: '10px' }}>
        
        <Col xs={{
            span: 24
        }} sm={{
            span: 24
        }} >
            <Title className={'sectionTitle'} level={2}>Login to Donate</Title>
            <Col span={1} style={{
                height: '0.6vh',
                backgroundColor: '#1d95d3',
                marginTop: '-2vh'
            }}></Col>
            <Col xs={{
                    span: 24,
                    offset: 0
                }} lg={{
                    span: 24,
                    offset: 0
                }} style={{
                    paddingTop: '2vh'
                }} span={4}>
                    <p>With an account, you will be able to view your past contributions and follow favourite projects.</p>
                </Col>
                <Col xs={{
            span: 24
        }} style={{}}>
            <hr></hr>
        </Col>
            <Form id="LoginForm" onSubmit={onSubmit} labelCol={{
                sm: 24
            }} wrapperCol={{
                sm: 24
            }} layout="horizontal">
                <Col xs={{
                    span: 24,
                    offset: 0
                }} lg={{
                    span: 12,
                    offset: 0
                }} style={{
                    paddingRight: '1vw'
                }}>
                    <FormControl getFieldDecorator={form.getFieldDecorator}
                        rules={[...ValidationUtility.required('Email Address is required'),
                        ]}
                        className='leftAlignLabel'
                        name="username" label="Email Address">
                        <Input placeholder="Enter Email Address" />
                    </FormControl>
                </Col>
                <Col xs={{
                    span: 24,
                    offset: 0
                }} lg={{
                    span: 12,
                    offset: 0
                }} 
                // style={{
                //     paddingRight: '1vw'
                // }}
                >
                    <FormControl getFieldDecorator={form.getFieldDecorator} // rules={[
                        //     ...ValidationUtility.required('Password is required'),
                        // ]}
                        className='leftAlignLabel'
                        name="password" label="Password" style={{
                            textAlign: 'initial'
                        }}>
                        <Input.Password placeholder="Enter Password" type="password" />
                    </FormControl>
                </Col>
                <Col style={{ float: 'right',
                    paddingTop: '2vh'
                }} xs={{span:24}} md={{span:4}}>
                    <Button style={{
                        backgroundColor: '#00aed8',
                        width: '100%',
                        fontWeight: 600,
                        fontSize: '16px'
                    }} type="primary" htmlType='submit'>Login</Button>
                </Col>
                
            </Form>

        
       
        </Col>
    </LeftAlignedRow>
    );
};

export const DonateLoginForm = Form.create<Props>({
    name: 'DonateLoginForm',
})(DonateLoginFormWithoutForm);