import { Col, Row } from 'antd';
import * as React from 'react';
import { RouteProps } from 'react-router-dom';
import styled from 'styled-components';
import ConfirmEmailForm from '../../components/UserManagement/ConfirmEmailForm/ConfirmEmailForm';
import NotificationService from '../../Core/NotificationService';// 'Core/NotificationService';
import { ConfirmEmailFormHandler } from '../../components/UserManagement/ConfirmEmailForm/ConfirmEmailFormHandler';
import { ConfirmEmailFormModel } from '../../components/UserManagement/ConfirmEmailForm/Models/ConfirmEmailFormModel';
import queryString from 'query-string';
import { Redirect } from 'react-router';


export interface Props extends RouteProps {
  children?: React.ReactNode;
}

export interface State {
  isConfirmed: boolean;
}

const TallCol = styled(Col)`
  height: 100%;
  min-height: 200px;
`;

export default class ConfirmEmailPage extends React.Component<Props, State> {
  confirmEmailFormHandler: ConfirmEmailFormHandler;


  constructor(props: Props) {
    super(props);
    this.confirmEmailFormHandler = new ConfirmEmailFormHandler(props);

    this.state = { isConfirmed: false };
  }

  componentDidMount() {
    const values = queryString.parse(this.props?.location?.search)
    this.confirmEmailFormHandler.ConfirmEmail(values.email, values.token).then(x => {
      NotificationService.success(
        'Email Confirmed',
        <>
          <p>
            Your email address has been confirmed.
          </p>
        </>, undefined, true
      );
      // setTimeout(() => { this.setState({ isConfirmed: true }); }, 15000);
      this.setState({ isConfirmed: true });

      return x;
    });

    let state: { from?: string; noPermission?: boolean } = {};

    // if (this.props.location) {
    //   state = this.props.location.state || {};
    //   if (!!state.noPermission) {
    //     NotificationService.error(
    //       'No Permission',
    //       <>
    //         <p>
    //           Sorry, but you do not have permission to access that page. Either login with a user
    //           who does or continue using the site.
    //         </p>
    //       </>
    //     );
    //   }
    // }
  }

  render() {
    if (this.state.isConfirmed) {
      return <Redirect to="/login" />;
    }

    return (
      <Row type="flex" align="middle">
        <TallCol xs={12} sm={12} md={8}>
          <ConfirmEmailForm {...this.props} />
        </TallCol>
      </Row>
    );
  }
}
















