import React from 'react';
import { Form, Row, Input, Col, Button, Select, Typography, Steps, Radio, Divider, DatePicker, Upload, Icon, Descriptions, Popover } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import FormControl from 'components/Forms/FormControl';
import SimpleUpload from 'components/Forms/SimpleUpload';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import JcsdAccountHandler from '../JcsdAccountHandler';
import ValidationUtility from '../../../Core/ValidationUtility';
import SimpleSelect from '../../Forms/SimpleSelect';
import JointHolderModel from '../JcsdAccountWizardForm/Models/JointHolderModel/JointHolderModel'
import {
    UserStatus,
    SlimUser,
    Operations,
    
} from '../../../Core/Api/Api';
import Utility from 'Core/Utility';
import ISelectOption from 'components/Forms/SimpleSelect/ISelectOption';
import {
    singleColFormColSize,
    FullWithButton,
    doubleColFormColSize,
} from '../../Forms/Form.Styled';
import MaskedInput from 'components/Forms/MaskedInput';
import { RightAlignedRow } from 'components/Styled/Layout/RightAlignedRow';
import DetailsMode from 'Core/models/DetailsMode';
import NotificationService from 'Core/NotificationService';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import AuthContext from 'Context/AuthContext';
import Paragraph from 'antd/lib/typography/Paragraph';
import moment from 'moment';
import { UploadOutline } from '@ant-design/icons';


const { Title } = Typography;
const { Step } = Steps;

export interface Props extends FormComponentProps, RouteComponentProps {
    children?: React.ReactNode;
}

const steps = [
    {
        title: 'Account Details',
        content: 'First-content',
    },
    {
        title: 'Joint Holders',
        content: 'Second-content',
    },
    {
        title: 'Review',
        content: 'Last-content',
    },
];


export interface State {
    current: number,
    isCompany: boolean,
    isIndividual: boolean,
    isBrokerVM: boolean,
    brokers: any,
    formValues: any,
    jointHolders: JointHolderModel[],
    hasJointHolders: boolean,
    clientTypeJoint1: any,
    clientTypeJoint2: any,
    clientTypeJoint3: any,
    dobMode: "decade" | "time" | "year" | "month" | "date" | undefined

}

export class JcsdAccountWizardFormWithoutFormAndRouter extends React.Component<Props, State> {
    static contextType = AuthContext;
    context!: React.ContextType<typeof AuthContext>;

    handler: JcsdAccountHandler;

    constructor(props: Props) {
        super(props);

        this.state = {
            current: 0,
            isCompany: false,
            isIndividual: false,
            brokers: [],
            jointHolders: [],
            isBrokerVM: true,
            formValues: null,
            clientTypeJoint1: null,
            clientTypeJoint2: null,
            clientTypeJoint3: null,
            hasJointHolders: true,
            dobMode: 'decade'

        };
        this.handler = new JcsdAccountHandler(props);
    }


    componentDidMount() {
    
    }

    onChange = e => {
        if (e.target.value == 1) {
            this.props.form.resetFields(["primaryBusinessName"]);
        } else if (e.target.value == 2) {
            this.props.form.resetFields(["primaryFirstName", "primaryLastName", "primaryDob"]);
        } else {

        }
        this.setState({
            isCompany: (e.target.value == 1 ? false : true),
            isIndividual: (e.target.value == 2 ? false : true),
        });
        //this.state.jointHolders.
    };

    onBrokerChange = e => {

        this.setState({
            isBrokerVM: (e == "10" ? true : false),
        });
        //this.state.jointHolders.
    };

    onJointHolder1Change = e => {
        if (e.target.value == 2) {
            this.props.form.resetFields(["lastNameHolder1", "dobHolder1"]);
        }

        this.setState({
            clientTypeJoint1: (e.target.value)
        });
    };

    onJointHolder2Change = e => {
        if (e.target.value == 2) {
            this.props.form.resetFields(["lastNameHolder2", "dobHolder2"]);
        }

        this.setState({
            clientTypeJoint2: (e.target.value)
        });
    };

    onJointHolder3Change = e => {
        if (e.target.value == 2) {
            this.props.form.resetFields(["lastNameHolder3", "dobHolder3"]);
        }

        this.setState({
            clientTypeJoint3: (e.target.value)
        });
    };

    addJointHolder() {
        const currentJointHolders = this.state.jointHolders;

        currentJointHolders.push(
            new JointHolderModel()
        )
    }

    // setRoles(request: { roles?: string[] }, arg1: any) {
    //   if (arg1.map) {
    //     request.roles = arg1;
    //   } else {
    //     request.roles = [];
    //     request.roles.push(arg1);
    //   }
    // }

  
    moveIfValid() {

        const current = this.state.current + 1;
        this.setState({ current }, () => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        });

        var formValues = this.props.form.getFieldsValue();
        this.setState({ formValues })
    }



    clearJointHolders() {
        var data = {
            clientTypeHolder1: null,
            firstNameHolder1: null,
            lastNameHolder1: null,
            dobHolder1: null,
            trnHolder1: null,
            clientTypeHolder2: null,
            firstNameHolder2: null,
            lastNameHolder2: null,
            dobHolder2: null,
            trnHolder2: null,
            clientTypeHolder3: null,
            firstNameHolder3: null,
            lastNameHolder3: null,
            dobHolder3: null,
            trnHolder3: null,
        };
        this.setState({ clientTypeJoint1: null, clientTypeJoint2: null, clientTypeJoint3: null });

        this.props.form.setFieldsValue(data ?? {});

    }

    onPanelChange = (v, m) => {
        if (m != null) {
            this.setState({
                dobMode: m
            });
        } else {
            var newMode;
            switch (this.state.dobMode) {
                case 'date': newMode = 'date';
                    break;
                case 'decade': newMode = 'year';
                    break;
                case 'month': newMode = 'date';
                    break;
                case 'year': newMode = 'month';
                    break;
                default: newMode = null;
                    break;

            }

            this.setState({
                dobMode: newMode
            });
        }
    }

    onPanelOpen = (v) => {

        this.setState({
            dobMode: 'decade'
        });
                   
    }

    prev() {
        if (this.state.current == 2 && !this.state.hasJointHolders) {
            const current = this.state.current - 2;
            this.setState({ current }, () => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            });

        } else {
            const current = this.state.current - 1;
            this.setState({ current }, () => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            });
        }
    }

    render() {
        var { getFieldDecorator } = this.props.form;
        var { forms } = this.props.form.getFieldsValue();
        const { current } = this.state;
        return (
            <Row>
                <Col xs={18} offset={3}>
                    <Form {...doubleColFormColSize} style={{ backgroundColor: 'white', padding: '30px' }} layout="horizontal"  >
                        <Title>Add Account</Title>
                        <p>
                            If you do not currently possess an active JCSD number, please contact our Client Services Department to initiate the process.

                            <br />
                            Email us at: <b>vmwmclientservices@myvmgroup.com</b>
                            <br />
                            Tel.: <b>1 876 960 5000-3</b>
                            <br />
                            <br />
                        </p>

                        <Steps current={current}>
                            {steps.map(item => (
                                <Step style={{ fontSize: '18px' }} key={item.title} title={item.title} />
                            ))}
                        </Steps>
                        <div className="steps-content">
                            <div style={this.state.current == 0 ? {} : { display: 'none' }}>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            label="Client Type" >
                                            {getFieldDecorator("accountType", {
                                                rules: [{ required: true, message: "Please select a client type!" }]
                                            })(
                                                <Radio.Group onChange={this.onChange}>
                                                    <Radio value={1}>Individual</Radio>
                                                    <Radio value={2}>Company</Radio>
                                                </Radio.Group>
                                            )}
                                        </Form.Item>


                                        <div id="personalDiv" style={!this.state.isCompany ? {} : { display: 'none' }}>
                                            <Row>
                                                <Col span={23}>
                                                    <FormControl
                                                        getFieldDecorator={getFieldDecorator}
                                                        rules={[...ValidationUtility.If(!this.state.isCompany, ValidationUtility.required('First name is required'))]}
                                                        name="primaryFirstName"
                                                        label="Primary Holder First Name">
                                                        <Input />
                                                    </FormControl>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={23}>
                                                    <FormControl
                                                        getFieldDecorator={getFieldDecorator}
                                                        rules={[...ValidationUtility.If(!this.state.isCompany, ValidationUtility.required('Last name is required'))]}
                                                        name="primaryLastName"
                                                        label="Primary Holder Last Name">
                                                        <Input />
                                                    </FormControl>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={23}>
                                                    <FormControl
                                                        getFieldDecorator={getFieldDecorator}
                                                        rules={[...ValidationUtility.If(!this.state.isCompany, ValidationUtility.required('Date of Birth is required'))]}
                                                        name="primaryDob"
                                                        label="Primary Holder Date of Birth">
                                                        <DatePicker disabledDate={d => !d || d.isSameOrAfter(moment())} mode={this.state.dobMode} onPanelChange={this.onPanelChange} onOpenChange={this.onPanelOpen} defaultPickerValue={moment("1999-01-01")} ></DatePicker>
                                                        {/*<DatePicker disabledDate={d => !d || d.isSameOrAfter(moment())} ></DatePicker>*/}
                                                    </FormControl>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div id="companyDiv" style={this.state.isCompany ? {} : { display: 'none' }}>
                                            <Row>
                                                <Col span={23}>
                                                    <FormControl
                                                        getFieldDecorator={getFieldDecorator}
                                                        rules={[...ValidationUtility.If(this.state.isCompany, ValidationUtility.required('Business name is required'))]}
                                                        name="primaryBusinessName"
                                                        label="Business Name">
                                                        <Input />
                                                    </FormControl>
                                                </Col>
                                            </Row>
                                        </div>
                                        <Row>
                                            <Col span={23}>
                                                <FormControl
                                                    getFieldDecorator={getFieldDecorator}
                                                    rules={[...ValidationUtility.required('TIN is required')]}
                                                    name="primaryTrn"
                                                    label="Primary Holder TIN">
                                                    <Input placeholder={'eg. TRN, SSN, ITIN, etc.'} />
                                                </FormControl>
                                            </Col>
                                            <Col span={1}>
                                                <div style={{ marginTop: '8px', marginLeft: '7px' }} >
                                                    <Popover placement="topLeft" title={'TIN Number'} content={'This is your Tax Identification Number'} trigger="click">
                                                        <Icon style={{ fontSize: '16px' }} type="question-circle" />
                                                    </Popover>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col span={23}>
                                                <FormControl
                                                    getFieldDecorator={getFieldDecorator}
                                                    rules={[...ValidationUtility.required('Account Nickname is required')]}
                                                    name="accountName"
                                                    label="Account Nickname">
                                                    <Input />
                                                </FormControl>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col span={23}>
                                                <FormControl
                                                    getFieldDecorator={getFieldDecorator}
                                                    rules={[...ValidationUtility.required('JCSD Number is required')]}
                                                    name="jcsdNumber"
                                                    label="JCSD Number">
                                                    <Input />
                                                </FormControl>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={23}>
                                                <FormControl
                                                    getFieldDecorator={getFieldDecorator}
                                                    rules={[
                                                        ...ValidationUtility.required('Broker is required'),
                                                    ]
                                                    }
                                                    name="brokerCode"
                                                    label="Broker">
                                                    <SimpleSelect onChange={this.onBrokerChange}
                                                        options={this.state.brokers}></SimpleSelect>
                                                </FormControl>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col span={23}>
                                                <FormControl

                                                    getFieldDecorator={getFieldDecorator}
                                                    rules={[
                                                        ...ValidationUtility.If(!this.state.isBrokerVM, ValidationUtility.required('Id needs to be uploaded')),
                                                    ]
                                                    }
                                                    name="idFile"
                                                    label="Upload Id">
                                                    <SimpleUpload>
                                                        <Button>
                                                            <Icon type="upload" /> Click to Upload ID
                                                    </Button>
                                                    </SimpleUpload>
                                                </FormControl>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col span={23}>
                                                <Form.Item
                                                    label="Are there 'Joint Holders' on this account?" >
                                                    {getFieldDecorator("hasJointHolders", {
                                                        rules: [{ required: true, message: "Please select an option!" }]
                                                    })(
                                                        <Radio.Group>
                                                            <Radio value={true}>Yes</Radio>
                                                            <Radio value={false}>No</Radio>
                                                        </Radio.Group>
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                    </Col>
                                </Row>
                            </div>
                            <div style={this.state.current == 1 ? {} : { display: 'none' }} >
                                <div style={this.state.hasJointHolders ? {} : { display: 'none' }}>
                                    <Row>
                                        <Col>

                                            <Divider orientation="left">Joint Holder 1</Divider>

                                            <Form.Item
                                                label="Client Type" >
                                                {getFieldDecorator("clientTypeHolder1", {
                                                })(
                                                    <Radio.Group onChange={this.onJointHolder1Change}>
                                                        <Radio value={1}>Individual</Radio>
                                                        <Radio value={2}>Company</Radio>
                                                    </Radio.Group>
                                                )}
                                            </Form.Item>

                                            <FormControl
                                                getFieldDecorator={getFieldDecorator}
                                                rules={[...ValidationUtility.If(this.state.clientTypeJoint1 != null, ValidationUtility.required('First name is required'))]}
                                                name="firstNameHolder1"
                                                label="Joint Holder First Name">
                                                <Input />
                                            </FormControl>
                                            <FormControl
                                                getFieldDecorator={getFieldDecorator}
                                                rules={[...ValidationUtility.If(this.state.clientTypeJoint1 == 1, ValidationUtility.required('Last name is required'))]}
                                                name="lastNameHolder1"
                                                label="Joint Holder Last Name">
                                                <Input disabled={this.state.clientTypeJoint1 != 1 ? true : false} />
                                            </FormControl>
                                            <FormControl
                                                getFieldDecorator={getFieldDecorator}
                                                rules={[...ValidationUtility.If(this.state.clientTypeJoint1 == 1, ValidationUtility.required('Date of Birth is required'))]}
                                                name="dobHolder1"
                                                label="Joint Holder Date of Birth">
                                                <DatePicker mode={this.state.dobMode} onPanelChange={this.onPanelChange} onOpenChange={this.onPanelOpen} defaultPickerValue={moment("1999-01-01")}   disabled={this.state.clientTypeJoint1 != 1 ? true : false} disabledDate={d => !d || d.isSameOrAfter(moment())} ></DatePicker>
                                            </FormControl>
                                            <FormControl
                                                getFieldDecorator={getFieldDecorator}
                                                rules={[...ValidationUtility.If(this.state.clientTypeJoint1 != null, ValidationUtility.required('TRN is required'))]}
                                                name="trnHolder1"
                                                label="Joint Holder TRN">
                                                <Input />
                                            </FormControl>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Divider orientation="left">Joint Holder 2</Divider>

                                            <Form.Item
                                                label="Client Type" >
                                                {getFieldDecorator("clientTypeHolder2", {
                                                })(
                                                    <Radio.Group onChange={this.onJointHolder2Change}>
                                                        <Radio value={1}>Individual</Radio>
                                                        <Radio value={2}>Company</Radio>
                                                    </Radio.Group>
                                                )}
                                            </Form.Item>

                                            <FormControl
                                                getFieldDecorator={getFieldDecorator}
                                                rules={[...ValidationUtility.If(this.state.clientTypeJoint2 != null, ValidationUtility.required('First name is required'))]}
                                                name="firstNameHolder2"
                                                label="Joint Holder First Name">
                                                <Input />
                                            </FormControl>
                                            <FormControl
                                                getFieldDecorator={getFieldDecorator}
                                                rules={[...ValidationUtility.If(this.state.clientTypeJoint2 == 1, ValidationUtility.required('Last name is required'))]}
                                                name="lastNameHolder2"
                                                label="Joint Holder Last Name">
                                                <Input disabled={this.state.clientTypeJoint2 != 1 ? true : false} />
                                            </FormControl>
                                            <FormControl
                                                getFieldDecorator={getFieldDecorator}
                                                rules={[...ValidationUtility.If(this.state.clientTypeJoint2 == 1, ValidationUtility.required('Date of Birth is required'))]}
                                                name="dobHolder2"
                                                label="Joint Holder Date of Birth">
                                                <DatePicker mode={this.state.dobMode} onPanelChange={this.onPanelChange} onOpenChange={this.onPanelOpen} defaultPickerValue={moment("1999-01-01")}  disabled={this.state.clientTypeJoint2 != 1 ? true : false} disabledDate={d => !d || d.isSameOrAfter(moment())} ></DatePicker>
                                            </FormControl>
                                            <FormControl
                                                getFieldDecorator={getFieldDecorator}
                                                rules={[...ValidationUtility.If(this.state.clientTypeJoint2 != null, ValidationUtility.required('TRN is required'))]}
                                                name="trnHolder2"
                                                label="Joint Holder TRN">
                                                <Input />
                                            </FormControl>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Divider orientation="left">Joint Holder 3</Divider>

                                            <Form.Item
                                                label="Client Type" >
                                                {getFieldDecorator("clientTypeHolder3", {
                                                })(
                                                    <Radio.Group onChange={this.onJointHolder3Change}>
                                                        <Radio value={1}>Individual</Radio>
                                                        <Radio value={2}>Company</Radio>
                                                    </Radio.Group>
                                                )}
                                            </Form.Item>

                                            <FormControl
                                                getFieldDecorator={getFieldDecorator}
                                                rules={[...ValidationUtility.If(this.state.clientTypeJoint3 != null, ValidationUtility.required('First name is required'))]}
                                                name="firstNameHolder3"
                                                label="Joint Holder First Name">
                                                <Input />
                                            </FormControl>
                                            <FormControl
                                                getFieldDecorator={getFieldDecorator}
                                                rules={[...ValidationUtility.If(this.state.clientTypeJoint3 == 1, ValidationUtility.required('Last name is required'))]}
                                                name="lastNameHolder3"
                                                label="Joint Holder Last Name">
                                                <Input disabled={this.state.clientTypeJoint3 != 1 ? true : false} />
                                            </FormControl>
                                            <FormControl
                                                getFieldDecorator={getFieldDecorator}
                                                rules={[...ValidationUtility.If(this.state.clientTypeJoint3 == 1, ValidationUtility.required('Date of Birth is required'))]}
                                                name="dobHolder3"
                                                label="Joint Holder Date of Birth">
                                                <DatePicker mode={this.state.dobMode} onPanelChange={this.onPanelChange} onOpenChange={this.onPanelOpen} defaultPickerValue={moment("1999-01-01")}  disabled={this.state.clientTypeJoint3 != 1 ? true : false} disabledDate={d => !d || d.isSameOrAfter(moment())} ></DatePicker>
                                            </FormControl>
                                            <FormControl
                                                getFieldDecorator={getFieldDecorator}
                                                rules={[...ValidationUtility.If(this.state.clientTypeJoint3 != null, ValidationUtility.required('TRN is required'))]}
                                                name="trnHolder3"
                                                label="Joint Holder TRN">
                                                <Input />
                                            </FormControl>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <div style={this.state.current == 2 ? {} : { display: 'none' }}>


                                <Row>
                                    <Col span={18}>
                                        <Descriptions
                                            title="Account Details & Primary Account Holder"
                                            bordered
                                            column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }} >
                                            <Descriptions.Item label="Account Type">{this.state.formValues?.accountType == 1 ? "Individual" : "Company"}</Descriptions.Item>
                                            {
                                                this.state?.isCompany ?
                                                    <Descriptions.Item className={this.state?.isCompany ? "" : ""} label="Business Name">{this.state.formValues?.primaryBusinessName ?? ""}</Descriptions.Item>
                                                    : null
                                            }
                                            {
                                                !this.state?.isCompany ?
                                                    <Descriptions.Item label="Primary Holder First Name">{this.state.formValues?.primaryFirstName ?? ""}</Descriptions.Item>
                                                    : null
                                            }
                                            {
                                                !this.state?.isCompany ?
                                                    <Descriptions.Item label="Primary Holder Last Name">{this.state.formValues?.primaryLastName ?? ""}</Descriptions.Item>
                                                    : null
                                            }
                                            {
                                                !this.state?.isCompany ?
                                                    <Descriptions.Item label="Primary Holder Date of Birth">{this.state.formValues?.primaryDob != null ? this.state.formValues?.primaryDob.format("YYYY-MM-DD") : ""}</Descriptions.Item>
                                                    : null
                                            }

                                            <Descriptions.Item label="Primary Holder TRN">{this.state.formValues?.primaryTrn ?? ""}</Descriptions.Item>
                                            <Descriptions.Item label="Account Nickname">{this.state.formValues?.accountName ?? ""}</Descriptions.Item>
                                            <Descriptions.Item label="JCSD Number">{this.state.formValues?.jcsdNumber ?? ""}</Descriptions.Item>
                                            <Descriptions.Item label="Broker">{this.state.formValues?.brokerCode != null ? this.state.brokers.find((item) => { return item.value == this.state.formValues?.brokerCode })?.text : ""}</Descriptions.Item>


                                        </Descriptions>
                                        {
                                            this.state.formValues?.clientTypeHolder1 != null ?
                                                <div>

                                                    <Descriptions
                                                        title="Joint Holder 1"
                                                        bordered
                                                        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }} >
                                                        <Descriptions.Item label="Account Type">{this.state.formValues?.clientTypeHolder1 == 1 ? "Individual" : "Company"}</Descriptions.Item>

                                                        <Descriptions.Item label="First Name">{this.state.formValues?.firstNameHolder1 ?? ""}</Descriptions.Item>
                                                        <Descriptions.Item label="Last Name">{this.state.formValues?.clientTypeHolder1 == 1 ? this.state.formValues?.lastNameHolder1 ?? "" : "N/A"}</Descriptions.Item>
                                                        <Descriptions.Item label="Date of Birth">{this.state.formValues?.clientTypeHolder1 == 1 ? this.state.formValues?.dobHolder1?.format("YYYY-MM-DD") ?? "" : "N/A"}</Descriptions.Item>
                                                        <Descriptions.Item label="TRN">{this.state.formValues?.trnHolder1 ?? ""}</Descriptions.Item>
                                                    </Descriptions>
                                                </div> :
                                                null
                                        }
                                        {
                                            this.state.formValues?.clientTypeHolder2 != null ?
                                                <div>

                                                    <Descriptions
                                                        title="Joint Holder 2"
                                                        bordered
                                                        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }} >
                                                        <Descriptions.Item label="Account Type">{this.state.formValues?.clientTypeHolder1 == 1 ? "Individual" : "Company"}</Descriptions.Item>
                                                        <Descriptions.Item label="First Name">{this.state.formValues?.firstNameHolder2 ?? ""}</Descriptions.Item>
                                                        <Descriptions.Item label="Last Name">{this.state.formValues?.clientTypeHolder2 == 1 ? this.state.formValues?.lastNameHolder2 ?? "" : "N/A"}</Descriptions.Item>
                                                        <Descriptions.Item label="Date of Birth">{this.state.formValues?.clientTypeHolder2 == 1 ? this.state.formValues?.dobHolder2?.format("YYYY-MM-DD") ?? "" : "N/A"}</Descriptions.Item>
                                                        <Descriptions.Item label="TRN">{this.state.formValues?.trnHolder2 ?? ""}</Descriptions.Item>
                                                    </Descriptions>
                                                </div> :
                                                null
                                        }
                                        {
                                            this.state.formValues?.clientTypeHolder3 != null ?
                                                <div>

                                                    <Descriptions
                                                        title="Joint Holder 3"
                                                        bordered
                                                        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }} >
                                                        <Descriptions.Item label="Account Type">{this.state.formValues?.clientTypeHolder3 == 1 ? "Individual" : "Company"}</Descriptions.Item>
                                                        <Descriptions.Item label="First Name">{this.state.formValues?.firstNameHolder3 ?? ""}</Descriptions.Item>
                                                        <Descriptions.Item label="Last Name">{this.state.formValues?.clientTypeHolder3 == 1 ? this.state.formValues?.lastNameHolder3 ?? "" : "N/A"}</Descriptions.Item>
                                                        <Descriptions.Item label="Date of Birth">{this.state.formValues?.clientTypeHolder3 == 1 ? this.state.formValues?.dobHolder3?.format("YYYY-MM-DD") ?? "" : "N/A"}</Descriptions.Item>
                                                        <Descriptions.Item label="TRN">{this.state.formValues?.trnHolder3 ?? ""}</Descriptions.Item>
                                                    </Descriptions>
                                                </div> :
                                                null
                                        }
                                    </Col>
                                </Row>
                            </div>

                        </div>

                        <RightAlignedRow>
                            <div className="steps-action">
                                {current > 0 && (
                                    <Button style={{ margin: 8 }} size='large' onClick={() => this.prev()}>
                                        Previous
                                    </Button>
                                )}
                                {current < steps.length - 1 && (
                                    <Button type="primary" size='large' >
                                        Next
                                    </Button>
                                )}
                                {current === steps.length - 1 && (
                                    <Button type="primary" size='large' >
                                        Done
                                    </Button>
                                )}
                            </div>
                        </RightAlignedRow>

                    </Form>
                </Col>
            </Row>
        );
    }
}

const JcsdAccountWizardFormWithRouter = Form.create<Props>({
    name: 'UserDetails',
})(JcsdAccountWizardFormWithoutFormAndRouter);

const JcsdAccountWizardForm = withRouter(JcsdAccountWizardFormWithRouter);

export default JcsdAccountWizardForm;













