import * as React from 'react';
import { Layout, Col, Row, Input, Icon, Button } from 'antd';
import AppMenu from '../../AppMenu';
import LandingPageMenu from '../../LandingPageMenu';
import AppRoute from '../../../../Core/models/AppRoute';
import Logo, { DarkLogo } from '../../../Styled/Logo';
import UserBar from '../../UserBar';
import { logoLayoutSizes, menuLayoutSizes, authBarLayoutSizes, LightHeader, DarkHeader, loggedInAuthBarLayoutSizes, loggedInMenuLayoutSizes } from './TopBar.Styled';
import AuthBar from 'components/Layout/AuthBar';
import { RightAlignedRow } from '../../../Styled/Layout/RightAlignedRow';
import AuthContext from '../../../../Context/AuthContext';
import { CenterAlignedRow } from '../../../Styled/Layout/CenterAlignedRow';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const { Header } = Layout;
const { Search } = Input;

const HowToDonateButton = styled(Button)`
        background-color: #01AED8;
        color: #ffff;
        box-shadow: 0.2px 0.2px 5px rgba(0, 0, 0, 0.3);
    `;

export interface Props {
    children?: React.ReactNode;
    menu: AppRoute[];
}

export interface State { }

export default class TopBar extends React.Component<Props, State> {
    static contextType = AuthContext;
    context!: React.ContextType<typeof AuthContext>;

    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <DarkHeader>
                <Row style={{ margin: '0px' }} type="flex" align="middle" gutter={24}>

                    {this.context.data ?
                        <>  <Col {...loggedInMenuLayoutSizes}>
                            <CenterAlignedRow type="flex" justify="center">
                                <Col span={24} >
                                    {/*  <AppMenu mode="horizontal" menu={this.props.menu} />*/}
                                </Col>
                            </CenterAlignedRow>
                        </Col>

                            <Col {...loggedInAuthBarLayoutSizes}>
                                <RightAlignedRow  style={{display: 'flex', alignItems: 'center'}}>
                                    <Col span={24}>
                                        <AuthBar />
                                    </Col>
                                </RightAlignedRow>
                            </Col>
                        </>
                        :
                        <>  <Col sm={{ span: 3, order: 1, offset: 0 }} xs={{ span: 12, order: 1 }}>
                            <CenterAlignedRow type="flex" justify="center" className="social-media">
                                <Col span={6}>
                                    <a target='_blank' href="https://www.instagram.com/jssexchange/"><Icon type="instagram" /></a>
                                </Col>
                                <Col span={6}>
                                    <a target='_blank' href="https://www.linkedin.com/company/jssexchange"><Icon type="linkedin" theme='filled' /></a>
                                </Col>
                                <Col span={6}>
                                    <a target='_blank' href="https://twitter.com/jssexchange"><Icon style={{}} type="twitter" /></a>
                                </Col>
                                <Col span={6}>
                                    <a target='_blank' href="https://www.facebook.com/jssexchange"><Icon style={{}} type="facebook" /></a>
                                </Col>
                            </CenterAlignedRow>
                        </Col>
                            <Col sm={{ span: 5, order: 2, offset: 0 }} xs={{ span: 12, order: 2, offset: 0 }}>
                                <CenterAlignedRow type="flex" justify="start">
                                    <Col span={24} style={{ color: 'white', textAlign: 'left', fontWeight: 300 }} >
                                        <Icon type="phone" theme="filled" rotate={90} /> <span>+1 (876) 967-3271</span>
                                    </Col>
                                </CenterAlignedRow>
                            </Col>
                            <Col sm={{ span: 8, order: 3, offset: 1 }} xs={{ span: 12, order: 3, offset: 7 }}>
                                <CenterAlignedRow type="flex" justify="center">
                                    <Col span={24} style={{ color: 'white', textAlign: 'center', fontWeight: 300 }} >
                                        <NavLink to={{
                                            pathname: '/how-to-donate'
                                        }}><HowToDonateButton><Icon type="heart" theme="filled" />How to donate<Icon type="heart" theme="filled" /></HowToDonateButton>
                                       </NavLink>
                                    </Col>
                                </CenterAlignedRow>
                            </Col>
                            
                            <Col sm={{ span: 5, order: 4, offset: 0 }} xs={{ span: 18, order: 4, offset: 0 }} style={{ maxWidth: '100vw'}} >
                                <RightAlignedRow style={{  display: 'flex', alignItems: 'center'}}>
                                    <Col span={24}>
                                        <AuthBar />
                                    </Col>
                                </RightAlignedRow>
                            </Col>
                        </>}

                </Row>
                <Row style={{ backgroundColor: 'white' }}>
                    <Col sm={8} lg={6}>
                        <Logo disableLinking={true} />
                    </Col>
                    <Col sm={16} lg={11} style={{paddingRight:'20px' }}>
                        <b>
                            <AppMenu mode="horizontal" menu={this.props.menu} />
                        </b>
                    </Col>
                </Row>
            </DarkHeader >
        );
    }
}
















