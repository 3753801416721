import { Col, Row } from 'antd';
import * as React from 'react';
import { RouteProps } from 'react-router-dom';
import styled from 'styled-components';
import RegisterForm from '../../components/UserManagement/RegisterForm/RegisterForm';
import NotificationService from 'Core/NotificationService';

export interface Props extends RouteProps {
  children?: React.ReactNode;
}

export interface State { }

const TallCol = styled(Col)`
  height: 100%;
  min-height: 200px;
`;

export default class RegisterPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    let state: { from?: string; noPermission?: boolean } = {};

    if (this.props.location) {
      state = this.props.location.state || {};
      if (!!state.noPermission) {
        NotificationService.error(
          'No Permission',
          <>
            <p>
              Sorry, but you do not have permission to access that page. Either login with a user
              who does or continue using the site.
            </p>
          </>
        );
      }
    }
  }

  render() {
    return (
      <Row type="flex" align="middle">
        <TallCol >
          <RegisterForm {...this.props} />
        </TallCol>
      </Row>
    );
  }
}
















