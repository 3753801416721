import { Button, Col, Row } from 'antd';
import * as React from 'react';
import { NavLink, RouteProps } from 'react-router-dom';
import styled from 'styled-components';
import { ProjectsListView } from 'components/ProjectsListView';
import { Redirect } from 'react-router';
import { BannerDiv, CenteredFormContent } from 'components/Layout/LoginLayout/LoginLayout.Style';
import ProjectDetails from '../../components/ProjectList/ProjectDetails';

export interface Props extends RouteProps {
  children?: React.ReactNode;
}

export interface State {
  isConfirmed: boolean;
}

const TallCol = styled(Col)`
  height: 100%;
  min-height: 200px;
`;

export default class ProjectDetailsPage extends React.Component<Props, State> {
  
  constructor(props: Props) {
    super(props);
   

    this.state = { isConfirmed: false };
  }

  componentDidMount() {
  
  }

  render() {
      return <CenteredFormContent>
          <BannerDiv className='project-list'>
              <Row style={{ height: '100%', display: 'flex' }}>
                  <Col sm={12} className={'bannerNav'}>
                      <Col className={'backDiv'} sm={8}>
                          <NavLink to={{
                              pathname: '/projects' + encodeURI(''),
                          }}><a className="backToHome" target="">Back to Projects</a></NavLink>
                      </Col>
                  </Col>
                  <Col span={12} style={{ display: 'flex', justifyContent: 'right' }}  className='bannerNav'>
                      <Col style={{ display: 'flex', justifyContent: 'right', paddingRight: '40px' }} span={24}>
                          {/*<Button style={{ whiteSpace: 'normal' }} className={'donateHeader'}>Donate To JSSE General Fund</Button>*/}
                      </Col>
                  </Col>
              </Row>
          </BannerDiv>
          <Row type="flex" align="middle" justify="center">
              <Col >
                  <ProjectDetails {...this.props}/>
              </Col>
          </Row>

      </CenteredFormContent>;
  }
}










