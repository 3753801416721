import { Drawer } from 'antd';
import { PDFViewer } from 'components/Forms/PDFViewer';
import React, { useEffect } from 'react';

interface Props {
  visible?: boolean;
  onClose?: Function;
  pdfUrl: any;
}

export default ({ visible, onClose, pdfUrl }: Props) => {

  useEffect(() => {

  }, []);

  return (
    <>
      <Drawer width={"100%"} onClose={() => onClose?.()} visible={visible} destroyOnClose={true} >
        {
          <PDFViewer
            document={{
              url: pdfUrl
            }}
            navbarOnTop={true}
            hideRotation={true}
          />
        }
      </Drawer>
    </>
  );
}
