import * as React from 'react';
import { Layout, Row, Col, Icon } from 'antd';
import { CenteredContent, MainLogo, ResetWithBackground, ResetContent } from './ResetLayout.Style';
import Title from 'antd/lib/typography/Title';
import Paragraph from 'antd/lib/typography/Paragraph';
import { SFooter } from '../Footer.Styled';
import { FooterLayout } from '../FooterLayout';
const { Header, Content, Footer } = Layout;

export interface Props {
  children?: React.ReactNode;
}

export interface State { }

export default class ResetLayout extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <ResetWithBackground className="react-transition fade-in" style={{ animationDuration: '750ms' }}>
            <CenteredContent>
                <Row type="flex" align="middle" justify="center">
                    <Col style={{ backgroundColor: 'white' }}>
                        <ResetContent>
                            {this.props.children}
                        </ResetContent>
                    </Col>
                </Row>
            </CenteredContent>
            <FooterLayout />
      </ResetWithBackground>
    );
  }
}
















