import * as Media from 'styled-media-helper';
const sizes = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

const ScreenHelper = new Media(sizes);
export default ScreenHelper;
















