import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import styled from 'styled-components';

interface Props {
  value?: any;
  fontSize?: number;
}

const StyledReactQuill = styled(ReactQuill) <Props>`
 .ql-editor {
  padding: 0px;
 }

 .ql-container {
  font-size: ${(props) => props.fontSize ? `${props.fontSize}px` : "14px"};
 }
`;

export default ({ value, fontSize }: Props) => {
  return <StyledReactQuill theme="bubble" readOnly={true} value={value} fontSize={fontSize} />;
}