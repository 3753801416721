import * as React from 'react';
import ChangePasswordForm from 'components/UserManagement/ChangePasswordForm';

export interface Props {
  children?: React.ReactNode;
}

export interface State {}

export default class ChangePasswordPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return <ChangePasswordForm />;
  }
}










