import * as React from 'react';
import { Button, Icon } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';

export interface Props extends RouteComponentProps {
  children?: React.ReactNode;
}

export interface State {}

export class GoBackButtonWithoutRouter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  goBack = () => {
    if (this.props.history.length) {
      this.props.history.goBack();
    } else {
      this.props.history.push('/');
    }
  };

  render() {
    return (
      <Button size="large" type="link" onClick={this.goBack}>
        <Icon type="arrow-left" />
      </Button>
    );
  }
}

const GoBackButton = withRouter(GoBackButtonWithoutRouter);

export default GoBackButton;










