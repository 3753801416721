import { Form, Icon, Input, Button, Checkbox, Row, Col } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React from 'react';
import { Redirect, RouteProps, NavLink } from 'react-router-dom';
import AuthContext from '../../../Context/AuthContext';
import FormControl from '../../Forms/FormControl';
import { StyleLoginForm } from './LoginForm.Style';
import { LoginFormHandler } from './LoginFormHandler';
import Title from 'antd/lib/typography/Title';
import { MainLogo } from '../../Layout/LoginLayout/LoginLayout.Style';

export interface Props extends FormComponentProps, RouteProps {
    children?: React.ReactNode;
}

export interface State {
    isLoggedIn: boolean;
}

class BaseLoginForm extends React.Component<Props, State> {
    static contextType = AuthContext;
    context!: React.ContextType<typeof AuthContext>;

    loginFormHandler: LoginFormHandler;

    constructor(props: Props) {
        super(props);
        this.loginFormHandler = new LoginFormHandler(props);
        this.state = { isLoggedIn: false };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        if (this.context.data) {
            this.setState({ isLoggedIn: true });
        }
    }

    handleSubmit(e: React.FormEvent) {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log(values);
                this.loginFormHandler.RequestLogin(values).then(user => {
                    this.context.Update(user);
                    this.setState({ isLoggedIn: true });
                });
            }
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        let state: { from?: any; noPermission?: boolean } = {};

        if (this.props.location) {
            state = this.props.location.state || {};
        }

        if (this.state.isLoggedIn) {
            return this.ShouldRedirectTo(state) ? (
                <Redirect to={state.from} />
            ) : (
                <Redirect to="/dashboard" />
            );
        }

        return (
            <StyleLoginForm onSubmit={this.handleSubmit}>
                <NavLink to="/" ><MainLogo /></NavLink>

                <Title style={{ color: 'black' }} level={4}>
                    LOGIN TO YOUR ACCOUNT
        </Title>

                <FormControl
                    getFieldDecorator={getFieldDecorator}
                    rules={[{ required: true, message: 'Please input your email!' }]}
                    name="username">
                    <Input
                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="Email"
                    />
                </FormControl>
                <FormControl
                    getFieldDecorator={getFieldDecorator}
                    rules={[{ required: true, message: 'Please input your Password!' }]}
                    name="password">
                    <Input.Password
                        prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="password"
                        placeholder="Password"
                    />
                </FormControl>
                {/*<Form.Item>
          {getFieldDecorator('remember', {
            valuePropName: 'checked',
            initialValue: false,
          })(<Checkbox>Remember me</Checkbox>)}
        </Form.Item> */}
                <Button
                    type="primary"
                    htmlType="submit"
                    style={{ borderColor: '#F0403D', backgroundColor: '#F0403D', color: 'white' }}
                    className="login-form-button">
                    Log in
        </Button>
                <Form.Item className="login-form-sign-up">
                    New user? <NavLink to={{ pathname: 'register' }}>Sign Up</NavLink>
                
                </Form.Item>
                <Form.Item className="login-form-forgot">
                    <NavLink to="/reset-password">Forgot password?</NavLink>
                </Form.Item>
                <Form.Item className="login-form-sign-up" style={{ marginTop: '-25px' }}>
                    <NavLink to={{ pathname: '/resend-activation' }}>Resend Activation Email</NavLink>
                </Form.Item>
                <Form.Item className="login-form-sign-up" style={{ marginTop: '-25px' }}>
                    <NavLink to="/" >Return to Homepage</NavLink>

                </Form.Item>

            </StyleLoginForm>
        );
    }

    private ShouldRedirectTo(state: { from?: any; noPermission?: boolean | undefined }): boolean {
        return !!state.from && state.from.pathname !== '/logout';
    }
}

const LoginForm = Form.create<Props>({
    name: 'Login',
})(BaseLoginForm);

export default LoginForm;










