import * as React from 'react';
import styled from 'styled-components';
import { Layout, Form, Button } from 'antd';

const { Footer } = Layout;

export const SFooter = styled(Footer)`
  height: fit-content;
  width: 100%;
  color: white !important;
  padding: 0px!important;


    .social-media a{
        color:#00aed8;
    }

   a{
        color:white;
        :hover{
            color:#00aed8;
        }
    }

    .lowerFooter{
        background-color: #141414!important;
        padding-bottom: 20px;
        man-width: 100vw;
        min-height: 3vw;
        padding-top: 10px;
    }
    .upperFooter{
        background-color: #033D5A!important;
        max-width: 100vw;
        padding: 50px;
        padding-bottom: 0px;
}
    }
`;












