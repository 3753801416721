//import React, { createContext, useContext, useState } from 'react';
import * as React from 'react';
import CartContext from '../../Context/CartContext';
import { SecuredClient } from 'Core/Api/SecuredClient';

export interface Props {
    children?: React.ReactNode;
}

export interface State {
    cartItems: number;
}

export function CartProvider({ children }: { children: React.ReactNode }) {

    const [cartItems, setCartItems] = React.useState(0);

    const addToCart = () => {
        setCartItems(prevItems => prevItems + 1);
    };

    const removeFromCart = () => {
        setCartItems(prevItems => prevItems - 1);
    };

    const updateCartItemCount = async() => {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;
        var response = await client.getNumberOfCartItems();
        console.log(response.numberOFCartItems);
        setCartItems(response.numberOFCartItems ? response.numberOFCartItems : 0);
    };

    React.useEffect(() => {
        updateCartItemCount();
    }, []);

    return (
        <CartContext.Provider value={{ cartItems, addToCart, removeFromCart, updateCartItemCount }}>
            {children}
        </CartContext.Provider>
    );
}

export default CartProvider;