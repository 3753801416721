import { Button, Divider, Drawer, Typography } from 'antd';

import { FullWithButton } from 'components/Forms/Form.Styled';
import ISelectOption from 'components/Forms/SimpleSelect/ISelectOption';
import { IDocumentModel, IProjectFullDetailsModel, Operations, ProjectFullDetailsModel, ProjectStatus, ProjectUpdateStatus } from 'Core/Api/Api';
import NotificationService from 'Core/NotificationService';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import Utility from 'Core/Utility';
import moment from 'moment';
import ConfigService from 'Core/ConfigService';
import ShowIfHavePermission from 'components/Forms/ShowIfHavePermission';
import ShowIfTrue from 'components/Forms/ShowIfTrue';
import { startCase } from 'lodash';
import { ColoredPill } from 'components/Forms/ColoredPill';
import { ProjectDetailsDisplay } from '../../ProjectList/ProjectDetails/ProjectDetailsDisplay';
import { ProjectsHandler } from 'components/ProjectsListView/ProjectsHandler';
import { CompanyInfoView } from '../../ProjectList/ProjectDetails/CompanyInfoView';
import { ProjectApprovalsDisplay } from '../../ProjectList/ProjectDetails/ProjectApprovalsDisplay';
import { ProjectApprovalSidebar } from '../../ProjectList/ProjectDetails/ProjectApprovalSidebar';
import { SubmitForApprovalDrawer } from '../CompanyProjectsCard/SubmitForApprovalDrawer';
import DetailsMode from 'Core/models/DetailsMode';
import DetailsData from 'Core/models/DetailsData';
import { useLocation } from 'react-router';

interface State {
  project?: ProjectFullDetailsModel;
  projectNames?: ISelectOption[];
  statusList?: ISelectOption[];
  paymentUrl?: string;
  formattedDocuments?: IDocumentModel[] | undefined;
}

const ProjectViewContainer = styled.div`
    display: grid;

  grid-template-columns: auto auto auto auto;
  /* grid-template-rows: min-content min-content min-content 1fr; */
  gap: 10px;
  column-gap: 20px;
  grid-template-areas: 
    "project project project company"
    "project project project company"
    "project project project comments"
    "project project project comments"
    "project project project actions"
    "project project project actions"
  ;
`;

const ProjectInfo = styled.div`
  grid-area: project;

  min-width: 50%;

`;

const ProjectCompany = styled.div`
  grid-area: company;

`;

const ProjectComments = styled.div`
  grid-area: comments;
  
`;

const ProjectActions = styled.div`
  grid-area: actions;
  
  .success{
    background-color: #269b48;
    display: inline-block;
    color: #ffff;
}

.view{
    width: 88px;
    height: 32px;
    background-color: #00aed8;
    display: inline-block;
    color: #ffff;
}
`;

const ActionsFooter = styled.div`
  display: flex;
  margin: auto -24px;
  position: sticky;
  bottom: 0px;
  border-top: 1px solid black;
  background-color: white;
  min-height: 50px;
  padding-top: 20px;
  justify-items: center;


`;


interface Props {
  visible?: boolean;
  onClose?: Function;
  onSubmitted?: Function;
  projectUpdate?: Function;
  isEditMode?: boolean;
}

export default ({ visible, onClose, onSubmitted, projectUpdate, isEditMode }: Props) => {

  let entrepreneurProjectsHandler = new ProjectsHandler({});
  let history = useHistory();
  let params = useParams<{ id?: string }>();
  let config = ConfigService.getSync();
  let location = useLocation<DetailsData>();

  const [nameFieldIsDirty, setNameFieldIsDirty] = React.useState<boolean>(false);
  const [fundingPropose, setFundingPropose] = React.useState<ISelectOption[] | undefined>();
  const [fundingTypes, setFundingTypes] = React.useState<ISelectOption[] | undefined>();
  const [category, setCategory] = React.useState<ISelectOption[] | undefined>();
  const [project, setProject] = React.useState<ProjectFullDetailsModel | undefined>();
  const [formattedOtherDocuments, setFormattedOtherDocuments] = useState<IDocumentModel[] | undefined>(undefined);
  const [showApprovalDraw, setShowApprovalDraw] = useState(false);
  const [openSubmitDraw, setOpenSubmitDraw] = useState(false);


  useEffect(() => {
    GetCategories();
    GetFundingPurpose();
    GetFundingType()
  }, []);

  useEffect(() => {
    getProject();
    handleProjectSumittedForApproval();

  }, []);


  // getRoleDetails() {
  //   const urlState = this.props.location.state as DetailsData | undefined;
  //   if (urlState && urlState.mode == DetailsMode.Edit) {
  //     this.handler.GetRoleById(urlState.id).then(
  //       x => {
  //         this.setState({ role: x.role, detailsMode: DetailsMode.Edit }, () => {
  //           this.props.form.setFieldsValue(x.role ?? {});
  //         });
  //       },
  //       x => NotificationService.error(x.message)
  //     );
  //   } else {
  //     this.setState({ detailsMode: DetailsMode.New });
  //   }
  // }


  const getProject = () => {

    const urlState = location.state;
    isEditMode = urlState?.mode == DetailsMode.Edit;

    if (params?.id) {
      entrepreneurProjectsHandler.GetProjectByIdForEntrepreneur(params?.id).then(response => {
        if (response.project) {
          const project = response.project;
          setProject(project);
          const projectDocuments: IDocumentModel[] = [];

          response.project?.projectDocuments?.forEach(document => {
            const documentModel: IDocumentModel = {};
            if (document?.document) {
              documentModel.content = { name: document?.document?.name, url: document?.document?.url } as any;
            }

            documentModel.name = document?.document?.name;
            documentModel.fileType = document?.document?.type;
            documentModel.description = document?.description;
            documentModel.url = document.videoUrl;
            documentModel.id = document.id;
            documentModel.removed = false;
            projectDocuments.push(documentModel);

          });

          setFormattedOtherDocuments(projectDocuments);
        }
      }).catch(error => {
        history.push("NotFound");
      });
    }
  }

  const handleProjectSumittedForApproval = () => {
    setOpenSubmitDraw(false);
    projectUpdate?.();
  }

  const handleSubmitForApproval = () => {
    setOpenSubmitDraw(true);
  }

  const ProjectCanBeSubmitted = (project) => {
    return project?.status == ProjectStatus.Draft ||
      project?.status == ProjectStatus.Rejected;
  }

  const GetCategories = () => {
    // const categories = companyProjectsHandler.GetProjectCategory();
    // setCategory(categories);
  }

  const GetFundingPurpose = () => {
    // const fundingPurpose = companyProjectsHandler.GetFundingPurpose();
    // setFundingPropose(fundingPurpose);
  }

  const GetFundingType = () => {
    // const fundingTypes = companyProjectsHandler.GetFundingType();
    // setFundingTypes(fundingTypes);
  }

  const ProjectMeta = ({ project }: { project: IProjectFullDetailsModel | undefined }) => {
    return (<>
      {project?.lastSubmittedDate && <Typography.Text><b>Submitted On</b> {Utility.renderDateWithReference(moment(project?.lastSubmittedDate), config.Display.DateTimeFormat)}</Typography.Text>}
      <br />
      <Typography.Text><b>Status: </b> <ColoredPill
        value={startCase( project?.status === ProjectStatus.FirstApproved ? ProjectStatus.UnderReview : project?.status )}
        options={[{ color: "#c3bfbf", value: ProjectStatus.Draft },
        { color: "#3434d6d9", value: ProjectStatus.Submitted },
        { color: "#ede71ec9", value: ProjectStatus.UnderReview },
        { color: "#28e1decd", value: startCase(ProjectStatus.FirstApproved) },
        { color: "#2d5f00", value: ProjectStatus.Finalized },
        { color: "red", value: ProjectStatus.Rejected },
        { color: "#87d068", value: ProjectStatus.Completed }]} />
      </Typography.Text>
      <br />
      {project?.editedProjectUpdateStatus !== ProjectUpdateStatus.Accepted && <Typography.Text>
        <b>Update Status: </b> <ColoredPill
          value={project?.editedProjectUpdateStatus}
          options={[
            { color: "#c3bfbf", value: ProjectUpdateStatus.Draft },
            { color: "#3434d6d9", value: ProjectUpdateStatus.Submitted },
            { color: "red", value: ProjectUpdateStatus.Rejected },
            { color: "#28e1decd", value: ProjectUpdateStatus.FirstApproved }
          ]} />
      </Typography.Text>}
    </>
    )
  }

  return (<><ProjectViewContainer>
    <ProjectInfo>
      <ProjectDetailsDisplay otherDocuments={formattedOtherDocuments} fundingTypes={fundingTypes} fundingPurposes={fundingPropose} project={project} categories={category} />
    </ProjectInfo>
    <ProjectCompany>
      <CompanyInfoView company={project?.company} />
      <Divider />
      <ProjectMeta project={project} />
    </ProjectCompany>
    <ProjectComments>
      <ProjectApprovalsDisplay approvals={project?.projectApprovals} />
    </ProjectComments>

    <ProjectActions>
      <ShowIfTrue condition={project?.status == ProjectStatus.Submitted || project?.status == ProjectStatus.UnderReview}>
        <ShowIfHavePermission operations={[Operations.Approve_Project]}>
          <Button onClick={x => setShowApprovalDraw(true)} type="default">
            Approve / Reject
          </Button>
        </ShowIfHavePermission>
      </ShowIfTrue>
      <ShowIfTrue condition={project?.status == ProjectStatus.FirstApproved}>
        <ShowIfHavePermission operations={[Operations.Second_Approve_Project]}>
          <Button onClick={x => setShowApprovalDraw(true)} type="default">
            Approve / Reject
          </Button>
        </ShowIfHavePermission>
      </ShowIfTrue>
      <Button onClick={x => history.goBack()} type="default">
        Back
      </Button>
      &nbsp;
      <ShowIfTrue condition={project?.status !== ProjectStatus.Completed}>
        <ShowIfHavePermission operations={[Operations.Edit_Project]}>
          <NavLink to={{
            pathname: `proj/edit/${encodeURI(project?.id ?? '')}`,
            state: { id: params?.id, mode: DetailsMode.New }
          }}>
            <Button className={'view'}>Edit</Button>
          </NavLink>
        </ShowIfHavePermission>
      </ShowIfTrue>

      <ShowIfTrue condition={ProjectCanBeSubmitted(project)}>
        <ShowIfHavePermission operations={[Operations.Edit_Project]}>
            <Button onClick={handleSubmitForApproval} className='success' >Submit For Approval</Button>
        </ShowIfHavePermission>
      </ShowIfTrue>
    </ProjectActions>

  </ProjectViewContainer >
    <ProjectApprovalSidebar visible={showApprovalDraw} onClose={x => setShowApprovalDraw(false)} project={project} onSubmitted={x => (getProject(), setShowApprovalDraw(false))} />
    <SubmitForApprovalDrawer onSubmitted={handleProjectSumittedForApproval} id={params?.id} visible={openSubmitDraw} onClose={x => setOpenSubmitDraw(false)} />
  </>
  );
}