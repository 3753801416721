import * as React from 'react';
import { Route, Switch, RouteComponentProps, Redirect } from 'react-router-dom';
import { AppRoutes } from '../../../../Core/AppRoutes';
import AppRoute, { LayoutType } from '../../../../Core/models/AppRoute';
import Utility from '../../../../Core/Utility';
import ILayout from '../../../../Core/models/ILayout';
import { renderRoutes } from 'react-router-config';
import GatedRoute from '../GatedRoute';

export interface Props {
    defaultLayout: LayoutType;
    isDefault?: boolean;
}

export interface State { }

export default class RoutesRenderer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    render() {
        const routes = AppRoutes.map(route =>
            this.renderRoute(route, undefined, this.props.defaultLayout)
        );
        return (
            <>
                {routes.map(x => (
                    <Switch key={x.key}>{x}</Switch>
                ))}
            </>
        );
    }

    renderRoute(
        route: AppRoute,
        parentPath: string | undefined = '',
        parentLayout: LayoutType,
        parentOperationsRequired: string[] | undefined = []
    ): { key: any } {
        const fullPath = Utility.combinePath(parentPath, route.path as string);
        const currentLayout = route.layout || parentLayout;
        var effectiveRequiredOperations = this.MergeJoin(parentOperationsRequired, route);
        return (
            <React.Fragment key={route.title ?? '' + route.redirect ?? '' + route.routes ?? ''}>
                {this.wrapInLayout(route, fullPath, currentLayout, effectiveRequiredOperations)}
                {route.routes &&
                    route.routes.map(childRoute =>
                        this.renderRoute(childRoute, fullPath, currentLayout, effectiveRequiredOperations)
                    )}
            </React.Fragment>
        );
    }

    private MergeJoin(parentOperationsRequired: string[], route: AppRoute): any {
        var allOperations = [...parentOperationsRequired, ...(route.requiredOperations ?? [])];
        var effectiveOperations = allOperations.filter((value, index, array) => {
            return index === array.indexOf(value);
        });
        return effectiveOperations;
    }

    wrapInLayout(
        route: AppRoute,
        fullPath: string,
        CurrentLayout: LayoutType,
        requiredOperations: string[] = []
    ) {
        const requriedOperationsUsed = route.skipParentRequiredOperations
            ? route.requiredOperations
            : requiredOperations;

        return (
            <GatedRoute
                path={fullPath}
                requiredOperations={requriedOperationsUsed}
                exact={route.exact === undefined ? true : route.exact}
                key={route.path as string + route.title + fullPath}
                render={matchProps => (
                    <CurrentLayout>
                        {/* Render a redirect if redirect property is set */}
                        {route.redirect && <Redirect to={route.redirect} />}
                        {/* else render the component */}
                        <Route component={route.component} />
                    </CurrentLayout>
                )}
            />
        );
    }
}













