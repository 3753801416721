import * as React from 'react';
import { Menu as AntdMenu, Icon, Col, Row } from 'antd';
import AppRoute from '../../../Core/models/AppRoute';
import { NavLink, withRouter, RouteComponentProps, matchPath } from 'react-router-dom';
import Utility from '../../../Core/Utility';
import logo from '../../../assets/images/logo.svg';
import AuthContext from 'Context/AuthContext';
import Menu, { MenuProps } from 'antd/lib/menu';
import { AntdIconMenu, TitleSpan } from 'components/Styled/Layout/AntdIconMenu';
import CartMenuItem from '../AppMenu/LandingLayout/CartMenuItem';
import styled from 'styled-components';
const SubMenu = AntdMenu.SubMenu;
const MenuItemGroup = AntdMenu.ItemGroup;

export declare type MenuMode =
    | 'vertical'
    | 'vertical-left'
    | 'vertical-right'
    | 'horizontal'
    | 'inline';



export interface Props extends MenuProps, RouteComponentProps {
    children?: React.ReactNode;
    menu: AppRoute[];
    mode?: MenuMode;
    onlySubMenuNavigates?: boolean;
    //hideicons?: boolean;
}

export interface State { }

export class AppMenuWithoutRouter extends React.Component<Props, State> {
    static contextType = AuthContext;
    context!: React.ContextType<typeof AuthContext>;

    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    iconRender(menu: AppRoute) {
        
       return <></>;
        
        if ((menu.icon as string).startsWith != undefined) {
            const menuIcon = menu.icon as string;
            return <Icon type={menu.icon || ''} style={{ fontSize: '1.2em', color: '#00aed8' }} />
        } else {
            return menu.icon as React.ReactNode;
        }
    }

    RenderNavLink(menu: AppRoute, fullPath: string = ''): React.ReactNode {
        if (menu.hideInMainMenu) {
            return;
        }

        return (
            <React.Fragment>
                {this.iconRender(menu)}
                <TitleSpan>
                    {menu.path === '/donate' ? <CartMenuItem /> : menu.title}
                </TitleSpan>
                <NavLink to={fullPath} />
            </React.Fragment>
        );
    }

    RenderChildMenuItems(menuItems: AppRoute[], parentPath: string = ''): React.ReactNode {
        return menuItems.map((menuItem: AppRoute) => this.getMenuItem(menuItem, parentPath));
    }

    getMenuItem(menu: AppRoute, parentPath: string = ''): React.ReactNode {
        // Hide from menu if option is true or it required permission that the user does not have
        const user = this.context.data;
        if (menu.hideInMainMenu || !Utility.HasOperations(user, menu.requiredOperations)) {
            return null;
        }

        const fullPath = Utility.combinePath(parentPath, menu.path as string);

        const clickEvent = () => this.props.history.push(fullPath);

        const hasVisibleSubItems: boolean = !!menu.routes?.some(x =>
            this.isVisibleOrhasVisibleSubItems(x)
        );
        let Element: any = hasVisibleSubItems ? SubMenu : AntdMenu.Item;
        const title: any = hasVisibleSubItems ? this.GetTitle(menu) : menu.title;

        // we are going to spread this object on the menu element to handle to click action
        // if it is a submenu the it should be onTitleClick else, onClick
        let clickHandler: any = hasVisibleSubItems
            ? { onTitleClick: clickEvent }
            : { onClick: clickEvent };
        clickHandler = this.props.onlySubMenuNavigates && hasVisibleSubItems ? {} : clickHandler;
        // should we show menu
        const path = { path: this.props.match.path, exact: true };
        const showActive = Utility.isPathOrSubpathAMatch(fullPath, path);
        const activeClass = showActive ? 'ant-menu-item-selected' : '';

        // if menu skip, don't show this route, just render the children
        if (menu.skip) {
            return hasVisibleSubItems
                ? this.RenderChildMenuItems(menu.routes as any, fullPath)
                : this.RenderNavLink(menu, fullPath);
        } else {
            return (
                <Element key={menu.path as string} className={activeClass} title={title} onClick={() => undefined} {...clickHandler} onItemHover={() => undefined}  >
                    {hasVisibleSubItems
                        ? this.RenderChildMenuItems(menu.routes as any, fullPath)
                        : this.RenderNavLink(menu, fullPath)}
                </Element>
            );
        }
    }

    isVisibleOrhasVisibleSubItems(route: AppRoute): boolean {
        return (
            !!route.routes?.filter(y => this.isVisibleOrhasVisibleSubItems(y)) || !route.hideInMainMenu
        );
    }

    GetTitle(menu: AppRoute): any {
        return (
            <React.Fragment>
                {this.iconRender(menu)} <span>{menu.title}</span>
            </React.Fragment>
        );
    }

    render() {
        const { mode, menu, ...rest } = this.props;
        return (
            <AntdIconMenu
                style={{ borderBottomWidth: "0px", fontSize: "20px", textAlign: "right" }}
                theme="light"
                inlineIndent={10}
                overflowedIndicator={<Icon style={{ fontSize: '0.8em' }} type="menu" />}
                mode={this.props.mode} {...rest}>
                {this.props.menu.map(item => this.getMenuItem(item))}
            </AntdIconMenu>
        );
    }
}

const AppMenu = withRouter(AppMenuWithoutRouter);
export default AppMenu;
