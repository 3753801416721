import * as React from 'react';
import { Layout, Row, Col, Icon, Button } from 'antd';
import { AppRoutes } from '../../../Core/AppRoutes';

import ILayout from '../../../Core/models/ILayout';
import { SFooter } from '../Footer.Styled';
import DarkLogoImg from '../../../assets/images/dark_logo.png';
import { DarkLogo } from '../../Styled/Logo';
import GoBackButton from '../../Forms/GoBackButton/GoBackButton';
import TopBar from '../LandingLayout/TopBar';
import { FooterLayout } from '../FooterLayout';
const { Content, Footer } = Layout;

export interface Props extends ILayout {
    children?: React.ReactNode;
}

export interface State { }

export default class CenteredLandingLayout extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Layout className="react-transition fade-in" style={{ animationDuration: '750ms' }}>
                <TopBar menu={AppRoutes} />
                <Row type="flex" justify="center" style={{ minHeight: '85vh' }}>
                    <Col xs={24}>
                        <Content>
                            <Row type="flex" align="top" justify="center">
                                <Col xs={24} sm={1}>
                                    <GoBackButton />
                                </Col>
                                <Col xs={24} sm={23} lg={15}>{this.props.children}</Col>
                            </Row>
                        </Content>
                    </Col>
                </Row>
                <FooterLayout />
            </Layout>
        );
    }
}










