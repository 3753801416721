import * as React from 'react';

import { RouteComponentProps, NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import { startCase } from 'lodash';
import { RightAlignedRow } from '../../Styled/Layout/RightAlignedRow';
import { Page, SearchPanel, ProjectDetailsDiv, Banner } from './../ProjectListStyled';
import TableFilterContextProvider from '../../Forms/DataGrid/TableFilterContextProvider';
import { Avatar, Button, Card, Col, Icon, Input, Modal, notification, Progress, Row, Tabs } from 'antd';
import ISelectOption from '../../Forms/SimpleSelect/ISelectOption';
import SimpleSelect from '../../Forms/SimpleSelect';
import { ProjectsHandler } from '../../ProjectsListView/ProjectsHandler';
import { IDocumentModel, Project, ProjectFullDetailsModel } from '../../../Core/Api/Api';
import NumberFormat from 'react-number-format';
import DetailsMode from '../../../Core/models/DetailsMode';
import DetailsData from '../../../Core/models/DetailsData';
import Title from 'antd/lib/typography/Title';
import SharePopup from '../../Styled/SocialMedia/SharePopup';
import NotificationService from '../../../Core/NotificationService';
import { GalleryView } from './GalleryView';
import { DocumentItemDisplay } from 'components/Forms/FilesEditor/DocumentItemDisplay';
import { GalleryDocumentsView } from './GalleryDocumentsView';
import { useCartContext } from 'Context/CartContext';


const { TabPane } = Tabs;
const { Search } = Input;

interface Props extends RouteComponentProps<{ id: string }> {
    children?: React.ReactNode;
}

interface State {
    project?: ProjectFullDetailsModel;
    projectNames?: ISelectOption[];
    statusList?: ISelectOption[];
    paymentUrl?: string;
    formattedDocuments?: IDocumentModel[] | undefined;
    showModal: boolean;
}
const ProjectDetailsWithoutRouter: React.FC<Props> = (props) => {
    const handler = new ProjectsHandler(props);
    const cartContext = useCartContext();

    const [state, setState] = React.useState<State>({
        showModal: false,
    });

    const getProjectDetails =()=> {
        const urlState = props?.location?.state as DetailsData | undefined;

        if (urlState || props.match.params?.id) {
            var id = urlState?.id ?? props.match.params?.id;
            handler.GetProjectByIdForPublic(id).then(x => {
                setState((prevState) => ({ ...prevState, project: x.project }));

                const projectDocuments: IDocumentModel[] = [];

                x.project?.projectDocuments?.forEach(document => {
                    const documentModel: IDocumentModel = {};
                    if (document?.document) {
                        documentModel.content = { name: document?.document?.name, url: document?.document?.url } as any;
                    }
                    documentModel.name = document?.document?.name;
                    documentModel.fileType = document?.document?.type;
                    documentModel.description = document?.description;
                    documentModel.url = document.videoUrl;
                    documentModel.id = document.id;
                    documentModel.removed = false;
                    projectDocuments.push(documentModel);

                });
                setState((prevState) => ({...prevState, formattedDocuments: projectDocuments }));

            });
        }
    };

    const htmlDecode = (input) => {
        var e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }

    const addToCart = (event: React.SyntheticEvent) => {
        setState((prevState) => ({
            ...prevState,
            showModal: true,
        }));
        handler.AddToCart(state.project?.id).then(x => {
            NotificationService.success("Added to cart");
            cartContext.updateCartItemCount();

        });
    }

    const getCart = (event: React.SyntheticEvent) => {
        handler.GetCart().then(x => {
            console.log(x);
        });
    }

    const getUrl = (event: React.SyntheticEvent) => {
        handler.GetUrl("USD", 100.01).then(x => {
            setState((prevState) => ({ ...prevState, paymentUrl: x.paymentUrl }));
        });
    };

    const handleModalCancel = () => {
        setState({
            ...state,
            showModal: false,
        });
    };

    React.useEffect(() => {
        getProjectDetails();
    }, []);


    return (
        <Page style={{ width: '100%' }}>
            <TableFilterContextProvider>
                <ProjectDetailsDiv>
                    <Modal
                        destroyOnClose={true}
                        visible={state.showModal}
                        onCancel={handleModalCancel}
                        title={state.project?.name + " added to cart"}
                        footer={[
                            <Button key="ok" type='primary' onClick={() => handleModalCancel()}>
                                Ok
                            </Button>
                        ]}
                    >
                        <a style={{ fontSize: 20, textDecoration: 'underline' }} href='/donate'>View your cart</a>
                    </Modal>
                    <Row>
                        <Col span={24}>
                            <Col className={'image'} xxl={{ span: 12 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }}>
                                {
                                    state.project?.videoUrl ?
                                        <iframe className={'video'} src={state.project?.videoUrl} allowFullScreen allow={"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"} ></iframe>
                                        :
                                        <Avatar src={state.project?.projectDashboardFileUrl ?? state.project?.projectDashboardFileUrl} shape="square" />
                                }
                            </Col>
                            <Col className={'about'} xxl={{ span: 12 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }}>
                                <Col className={'company'} xs={{ span: 24 }}>
                                    <Col className={'whoAvatar'} xs={{ span: 3 }}>
                                        <Avatar src={state.project?.thumbnailFileUrl} size={70} />
                                    </Col>
                                    <Col className={'whoDetails'} xs={{ span: 12 }}>
                                        <p className={'whoFrom'}>{state.project?.company?.name}</p>
                                        <p className={'whoOccu'}>{state.project?.subTitle}</p>
                                    </Col>
                                </Col>
                                <br />
                                <p className={'projectTitle'} >{state.project?.name}</p>
                                <p className={'projectSubtitle'}>{state.project?.subTitle}</p>
                                {(state.project?.amountReceived ?? 0) >= (state.project?.amountRequested ?? 0) ?
                                    <Title className={'goal'}>JMD$<NumberFormat displayType='text' decimalScale={2} fixedDecimalScale={true} thousandSeparator=',' value={state.project?.amountReceived} />&nbsp;raised<div className={'green'}>&nbsp;of $<NumberFormat displayType='text' decimalScale={2} fixedDecimalScale={true} thousandSeparator=',' value={state.project?.amountRequested} /></div></Title>
                                    :
                                    <Title className={'goal'}><div className={'green'}>JMD$<NumberFormat displayType='text' decimalScale={2} fixedDecimalScale={true} thousandSeparator=',' value={state.project?.amountReceived} />&nbsp;raised </div>&nbsp;of $<NumberFormat displayType='text' decimalScale={2} fixedDecimalScale={true} thousandSeparator=',' value={state.project?.amountRequested} /></Title>
                                }
                                <Progress className={'progressBar'} showInfo={false} percent={(state.project?.amountRequested ?? 0) == 0 ? 0 : ((state.project?.amountReceived ?? 0) / (state.project?.amountRequested ?? 0)) * 100} ></Progress>
                                <Col xs={{ span: 24 }} style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', maxWidth: '100vw' }}>
                                    <Col sm={{ span: 9 }} xs={{ span: 24 }} style={{}}>
                                        <Button onClick={addToCart} className={'donate'}>Donate Now</Button>

                                        {/*<br />*/}
                                        {/*<Button onClick={this.getCart} className={'donate'}>Get Cart</Button>*/}
                                        {/*<Button onClick={this.getUrl} className={'donate'}>Get Url</Button>*/}
                                        {/*{this.state.paymentUrl ?*/}
                                        {/*    <>*/}
                                        {/*        <br />*/}
                                        {/*        <a href={this.state.paymentUrl} style={{ height: '61px', display: 'inline-block', padding: '19px 18px', minWidth: '221px', backgroundColor: '#031926', borderRadius: '4px', color: 'white', fontSize: '21px', textAlign: 'center', textTransform: 'uppercase', letterSpacing: '1px', textDecoration: 'none', fontFamily: 'Helvetica, Arial, sans-serif' }} target="_blank" title="Fygaro Payment Processor">*/}
                                        {/*            <svg style={{ display: 'inline', height: '24px', fill: '#d8aa4e', verticalAlign: 'sub' }} viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg">*/}
                                        {/*                <path  className="st0" d="M15.5 20.6c0 0 2.5-9.8-1-15.2S4.3 0.6 4.3 0.6 3.1 6.7 3.4 10.7 7.3 20.7 15.5 20.6z"></path>*/}
                                        {/*                <path  className="st0" d="M22 14.4c0 0 3.6-9.5 9.6-11.8s11.1 1.9 11.1 1.9-2.5 5.7-5.1 8.8S28.7 19.2 22 14.4z"></path>*/}
                                        {/*                <radialGradient  cx="22.1" cy="25.8" gradientUnits="userSpaceOnUse" r="18"><stop offset="0" stop-color="#FF9500"></stop>*/}
                                        {/*                    <stop  offset="0.1622" stop-color="#FF8E05"></stop><stop offset="0.3902" stop-color="#FF7C14"></stop><stop offset="0.6573" stop-color="#FF5E2D"></stop><stop offset="0.9518" stop-color="#FF354F"></stop><stop  offset="1" stop-color="#FF2D55"></stop></radialGradient>*/}
                                        {/*                <circle className="st1" cx="22.1" cy="25.8" r="18"></circle><path className="st2" d="M33.7 12c0 0-6.7-2.3-10.1 4.2-1.6 3.6-1.6 9.7-1.6 9.7S33.7 30 33.7 12z"></path><path className="st2" d="M10.5 12c0 0 6.5-2.4 9.9 4.2 1.6 3.6 1.6 9.7 1.6 9.7S10.5 30 10.5 12z"></path>*/}
                                        {/*                <path className="st3" d="M29.1 17.9c0 0-3.1-1.1-4.8 1.7-1.1 1.6-1.1 5.3-1.1 5.3S29.2 27 29.1 17.9z"></path><path className="st3" d="M14.8 17.9c0 0 3.1-1.1 4.8 1.7 1.1 1.6 1.1 5.3 1.1 5.3S14.8 27.1 14.8 17.9z"></path>*/}
                                        {/*            </svg>&nbsp;Donate Now</a>*/}
                                        {/*    </>*/}
                                        {/*    : ''*/}
                                        {/*}*/}

                                    </Col>
                                    <Col sm={{ span: 9 }} xs={{ span: 24 }} style={{}}><NavLink to={{
                                        pathname: '/pledge/' + encodeURI(state.project?.id ?? ''),
                                        state: { id: state.project?.id, mode: DetailsMode.New }
                                    }}><Button className={'makeAPledge'}>Make a Pledge</Button></NavLink></Col>
                                    <Col sm={{ span: 3 }} xs={{ span: 24 }} style={{}}><div style={{ textAlign: 'right' }}> <div className="" style={{ marginLeft: '15px' }}>
                                        <SharePopup shareMessage={'Donate now to the ' + (state.project?.name ?? '') + ' Project'}
                                            popupMessage={'Share Project'}
                                            url={window.location.href} title="Share">
                                            <Button style={{ borderRadius: '16px', color: 'white', backgroundColor: '#646464' }}><Icon type="share-alt" /> Share</Button>
                                        </SharePopup>
                                    </div></div></Col>
                                </Col>
                                <Col className={'details'}>
                                    <div dangerouslySetInnerHTML={{ __html: htmlDecode(state.project?.description) ?? "" }} />
                                    {//parser(state.project?.description ?? "",)
                                    }
                                </Col>
                            </Col>
                            <Col className={'hr'} span={24}>
                                <hr></hr>
                            </Col>
                            <Col className={'tab'} xxl={{ span: 12 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }}>
                                <Tabs>
                                    <TabPane tab="Background" key="tab-a">
                                        <div dangerouslySetInnerHTML={{ __html: htmlDecode(state.project?.description) ?? state.project?.description ?? "" }} />
                                    </TabPane>
                                    <TabPane tab="Organizer" key="tab-b">
                                        <strong>Name: </strong>{state.project?.company?.name}<br></br>
                                        <strong>Address: </strong>{state.project?.company?.address?.line1}<br></br>
                                        <strong>TRN: </strong>{state.project?.company?.trn}

                                        {state.project?.projectDocumentFileUrl ?
                                            <>
                                                <br />
                                                <br />
                                                <NavLink to={{ pathname: state.project?.projectDocumentFileUrl + '' }}
                                                    target='_blank'
                                                    download
                                                >Project Documents
                                                </NavLink>
                                                <br />
                                            </>
                                            :
                                            ''
                                        }
                                        {state.project?.company?.articlesOfIncorporationUrl ?
                                            <> <NavLink to={{ pathname: state.project?.company?.articlesOfIncorporationUrl + '' }}
                                                target='_blank'
                                                download>
                                                Articles Of Incorporation
                                            </NavLink>
                                                <br /> </>
                                            :
                                            ''
                                        }
                                        {state.project?.company?.proofOfTaxComplianceUrl ?
                                            <>
                                                <NavLink to={{ pathname: state.project?.company?.proofOfTaxComplianceUrl + '' }}
                                                    target='_blank'
                                                    download
                                                >Proof Of Tax Compliance
                                                </NavLink>
                                                <br />
                                            </>
                                            :
                                            ''
                                        }
                                    </TabPane>
                                    <TabPane tab="Documents" key="tab-c">
                                        <GalleryDocumentsView documents={state.formattedDocuments} />
                                    </TabPane>
                                    <TabPane tab="Gallery" key="tab-d">
                                        <GalleryView documents={state.formattedDocuments} />
                                    </TabPane>
                                    {/* <TabPane tab="Report" key="tab-e">
                                    </TabPane> */}
                                </Tabs>
                            </Col>
                            {/*
                                <Col className={'others'} xxl={{ span: 12 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }}>
                                    <Title style={{ width: 'fit-content', color: '#7bc257' }}>Other Causes</Title>
                                    <Col style={{ width: '2vw', height: '0.6vh', backgroundColor: '#1d95d3', marginTop: '-2vh' }} xs={{ span: 1 }} />
                                </Col>
                                */}
                        </Col>
                    </Row>
                </ProjectDetailsDiv>
            </TableFilterContextProvider>
        </Page>
    );

};
const ProjectDetails = withRouter(ProjectDetailsWithoutRouter);

export default ProjectDetails;











