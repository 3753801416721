import * as React from 'react';
import { Layout, Row } from 'antd';
import styled from 'styled-components';
import ScreenHelper from '../../Styled/ScreenHelper';
import logoSvg from '../../../assets/images/logo.png';
import ContactUsImg from 'assets/images/Group_3579.png';
import ProjectListImg from 'assets/images/projectsBanner.png';
import NewsImg from 'assets/images/news.png';
import ManagementTeamImg from 'assets/images/managementTeam.png';
import BoardOfDirectorsImg from 'assets/images/boardOfDirectors.png';
import AboutUsImg from 'assets/images/AboutUs.png';
import DonateImg from 'assets/images/Donate.png';
import Background1 from 'assets/images/background1.png';
import PledgeImg from 'assets/images/pledge.png';
import CSRbackground from 'assets/images/CSR.png';
import bannerAlt from 'assets/images/BannerAlt.png';

const { Header, Content, Footer } = Layout;

export const CenteredContent = styled(Content)`
  height: 100%;
  min-height: 60vh;
  margin-bottom:50px;

  ${ScreenHelper.down('md')} {
    padding-top: 1vh;
  }

  ${ScreenHelper.up('md')} {
    padding-top: 15vh;
  }
`;

export const CenteredFormContent = styled(Content)`
  height: 100%;
  min-height: 60vh;
  margin-bottom:170px;
 

  //${ScreenHelper.down('md')} {
  //  padding-top: 1vh;
  //}

  //${ScreenHelper.up('md')} {
  //  padding-top: 15vh;
  //}
`;

export const BannerDiv = styled.div`
    height: 260px;    
    //background-size: contain;
    background-position: center top!important;
    background-repeat: no-repeat;
    margin-bottom: 70px;


    &.contact-us {
        background: url(${ContactUsImg});
    }

    &.admin {
        height: 60px;
    }
    &.news {
        background: url(${NewsImg});
    }
    &.management{
        background: url(${ManagementTeamImg});
    }
    &.board{
        background: url(${BoardOfDirectorsImg});
    }
    &.about-us{
        background: url(${AboutUsImg});
    }

    &.donate{
        background: url(${DonateImg});
    }

    &.project-list {
        background-image: url(${ProjectListImg});
        margin-bottom: 0px;
    }
    &.pledge {
        background-image: url(${PledgeImg});
    }
    &.bannerAlternative {
        background-image: url(${bannerAlt})
    }
    
    .bannerNav{
        position: relative;
        height: 100%;

        .pageTitle{
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            h1{
                color: #f1f1f1;
                font-family: HelveticaNeueLTStd-57;
                font-weight: normal;
                font-stretch: condensed;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
            }
        }
    }
`;

export const AboutUsContainer = styled.div`
    .background-image {
        height: 100%;
       background-image: url(${Background1});
        padding-left: 6.5vw;
        padding-right: 6.5vw;
        padding-top: 4.5vh;
        padding-bottom: 4.5vh;
    }

    .who-we-are-text{
        max-width: 568px;
        padding-bottom: 3vh;
    }
     .csr-image {
        /*height:375px;*/
        background-image: url(${CSRbackground});
        padding-left: 6.5vw;
        padding-right: 6.5vw;
        padding-top: 4.5vh;
    }
    .who-we-are-text{
        max-width: 568px;
    }
    .csr-text{
        max-width: 704px;
        color: #fff;
    }
    .csr-Logo{

        @media only screen and (min-width: 574px) {
            padding-left: 56px;
            padding-right: 102px;

        }
    }

    .agility-Logo{
        height: inherit;
    }
    
    .logos{
        height: 100%;
        width: 100%
    }
    .agility-Logo{
        @media only screen and (max-width: 574px) {        
            //padding-left: 102px;
            //padding-top: 40px;
            height: 85%;
        }
    }


    .agility-text{
        
    }

    .agility{
        padding-left: 6.5vw;
        padding-right: 6.5vw;
        padding-top: 4.5vh;
    }   

    .background-tint {
        background-color: #7bc257;
        background-blend-mode: multiply;
        height: 100%
    }
    .a{
        margin-bottom: 70px;
    }
    .b{
        margin-bottom: 70px;
    }

    @media (max-width: 800px){
        display: flex;
        flex-wrap: wrap;

        .a{
            display: contents;
            margin-bottom: 0px;
        }
        .b{
            display: contents;
            margin-bottom: 0px;
        }
        .c{
            display: contents;
        }
    }

`;

export const ForgetPasswordWithBackground = styled(Layout)`
    
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    min-height:100vh;
`;


export const LoginWithBackground = styled(Layout)`
    
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    min-height:100vh;
`;

export const LoginContent = styled.div`
  background-color: white;
  padding: 20px 40px 10px 40px;
`;

export const MainLogo = styled.div`
  height: 9em;
  /*width: 21em;*/

  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  border: none;
  background: url(${logoSvg});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  ${ScreenHelper.down('sm')} {
    height: 8em;
    width: 10em;
  }
`;

