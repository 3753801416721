import { Avatar, Button, Card, Col, Icon, Progress, Row } from 'antd';
import ShowIfHavePermission from 'components/Forms/ShowIfHavePermission';
import ShowIfTrue from 'components/Forms/ShowIfTrue';
import { UniqueImage } from 'components/Forms/UniqueImage';
import SharePopup from 'components/Styled/SocialMedia/SharePopup';
import { CompanyDetailsModel, NewsListModel, Operations, Project, ProjectDetailsModel, ProjectListModel, ProjectStatus } from 'Core/Api/Api';
import ConfigService from 'Core/ConfigService';
import DetailsData from 'Core/models/DetailsData';
import DetailsMode from 'Core/models/DetailsMode';
import NotificationService from 'Core/NotificationService';
import Utility from 'Core/Utility';
import moment from 'moment';
import React, { SyntheticEvent, useState } from 'react';
import NumberFormat from 'react-number-format';
import { NavLink, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { CompanyProjectsHandler } from '../CompanyProjectsHandler';
import { ProjectCard } from './CompanyProjectsCard.Styled';
import { ReadMoreButton } from './ReadMoreButton';

interface Props {
  project?: ProjectListModel,
  isFullGrid?: boolean;
  isEditMode?: boolean;
  projectUpdate?: Function;
}

const ProjectContainer = styled.div<{ isFullGrid?: boolean }>`
    flex: 2 1 auto;
    width: ${props => props.isFullGrid ? "fit-content" : "min-content"};
    padding : 10px;
    display: grid;
    background-color: #fff;
    border-radius: 5px;
    min-height: 330px;
    min-width: 305px;

    column-gap: 1px;
    row-gap: 5px;
    /* grid-template-rows: auto auto min-content auto; */
    grid-template-areas : 
     "image   image   image   image"
     "image   image   image   image"
     "title   title   title   ."
     "title   title   title   ."
     "goal    goal    .       share"
     "goalbar goalbar goalbar goalbar"
     "action  action  action  action";
      
`;

const ProjectImage = styled.div`
  grid-area : image;
  text-align: center;

  img {
    max-width: 300px;
    width: 100%;
    aspect-ratio: 700/400;
    border-radius: 3px;
  }
`;

const ProjectTitle = styled.div`
  grid-area: title;
  min-height: 30px;
  max-height: 50px;
  container-type: inline-size;

  h1 {
    font-size: 8cqi;
    margin-top: 5px;
    margin-bottom: 5px;
    
  }

  svg {
    font-size: 1.3em;
    width: 100%;
    height: auto;
    text {
      transform: translate(20px, 202px);
      font-size: 62px;
      fill: #000;
    }
  }
  sub {
    font-style: italic ;
    font-size: 11px;
  }
`

const ProjectGoal = styled.div`
  grid-area: goal;

  display: flex;
  flex-wrap: nowrap;
  gap: 2px;

  font-size: 11px;
  p { display: inline-block}
  p:first-child{
    color: green;
  }
  
`;

const ProjectShareArea = styled.div`
  grid-area: share;
  display: flex;
  justify-content: flex-end;

  button {
    font-size: 12px;
    border-radius: 31px;
    background-color: #646464;
    &.ant-btn {
      color: white;
    }
  }
`

const ProjectGoalBar = styled.div`
  grid-area: goalbar;


`

const ProjectActions = styled.div`
  grid-area: action;

  display: flex;
  gap: 3px;
  justify-content: space-between;


  
`

const ArticleTime = styled.div`
  grid-area: time;
  font-size: 11px;
  column-gap: 0px;
  color: #269b48;
  
`

const ArticleAction = styled.div`
  grid-area: more;
  

  a {
    width: 64px;
    height: 23px;
    background-color: #00aed8;
    font-size: 11px;
    padding:  8px 15px;
    border-radius: 4px;
    color: #ffff;
    margin-left: 5px;
    margin-top: 2px;


  }

  button {
    font-size: 12px;
    border-radius: 4px;
    background-color: #646464;
    &.ant-btn {
      color: white;
    }
  }
`



export default ({ project, isFullGrid, isEditMode, projectUpdate }: Props) => {

  const history = useHistory();
  const config = ConfigService.getSync();
  const handler = new CompanyProjectsHandler({});
  const [openSubmitDraw, setOpenSubmitDraw] = useState(false);

  const getUrl = (projects: ProjectDetailsModel | undefined) => {
    if (!projects) { return; }
    const titleSlug = encodeURIComponent(projects.name?.replace(" ", "-").substring(0, 100) ?? '');
    const idSlug = encodeURIComponent(projects.id ?? '');
    const path = `projects/${idSlug}/${titleSlug}`;
    const url = new URL(globalThis.location.origin);
    url.pathname = path;
    console.log(url);
    return url.toString();
  }

  const addToCart = (id) => {
    handler.AddToCart(id).then(x => {
      NotificationService.success("Added to cart", <a href='/donate'>View your cart</a>);
    });
  }

  const goToProject = (projects: ProjectDetailsModel | undefined, e?: SyntheticEvent) => {
    if (!projects) { return; }
    e?.preventDefault();

    const viewDetailsData = new DetailsData();
    viewDetailsData.mode = DetailsMode.New;
    const titleSlug = encodeURIComponent(projects.name?.replace(" ", "-").substring(0, 100) ?? '');
    const idSlug = encodeURIComponent(projects.id ?? '');
    const path = `projects/${idSlug}/${titleSlug}`;
    history.push(path, viewDetailsData);

  }

  const Title = ({ title }) => {
    return <svg

      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 360.96 358.98"
    >
      <text

      >{title}</text>
    </svg>;
  }

  return (<><ProjectCard isEditMode={isEditMode} status={project?.status} xs={24} xl={12} xxl={8} className={'projectItem'}>
    <Card className={'projectCard'} >
      <Row>
        <Col className={'projectImage'} xs={{ span: 24 }}>
          <div className="center-avatar"> <Avatar src={project?.thumbnailFileUrl} shape="square" />
          </div>
        </Col>
        <Col style={{ paddingTop: '15px' }} className={'projectFooter'} xs={{ span: 24 }}>
          <p className={'projectTitle'}>{project?.name}</p>
          <p className={'projectSubtitle'}>{project?.companyName}</p>
          <div style={{ textAlign: 'right' }}> <div className="" style={{ marginLeft: '15px' }}>
            <ShowIfTrue condition={!isEditMode}>
              <SharePopup shareMessage={'Donate now to the ' + (project?.name ?? '') + ' Project'}
                popupMessage={'Share Project'}
                url={getUrl(project) ?? ""} title="Share">
                <Button style={{ borderRadius: '16px', color: 'white', backgroundColor: '#646464' }}><Icon type="share-alt" /> Share</Button>
              </SharePopup>
            </ShowIfTrue>
          </div></div>
          <div style={{ paddingTop: '10px' }} >
            {(project?.amountReceived ?? 0) >= (project?.amountRequested ?? 0) ?
              <div className={'goal'}>JMD$<NumberFormat displayType='text' decimalScale={2} fixedDecimalScale={true} thousandSeparator=',' value={project?.amountReceived} />&nbsp;raised <div className={'green'}>&nbsp;of $<NumberFormat displayType='text' decimalScale={2} fixedDecimalScale={true} thousandSeparator=',' value={project?.amountRequested} /></div></div>
              :
              <div className={'goal'}><div className={'green'}>JMD$<NumberFormat displayType='text' decimalScale={2} fixedDecimalScale={true} thousandSeparator=',' value={project?.amountReceived} />&nbsp; raised </div>&nbsp;of $<NumberFormat displayType='text' decimalScale={2} fixedDecimalScale={true} thousandSeparator=',' value={project?.amountRequested} /></div>
            }
            <Progress className={'progressBar'} showInfo={false} percent={(project?.amountRequested ?? 0) == 0 ? 0 : ((project?.amountReceived ?? 0) / (project?.amountRequested ?? 0)) * 100} />
          </div>
        </Col>
        <Col className={'projectButtons'} xs={{ span: 24 }}>
           <Col style={{ display: 'inline-block', paddingRight: '1px' }}>
            <NavLink to={{
              pathname: `/user-profile/projects/view/${encodeURI(project?.id ?? '')}`,
              state: { id: project?.id, mode: DetailsMode.Edit },
            }}>
              <Button className={'view'}>View</Button>
            </NavLink>
          </Col>
          <ShowIfTrue condition={!isEditMode}>
            <Col style={{ display: 'inline-block', paddingRight: '1px' }}>
              <Button className={'donate'} onClick={() => addToCart(project?.id)} >Donate</Button>
            </Col>
            <Col style={{ display: 'inline-block' }}>
              <NavLink to={{
                pathname: 'pledge/' + encodeURI(project?.id ?? ''),
                state: { id: project?.id, mode: DetailsMode.New }
              }}>
                <Button className={'makeAPledge'}>Make a Pledge</Button>
              </NavLink>
            </Col>
          </ShowIfTrue>
        </Col>
      </Row>
    </Card>
  </ProjectCard>
  </>);
}