//import React, { createContext, useContext, useState } from 'react';
import * as React from 'react';

interface CartContextType {
    cartItems: number;
    addToCart: () => void;
    removeFromCart: () => void;
    updateCartItemCount: () => void;
}

const CartContext = React.createContext<CartContextType | undefined>(undefined);

export function useCartContext() {
    const context = React.useContext(CartContext);
    if (!context) {
        throw new Error('useCartContext must be used within a CartProvider');
    }
    return context;
}

export default CartContext;