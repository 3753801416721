import { Button, Col, Row } from 'antd';
import * as React from 'react';
import { RouteProps } from 'react-router-dom';
import styled from 'styled-components';
import { FAQView } from 'components/Information/FAQView';
import { Redirect } from 'react-router';
import { BannerDiv, CenteredFormContent } from '../../../components/Layout/LoginLayout/LoginLayout.Style';

export interface Props extends RouteProps {
  children?: React.ReactNode;
}

export interface State {
  isConfirmed: boolean;
}

const TallCol = styled(Col)`
  height: 100%;
  min-height: 200px;
`;

export default class AboutUsPage extends React.Component<Props, State> {
  
  constructor(props: Props) {
    super(props);
   

    this.state = { isConfirmed: false };
  }

  componentDidMount() {
  
  }

    render() {
        return <CenteredFormContent>
            <BannerDiv className='about-us'>
                <Row style={{ height: '100%' }}>
                    <Col sm={24} className={'bannerNav'}>
                    </Col>
                </Row>
            </BannerDiv>
            <Row type="flex" align="middle" justify="center">
                <Col style={{ width: '100%' }}>
                    <FAQView />
                </Col>
            </Row>

        </CenteredFormContent>;
  }
}










