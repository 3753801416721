import * as React from 'react';
import styled from 'styled-components';
import { Avatar, Layout, Row } from 'antd';

export const CenterAlignedRow = styled(Row)`
  text-align: center;
`;


export const TopAvatar = styled(Avatar)`
    img {
        position: absolute;
        top: 0px;
        height: auto;
    }
`;










