import { Row, Col, Button } from 'antd';
import { EditCompanyProjectsForm } from 'components/CompanyProjects/EditCompanyProjectsForm';
import { CenteredFormContent, BannerDiv } from 'components/Layout/LoginLayout/LoginLayout.Style';
import React from 'react';
import styled from 'styled-components';

const Wrap = styled.div``;

interface Props {
}

export default ({ }: Props) => {
    return <CenteredFormContent>
        <BannerDiv className={"project-list"}>
            <Row style={{ height: '100%' }}>
            </Row>
        </BannerDiv>
        <Row type="flex" align="middle" justify="center">
            <Col span={20} >
                <EditCompanyProjectsForm />
            </Col>
        </Row>
    </CenteredFormContent>;;
}