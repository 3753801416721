import * as React from 'react';
import { Layout, Col, Row, Input } from 'antd';
import AppMenu from '../../AppMenu';
import AppRoute from '../../../../Core/models/AppRoute';
import Logo, { DarkLogo } from '../../../Styled/Logo';
import UserBar from '../../UserBar';
import { logoLayoutSizes, menuLayoutSizes, authBarLayoutSizes, LightHeader, DarkHeader } from './TopBar.Styled';
import AuthBar from 'components/Layout/AuthBar';
import { RightAlignedRow } from '../../../Styled/Layout/RightAlignedRow';
import AuthContext from '../../../../Context/AuthContext';
import { CenterAlignedRow } from '../../../Styled/Layout/CenterAlignedRow';

const { Header } = Layout;
const { Search } = Input;

export interface Props {
    children?: React.ReactNode;
    menu: AppRoute[];
}

export interface State { }

export default class TopBar extends React.Component<Props, State> {
    static contextType = AuthContext;
    context!: React.ContextType<typeof AuthContext>;

    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <DarkHeader>
                <Row type="flex" align="middle">
                    <Col {...logoLayoutSizes} >
                        <Logo disableLinking={false} />
                    </Col>
                    <Col {...menuLayoutSizes}>
                        <CenterAlignedRow type="flex" justify="center">
                            <Col span={24} >

                                {this.context.data ? <AppMenu mode="horizontal" menu={this.props.menu} /> : ''}
                            </Col>
                        </CenterAlignedRow>

                    </Col>
                    <Col {...authBarLayoutSizes}>
                        <RightAlignedRow  >
                            <Col>
                                <AuthBar />
                            </Col>
                        </RightAlignedRow>
                    </Col>

                </Row>
            </DarkHeader>
        );
    }
}


export class LoggedInTopBar extends React.Component<Props, State> {
    static contextType = AuthContext;
    context!: React.ContextType<typeof AuthContext>;

    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <DarkHeader>
                <Row type="flex" align="middle">
                    <Col {...logoLayoutSizes} >
                        <DarkLogo disableLinking={false} />
                    </Col>
                    <Col {...menuLayoutSizes}>
                        <CenterAlignedRow type="flex" justify="center">
                            <Col span={24} >
                                <Search
                                    placeholder="input search text"
                                    onSearch={value => console.log("")}
                                    style={{ width: '40%' }} />

                                {this.context.data ? <AppMenu mode="horizontal" menu={this.props.menu} /> : ''}
                            </Col>
                        </CenterAlignedRow>

                    </Col>
                    <Col {...authBarLayoutSizes}>
                        <RightAlignedRow  >
                            <Col>
                                <AuthBar />
                            </Col>
                        </RightAlignedRow>
                    </Col>

                </Row>
            </DarkHeader>
        );
    }
}















