import * as React from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';

export const logoLayoutSizes = {
    xs: { span: 6, order: 1, offset: 0 },
    sm: { span: 6, order: 1, offset: 0 },
    md: { span: 6, order: 1, offset: 0 },
    lg: { span: 6, order: 1, offset: 0 },
    xl: { span: 6, order: 1, offset: 0 },
};

export const menuLayoutSizes = {
    xs: { span: 12, order: 2 },
    sm: { span: 12, order: 2 },
    md: { span: 12, order: 2 },
    lg: { span: 12, order: 2 },
    xl: { span: 12, order: 2 },
};

export const authBarLayoutSizes = {
    xs: { span: 6, order: 3 },
    sm: { span: 6, order: 3 },
    md: { span: 6, order: 3 },
    lg: { span: 6, order: 3 },
    xl: { span: 6, order: 3 },
};

export const LightHeader = styled(Layout.Header)`
  &.ant-layout-header {
    background-color: #fff;
  }
`;

export const DarkHeader = styled(Layout.Header)`
  &.ant-layout-header {
  
  }
`;

















