import { Button, Col, Row } from 'antd';
import * as React from 'react';
import { RouteProps } from 'react-router-dom';
import styled from 'styled-components';
import { ProjectsListView } from 'components/ProjectsListView';
import { Redirect } from 'react-router';
import { BannerDiv, CenteredFormContent } from 'components/Layout/LoginLayout/LoginLayout.Style';
import CartListView from '../../components/Cart/CartListView';

export interface Props extends RouteProps {
  children?: React.ReactNode;
}

export interface State {
  isConfirmed: boolean;
}

const TallCol = styled(Col)`
  height: 100%;
  min-height: 200px;
`;

export default class CartListPage extends React.Component<Props, State> {
  
  constructor(props: Props) {
    super(props);
   

    this.state = { isConfirmed: false };
  }

  componentDidMount() {
  
  }

  render() {
      return <CenteredFormContent>
          <BannerDiv className='project-list'>
              <Row style={{ height: '100%' }}>
                  <Col sm={24} className={'bannerNav'}>
                      <Col className={'backDiv'} sm={8}>
                          <a className="backToHome" target="">Back to Home</a>
                      </Col>
                      {/* <Col className={'donateDiv'} sm={8}> */}
                      <Col className={'voluDiv'} sm={8}>
                          <Button className={'donateHeader'}>Donate</Button>
                      </Col>
                      {/* <Col className={'voluDiv'} sm={8}>
                          <Button className={'volunteer'}>Volunteer</Button>
                      </Col> */}
                  </Col>
              </Row>
          </BannerDiv>
          <Row type="flex" align="middle" justify="center">
              <Col style={{ width: '100%' }} >
                  <CartListView {...this.props}/>
              </Col>
          </Row>

      </CenteredFormContent>;
  }
}










