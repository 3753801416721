import * as React from 'react';
import TableRequest from 'Core/TableUtility/Models/TableRequest';
import TableFilterContext from './TableFilterContext';

export interface Props {
  children?: React.ReactNode;
  beforeUpdate?(tableRequest: TableRequest): TableRequest;
}

export interface State {
  tableRequest?: TableRequest;
}

export default class TableFilterContextProvider extends React.Component<Props, State> {
  update: (updateTableRequest: TableRequest) => void;

  constructor(props: Props) {
    super(props);

    this.state = { tableRequest: { filters: [] } };
    this.update = this.updateTableRequest;
  }

  updateTableRequest = (newTableRequest: TableRequest) => {
    let mergedTableRequest = this.props.beforeUpdate
      ? this.props.beforeUpdate(newTableRequest)
      : newTableRequest;
    this.setState({ tableRequest: mergedTableRequest });
  };

  render() {
    return (
      <TableFilterContext.Provider value={{ Update: this.update, data: this.state.tableRequest }}>
        {this.props.children}
      </TableFilterContext.Provider>
    );
  }
}
















