import React, { useState } from 'react';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
// import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import { ErrorBoundary } from "react-error-boundary";
interface Props {
    document: { url: string };
    navbarOnTop: boolean;
    hideRotation: boolean;
}

export default (props: Props) => {

    const [showNow, setShowNow] = useState(true);
    const [retryCount, setRetryCount] = useState(0);

    const ErrorBondaryDiv = () => (<a onClick={() => { setShowNow(false); setTimeout(() => setShowNow(true), 500) }}>"Error! Click Here to Refresh"</a >)

    const retryMethod = () => {
        if (retryCount < 2) {
            setRetryCount(retryCount + 1);
            setShowNow(false);
            setTimeout(() => setShowNow(true), 200);
        }
    }

    return (<Worker workerUrl='https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.js' ><div style={{ minWidth: "50vw", width: "85vw" }}>
        {showNow && <ErrorBoundary onError={retryMethod} fallbackRender={({ error }) => <ErrorBondaryDiv />} >
            <Viewer fileUrl={props.document.url}
                renderError={(x) => <div onClick={() => { setShowNow(true); setTimeout(() => setShowNow(true), 500) }}>Click here to refresh</div>}
                defaultScale={SpecialZoomLevel.PageWidth}
                plugins={[
                ]} />
        </ErrorBoundary >}
    </div ></Worker>);
}