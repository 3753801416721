import * as React from 'react';

import { RouteComponentProps, NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Banner } from 'components/News/NewsStyled';
import { TopAvatar } from '../Styled/Layout/CenterAlignedRow';
import { ProfileContainerHolder } from 'components/BoardOfDirectors/ProfileContainerHolder';

import Henley_Morgan from 'assets/images/management/selection-Henley_Morgan.jpg';
import Livingstone_Morrison from 'assets/images/management/selection-Mr_Morrison.jpg';
import Kadamawe_Knife from 'assets/images/selection/selection-Dr_K_adamawe_K_nife.jpg';
import Robin_Levy from 'assets/images/selection/selection-Robin_Levy.jpg';
import MarleneStreetForrest from 'assets/images/board/board_Marlene_Street-Forrest.jpg';
import CharmaineBrimm from 'assets/images/management/management_charmaine_brimm.jpg';
import Onyka_BarrettScott from 'assets/images/board/board-Onyka_Barrett-Scott.jpg';

import Henley_MorganPdf from 'assets/profiles/committee/dr_henley_morgan.pdf';
import Livingstone_MorrisonPdf from 'assets/profiles/committee/livingstone_morrison_BIO.pdf';
import Kadamawe_KnifePdf from 'assets/profiles/committee/k_adamawe_k_nife.pdf';
import Robin_LevyPdf from 'assets/profiles/committee/robin_levy.pdf';
import MarleneStreetForrestPdf from 'assets/profiles/board/marlene_street_forrest.pdf';
import CharmaineBrimmPdf from 'assets/profiles/board/charmaine_brimm.pdf';
import Onyka_BarrettScottPdf from 'assets/profiles/board/onyka_barrett.pdf';


export interface Props extends RouteComponentProps {
    children?: React.ReactNode;
}

export interface State {
    committee?: any[];
}

export class ListingAndSelectionCommitteeWithoutRouter extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        this.getCommittee();
    }

    getCommittee() {
        this.setState({
            committee: [
                { name: 'Dr. Henley Morgan, OD', position: 'Director of the Jamaica Social Stock Exchange (JSSE) and Committee Member of Selection & Listing Committee (SLC)', img: <TopAvatar style={{ width: '348px', height: '400px', maxWidth: '86vw' }} src={Henley_Morgan} shape="square" />, pdfUrl: Henley_MorganPdf },
                { name: 'Dr. Marlene Street Forrest. JP, CD', position: 'Managing Director (JSE)', img: <TopAvatar style={{ width: '348px', height: '400px', maxWidth: '86vw' }} src={MarleneStreetForrest} shape="square" />, pdfUrl: MarleneStreetForrestPdf },
                { name: 'K’adamawe A.H.N. K’nIfe', position: 'Director of Centre for Entrepreneurship Thinking and Practice (UWI)', img: <TopAvatar style={{ width: '348px', height: '400px', maxWidth: '86vw' }} src={Kadamawe_Knife} shape="square" />, pdfUrl: Kadamawe_KnifePdf },
                { name: 'Livingstone Morrison', position: 'Managing Director - Franklin Covey Jamaica', img: <TopAvatar style={{ width: '348px', height: '400px', maxWidth: '86vw' }} src={Livingstone_Morrison} shape="square" />, pdfUrl: Livingstone_MorrisonPdf },
                { name: 'Chairmaine Brimm', position: 'Technical Specialist (PIOJ)', img: <TopAvatar style={{ width: '348px', height: '400px', maxWidth: '86vw' }} src={CharmaineBrimm} shape="square" />, pdfUrl: CharmaineBrimmPdf },
                { name: 'Robin Andrew Levy', position: 'Group Chief Executive Officer (JCCUL)', img: <TopAvatar style={{ width: '348px', height: '400px', maxWidth: '86vw' }} src={Robin_Levy} shape="square" />, pdfUrl: Robin_LevyPdf },
                { name: 'Onyka Barrett Scott', position: 'Chief Executive Officer (Pinnacle Impact International Limited)', img: <TopAvatar style={{ width: '348px', height: '400px', maxWidth: '86vw' }} src={Onyka_BarrettScott} shape="square" />, pdfUrl: Onyka_BarrettScottPdf },
            ]
        })
    }

    render() {
        return (
            <>
                <ProfileContainerHolder profiles={this.state.committee} />
            </>
        );
    }
}

const ListingAndSelectionCommittee = withRouter(ListingAndSelectionCommitteeWithoutRouter);

export default ListingAndSelectionCommittee;











