import * as React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { User, SlimUser, Role, PaymentListItem, PaymentStatus } from 'Core/Api/Api';
import SimpleTable from 'components/Forms/DataGrid/SimpleTable';
import UserHandler from 'components/Admin/UserHandler';
// import UserHandler from '../../UserHandler';
import { NavLink } from 'react-router-dom';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import DetailsMode from 'Core/models/DetailsMode';
import RoleHandler from 'components/Admin/RoleHandler';
import DetailsData from 'Core/models/DetailsData';
import { Button, Icon } from 'antd';
import Utility from 'Core/Utility';
import ShowIfTrue from 'components/Forms/ShowIfTrue';
import moment from 'moment';
import ConfigService from 'Core/ConfigService';
import NumberFormat from 'react-number-format';
import PaymentHandler from 'components/Admin/PaymentHandler';
import UserProfileHandler from '../UserProfileHandler';
import NotificationService from 'Core/NotificationService';
export interface Props {
    children?: React.ReactNode;
}

export interface State {
    countries: IFilterSelectOption[];
    jurisdictions: IFilterSelectOption[];
    roles: IFilterSelectOption[];
    profile: any;
    payments: any;
}

export default class PaymentsListTable extends React.Component<Props, State> {
    paymentHandler: PaymentHandler;
    config = ConfigService.getSync();
    constructor(props: Props) {
        super(props);

        this.state = {
            countries: [],
            jurisdictions: [],
            roles: [],
            profile: null,
            payments: null,
        };
        this.paymentHandler = new PaymentHandler(props);
    }

    onReceipt = (payment: PaymentListItem) => {
        this.paymentHandler.GetReceipt({ id: payment.id }).then(x => {
            Utility.downloadBase64File(x.fileBase64String, x.fileName);
        })
    }

    componentDidMount() {

    }

    paymentsListTableColumns: Array<ColumnProps<PaymentListItem>> = [
        {
            title: 'Receipt #',
            dataIndex: 'invoiceId',
        },
        {
            title: 'Currency',
            dataIndex: 'currency',
            sorter: true,
        },
        {
            title: 'Total',
            dataIndex: 'total',
            sorter: true,
            render: (text: any, record: PaymentListItem, index: number) => {
                return <NumberFormat value={record.total} prefix="$" thousandSeparator="," displayType='text' />;
            },
        },
        {
            title: 'Date',
            dataIndex: 'date',
            sorter: true,
            render: (text) => !text ? "" : Utility.renderDateWithReference(moment(text), this.config.Display.DateTimeFormat),
        },
        {
            title: 'Type',
            dataIndex: 'type',
        },
        {
            title: 'Status',
            dataIndex: 'status',
        },
        {
            title: 'Action',
            render: (text: any, record: PaymentListItem, index: number) => {
                return (
                    <>
                        <ShowIfTrue condition={record.status == PaymentStatus.Successful}>
                            <Button onClick={x => this.onReceipt(record)} type="link" title="Download Receipt"  >
                                <Icon title="Download" type="download" />
                            </Button>
                        </ShowIfTrue>
                    </>
                );
            },
        },
    ];

    render() {
        return (
            <SimpleTable<PaymentListItem>
                tableKey="id"
                dataFetcher={this.paymentHandler}
                columns={this.paymentsListTableColumns}
            />
        );
    }
}



