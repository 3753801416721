import { GetProjectTrackerInfoQueryRequest } from 'Core/Api/Api';
import { SecuredClient } from '../../Core/Api/SecuredClient';

export default class DashboardHandler {
    props: any;
    constructor(props) {
        this.props = props;
    }

    GetBanner() {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;

        return client.getBanner();
    }

    GetProjectTrackerInfo() {

        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;

        return client.getProjectTrackerInfo(new GetProjectTrackerInfoQueryRequest());
    }
}
