import { useEffect, useState } from 'react';
import * as React from 'react';
import { Button, Col, DatePicker, Form, Icon, Input, Upload, Row, Modal } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { fullWidthSingleColFormColSize, fullWidthSingleColFormColSizeTwo, FullWithButton } from 'components/Forms/Form.Styled';
import FormControl from 'components/Forms/FormControl';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import { RightAlignedRow } from 'components/Styled/Layout/RightAlignedRow';
import ValidationUtility from 'Core/ValidationUtility';
import { useHistory } from 'react-router-dom';

import NotificationService from 'Core/NotificationService';
import { FormComponentProps } from 'antd/lib/form';

import styled from 'styled-components';
import { RichTextEditor } from 'components/Forms/RichTextEditor';
import { CompanyProjectsHandler } from '../CompanyProjectsHandler';
import SimpleSelect from 'components/Forms/SimpleSelect';
import SimpleUpload from 'components/Forms/SimpleUpload';
import { FilesEditor } from 'components/Forms/FilesEditor';
import ISelectOption from 'components/Forms/SimpleSelect/ISelectOption';
import { ICreateProjectModel } from 'Core/Api/Api';
import { Base64FileUploader } from 'components/Forms/Base64FileUploader';
import MaskedInput from 'components/Forms/MaskedInput';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { CreateProjectGuidelineDetails } from '../CreateProjectGuidelineDetails';



export interface AddCompanyProjectFormProps extends FormComponentProps<ICreateProjectModel> {
  children?: React.ReactNode;
}

export const ButtonGroup = styled.div`
  
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(100px,200px));
    gap: 5px;
  `;

export function AddCompanyProjectForm({ children, form }: AddCompanyProjectFormProps) {

  let getFieldDecorator = form?.getFieldDecorator;
  let history = useHistory();
  let companyProjectsHandler = new CompanyProjectsHandler({});
  const [nameFieldIsDirty, setNameFieldIsDirty] = React.useState<boolean>(false);
  const [fundingPropose, setFundingPropose] = React.useState<ISelectOption[] | undefined>();
  const [fundingTypes, setFundingTypes] = React.useState<ISelectOption[] | undefined>();
  const [category, setCategory] = React.useState<ISelectOption[] | undefined>();
  const [showGuidelineModal, setShowGuidelineModal] = useState(false);
  
  useEffect(() => {
    GetCategories();
    GetFundingPurpose();
    GetFundingType()
  }, []);

  useEffect(() => {
    // This was done because the client was complaining that
    // While creating a project, there would get signed out.
    // This makes a api call that will refresh the token while on the page
    const timeOutHandle = setInterval(() => {
      companyProjectsHandler.RefreshToken();
    }, 30000);

    return () => clearInterval(timeOutHandle);
  }, [])

  const GetCategories = () => {
    const categories = companyProjectsHandler.GetProjectCategory();
    setCategory(categories);
  }

  const GetFundingPurpose = () => {
    const fundingPurpose = companyProjectsHandler.GetFundingPurpose();
    setFundingPropose(fundingPurpose);
  }

  const GetFundingType = () => {
    const fundingTypes = companyProjectsHandler.GetFundingType();
    setFundingTypes(fundingTypes);
  }

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    form?.validateFieldsAndScroll((errors, values) => {
      if (errors != null) { return; }
      values.timeToFunding = values?.timeToFunding ? moment(values?.timeToFunding).toISOString() : undefined;
      companyProjectsHandler.AddProject(values).then(response => {
        history.goBack();
        NotificationService.success(`Added ${response.project?.name}`, "", 10, true);
      });
    });
  };


  return (
    <>
      <RightAlignedRow type="flex" justify='center'>
        <Col span={20} style={{ paddingBottom: '30px' }}>
          <Button size='default' type="primary" onClick={() => setShowGuidelineModal(true)} >
            <b>View Guidelines</b>
          </Button>
        </Col>
      </RightAlignedRow>
      <LeftAlignedRow>
        <Col span={24}>
          <Form {...fullWidthSingleColFormColSizeTwo} layout="vertical" onSubmit={onSubmit}>
            <LeftAlignedRow type='flex' style={{ justifyContent: 'center' }}>
              <Col span={20}>
                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  rules={[...ValidationUtility.required('Title is required')]}
                  name="name"
                  label="Title">
                  <Input size='large' />
                </FormControl>
                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  rules={[...ValidationUtility.required('Sub-title is required')]}
                  name="subTitle"
                  label="Sub-title">
                  <Input />
                </FormControl>
                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  rules={[...ValidationUtility.required('Category is required')]}
                  name="category"
                  labelAlign='left'
                  label="Category">
                  <SimpleSelect options={category} />
                </FormControl>
                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  name="timeToFunding"

                  labelAlign='left'
                  label="Time To Fund">
                  <DatePicker />
                </FormControl>
                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  rules={
                    [
                      {
                        transform: ValidationUtility.TransformEditorState
                      },
                    ]
                  }
                  name="description"
                  label="Project Description/ Theory Of Change">
                  <RichTextEditor rows={9} />
                </FormControl>
                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  rules={[...ValidationUtility.required('Project Life Cycle Stage is required')]}
                  name="fundingPurpose"
                  label="Project Life Cycle Stage">
                  <SimpleSelect options={fundingPropose} />
                </FormControl>
                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  rules={[...ValidationUtility.required('Funding Type is required')]}
                  name="typeOfFunding"
                  label="Market">
                  <SimpleSelect options={fundingTypes} />
                </FormControl>
                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  rules={[...ValidationUtility.required('Goal Amount is required')]}
                  name="amountRequested"
                  label="Funding Target">
                  <NumberFormat
                    thousandSeparator="," customInput={Input} />
                </FormControl>
                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  name="thumbnail"
                  rules={[...ValidationUtility.required('Thumbnail is required'),
                  ...ValidationUtility.Base64ImageHasValidImageSizes([{ width: 350, height: 250 }], "thumbnail image size is not correct")]}
                  help={"Thumbnail images must be 350x250 pixels"}
                  validateTrigger="onChange"
                  label="Thumbnail">
                  <Base64FileUploader previewSize={{ width: "200", height: "150" }} accept='image/*' />
                </FormControl>
                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  name="dashboardPicture"
                  rules={[...ValidationUtility.required('Dashboard Image is required'),
                  ...ValidationUtility.Base64ImageHasValidImageSizes([{ width: 600, height: 300 }], "Dashboard image size is not correct")]}
                  help={"Dashboard images must be 600x300 pixels"}
                  validateTrigger="onChange"
                  label="Dashboard Image">
                  <Base64FileUploader accept='image/*' previewSize={{ width: "200", height: "150" }} />
                </FormControl>
                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  name="videoUrl"
                  label="Video Url (Youtube only)">
                  <Input />
                </FormControl>
                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  rules={[...ValidationUtility.required('Project Document is required')]}
                  name="businessPlan"
                  label="Project Document">
                  <Base64FileUploader accept={".pdf"} />
                </FormControl>
                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  name="documents"
                  label="Other Document(s)">
                  <FilesEditor />
                </FormControl>
              </Col>
            </LeftAlignedRow>


            <LeftAlignedRow type='flex' style={{ justifyContent: 'center' }}>
              <Col span={20}>
                <Form.Item
                  colon={false}>
                  <ButtonGroup>
                    <Button onClick={() => history.goBack()} type="default">
                      Back
                    </Button>
                    <FullWithButton type="primary" htmlType="submit">
                      Submit
                    </FullWithButton>
                  </ButtonGroup>
                </Form.Item>
              </Col>
            </LeftAlignedRow>
          </Form>
        </Col>

        <Modal width={"50%"} 
          destroyOnClose={true} 
          visible={showGuidelineModal} 
          onCancel={e => setShowGuidelineModal(false)}
          footer={[
            <Button key="ok" type='primary' onClick={() => setShowGuidelineModal(false)}>
              Ok
            </Button>
          ]}>
          <CreateProjectGuidelineDetails />
        </Modal>
      </LeftAlignedRow>

    </>
  );
}

export default Form.create({ name: "add-project-form" })(AddCompanyProjectForm);