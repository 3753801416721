import * as React from 'react';
import { CenterAlignedRow } from 'components/Styled/Layout/CenterAlignedRow';
import { Col, Form, Input, Row, Button } from 'antd';
import FormControl from 'components/Forms/FormControl';
import { FormComponentProps } from 'antd/lib/form';
import { withRouter, RouteComponentProps, NavLink } from 'react-router-dom';
import ValidationUtility from 'Core/ValidationUtility';
import PasswordResetHandler from '../RequestPasswordResetForm/PasswordResetHandler';
import { IResetPasswordCommandRequest } from 'Core/Api/Api';
import FormItem from 'antd/lib/form/FormItem';
import NotificationService from 'Core/NotificationService';
import ConfigService from 'Core/ConfigService';
import { MainLogo } from '../../Layout/ResetLayout/ResetLayout.Style';
import Title from 'antd/lib/typography/Title';
import { BrandedButton } from '../../Styled/Branded/BrandedButton';

export interface Props extends FormComponentProps, RouteComponentProps {
    children?: React.ReactNode;
}

export interface State {
    token?: string;
    emailAddress?: string;
}

export class ConfirmResetPasswordFormWithoutFormsAndRouter extends React.Component<Props, State> {
    handler: PasswordResetHandler;

    constructor(props: Props) {
        super(props);

        this.state = {};
        this.handler = new PasswordResetHandler(props);
    }

    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        const emailAddress = query.get('email');
        const token = query.get('token');

        if (!emailAddress || !token) {
            this.props.history.push('/reset-password');
            const errorMessage =
                'Please try reseting your password again or if this issue persist contact us at ' +
                ConfigService.getSync().SiteInfo.ContactUsEmail;
            NotificationService.error('Invalid Url', errorMessage, 0, false);
        } else {
            this.setState({ token: token, emailAddress: emailAddress });
        }
    }

    onSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        this.props.form.validateFields((error, values: IResetPasswordCommandRequest) => {
            if (error)
                return;

            values.token = this.state.token;
            values.emailAddress = this.state.emailAddress;
            this.handler.ResetPassword(values).then(() => {
                this.props.history.push('/login');
                NotificationService.success('Password has been reset');
            });
        });
    };

    render() {
        var { getFieldDecorator } = this.props.form;
        var passwordValidations = ValidationUtility.password;
        var compareValidations = ValidationUtility.compare;
        return (
            <Row>
                <Col>
                    <MainLogo />

                    <Title style={{ color: 'black' }} level={4}>
                        Reset Password
        </Title>
                    <Form onSubmit={this.onSubmit}>
                        <FormControl
                            getFieldDecorator={getFieldDecorator}
                            label="Password"
                            name="password"
                            rules={[...passwordValidations()]}>
                            <Input type="password" />
                        </FormControl>
                        <FormControl
                            getFieldDecorator={getFieldDecorator}
                            rules={[...compareValidations(this.props.form, 'password', 'Passwords do not match')]}
                            label="Confirm Password"
                            name="confirmationPassword">
                            <Input type="password" />
                        </FormControl>
                        <FormItem>
                            <Row>
                                <Col>
                                    <BrandedButton htmlType="submit">Reset Password</BrandedButton>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <NavLink to="/login">Go to Login</NavLink>
                                </Col>
                            </Row>
                        </FormItem>
                    </Form>
                </Col>
            </Row>
        );
    }
}

const ConfirmResetPasswordFormWithoutRouter = Form.create<Props>({
    name: 'ConfirmResetPassword',
})(ConfirmResetPasswordFormWithoutFormsAndRouter);

const ConfirmResetPasswordForm = withRouter(ConfirmResetPasswordFormWithoutRouter);

export default ConfirmResetPasswordForm;










