import React, { ChangeEvent } from 'react';
import Input, { InputProps } from 'antd/lib/input';
import FilterFieldWrapper from '../FilterFieldWrapper';
import { FilterFieldWrapperProps } from '../FilterFieldWrapper/FilterFieldWrapper';
import { TextInputChangeEventData } from 'react-native';
import debounce from 'lodash/debounce';

export interface FilterTextboxProps extends FilterFieldWrapperProps, InputProps {}

export default class FilterTextbox extends FilterFieldWrapper<FilterTextboxProps> {
  constructor(props: FilterFieldWrapperProps) {
    super(props);
    this.UpdateFilter = debounce(this.UpdateFilter.bind(this), 700);
  }

  onChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.UpdateFilter(event.currentTarget.value);
  };

  render() {
    const { filterFields, filterJoin, filterType, onFilterUpdated, ...inputProps } = this.props;
    return <Input type={this.props.type || 'Text'} onChange={this.onChange} {...inputProps} />;
  }
}
















