import * as React from 'react';
import { Col, Form, Input, Row, Card } from 'antd';
import FormControl from 'components/Forms/FormControl';
import { FormComponentProps } from 'antd/lib/form';
import { withRouter, RouteComponentProps, NavLink } from 'react-router-dom';
import ValidationUtility from 'Core/ValidationUtility';
import NotificationService from 'Core/NotificationService';
import Title from 'antd/lib/typography/Title';
import { CompanyProjectsHandler } from '../CompanyProjectsHandler';
import { AddressForm } from 'components/UserManagement/RegisterForm/AddressForm';
import { Base64FileUploader } from 'components/Forms/Base64FileUploader';
import { FullWithButton } from 'components/Forms/Form.Styled';


export interface Props extends FormComponentProps, RouteComponentProps {
    children?: React.ReactNode;
}

export interface State {
    company?: any,
}

export class EditCompanyProfileFormWithoutFormsAndRouter extends React.Component<Props, State> {
    handler: CompanyProjectsHandler;

    constructor(props: Props) {
        super(props);

        this.state = {};
        this.handler = new CompanyProjectsHandler(props);
        this.GetCompanyDetails = this.GetCompanyDetails.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }   

    componentDidMount() {
        this.GetCompanyDetails();
    }

    GetCompanyDetails = () => {
        this.handler.GetMyCompanyProfile().then(
            x => {
                this.setState({ company: x}, ()=>{
                    this.props.form.setFieldsValue(x ?? {});
                    x.articlesOfIncorporationUrl && this.props.form.setFieldsValue({ "proofOfIncorporationFileBase64": {name: x.articlesOfIncorporationFileName, url: x.articlesOfIncorporationUrl} });
                    x.proofOfTaxComplianceUrl && this.props.form.setFieldsValue({ "proofOfTaxComplianceFileBase64": {name: x.proofOfTaxComplianceFileName, url: x.proofOfTaxComplianceUrl} });
                });
            },
            x => NotificationService.error(x.message)
        )
    }

    onSubmit(event: React.FormEvent) {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((error, values: any) => {
            if (error) {
                return;
            }
            else {
                values.id = this.state.company.id;
                values.primaryUserId = this.state.company.userId;

                // console.log(values);

                this.handler.EditCompany(values).then(x => {
                    this.props.history.goBack();
                    NotificationService.success(`Updated ${values.name} profile`);
                });
            }
        });
    }

    render() {
        var { getFieldDecorator } = this.props.form;
        return (
            <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '40px' }}>
                <Col span={21} offset={1} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Card style={{ display: 'inline-block', verticalAlign: "center", width: '90%', height: '90%', padding: "50px 100px 100px 100px", borderRadius: '50px', border: '1px solid #01aed8', backgroundColor: 'white' }}>
                        <Row>
                            <Col>
                                <Form layout="horizontal" onSubmit={this.onSubmit}>
                                    <Row style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Title>Edit Company Profile </Title>
                                    </Row>
                                    <Row>
                                        <Col xs={{ span: 13, offset: 1 }} sm={{ span: 11, offset: 1 }}>
                                            <FormControl
                                                getFieldDecorator={getFieldDecorator}
                                                rules={[...ValidationUtility.required("Company Name is required")]}
                                                label="Company Name"
                                                name="name">
                                                <Input />
                                            </FormControl>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={{ span: 13, offset: 1 }} sm={{ span: 11, offset: 1 }}>
                                            <FormControl
                                                getFieldDecorator={getFieldDecorator}
                                                label="Company Telephone"
                                                name="phoneNumber"
                                                rules={[...ValidationUtility.required("Company Telephone is required")]}>
                                                <Input />
                                            </FormControl>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={{ span: 13, offset: 1 }} sm={{ span: 11, offset: 1 }}>
                                            <FormControl
                                                getFieldDecorator={getFieldDecorator}
                                                label="Company Tax Registration Number"
                                                name="taxIdNumber">
                                                <Input />
                                            </FormControl>
                                        </Col>
                                    </Row>

                                    <Row type='flex' align='bottom'>
                                        <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11 }}>
                                            <FormControl
                                                getFieldDecorator={getFieldDecorator}
                                                rules={[...ValidationUtility.required('Proof of Incorporation is required')]}
                                                name="proofOfIncorporationFileBase64"
                                                label="Proof of Incorporation"
                                            >
                                                <Base64FileUploader />
                                            </FormControl>
                                        </Col>
                                        <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11 }}>
                                            <FormControl
                                                getFieldDecorator={getFieldDecorator}
                                                rules={[...ValidationUtility.required('Proof of Tax Compliance is required')]}
                                                name="proofOfTaxComplianceFileBase64"
                                                label="Proof of Tax Compliance"
                                            >
                                                <Base64FileUploader />
                                            </FormControl>
                                        </Col>
                                    </Row>

                                    <AddressForm getFieldDecorator={getFieldDecorator} /> 

                                    <Form.Item>
                                        <Row>
                                            <Col span={4} offset={1} style={{ paddingRight: '10px' }}>
                                                <FullWithButton onClick={() => { this.props.history.goBack() }} type="default">
                                                    Back
                                                </FullWithButton>
                                            </Col>
                                            <Col span={4}>
                                                <FullWithButton type="primary" htmlType="submit">
                                                    Save
                                                </FullWithButton>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        );
    }
}

const EditCompanyProfileFormWithoutRouter = Form.create<Props>({
    name: 'CompanyProfileForm',
})(EditCompanyProfileFormWithoutFormsAndRouter);

const CompanyProfileForm = withRouter(EditCompanyProfileFormWithoutRouter);

export default CompanyProfileForm;










