import { SecuredClient } from 'Core/Api/SecuredClient';
import {
    BeginFygarolPaymentModel,
    BeginGuestFygarolPaymentModel,
    CartItemModel,
    ContactUsCommandRequest,
    GetAllUsersByFilterModel,
    GetProjectListByFilterForPublicRequest,
    OrderBy, ProjectListModel, SlimUser, User,
    ApproveProjectCommandRequest, IApproveProjectCommandRequest, IPledgeModel, PledgeModel
} from 'Core/Api/Api';
import Utility from 'Core/Utility';
import ITableDataFetcher from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataFetcher';
import TableRequest from 'Core/TableUtility/Models/TableRequest';
import ITableDataResponse from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataResponse';

export class ProjectsHandler implements ITableDataFetcher<ProjectListModel> {

    props: any;
    constructor(props) {
        this.props = props;
    }

    GetData(tableRequest: TableRequest): Promise<ITableDataResponse<ProjectListModel>> {
        return this.GetProjectsForPublic(tableRequest);
    }


    GetOfferings() {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;

        const request = new GetAllUsersByFilterModel();
        request.amountPerPage = 500;
        request.orderBy = [];
        request.orderBy.push(new OrderBy({ field: 'Name', ascending: true }));

        return client.getAllUsersByFilter(request);
    }

    GetProjectsForPublic(tableRequest: TableRequest) {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;

        const request = new GetProjectListByFilterForPublicRequest(tableRequest);
        request.amountPerPage = 12;
        if (!request?.orderBy?.length) {
            request.orderBy = [];
            request.orderBy.push(new OrderBy({ field: 'displayOrder', ascending: true }));
            //request.orderBy.push(new OrderBy({ field: 'lastSubmittedDate', ascending: true }));
        }

        return client.getPublicProjectListByFilter(request);
    }

    GetProjectByIdForPublic(id: string) {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;

        const request = id;

        return client.getProjectByIdForPublic(request);
    }

    GetProjectByIdForEntrepreneur(id: string) {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;

        const request = id;

        return client.getProjectByIdForEntrepreneur(request);
    }

    AddToCart(id?: string, amount?: number) {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;

        return client.addToCart(id, amount ?? 0);
    }

    GetCart() {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;

        return client.getBasicCart();
    }


    RemoveFromCart(id?: string) {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;

        return client.removeFromCart(id);
    }

    GetUrl(currency?: string, amount?: number, cartItems?: CartItemModel[]) {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;

        var model = new BeginFygarolPaymentModel();
        model.currency = currency;
        model.amount = amount;

        model.cartItems = cartItems;

        return client.beginFygaroPayment(model);
    }

    GetGuestUrl(currency?: string, amount?: number, cartItems?: CartItemModel[], email?: string, firstName?: string, lastName?: string, phoneNumber?: string) {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;

        var model = new BeginGuestFygarolPaymentModel();
        model.currency = currency;
        model.amount = amount;

        model.email = email;
        model.firstName = firstName;
        model.lastName = lastName;
        model.phoneNumber = phoneNumber;

        model.cartItems = cartItems;

        return client.beginGuestFygaroPayment(model);
    }


    CheckPaymentStatus(orderId?: string) {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;

        return client.checkPaymentStatus(orderId);
    }

    GetPaymentInfo(orderId: string) {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;

        return client.getPaymentDetails(orderId);
    }

    RequireAdditionalDetailsForPayment(email: string) {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;

        return client.requireAdditionalDetailsForPayment(email);
    }

    ApproveProject(model: IApproveProjectCommandRequest) {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;
        const request = new ApproveProjectCommandRequest(model);

        return client.approveProject(request);
    }

    Pledge(model: IPledgeModel) {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;
        const request = new PledgeModel(model);

        return client.pledge(request);
    }

    //SendContactRequest(request: ContactUsCommandRequest) {
    //    const client = new SecuredClient();
    //    client.showLoading = false;
    //    client.handleGeneralError = true;

    //    return client.reachOut(request);
    //}
}










