import { Button, Col, Row } from 'antd';
import * as React from 'react';
import { RouteProps } from 'react-router-dom';
import styled from 'styled-components';
import { ProjectsListView } from 'components/ProjectsListView';
import { Redirect } from 'react-router';
import { BannerDiv, CenteredFormContent } from 'components/Layout/LoginLayout/LoginLayout.Style';
import PledgeForm from 'components/ProjectList/Pledge';

export interface Props extends RouteProps {
    children?: React.ReactNode;
}

export interface State {
    isConfirmed: boolean;
}

const TallCol = styled(Col)`
  height: 100%;
  min-height: 200px;
`;

export default class PledgePage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);


        this.state = { isConfirmed: false };
    }

    componentDidMount() {

    }

    render() {
        return <CenteredFormContent>
            <BannerDiv className='pledge'>
                <Row style={{ height: '100%' }}>
                    <Col sm={24} className={'bannerNav'}>
                    </Col>
                </Row>
            </BannerDiv>
            <Row type="flex">
                <Col style={{ width: '100%' }}>
                    <PledgeForm {...this.props} />
                </Col>

            </Row>

        </CenteredFormContent>;
    }
}










