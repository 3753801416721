import * as React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import AuthContext from '../../../../Context/AuthContext';
import Utility from '../../../../Core/Utility';
import AuthUser from 'Core/models/AuthUser';

export interface Props extends RouteProps {
  children?: React.ReactNode;
  requiredOperations?: string[];
  render?: any;
}

export default class GatedRoute extends React.Component<Props> {
  static contextType = AuthContext;
  context!: React.ContextType<typeof AuthContext>;

  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    const { requiredOperations, render, ...rest } = this.props;
    const user = this.context.data as AuthUser;
    return (
      <Route
        {...rest}
        render={props =>
          Utility.HasOperations(user, requiredOperations || []) ? (
            render()
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location, noPermission: true },
              }}
            />
          )
        }
      />
    );
  }
}
















