import { Button, Drawer } from 'antd';
import { CompanyProjectsHandler } from 'components/CompanyProjects/CompanyProjectsHandler';
import { ButtonGroup } from 'components/CompanyProjects/AddCompanyProjectsForm/AddCompanyProjectsForm';
import { FullWithButton } from 'components/Forms/Form.Styled';
import ISelectOption from 'components/Forms/SimpleSelect/ISelectOption';
import { IDocumentModel, Project, ProjectFullDetailsModel } from 'Core/Api/Api';
import NotificationService from 'Core/NotificationService';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { SubmitForApprovalView } from '../SubmitForApprovalView';


const ActionsFooter = styled.div`
  display: flex;
  margin: auto -24px;
  position: sticky;
  bottom: 0px;
  border-top: 1px solid black;
  background-color: white;
  min-height: 50px;
  padding-top: 20px;
  justify-items: center;


`;

interface Props {
  id?: string;
  visible?: boolean;
  onClose?: Function;
  onSubmitted?: Function;
}

export default ({ id, visible, onClose, onSubmitted }: Props) => {

  let companyProjectsHandler = new CompanyProjectsHandler({});

  const [nameFieldIsDirty, setNameFieldIsDirty] = React.useState<boolean>(false);
  const [fundingPropose, setFundingPropose] = React.useState<ISelectOption[] | undefined>();
  const [fundingTypes, setFundingTypes] = React.useState<ISelectOption[] | undefined>();
  const [category, setCategory] = React.useState<ISelectOption[] | undefined>();
  const [project, setProject] = React.useState<ProjectFullDetailsModel | undefined>();
  const [formattedOtherDocuments, setFormattedOtherDocuments] = useState<IDocumentModel[] | undefined>(undefined);
  let history = useHistory();

  useEffect(() => {
    GetCategories();
    GetFundingPurpose();
    GetFundingType()
  }, []);

  useEffect(() => {
    if (id) {
      companyProjectsHandler.GetProjectById(id).then(response => {
        if (response.project) {
          const project = response.project;
          setProject(project);
          const otherDocuments: IDocumentModel[] = [];

          project?.projectDocuments?.forEach(document => {
            const documentModel: IDocumentModel = {};
            if (document?.document) {
              documentModel.content = { name: document?.document?.name, url: document?.document?.url } as any;
            }
            documentModel.name = document?.document?.name;
            documentModel.fileType = document?.document?.type;
            documentModel.description = document?.description;
            documentModel.url = document.videoUrl;
            documentModel.id = document.id;
            documentModel.removed = false;
            otherDocuments.push(documentModel);

          });

          setFormattedOtherDocuments(otherDocuments);
        }
      }).catch(error => {
        history.push("NotFound");
      });
    }
  }, [id]);

  const GetCategories = () => {
    const categories = companyProjectsHandler.GetProjectCategory();
    setCategory(categories);
  }

  const GetFundingPurpose = () => {
    const fundingPurpose = companyProjectsHandler.GetFundingPurpose();
    setFundingPropose(fundingPurpose);
  }

  const GetFundingType = () => {
    const fundingTypes = companyProjectsHandler.GetFundingType();
    setFundingTypes(fundingTypes);
  }

  const submitForApproval = () => {
    companyProjectsHandler.submitForApproval(project?.id).then(x => {
      NotificationService.success(`Submitted ${project?.name} For Approval`, "The project has been submitted for approval. Our team will let you know if it has been approved or if there are any changes required", 10, true);
      onSubmitted?.();
    })
  }

  const DrawerTitle = () => {
    return (<>
      <h1 style={{ lineHeight: "normal" }}>Submit {project?.name} Project For Approval</h1>
      <p>Please review project information before submitting for approval.<br />
        It will be reviewed by our team before it is published.</p>
    </>)
  }

  return <Drawer width={"75%"} onClose={() => onClose?.()} visible={visible} destroyOnClose={true} title={<DrawerTitle />} >
    <SubmitForApprovalView otherDocuments={formattedOtherDocuments} fundingTypes={fundingTypes} fundingPurposes={fundingPropose} project={project} categories={category} />
    <ActionsFooter>
      <ButtonGroup style={{ width: "100%", justifyContent: "center" }}>
        <Button onClick={x => onClose?.()} type="default">
          Back
        </Button>
        <FullWithButton onClick={submitForApproval} type="primary" htmlType="submit">
          Submit
        </FullWithButton>
      </ButtonGroup>
    </ActionsFooter>
  </Drawer>;
}