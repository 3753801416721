import * as React from 'react';

import { RouteComponentProps, NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import { startCase } from 'lodash';
import { RightAlignedRow } from '../Styled/Layout/RightAlignedRow';
import TableFilterContextProvider from '../Forms/DataGrid/TableFilterContextProvider';
import { Avatar, Button, Card, Col, Icon, Input, Progress, Row } from 'antd';
import ISelectOption from '../Forms/SimpleSelect/ISelectOption';
import SimpleSelect from '../Forms/SimpleSelect';
import { Banner } from 'components/News/NewsStyled';
import { TopAvatar } from '../Styled/Layout/CenterAlignedRow';
import { ProfileContainerHolder } from 'components/BoardOfDirectors/ProfileContainerHolder';

import NoraBlake from 'assets/images/management/management_nora_blake2.png';
import Andre_Gooden from 'assets/images/management/management_Andre_Gooden.jpg';
import Doreen_ParsonsSmith from 'assets/images/management/support-Doreen_Parsons-Smith.jpg';
import Neville_Ellis from 'assets/images/management/support-Neville_Ellis.jpg';
import Suzette_McNaught from 'assets/images/management/support-Suzette_McNaught.jpg';
import Suzette_Whyte from 'assets/images/management/support-Suzette_Whyte.jpg';
import Onyka_Barrett from 'assets/images/board/board-Onyka_Barrett-Scott.jpg';
import Charmaine_Brimm from 'assets/images/management/management_charmaine_brimm.jpg';

import NoraBlakePdf from 'assets/profiles/management/nora_blake.pdf';
import Andre_GoodenPdf from 'assets/profiles/management/andre_gooden.pdf';
import Doreen_ParsonsSmithPdf from 'assets/profiles/management/doreen_parsons-smith.pdf';
import Neville_EllisPdf from 'assets/profiles/management/neville_ellis.pdf';
import Suzette_McNaughtPdf from 'assets/profiles/management/suzette_mcnaught.pdf';
import Suzette_WhytePdf from 'assets/profiles/management/suzette_whyte.pdf';
import Onyka_BarrettPdf from 'assets/profiles/management/onyka_barrett.pdf';
import Charmaine_BrimmPdf from 'assets/profiles/management/charmaine_brimm.pdf';

const { Search } = Input;



export interface Props extends RouteComponentProps {
    children?: React.ReactNode;
}

export interface State {
    team?: any[];
}

export class ManagementTeamWithoutRouter extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        this.getTeam();
    }


    getTeam() {
        this.setState({
            team: [
                { name: 'Charmaine Brimm', position: 'Chairperson', img: <TopAvatar style={{ width: '348px', height: '400px', maxWidth: '86vw' }} src={Charmaine_Brimm} shape="square" />, pdfUrl: Charmaine_BrimmPdf },
                { name: 'Nora. M. Blake, JP', position: 'Manager, JSSE', img: <TopAvatar style={{ width: '348px', height: '400px', maxWidth: '86vw' }} src={NoraBlake} shape="square" />, pdfUrl: NoraBlakePdf },
                { name: 'Neville Ellis', position: 'Manager, Marketing', img: <TopAvatar className='' style={{ width: '348px', height: '400px', maxWidth: '86vw' }} src={Neville_Ellis} shape="square" />, pdfUrl: Neville_EllisPdf },
                { name: 'Andre Gooden', position: 'Manager, Business Development', img: <TopAvatar style={{ width: '348px', height: '400px', maxWidth: '86vw' }} src={Andre_Gooden} shape="square" />, pdfUrl: Andre_GoodenPdf },
                { name: 'Suzette McNaught', position: 'Manager, Information Technology', img: <TopAvatar style={{ width: '348px', height: '400px', maxWidth: '86vw' }} src={Suzette_McNaught} shape="square" />, pdfUrl: Suzette_McNaughtPdf },
                { name: 'Doreen Parsons-Smith', position: 'Manager, Human Resources', img: <Avatar style={{ width: '348px', height: '400px', maxWidth: '86vw' }} src={Doreen_ParsonsSmith} shape="square" />, pdfUrl: Doreen_ParsonsSmithPdf },
                { name: 'Suzette Whyte', position: 'Financial Controller', img: <Avatar style={{ width: '348px', height: '400px', maxWidth: '86vw' }} src={Suzette_Whyte} shape="square" />, pdfUrl: Suzette_WhytePdf },
                { name: 'Onyka Barrett Scott', position: 'Chief Executive Officer (Pinnacle Impact International Limited)', img: <Avatar style={{ width: '348px', height: '400px', maxWidth: '86vw' }} src={Onyka_Barrett} shape="square" />, pdfUrl: Onyka_BarrettPdf },
            ]
        });
    }

    render() {
        return (
            <>
                <ProfileContainerHolder profiles={this.state.team} />
            </>
        );
    }
}

const ManagementTeam = withRouter(ManagementTeamWithoutRouter);

export default ManagementTeam;











