import * as React from 'react';
import { Row, Col, Button, Tabs, Input, Card, Divider, Avatar, Carousel, Modal } from 'antd';
import TableFilterContextProvider from 'components/Forms/DataGrid/TableFilterContextProvider';
import { DashboardViewCard } from '.';
import { RouteComponentProps, NavLink, Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import DetailsData from 'Core/models/DetailsData';
import DetailsMode from 'Core/models/DetailsMode';
import { BannerDiv, NewsClasses, DashboardTitle, STabCardDiv, WhoWeAreClasses, LessonsLearntClasses, EndorsementClasses, ProjectClasses, Page } from './DashboardView.Style';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import Title from 'antd/lib/typography/Title';
import Search from 'antd/lib/input/Search';
import { BrandedButton } from '../../Styled/Branded/BrandedButton'
import Background1 from 'assets/images/background1.png';
import World from 'assets/images/world.png';
import CSRLogo from 'assets/images/CSRLogo.png';
import AgilityImage from 'assets/images/agilitycropped.jpg';

import Group_3649 from 'assets/images/Group_3649.png';
import Group_3650 from 'assets/images/Group_3650.png';
import Group_3651 from 'assets/images/Group_3651.png';
import Group_3652 from 'assets/images/Group_3652.png';
import Group_3653 from 'assets/images/Group_3653.png';
import LogoImg from '../../../assets/images/dashboard-logo.png';
import { CenterAlignedRow } from '../../Styled/Layout/CenterAlignedRow';
import NumberFormat from 'react-number-format';
import { random, startCase } from 'lodash';
import moment from 'moment';
import { RightAlignedRow } from '../../Styled/Layout/RightAlignedRow';
import { NewsArticleRow } from 'components/News/NewsArticleRow';
import ad1 from 'assets/images/ad.png';
import { useState, useEffect } from 'react';
import LeftArrow from 'assets/images/LeftArrow.png';
import RightArrow from 'assets/images/RightArrow.png';
import lessonsLearntImage from 'assets/images/lessonslearnt.jpg';
import socialPartners from 'assets/images/socialPartners.png';
import DashboardHandler from '../DashboardHandler';
import { ProjectsHandler } from 'components/ProjectsListView/ProjectsHandler';
import { ProjectListModel } from 'Core/Api/Api';

import DonationIconPng from 'assets/images/icons/Donations.png';
import FullyFundedIconPng from 'assets/images/icons/FullyFunded.png';
import ProjectsIconPng from 'assets/images/icons/Projects.png';
import ProjectManagementIconPng from 'assets/images/icons/ProjectManagement.png';

import { RichTextViewer } from '../../Forms/RichTextViewer';

//Social Partners
import UWJLogo from 'assets/images/social-partners/UnitedWay.jpg';
import UWJLogoSocial from 'assets/images/social-partners/United_Way_of_Jamaica_logo_vector.png';
import PIOJLogo from 'assets/images/social-partners/PIOJ-High-res.png';
import PSOJLogo from 'assets/images/social-partners/PSOJ-high-res-logo.png';
import MICAFLogo from 'assets/images/social-partners/MICAF-logo.png';
import JNFoundationLogo from 'assets/images/social-partners/JN-foundation-horizontal.png';
import BritishCouncilLogo from 'assets/images/social-partners/british-council.png';
import SALogo from 'assets/images/social-partners/SA logo.png';

//const { TabPane } = Tabs;
//const [showArrows, setShowArrows] = useState(false);
//const [divRef, setDivRef] = useState<HTMLDivElement | null>(null);


export interface Props extends RouteComponentProps {
    children?: React.ReactNode;
}

export interface State {
    news?: any[];
    projects?: ProjectListModel[];
    endorsements?: any[];
    lessonsLearnt?: any[];
    numberOfProjects?: number;
    totalDonations?: number;
    numberOfFundedProjects?: number;
    mainImageUrl?: string;
    topleftImageUrl?: string;
    toprightImageUrl?: string;
    bottomleftImageUrl?: string;
    bottomrightImageUrl?: string;

    donationAbr?: string;
    projectsAbr?: string;
    fundedProjectsAbr?: string;

    formattedDonations?: string;
    formattedProjects?: string;
    formattedFundedProjects?: string;

    socialPartners?: any[];
    showLoadMessageModal: boolean;

}

export class DashboardViewWithoutRouter extends React.Component<Props, State> {
    handler: DashboardHandler;
    projectsHandler: ProjectsHandler;

    constructor(props: Props) {
        super(props);

        this.state = {
            showLoadMessageModal: false,
        };
        this.handler = new DashboardHandler(props);
        this.projectsHandler = new ProjectsHandler(props);

    }

    useEffect() {

    }

    componentDidMount() {
        this.getNews();
        this.getProjects();
        this.getEndorsements();
        this.getProjectTrackerInfo();
        this.getLessons();
        this.updateBanner();
        this.getSocialPartnes();
    }

    handleModalCancel() {
        this.setState({
            showLoadMessageModal: false,
        });
    };

    getProjectTrackerInfo() {

        this.handler.GetProjectTrackerInfo().then(x => {
            this.setState({
                totalDonations: x.totalDonations,
                formattedDonations: this.formatNumber(x.totalDonations),
                numberOfProjects: x.numberOfProjects,
                formattedProjects: this.formatNumber(x.numberOfProjects),
                numberOfFundedProjects: x.numberOfFundedProjects,
                formattedFundedProjects: this.formatNumber(x.numberOfFundedProjects),
            })
            this.animateProjectTracker("total-donations", 0, this.state.formattedDonations, 5000);
            this.animateProjectTracker("total-projects", 0, this.state.formattedProjects, 2000);
            this.animateProjectTracker("total-fundedProjects", 0, this.state.formattedFundedProjects, 2000);

        });
    }

    getNews() {
        this.setState({
            news: [{ title: 'Acrecent paves the way for regional impact investing', url: "#" },
            { title: 'Social development expert: Caribbean must redefine climate justice', url: "#" },
            { title: 'JSSE Salutes Dr. Trisha Gayle for her philanthropy in the Social Sector', url: "#" },
            { title: 'PIOJ Best Practices Symposium', url: "#" },
            { title: 'The Jamaica Stock Exchange & You podcast is now available', url: "#" },
            { title: 'The Jamaica Stock Exchange & You podcast is now available', url: "#" },
            { title: 'The Jamaica Stock Exchange & You podcast is now available', url: "#" },
            ]
        });
    }
    /*
    <img className={'socialLogos'} src={BritishCouncilLogo} alt="British Council" />
                                    <img className={'socialLogos'} src={JNFoundationLogo} alt="JN Foundation" />
                                    <img className={'socialLogos'} src={UWJLogoSocial} alt="United Way" />
                                    <img className={'socialLogos'} src={PIOJLogo} alt="PIOJ" />
                                    <img className={'socialLogos'} src={PSOJLogo} alt="PSOJ" />
                                    <img className={'socialLogos'} src={MICAFLogo} alt="MICAF" />
                                    <img className={'socialLogos'} src={SALogo} alt="Software Architects" />

    */
    getSocialPartnes() {
        this.setState({
            socialPartners: [
                { img: JNFoundationLogo, alt: "JN Foundation" },
                { img: BritishCouncilLogo, alt: "British Council" },
                { img: UWJLogoSocial, alt: "United Way" },
                { img: PIOJLogo, alt: "PIOJ" },
                { img: PSOJLogo, alt: "PSOJ" },
                { img: MICAFLogo, alt: "MICAF" },
                { img: SALogo, alt: "Software Architects" }
            ]
        });
    }

    getLessons() {
        this.setState({
            lessonsLearnt: [{ text: 'The legal definition of a charitable organization (and of charity) varies between countries and in some instances regions of the country. The regulation, the tax treatment.', from: "Jonathan Richards", occupation: "Zion Eye Media", img: '#' },
            { text: 'The legal definition of a charitable organization (and of charity) varies between countries and in some instances regions of the country. The regulation, the tax treatment.', from: "Jonathan Richards", occupation: "Zion Eye Media", img: '#' },
            { text: 'The legal definition of a charitable organization (and of charity) varies between countries and in some instances regions of the country. The regulation, the tax treatment.', from: "Jonathan Richards", occupation: "Zion Eye Media", img: '#' },
            { text: 'The legal definition of a charitable organization (and of charity) varies between countries and in some instances regions of the country. The regulation, the tax treatment.', from: "Jonathan Richards", occupation: "Zion Eye Media", img: '#' },
            ]
        });
    }

    getEndorsements() {
        this.setState({
            //im just doing an example of how the endorsements will be spliced

            endorsements: [{
                id: 1,
                img: Group_3649,
                text1: 'On behalf of the Planning Institute of Jamaica (PIOJ), I extend heartfelt congratulations to the Jamaica Stock Exchange(JSE) for the establishment and launch of the Jamaica Social Stock Exchange(JSSE).The creation of this platform now provides the actors within Jamaica�s Social Enterprise(SE) sector, with access to additional resources that will enable them to expand and scale up their businesses, in order to meet the increased demand for their goods and services.Research shows that the SE sector grows at a rate that is ten times faster than that of regular MSMEs, thereby signaling its usefulness as a high growth model.In this regard, the PIOJ commends the JSE, not only for their recognition on the importance of this sector, but importantly, their proactivity in contributing to its growth with the development of the JSSE.The Social Enterprise sector is now poised to contribute not only to the achievement of the Government�s �5 in 4� Growth Agenda, but also to the achievement of Vision 2030 Jamaica and the Social Agenda in alignment with the UN Sustainable Development Goals (SDGs).',
                text2: '',
                shortText: true,
                logo: PIOJLogo
            },
            {
                id: 2,
                img: Group_3650,
                text1: 'The United Way of Jamaica congratulates the Jamaica Stock Exchange on its forward-thinking thrust to introduce a Social Stock Exchange. Mobilizing capital for social good is something the United Way of Jamaica has been doing successfully for the past thirty-three years. We have systems in place for this and are delighted to partner with the Jamaica Social Stock Exchange (SSE) to ensure its realization and success.',
                text2: 'By partnering with the JSSE, the United Way of Jamaica will help to ensure that the creation of this new facility, designed to drive social enterprises and deliver social benefits, will positively impact the lives of everyday Jamaicans, build stronger communities and a stronger, more equitable Jamaica.',
                shortText: true,
                logo: UWJLogo
            },
            {
                id: 3,
                img: Group_3651,
                text1: 'The United Way of Jamaica congratulates the Jamaica Stock Exchange on its forward-thinking thrust to introduce a Social Stock Exchange. Mobilizing capital for social good is something the United Way of Jamaica has been doing successfully for the past thirty-three years. We have systems in place for this and are delighted to partner with the Jamaica Social Stock Exchange (SSE) to ensure its realization and success.',
                text2: 'By partnering with the JSSE, the United Way of Jamaica will help to ensure that the creation of this new facility, designed to drive social enterprises and deliver social benefits, will positively impact the lives of everyday Jamaicans, build stronger communities and a stronger, more equitable Jamaica.',
                shortText: true,
                logo: UWJLogo
            },
            {
                id: 4,
                img: Group_3652,
                text1: 'The United Way of Jamaica congratulates the Jamaica Stock Exchange on its forward-thinking thrust to introduce a Social Stock Exchange. Mobilizing capital for social good is something the United Way of Jamaica has been doing successfully for the past thirty-three years. We have systems in place for this and are delighted to partner with the Jamaica Social Stock Exchange (SSE) to ensure its realization and success.',
                text2: 'By partnering with the JSSE, the United Way of Jamaica will help to ensure that the creation of this new facility, designed to drive social enterprises and deliver social benefits, will positively impact the lives of everyday Jamaicans, build stronger communities and a stronger, more equitable Jamaica.',
                shortText: true,
                logo: UWJLogo
            }
            ]
        });
    }

    formatShortText(text, part, remaining?) {
        if (part == 1) {
            if (text.length > 500) {
                return text.slice(0, 500) + "...";
            } else {
                return text;
            }
        } else if (part == 2) {
            if (text.length > remaining && remaining > 0) {
                return text.slice(0, remaining) + "...";
            } else {
                return '';
            }
        }

    }

    toggleText(item) {
        this.setState(prevState => {
            const updatedEndorsements = prevState.endorsements?.map(endorsement => {
                if (endorsement.id === item.id) {
                    return { ...endorsement, shortText: !endorsement.shortText };
                }
                return endorsement;
            });
            return { endorsements: updatedEndorsements };
        });
    }

    getProjects() {
        this.projectsHandler.GetProjectsForPublic({}).then(x => {
            let randomProjectList: ProjectListModel[] | undefined = [];
            const nonFullFunded = x.results?.filter(x => (x.amountReceived ?? 0) <= (x.amountRequested ?? 0));
            if (nonFullFunded?.length && nonFullFunded?.length > 4) {
                randomProjectList.push(nonFullFunded?.splice(random(0, nonFullFunded.length - 1), 1)[0]!)
                randomProjectList.push(nonFullFunded?.splice(random(0, nonFullFunded.length - 1), 1)[0]!)
                randomProjectList.push(nonFullFunded?.splice(random(0, nonFullFunded.length - 1), 1)[0]!)

            } else {
                randomProjectList = nonFullFunded?.splice(0, 3);
            }

            this.setState({ projects: randomProjectList });

        })
    }

    formatNumber(number) {
        if (number >= 1000000000) {
            return Math.round(number / 1000000000).toString();
        } else if (number >= 1000000) {
            return Math.round(number / 1000000).toString();
        } else if (number >= 1000) {
            return Math.round(number / 1000).toString();
        } else {
            return Math.round(number).toString();
        }
    }

    formatAbbreviation(number) {

        if (number >= 1000000000) {
            return 'B+';
        } else if (number >= 1000000) {
            return 'M+';
        } else if (number >= 1000) {
            return 'K+';
        } else {
            return '';
        }
    }

    animateProjectTracker(id, start, end, duration) {
        var obj = document.getElementById(id) ?? null;
        if (start === end) return;
        if (obj) {
            obj.innerHTML = start;
        }
        var range = end - start;
        var current = start;
        var increment = end > start ? 1 : -1;
        var stepTime = Math.abs(Math.floor(duration / range));
        var timer = setInterval(function () {
            current += increment;
            if (obj) {
                obj.innerHTML = current;
            }
            if (current == end) {
                clearInterval(timer);
            }
        }, stepTime);
    }


    updateBanner() {
        this.handler.GetBanner().then(response => {
            if (response.images?.mainImageFileId != null) {
                this.setState({ mainImageUrl: "/api/file/" + response.images?.mainImageFileId + "/" + response.images?.mainImageFileName })
            } else {
                this.setState({ mainImageUrl: Group_3649 })
            }
            if (response.images?.topLeftImageFileId != null) {
                this.setState({ topleftImageUrl: "/api/file/" + response.images?.topLeftImageFileId + "/" + response.images?.topLeftImageFileName })
            } else {
                this.setState({ topleftImageUrl: Group_3652 })
            }
            if (response.images?.topRightImageFileId != null) {
                this.setState({ toprightImageUrl: "/api/file/" + response.images?.topRightImageFileId + "/" + response.images?.topRightImageFileName })
            } else {
                this.setState({ toprightImageUrl: Group_3650 })
            }
            if (response.images?.bottomLeftImageFileId != null) {
                this.setState({ bottomleftImageUrl: "/api/file/" + response.images?.bottomLeftImageFileId + "/" + response.images?.bottomLeftImageFileName })
            } else {
                this.setState({ bottomleftImageUrl: Group_3653 })
            }
            if (response.images?.bottomRightImageFileId != null) {
                this.setState({ bottomrightImageUrl: "/api/file/" + response.images?.bottomRightImageFileId + "/" + response.images?.bottomRightImageFileName })
            } else {
                this.setState({ bottomrightImageUrl: Group_3651 })
            }

        })
    }

    render() {
        const responsiveSettings = [
            {
                breakpoint: 768, 
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 1350,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 0,
                settings: {
                    slidesToShow: 3,
                },
            },
        ];
        return (
            <Page>
                <TableFilterContextProvider>
                    <Modal className={'defaultModals'} 
                        destroyOnClose={true}
                        visible={this.state.showLoadMessageModal}
                        onCancel={e => this.setState({showLoadMessageModal : false})}
                        footer={[
                            <Button key="ok" type='primary' onClick={() => this.handleModalCancel()}>
                                <strong>OK</strong>
                            </Button>
                        ]}
                    >
                        <Title level={3}>DONATE WITHOUT AN ACCOUNT</Title>
                        <ol>
                            <li>Click <b><i>Donate</i></b> on a specific project</li>
                            <li>Click <b><i>View your Cart or My Cart</i></b> at the top of the page</li>
                            <li>Scroll down to <b><i>donate without an account</i></b></li>
                            <li>Add the <b><i>donation amount</i></b></li>
                            <li>Enter your <b><i>email address, first and last name, and phone number</i></b></li>
                            <li><b><i>Read Privacy Notice</i></b></li>
                            <li>Click <b><i>Continue</i></b></li>
                            <li>Click <b><i>Donate Now!</i></b></li>
                        </ol>
                    </Modal>
                    <Row style={{ backgroundColor: '#ffff' }}>
                        <Row>
                            <Col xs={{ span: 24 }} lg={{ span: 24 }} style={{ maxWidth: '100vw' }}>
                                <BannerDiv>
                                    <Row style={{ height: '100%', marginRight: '0px' }} gutter={[10, 0]}>
                                        <Col xs={{ span: 24 }} sm={{ span: 12 }} style={{ height: '100%' }}>
                                            <Row>
                                                <Col span={24}>
                                                    <img src={this.state.mainImageUrl} style={{ width: '98.2%', height: '98.2%' }} />
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col xs={{ span: 24 }} sm={{ span: 12 }} style={{ height: '100%' }}>
                                            <Row style={{ height: '100%' }} gutter={[10, 10]}>
                                                <Col span={12} style={{ height: '100%' }}>
                                                    <img src={this.state.topleftImageUrl} style={{ width: '100%', height: '100%' }} />
                                                </Col>
                                                <Col span={12} style={{ height: '100%' }}>
                                                    <img src={this.state.toprightImageUrl} style={{ width: '100%', height: '100%' }} />
                                                </Col>
                                                <Col span={12} style={{ height: '100%' }}>
                                                    <img src={this.state.bottomleftImageUrl} style={{ width: '100%', height: '100%' }} />
                                                </Col>
                                                <Col span={12} style={{ height: '100%' }}>
                                                    <img src={this.state.bottomrightImageUrl} style={{ width: '100%', height: '100%' }} />
                                                </Col>
                                            </Row>
                                            {/* <Row style={{ height: '50%', paddingTop: '4px' }}>
                                            </Row> */}
                                        </Col>
                                    </Row>
                                </BannerDiv>
                            </Col>
                        </Row>
                        {/* PROJECT HERE */}
                        <ProjectClasses>
                            <Row>
                                <Col className={'projects'} sm={{ span: 16 }}>
                                    <Col xs={{ span: 12 }}>
                                        <Title style={{ width: 'fit-content', color: '#133b3a' }}>Projects</Title>
                                        <Col style={{ width: '2vw', height: '0.6vh', backgroundColor: '#7bc257', marginTop: '-2vh' }} xs={{ span: 1 }} />
                                    </Col>
                                    <Col style={{ position: "relative" }} xs={{ span: 12 }}>
                                        <Button className={'viewAllCauses'}> <Link to='/projects'>View All Causes</Link></Button>
                                    </Col>
                                    <Row className="innerProject">
                                        {this.state.projects?.map(item => {
                                            return <Col xs={8} className={'projectItems'}>
                                                <Card className={'projectItem'} >
                                                    <Link to={`/projects/view/${item.id}`} onClick={() => window.scrollTo(0, 0)}>
                                                        <Col className={'projectImage'} xs={{ span: 24 }}>
                                                            <div className="center-avatar"> <Avatar src={item.thumbnailFileUrl} shape="square" />
                                                            </div>
                                                        </Col>
                                                        <Col className={'projectFooter'} xs={{ span: 24 }}>
                                                            <p className={'projectTitle'}>{item.name}</p>
                                                            <p className={'projectSubtitle'}>{item.subTitle}</p>
                                                            {(item.amountReceived ?? 0) >= (item.amountRequested ?? 0) ?
                                                                <div className={'goal'}>JMD$<NumberFormat thousandSeparator="," value={item.amountReceived} displayType="text" /> raised <div className={'green'}>of $<NumberFormat thousandSeparator="," value={item.amountRequested} displayType="text" /></div></div>
                                                                :
                                                                <div className={'goal'}><div className={'green'}>JMD$<NumberFormat thousandSeparator="," value={item.amountReceived} displayType="text" /> raised</div> of $<NumberFormat thousandSeparator="," value={item.amountRequested} displayType="text" /></div>
                                                            }
                                                        </Col>
                                                    </Link>
                                                </Card>
                                            </Col>
                                        })}
                                    </Row>
                                </Col>
                                <Col style={{ paddingTop: '2vh', paddingLeft: '2.25vw' }} sm={{ span: 24 }} md={{ span: 16 }} xl={{ span: 8 }}>
                                    <Row className='projectTrackerHeader'>
                                        <Col span={24}>
                                            <Col style={{ width: '2vw', height: '0.6vh', backgroundColor: '#ffff', marginTop: '2vh', marginLeft: '1vw' }} xs={{ span: 1 }} />
                                            <Title style={{ paddingLeft: '1vw', paddingTop: '3vh', color: '#ffff' }}>Project Tracker</Title>
                                        </Col>
                                    </Row>
                                    <Row className='projectTrackerBody'>
                                        
                                        <Col className='details' sm={{ span: 24 }} style={{  display: 'flex', alignItems: 'center'}}>
                                           <Col xs={{ span: 3 }} sm={{ span: 5 }} >
                                            <Avatar src={DonationIconPng} size={65} shape="square" />
                                        </Col> 
                                        <Col xs={{ span: 11 }} sm={{ span: 10 }} >
                                                <p className='number' style={{ margin: 0 }}>
                                                    <span>$<span id="total-donations"></span>{this.formatAbbreviation(this.state.totalDonations)}</span>
                                                </p>
                                            </Col>
                                            <Col xs={{ span: 10 }}sm={{ span: 9 }}>
                                                <p className='amount' style={{ margin: 0 }}> Total Donations!</p>
                                            </Col>
                                        </Col>
                                        <Col style={{ width: '100%' }} sm={{ span: 24 }}><hr className={'hr'}></hr></Col>
                                        
                                        <Col className='details' sm={{ span: 24 }} style={{  display: 'flex', alignItems: 'center'}}>
                                            <Col xs={{ span: 3 }} sm={{ span: 5 }} >
                                            <Avatar src={ProjectsIconPng} size={65} shape="square" />
                                        </Col>
                                        <Col xs={{ span: 11 }} sm={{ span: 10}}>
                                                <p className='number' style={{ margin: 0 }}>
                                                    <span><span id="total-projects"></span>{this.formatAbbreviation(this.state.numberOfProjects)}</span>
                                                </p>
                                            </Col>
                                            <Col xs={{ span: 10 }} sm={{ span: 9 }} style={{ alignItems: 'center'}}>
                                                <p className='amount' style={{ margin: 0 }}> Projects!</p>
                                            </Col>
                                        </Col>
                                        <Col style={{ width: '100%' }} sm={{ span: 24 }}><hr className={'hr'}></hr></Col>
                                        
                                        <Col className='details' sm={{ span: 24 }} style={{  display: 'flex', alignItems: 'center'}}>
                                            <Col xs={{ span: 3 }} sm={{ span: 5 }}>
                                            <Avatar src={FullyFundedIconPng} size={65} shape="square" />
                                            </Col>
                                        <Col xs={{ span: 11 }} sm={{ span: 10 }} >
                                                <p className='number' style={{ margin: 0 }}>
                                                    <span><span id="total-fundedProjects"></span>{this.formatAbbreviation(this.state.numberOfFundedProjects)}</span>
                                                </p>
                                            </Col>
                                            <Col xs={{ span: 10 }} sm={{ span: 9 }} >
                                                <p className='amount' style={{ margin: 0 }}> Funded Projects!</p>
                                            </Col>
                                        </Col>
                                        <Col style={{ width: '100%' }} xs={{ span: 24 }}><hr className={'hr'}></hr></Col>
                                    </Row>
                                </Col>

                            </Row>
                        </ProjectClasses>
                    </Row>
                    <Row >
                        <WhoWeAreClasses>
                            <Carousel
                                arrows={true}
                                autoplay={true}
                                autoplaySpeed={1900}
                                draggable={true}>
                                <Row className='whoWeAre'>
                                    <Col className='imageDiv' xs={{ span: 24 }} md={{ span: 12 }}>
                                        <img src={World} />
                                    </Col>
                                    <Col className='textDiv' xs={{ span: 24 }} md={{ span: 12 }}>
                                        <div className={'background-image'}>
                                            <Title style={{ color: '#269b48' }}>Who We Are</Title>
                                            <Col style={{ width: '2vw', height: '0.6vh', backgroundColor: '#7bc257', marginTop: '-2vh' }} xs={{ span: 1 }} />
                                            <div className="who-we-are-text">
                                                <div>
                                                    <p>The Jamaica Social Stock Exchange, JSSE, seeks to engage the entire
                                                        Jamaican economy in promoting the Social Capital Market, believing
                                                        that true development of any country happens in all areas of life
                                                        and facets of society.
                                                    </p>
                                                    <p>The JSSE is a state-of-the-art virtual environment and a venue for
                                                        the process where the "socially responsible investor(s)", people
                                                        interested in contributing and improving the quality of life in
                                                        Jamaica, are enabled to invest in wholesome projects that require
                                                        public funding, and by extension promote the socio-cultural economy
                                                        and the protection of the physical environment.

                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className={'CSR csr-image background-tint'}>
                                    <Col xs={{ span: 24, order: 2 }} sm={{ span: 15, order: 1 }} >
                                        <Title style={{ color: '#fff' }}>Corporate Social Responsibility (CSR)</Title>
                                        <Col style={{ width: '2vw', height: '0.6vh', backgroundColor: '#7bc257', marginTop: '-2vh' }} xs={{ span: 1 }} />
                                        <div className="csr-text">
                                            <div >
                                                <p>The JSSE, is a social program driven by the JSE&#39;s own practice of
                                                    Corporate Social Responsibility (CSR), in partnership with
                                                    companies listed on the JSE, international development partners,
                                                    local donors and donors in the Jamaican Diaspora. The JSSE,
                                                    through this initiative, will demonstrate a practical application
                                                    of the five (5) P&#39;s of the United Nations Sustainable Development
                                                    Goals (SDGs) with a People-Centered approach of People, Planet,
                                                    Prosperity, Partnerships and Peace.
                                                </p>
                                                <p>Believing in the Value proposition - &#34;Sustainable Growth in the
                                                    Social Sector is good for business&#34;. The JSSE will be
                                                    implementing this socially-oriented initiative in Phases.
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className={'csr-Logo'} xs={{ span: 24, order: 1 }} sm={{ span: 9, order: 2 }} style={{}} >
                                        <img src={CSRLogo} style={{ maxWidth: '100%' }} />
                                    </Col>
                                </Row>
                                <Row className={'jSEAgility'}>
                                    <Col className={'agility-Logo'} md={{ span: 9 }} style={{}} >
                                        <img src={AgilityImage} />
                                    </Col>
                                    <Col className={'agility'} md={{ span: 15 }} style={{ paddingTop: '40px' }}>
                                        <Title style={{ color: '#269b48' }}>JSSE&#39;s Agility</Title>
                                        <Col style={{ width: '2vw', height: '0.6vh', backgroundColor: '#7bc257', marginTop: '-2vh' }} xs={{ span: 1 }} />
                                        <div className="agility-text">
                                            <div >
                                                <p>Globally, businesses are being responsive to the fact that non-financial
                                                    factors, especially socio-cultural and environmental impact, do in fact,
                                                    affect the long-term returns. Consequently, the consuming public and
                                                    investors, are now requiring more people and businesses to conscientiously
                                                    ponder such considerations, especially considering how general business
                                                    practices are pursued. It is in response to this that the Jamaica Stock
                                                    Exchange seeks to introduce, the Jamaica Social Stock Exchange, JSSE.
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Carousel>
                        </WhoWeAreClasses>
                    </Row>
                    <Row>
                        <CenterAlignedRow>
                            <Col xs={{ span: 24 }} style={{}}>
                                <Title className={'sectionTitle'}>News</Title>
                                <Col span={1} style={{ height: '0.6vh', backgroundColor: '#7bc257', left: '46.7vw', marginTop: '-2vh' }}  ></Col>
                            </Col>
                        </CenterAlignedRow>
                        <NewsArticleRow showDate={false} />

                        {/* NEWS HERE */}
                    </Row>
                    <Row>
                        <EndorsementClasses>
                            <Row className='endorsementRow'>
                                <Col md={{ span: 12 }} sm={{ span: 24 }} className={'endorsements'}>
                                    <Title style={{ color: '#fff' }}>Endorsements</Title>
                                    <Col style={{ width: '2vw', height: '0.6vh', backgroundColor: '#7bc257', marginTop: '-2vh' }} xs={{ span: 1 }} />
                                    <div className="endorsementItems">
                                        <Carousel
                                            arrows={true}
                                            autoplay autoplaySpeed={1900}
                                            draggable={true}
                                            infinite={true}
                                        >
                                            {this.state.endorsements?.map(item => {
                                                return <div key={item.id}>
                                                    <Col xs={{ span: 20, offset: 2 }} sm={{ span: 11, offset: 0 }} style={{ height: '290px', display: 'block' }} >
                                                        <img src={item.img} style={{ width: '100%' }} />
                                                        <br />
                                                        {item.logo === PIOJLogo ?
                                                            <img src={item.logo} style={{ width: '50%' }} />
                                                            :
                                                            <img src={item.logo} style={{ width: '40%' }} />
                                                        }
                                                    </Col>
                                                    {item.shortText ?
                                                        <Col className={'endorsementText'} xs={{ span: 20, offset: 2 }} sm={{ span: 11, offset: 0 }} style={{ height: '400px', display: 'block' }} >
                                                            <RichTextViewer value={this.formatShortText(item.text1, 1)} />
                                                            <br></br>
                                                            <RichTextViewer value={this.formatShortText(item.text2, 2, 500 - item.text1.length)} />
                                                            <Button className='readMoreButton' onClick={() => this.toggleText(item)}>Read More</Button>
                                                        </Col>
                                                        :
                                                        <Col className={'endorsementText'} xs={{ span: 20, offset: 2 }} sm={{ span: 11, offset: 0 }} style={{ height: '400px' }} >
                                                            <RichTextViewer value={item.text1} />
                                                            <br></br>
                                                            <RichTextViewer value={item.text1} />
                                                            <Button className='readMoreButton' onClick={() => this.toggleText(item)}>Read Less</Button>
                                                        </Col>
                                                    }

                                                </div>
                                            })}
                                        </Carousel>
                                    </div>
                                </Col>
                                <Col md={{ span: 12 }} sm={{ span: 24 }} className={'ad'}>
                                    <img src={ad1} width='100%' />
                                </Col>
                            </Row>
                        </EndorsementClasses>
                    </Row>
                    {/*
                    <Row>
                        <Col>
                            <LessonsLearntClasses>
                                <Col sm={{ span: 24 }}>
                                    <Col sm={{ span: 12 }} className={'ad'}>
                                        <img src={lessonsLearntImage} style={{ maxWidth: '100vw' }} />
                                    </Col>
                                    <Col sm={{ span: 12 }} className={'lessonsLearnt'}>
                                        <Title style={{ color: '#269b48' }}>Lessons Learnt</Title>
                                        <Col style={{ width: '2vw', height: '0.6vh', backgroundColor: '#7bc257', marginTop: '-2vh', right: '-17vw' }} xs={{ span: 1 }} />
                                        <Carousel
                                            arrows={true}
                                            autoplay autoplaySpeed={7000}
                                            draggable={true}>
                                            {this.state.lessonsLearnt?.map(item => {
                                                return <div className={'lessonsItem'}>
                                                    <Col className={'lessonsText'} xs={{ span: 24 }} >
                                                        <p>{item.text}</p>
                                                    </Col>
                                                    <Col className={'who'} xs={{ span: 24 }}>
                                                        <Col className={'whoAvatar'} xs={{ span: 12 }}>
                                                            <Avatar src={item.img} size={70} />
                                                        </Col>
                                                        <Col className={'whoDetails'} xs={{ span: 12 }}>
                                                            <p className={'whoFrom'}>{item.from}</p>
                                                            <p className={'whoOccu'}>{item.occupation}</p>
                                                        </Col>
                                                    </Col>
                                                </div>
                                            })}
                                        </Carousel>
                                    </Col>
                                </Col>

                            </LessonsLearntClasses>
                        </Col>
                    </Row>
                    */}

                    <Row>
                        <Col sm={{ span: 24 }} >
                            <Col sm={{ span: 24 }} className={'socialPartners'}>
                                <Title style={{ color: '#133b3a' }}>Social Partners</Title>
                                <Col
                                    style={{
                                        width: '2vw',
                                        height: '0.6vh',
                                        backgroundColor: '#1d95d3',
                                        marginTop: '-2vh',
                                        right: '-41.8vw',
                                    }}
                                    xs={{ span: 1 }}
                                />
                            </Col>
                            <Col sm={{ span: 22, offset: 1 }} >
                                <Carousel
                                    arrows={false}
                                    autoplay={true}
                                    autoplaySpeed={0}
                                    speed={1500}
                                    draggable={true}
                                    slidesToShow={3}
                                    swipeToSlide={true}
                                    touchMove={true}
                                    infinite={true}
                                    cssEase='Linear'
                                    responsive={responsiveSettings}
                                >
                                    {this.state.socialPartners?.map(item => (
                                        <div key={item.id}>
                                            <img className ='socialLogos' src={item.img} alt={item.alt} />
                                        </div>
                                    ))}
                                </Carousel>
                            </Col>
                        </Col>
                    </Row>


                </TableFilterContextProvider >
            </Page >
        );
    }
}

const DashboardView = withRouter(DashboardViewWithoutRouter);

export default DashboardView;












