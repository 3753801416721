import { Button, Drawer, Empty } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { DocumentModel, IDocumentModel } from 'Core/Api/Api';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AddDocumentForm } from './AddDocumentForm';
import { DocumentItemDisplay } from './DocumentItemDisplay';

const DocumentsListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

interface Props {
  onChange?: Function;
  value?: IDocumentModel[];
}

export default ({ value, onChange }: Props) => {

  const [showAddDocumentDrawer, setShowAddDocumentDrawer] = useState(false);
  const [editedDocument, setEditedDocument] = useState<IDocumentModel | undefined>(undefined);


  const handleOnDelete = (document: IDocumentModel) => {
    document.removed = true;
    onChange?.(value)

  }

  const handleOnEdit = (document: IDocumentModel) => {
    setEditedDocument(document);
    setShowAddDocumentDrawer(true);
  }

  const handleEditedDocument = (documentModel: IDocumentModel) => {
    let newValues = value?.filter(x => x != editedDocument) ?? [];
    newValues = [...newValues, documentModel];
    onChange?.(newValues);
    setEditedDocument(undefined);
    setShowAddDocumentDrawer(false);
  }


  const handleAddingFile = (documentModel: IDocumentModel) => {
    let newValue = [...value ?? [], documentModel];
    onChange?.(newValue);
    setEditedDocument(undefined);
    setShowAddDocumentDrawer(false);
  }

  return (<>
    <DocumentsListContainer>
      {!value?.length && <Empty description={"No Documents"}></Empty>}
      {value?.map(x => (<DocumentItemDisplay onDelete={handleOnDelete} onEdit={handleOnEdit} document={x} />))}
    </DocumentsListContainer>
    <Button onClick={e => setShowAddDocumentDrawer(true)}>Add Document</Button>
    <Drawer width={"50%"} destroyOnClose={true} onClose={e => (setEditedDocument(undefined), setShowAddDocumentDrawer(false))} visible={showAddDocumentDrawer} title="Add Document">
      <AddDocumentForm value={editedDocument} onCancel={e => (setEditedDocument(undefined), setShowAddDocumentDrawer(false))} onAddDocument={handleAddingFile} onEditDocument={handleEditedDocument} />
    </Drawer>
  </>);
}