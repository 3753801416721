export default class JointHolderModel {
    constructor() {
        this.clientType = "";
        this.firstName = "";
        this.lastName = "";
        this.dob = null;
        this.trn = "";
    }

    clientType: string;
    firstName: string;
    lastName: string;
    trn: string;
    dob: Date | null;
}















