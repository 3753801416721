import * as React from 'react';
import { Row, Col, Icon } from 'antd';
import { AppRoutes } from 'Core/AppRoutes';
import { CenteredContent, MainLogo, RegisterWithBackground, RegisterContent } from './RegisterLayout.Style';
import TopBar from 'components/Layout/LandingLayout/TopBar';
import { RegisterFooterLayout } from "../RegisterFooterLayout";
import { FooterLayout } from '../FooterLayout';
export interface Props {
    children?: React.ReactNode;
}

export interface State { }

export default class RegisterLayout extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <RegisterWithBackground className="react-transition fade-in" style={{ animationDuration: '750ms' }}>
                <TopBar menu={AppRoutes} />
                <CenteredContent>
                    <Row type="flex" align="middle" justify="center">
                        <Col style={{ backgroundColor: 'white', boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)', maxWidth: '90%', paddingBottom: '2vh', paddingTop: '2vh' }}>
                            <RegisterContent>
                                {this.props.children}
                            </RegisterContent>
                        </Col>
                    </Row>

                </CenteredContent>
                <FooterLayout />
            </RegisterWithBackground>
        );
    }
}










