import * as React from 'react';

export interface Props {
  children?: React.ReactNode;
  condition?: boolean | any;
}

export interface State {}

export default class ShowIfTrue extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return <>{this.props.condition && this.props.children}</>;
  }
}
