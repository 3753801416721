import * as React from 'react';
import { UpdatableContext } from 'Context/UpdatableContext';
import TableRequest from 'Core/TableUtility/Models/TableRequest';

const data = {};
const Update = () => {};

const TableFilterContext = React.createContext<UpdatableContext<TableRequest>>({ data, Update });

export default TableFilterContext;
















