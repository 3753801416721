import { ConfirmEmailFormModel } from './Models/ConfirmEmailFormModel';
import { SecuredClient } from '../../../Core/Api/SecuredClient';
import { ConfirmRegistrationModel, SlimUser } from '../../../Core/Api/Api';

export class ConfirmEmailFormHandler {
  props: any;
  constructor(props) {
    this.props = props;
  }

  ConfirmEmail(email: string, token: string): Promise<SlimUser | undefined> {
    const client = new SecuredClient();
      const request = new ConfirmRegistrationModel();
    request.init({ emailAddress: email, token: token });
    client.showLoading = true;
    client.handleGeneralError = true;
    return client.confirmRegistration(request).then(x => {
      // the user is logged in. store access token
      // and user object for reference
      // if (x.accessToken && x.loggedIn) {
      //   //sessionStorage.setItem('token', x.accessToken);
      // }

      if (x.user) {
        sessionStorage.setItem('user', JSON.stringify(x.user));
      }
      return x.user;
    });
  }
}
















