import React, { SyntheticEvent } from 'react';
import { Row, Form, Input, Button, Col } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import FormControl from 'components/Forms/FormControl';
import FormItem from 'antd/lib/form/FormItem';
import PasswordResetHandler from './PasswordResetHandler';
import { IResetPasswordCommandRequest, IRequestPasswordResetCommandRequest } from 'Core/Api/Api';
import { RouteComponentProps, NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import NotificationService from 'Core/NotificationService';
import { CenterAlignedRow } from 'components/Styled/Layout/CenterAlignedRow';
import { MainLogo } from '../../Layout/LoginLayout/LoginLayout.Style';
import Title from 'antd/lib/typography/Title';
import { BrandedButton } from '../../Styled/Branded/BrandedButton';

export interface Props extends FormComponentProps, RouteComponentProps {
  children?: React.ReactNode;
}

export interface State {}

export class RequestPasswordResetFormWithoutFormAndRouter extends React.Component<Props, State> {
  handler: PasswordResetHandler;

  constructor(props: Props) {
    super(props);

    this.state = {};
    this.handler = new PasswordResetHandler(props);
  }

  goBack = (e: SyntheticEvent) => {
    e.preventDefault();
    this.props.history.goBack();
  };

  onSumbit = (e: SyntheticEvent) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(
      (error, values: IRequestPasswordResetCommandRequest) => {
        if (error) return;

        this.handler.RequestResetPassword(values).then(() => {
          this.props.history.push('/login');
          NotificationService.success('Password reset password email has been sent.');
        });
      }
    );
  };

  render() {
    var { getFieldDecorator } = this.props.form;
      return (

      <Row>
              <Col>
                  <MainLogo />

                  <Title style={{ color: 'black' }} level={4}>
                      Forget Password
        </Title>
          <Form onSubmit={this.onSumbit}>
            <FormControl
              getFieldDecorator={getFieldDecorator}
              label="Email Address"
              name="emailAddress">
              <Input></Input>
            </FormControl>
            <FormItem>
              <Row>
                <Col>
                                  <BrandedButton htmlType="submit">Send Reset Password Email</BrandedButton>
                </Col>
              </Row>
              <CenterAlignedRow>
                <Col>
                  <Button type="link" onClick={this.goBack}>
                    Go Back
                  </Button>
                </Col>
              </CenterAlignedRow>
            </FormItem>
          </Form>
        </Col>
      </Row>
    );
  }
}

const RequestPasswordResetFormWithoutRouter = Form.create<Props>({ name: 'ForgetPassword' })(
  RequestPasswordResetFormWithoutFormAndRouter
);

const RequestPasswordResetForm = withRouter(RequestPasswordResetFormWithoutRouter);

export default RequestPasswordResetForm;










