import { SecuredClient } from 'Core/Api/SecuredClient';
import {
    ContactUsCommandRequest,
    GetAllUsersByFilterModel,
    GetProjectListByFilterForPublicRequest,
    OrderBy, SlimUser, User,
} from 'Core/Api/Api';
import Utility from 'Core/Utility';
import ITableDataFetcher from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataFetcher';
import TableRequest from 'Core/TableUtility/Models/TableRequest';
import ITableDataResponse from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataResponse';

export class CartHandler implements ITableDataFetcher<SlimUser> {

    props: any;
    constructor(props) {
        this.props = props;
    }

    GetData(tableRequest: TableRequest): Promise<ITableDataResponse<SlimUser>> {
        return this.GetOfferings();
    }


    async GetNumberOfCartItemsGuest() {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;

        return await client.getNumberOfCartItems();
    }

    GetNumberOfCartItems() {

    }

    GetOfferings() {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;

        const request = new GetAllUsersByFilterModel();
        request.amountPerPage = 500;
        request.orderBy = [];
        request.orderBy.push(new OrderBy({ field: 'Name', ascending: true }));

        return client.getAllUsersByFilter(request);
    }

    GetProjectsForPublic() {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;

        const request = new GetProjectListByFilterForPublicRequest();
        request.amountPerPage = 12;
        request.orderBy = [];
        request.orderBy.push(new OrderBy({ field: 'Name', ascending: true }));

        return client.getPublicProjectListByFilter(request);
    }

    GetProjectByIdForPublic(id: string) {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;

        const request = id;

        return client.getProjectByIdForPublic(request);
    }

    AddToCart(id?: string) {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;

        return client.addToCart(id, 0);
    }

    GetCart() {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;

        return client.getBasicCart();
    }

    //SendContactRequest(request: ContactUsCommandRequest) {
    //    const client = new SecuredClient();
    //    client.showLoading = false;
    //    client.handleGeneralError = true;

    //    return client.reachOut(request);
    //}
}










