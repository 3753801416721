import { SecuredClient } from 'Core/Api/SecuredClient';
import {
    ContactUsCommandRequest,
    GetAllUsersByFilterModel,
    OrderBy, SlimUser, User,
} from 'Core/Api/Api';
import Utility from 'Core/Utility';
import ITableDataFetcher from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataFetcher';
import TableRequest from 'Core/TableUtility/Models/TableRequest';
import ITableDataResponse from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataResponse';

export class ContactUsHandler implements ITableDataFetcher<SlimUser> {

    props: any;
    constructor(props) {
        this.props = props;
    }

    GetData(tableRequest: TableRequest): Promise<ITableDataResponse<SlimUser>> {
        return this.GetOfferings();
    }


    GetOfferings() {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;

        const request = new GetAllUsersByFilterModel();
        request.amountPerPage = 500;
        request.orderBy = [];
        request.orderBy.push(new OrderBy({ field: 'Name', ascending: true }));

        return client.getAllUsersByFilter(request);
    }

    SendContactRequest(request: ContactUsCommandRequest) {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;
       
        return client.reachOut(request);
    }
}










