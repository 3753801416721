import * as React from 'react';
import { Layout, Row } from 'antd';
import styled from 'styled-components';
import ScreenHelper from '../../Styled/ScreenHelper';
import logoSvg from '../../../assets/images/logo.png';

const { Content } = Layout;

export const CenteredContent = styled(Content)`
  height: 100%;
  min-height: 100vh;
  margin-bottom:170px;

  ${ScreenHelper.down('md')} {
    padding-top: 1vh;
  }

  ${ScreenHelper.up('md')} {
    padding-top: 15vh;
  }
`;


export const RegisterWithBackground = styled(Layout)`
    
    background-size: cover;
    background-position: right top;
    background-repeat: no-repeat;
`;

export const RegisterContent = styled.div`
  background-color: white;
  padding: 20px 40px 10px 40px;
`;

export const MainLogo = styled.div`
  height: 9em;
  /*width: 21em;*/

  margin-bottom: 30px;
  margin-left: auto;

  margin-right: auto;
  border: none;
  background: url(${logoSvg});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  ${ScreenHelper.down('sm')} {
    height: 8em;
    width: 10em;
  }
`;
















