import { Col, Button, Row, Form } from 'antd';
import FilterTextbox from 'components/Forms/DataGrid/FilterTextbox';
import TableFilterContextProvider from 'components/Forms/DataGrid/TableFilterContextProvider';
import InfoIcon from 'components/Forms/InfoIcon';
import { RightAlignedRow } from 'components/Styled/Layout/RightAlignedRow';
import { JoinType } from 'Core/Api/Api';
import React from 'react';
import { NewsListGrid } from './NewsListGrid';

interface Props {
}

export default ({ }: Props) => {
  return <TableFilterContextProvider>
    <Row>
      <Col md={24}>
        <Row type="flex" justify='center'>
          <Col span={12}>
            <Form.Item label="" labelAlign="left">
              <FilterTextbox
                filterFields="title,overview,description"
                filterJoin={JoinType.Or}
                placeholder={"Search title or keyword"}
                suffix={<InfoIcon text="Search for article" />}
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
    <Row>
          <Col xs={{ span: 22, offset: 1 }} md={{ span : 20, offset: 2 }}>
        <NewsListGrid />
      </Col>
    </Row>
  </TableFilterContextProvider>;
}