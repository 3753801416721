import { DatePicker } from 'antd';
import { DatePickerProps } from 'antd/lib/date-picker/interface';
import moment from 'moment';
import React from 'react';

interface Props extends DatePickerProps {
}

export default (props: Props) => {

  const formatedValue = props.value?.calendar ? props.value : props.value ? moment(props.value) : undefined;

  return <DatePicker {...props} value={formatedValue} />;
}