import * as React from 'react';
import { Col, Row } from 'antd';
import { RouteProps } from 'react-router-dom';
import ListingAndSelectionCommittee from 'components/ListingAndSelectionCommittee';
import { BannerDiv, CenteredFormContent } from 'components/Layout/LoginLayout/LoginLayout.Style';

import { Redirect } from 'react-router';
import Title from 'antd/lib/typography/Title';

export interface Props extends RouteProps {
    children?: React.ReactNode;
}

export interface State {
    isConfirmed: boolean;
}

export default class ListingAndSelectionCommitteePage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = { isConfirmed: false };
    }

    componentDidMount() {

    }

    render() {
        return <CenteredFormContent>
            <BannerDiv className={"bannerAlternative"}>
                <Row style={{ height: '100%' }}>
                    <Col sm={24} className={'bannerNav'}>
                        <div className='pageTitle'>
                            <Title>Selections And Listing Committee</Title>
                        </div>
                    </Col>
                </Row>
            </BannerDiv>
            <Row type="flex" align="middle" justify="center">
                <Col >
                    <ListingAndSelectionCommittee {...this.props} />
                </Col>
            </Row>
        </CenteredFormContent>;
    }
}


